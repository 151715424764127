import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProductState as ProductStateEnum, ProductState as ProductStateType } from '@constants';
import * as Sentry from '@sentry/nextjs';
import { useI18n } from '@hooks/i18n';
import { GET_CUSTOMER_MENU_INFO } from '@queries/me';
import { useApolloClient } from '@apollo/client';
import { useAuthentication } from '@hooks/authentication';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';
import { Divider, P } from '@atoms';
import { ThemedList } from '@molecules';
import PanelStyle from './productState.styled';
import {
  ApprovedFinance,
  Canceled,
  Contracted,
  DocumentsRejected,
  PendingDelivery,
  PendingProspect,
  PendingSales,
} from './components';

interface Props {
  leaseNumber: string;
  productState: ProductStateType;
  progress?: number;
  link?: string;
  buttonText?: string;
  hasDeliveryTime?: boolean;
  deliveryTime?: number | null | undefined;
}

const Footer = styled.footer`
  grid-area: 3/1/4/2;
`;

export const ProductState: React.FC<Props> = ({
  leaseNumber,
  productState,
  progress = 0,
  link = '',
  buttonText = '',
  hasDeliveryTime = false,
  deliveryTime,
}) => {
  const { t } = useI18n();
  const [customerInfo, setCustomerInfo] = useState<any>();
  const apolloClient = useApolloClient();
  const { accessToken } = useAuthentication();

  useEffect(() => {
    const getCustomerInfo = async () => {
      const { data } = await apolloClient.query({
        fetchPolicy: 'no-cache',
        query: GET_CUSTOMER_MENU_INFO,
      });
      setCustomerInfo(data?.me);
    };
    if (accessToken) {
      getCustomerInfo();
    }
  }, []);

  const panelContent = {
    summary: t('approved-quote-summary'),
    hiddenContent: t('approved-quote-summary-hidden'),
    title: t('levertijd'),
  };

  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[leaseNumber]?.isPreorder;

  const stepsList = t('instruction-steps').split('\\n');

  if (progress && progress >= 0 && progress < 100) {
    return (
      <Footer>
        <PendingProspect leaseNumber={leaseNumber} progress={progress} />
      </Footer>
    );
  }

  if (isPreorder && productState === ProductStateEnum.PendingFinance) {
    return (
      <Footer>
        <Divider />
        <P marginBottom="1.5rem">{ t('preorder-congratulation-text') }</P>
        <ThemedList ordered>
          { stepsList.map((step) => (
            <li key={t(step)} style={{ width: '100%' }}>
              {t(step)}
            </li>
          ))}
        </ThemedList>
      </Footer>
    );
  }

  switch (productState) {
    case ProductStateEnum.PendingProspect:
      return (
        <Footer>
          <PendingProspect leaseNumber={leaseNumber} progress={progress} />
        </Footer>
      );

    case ProductStateEnum.CreditCheckApproved:
    case ProductStateEnum.ReadyForCreditCheck:
    case ProductStateEnum.PendingSales:
    case ProductStateEnum.PendingFinance:
      return (
        <Footer>
          <PendingSales productState={productState} leaseNumber={leaseNumber} />
        </Footer>
      );

    case ProductStateEnum.ApprovedFinance:
      return (
        <Footer>
          <ApprovedFinance productState={productState} leaseNumber={leaseNumber} />
        </Footer>
      );

    case ProductStateEnum.ReadyForContract:
      if (hasDeliveryTime && deliveryTime && deliveryTime < 999) {
        return (
          <>
            { !customerInfo?.isDebtorChange
              && <PanelStyle content={panelContent} />}
          </>
        );
      }

      return (
        <Footer>
          <PendingDelivery productState={productState} />
        </Footer>
      );

    case ProductStateEnum.DocumentsRejected:
      return (
        <Footer>
          <DocumentsRejected productState={productState} leaseNumber={leaseNumber} />
        </Footer>
      );

    case ProductStateEnum.CreditCheckDenied:
    case ProductStateEnum.QuoteCanceled:
    case ProductStateEnum.Cancelled:
      return (
        <Footer>
          <Canceled productState={productState} />
        </Footer>
      );

    case ProductStateEnum.QuoteExpired:
      return (
        <Footer>
          <Canceled productState={productState} />
        </Footer>
      );

    case ProductStateEnum.Contracted:
    case ProductStateEnum.ContractSigned:
    case ProductStateEnum.ContractOrdered:
    case ProductStateEnum.ContractDelivered:
    case ProductStateEnum.ContractRegistered:
    case ProductStateEnum.ContractEnded:
    case ProductStateEnum.ContractCanceled:
      return (

        <Footer>
          <Contracted link={link} buttonText={buttonText} />
        </Footer>
      );

    default:
      Sentry.captureException(`Lease object ${leaseNumber} has an unknown state: ${productState}`);
      return null;
  }
};
