import styled from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';

export const Overlay = styled.div<StyledProps>`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.headerNavigation.overlay.zIndex};
`;
