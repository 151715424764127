import { createContext } from 'react';
import { MessageStackState } from './types';

export const initialState: MessageStackState = {
  messages: {},
  infoMessage: () => {},
  hideMessage: () => {},
};

export const MessageStackContext = createContext<MessageStackState>(initialState);
