import { gql } from '@apollo/client';

const PAGINATED_QUOTES = gql`
  query PaginatedQuotes($field: String, $direction: SortDirectionEnum) {
    paginatedQuotes(sort: { field: $field, direction: $direction }) {
      items {
        state
        prospectProgress
        quoteNumber
        cancelUri
        leaseItem {
          recurringCosts
          make
          model
          price
          color {
            id
            name
            imageUrl
          }
          package {
            id
            engine
            name
            deliveryTime
          }
        }
      }
    }
  }
`;

export default PAGINATED_QUOTES;
