import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CardActions, CardContent, CardHeader, LinkButton, ActionButton,
} from '@atoms';
import { FieldsWrapper } from '@atoms/form';
import { Card } from '@molecules';
import { InputRHFField, SelectRHFField } from '@molecules/form';
import { useI18n } from '@hooks/i18n';
import { getEnv } from '@utils';
import { ENTITIES } from '@constants';

interface Props {
  initialValues: {
    [key: string]: any;
  };
  onSubmit: (...args: Array<any>) => any;
  updating: boolean;
}

export const EditProfileForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  updating = false,
}) => {
  const { t } = useI18n();
  const methods = useForm({ defaultValues: initialValues });
  const [enableCompanyFields, setEnableCompanyFields] = useState(false);
  const {
    register, errors, handleSubmit,
  } = methods;
  const provinceList = t('provincelist')/* Comma seperated list with the provinces */;
  const provinces: string[] = provinceList.replace(', ', ',').split(',');

  const isB2B = getEnv('NEXT_PUBLIC_MYENV_ENVIRONMENT') === ENTITIES.JLBL;

  useEffect(() => {
    if (initialValues?.hasSignedContract && isB2B) {
      setEnableCompanyFields(true);
    }
  }, [initialValues]);

  return (
    <FormProvider
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...methods}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          renderHeader={() => <CardHeader>{t('my-profile-page-edit-data-title')}</CardHeader>}
          renderFooter={() => (
            <CardActions>
              <LinkButton outline to="/profile">
                {t('cancel')}
              </LinkButton>
              <ActionButton type="submit" disabled={updating} loading={updating}>
                {t('submit')}
              </ActionButton>
            </CardActions>
          )}
        >
          <CardContent>
            <input ref={register} name="prospectNumber" type="hidden" defaultValue={initialValues.prospectNumber} />
            <input ref={register} name="houseNumberAddition" type="hidden" defaultValue={initialValues.houseNumberAddition || ' '} />
            <FieldsWrapper
              columns="3fr 1fr"
              areas={{
                default: '"a a" "b b"',
                sm: '"a b"',
              }}
            >
              <InputRHFField
                name="address"
                label={t('street')}
                placeholder={t('street-placeholder')}
                fieldRef={register({
                  required: t('form-required'),
                })}
                defaultValue={initialValues.address}
                error={errors.street}
              />
              <InputRHFField
                name="houseNumber"
                label={t('house-number')}
                placeholder={t('house-number-placeholder')}
                fieldRef={register({
                  required: t('form-required'),
                })}
                defaultValue={initialValues.houseNumber}
                error={errors.houseNumber}
              />
            </FieldsWrapper>
            <FieldsWrapper
              columns="1fr 2fr"
              areas={{
                default: '"a a" "b b"',
                sm: '"a b"',
              }}
            >
              <InputRHFField
                name="zipCode"
                label={t('zip-code')}
                placeholder={t('zip-code-placeholder')}
                fieldRef={register({
                  required: t('form-required'),
                })}
                defaultValue={initialValues.zipCode}
                error={errors.zipCode}
              />
              <InputRHFField
                name="city"
                label={t('city')}
                placeholder={t('city-placeholder')}
                fieldRef={register({
                  required: t('form-required'),
                })}
                defaultValue={initialValues.city}
                error={errors.city}
              />
            </FieldsWrapper>
            <SelectRHFField
              name="province"
              label={t('province')}
              fieldRef={register({
                required: t('form-required'),
              })}
              error={errors.province}
              defaultValue={initialValues.province}
            >
              <option value="">{t('select')}</option>
              {provinces.map((prov) => <option value={prov} key={prov}>{prov}</option>)}
            </SelectRHFField>
            <FieldsWrapper
              columns="1fr 1fr"
              areas={{
                default: '"a a" "b b"',
                sm: '"a b"',
              }}
            >
              <InputRHFField
                name="phone"
                label={t('phone')}
                placeholder={t('phone-placeholder')}
                fieldRef={register({
                  required: t('form-required'),
                })}
                defaultValue={initialValues.phone}
                error={errors.phone}
              />
              <InputRHFField name="phone2" label={t('phone-number-2')} placeholder={t('phone-number-2-placeholder')} fieldRef={register} defaultValue={initialValues.phone2} />
            </FieldsWrapper>
            {isB2B
              && (
              <FieldsWrapper
                columns="1fr 1fr"
                areas={{
                  default: '"a a" "b b"',
                  sm: '"a b"',
                }}
              >
                <InputRHFField
                  name="cocNumber"
                  label={t('coc-number-label')}
                  placeholder={t('coc-number-placeholder')}
                  fieldRef={register({
                    required: t('form-required'),
                  })}
                  defaultValue={initialValues.cocNumber}
                  error={errors.cocNumber}
                  disabled={enableCompanyFields}
                />
                <InputRHFField
                  name="companyName"
                  label={t('company-name-label')}
                  placeholder={t('company-number-placeholder')}
                  fieldRef={register({
                    required: t('form-required'),
                  })}
                  defaultValue={initialValues.companyName}
                  error={errors.companyName}
                  disabled={enableCompanyFields}
                />
              </FieldsWrapper>
              )}

          </CardContent>
        </Card>
      </form>
    </FormProvider>
  );
};
