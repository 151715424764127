import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as StyledProps, HeadingLevel } from '@type';
import SvgIcon from '../../svgIcon';
import Heading from '../../heading';

interface StyledComponentProps extends StyledProps {
  action?: any;
}

const Header = styled.header<StyledComponentProps>`
  display: flex;
  color: ${({ theme }) => theme.title.color};
  padding: 1rem;

  ${({ action }) => action && css`
    justify-content: space-between;
  `};

  ${media.sm(css`
    padding: 1.5rem;
  `)};

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }

  div {
    &.icon-left {
      margin-right: 1.5rem;
    }

    &.icon-right {
      margin-left: 1.5rem;
    }
  }
`;

interface Props {
  action?: any;
  rightIcon?: string;
  leftIcon?: string;
  iconSize?: string;
  level?: HeadingLevel;
}

export const CardHeader: React.FC<Props> = ({
  action,
  children,
  level = HeadingLevel.H2,
  leftIcon,
  rightIcon,
  iconSize = '2rem',
}) => (
  <Header action={action}>
    {leftIcon && (
      <SvgIcon className="icon-left" icon={leftIcon} width={iconSize} height={iconSize} />
    )}
    <Heading level={level}>{children}</Heading>
    {rightIcon && (
      <SvgIcon className="icon-right" icon={rightIcon} width={iconSize} height={iconSize} />
    )}
    {action && action}
  </Header>
);
