import React from 'react';
import { ProductState } from '@constants';
import { Divider } from '@atoms';
import { Documents } from './documents';

interface Props {
  productState: ProductState;
}

const Canceled: React.FC<Props> = ({
  productState,
}) => (
  <>
    <Divider />
    <Documents productState={productState} />
  </>
);

export default Canceled;
