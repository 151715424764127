import { GetTranslation } from '@hooks/i18n/types';

export const uploadTranslations = (t: GetTranslation) => (
  category: string,
  label: string,
  defaultValue: string,
): string => {
  const key = `${category.toLowerCase().replace(/[\s-]/g, '_')}_${label.replace('-', '_')}`;
  const types: Record<string, string> = {
    // Category General
    general_achterkant_id_kaart: t('upload-general-achterkant-id-kaart', { defaultValue, type: 'html' }),
    general_achterkant_rijbewijs: t('upload-general-achterkant-rijbewijs', { defaultValue, type: 'html' }),
    general_afleverdocumenten: t('upload-general-afleverdocumenten', { defaultValue, type: 'html' }),
    general_algemene_voorwaarden: t('upload-general-algemene-voorwaarden', { defaultValue, type: 'html' }),
    general_algemene_voorwaarden_keurmerk: t('upload-general-algemene-voorwaarden-keurmerk', { defaultValue, type: 'html' }),
    general_bankafschrift: t('upload-general-bankafschrift', { defaultValue, type: 'html' }),
    general_bestelling: t('upload-general-bestelling', { defaultValue, type: 'html' }),
    general_collegekaart: t('upload-general-collegekaart', { defaultValue, type: 'html' }),
    general_contracts_ondersteuning: t('upload-general-contracts-ondersteuning', { defaultValue, type: 'html' }),
    general_doorlopende_machtiging: t('upload-general-doorlopende-machtiging', { defaultValue, type: 'html' }),
    general_inkomensverklaring_belastingdienst: t('upload-general-inkomensverklaring-belastingdienst', { defaultValue, type: 'html' }),
    general_loonstrook: t('upload-general-loonstrook', { defaultValue, type: 'html' }),
    general_ondertekend_leasecontract: t('upload-general-ondertekend-leasecontract', { defaultValue, type: 'html' }),
    general_paspoort_of_id_kaart: t('upload-general-paspoort-of-id-kaart', { defaultValue, type: 'html' }),
    general_pensioenspecificatie: t('upload-general-pensioenspecificatie', { defaultValue, type: 'html' }),
    general_ott_machtiging: t('upload-general-ott-machtiging', { defaultValue, type: 'html' }),
    general_privé_upload: t('upload-general-privé-upload', { defaultValue, type: 'html' }),
    general_recente_kopie_bankafschrift: t('upload-general-recente-kopie-bankafschrift', { defaultValue, type: 'html' }),
    general_schadevrij_verklaring: t('upload-general-schadevrij-verklaring', { defaultValue, type: 'html' }),
    general_specificatie_aow: t('upload-general-specificatie-aow', { defaultValue, type: 'html' }),
    general_studenten_ov: t('upload-general-studenten-ov', { defaultValue, type: 'html' }),
    general_uittreksel_kamer_van_koophandel: t('upload-general-uittreksel-kamer-van-koophandel', { defaultValue, type: 'html' }),
    general_var_verklaring: t('upload-general-var-verklaring', { defaultValue, type: 'html' }),
    general_voorkant_rijbewijs: t('upload-general-voorkant-rijbewijs', { defaultValue, type: 'html' }),
    general_voorloop_contract: t('upload-general-voorloop-contract', { defaultValue, type: 'html' }),
    general_werkgeversverklaring: t('upload-general-werkgeversverklaring', { defaultValue, type: 'html' }),
    general_duo_studieschuld_overzicht: t('upload-duo-student-loan', { defaultValue, type: 'html' }),

    // Category Co Signee
    co_signee_achterkant_id_kaart: t('upload-cosignee-achterkant-id-kaart', { defaultValue, type: 'html' }),
    co_signee_achterkant_rijbewijs: t('upload-cosignee-achterkant-rijbewijs', { defaultValue, type: 'html' }),
    co_signee_afleverdocumenten: t('upload-cosignee-afleverdocumenten', { defaultValue, type: 'html' }),
    co_signee_algemene_voorwaarden: t('upload-cosignee-algemene-voorwaarden', { defaultValue, type: 'html' }),
    co_signee_algemene_voorwaarden_keurmerk: t('upload-cosignee-algemene-voorwaarden-keurmerk', { defaultValue, type: 'html' }),
    co_signee_bankafschrift: t('upload-cosignee-bankafschrift', { defaultValue, type: 'html' }),
    co_signee_bestelling: t('upload-cosignee-bestelling', { defaultValue, type: 'html' }),
    co_signee_collegekaart: t('upload-cosignee-collegekaart', { defaultValue, type: 'html' }),
    co_signee_contracts_ondersteuning: t('upload-cosignee-contracts-ondersteuning', { defaultValue, type: 'html' }),
    co_signee_doorlopende_machtiging: t('upload-cosignee-doorlopende-machtiging', { defaultValue, type: 'html' }),
    co_signee_inkomensverklaring_belastingdienst: t('upload-cosignee-inkomensverklaring-belastingdienst', { defaultValue, type: 'html' }),
    co_signee_loonstrook: t('upload-cosignee-loonstrook', { defaultValue, type: 'html' }),
    co_signee_ondertekend_leasecontract: t('upload-cosignee-ondertekend-leasecontract', { defaultValue, type: 'html' }),
    co_signee_paspoort_of_id_kaart: t('upload-cosignee-paspoort-of-id-kaart', { defaultValue, type: 'html' }),
    co_signee_pensioenspecificatie: t('upload-cosignee-pensioenspecificatie', { defaultValue, type: 'html' }),
    co_signee_ott_machtiging: t('upload-cosignee-ott-machtiging', { defaultValue, type: 'html' }),
    co_signee_privé_upload: t('upload-cosignee-privé-upload', { defaultValue, type: 'html' }),
    co_signee_recente_kopie_bankafschrift: t('upload-cosignee-recente-kopie-bankafschrift', { defaultValue, type: 'html' }),
    co_signee_schadevrij_verklaring: t('upload-cosignee-schadevrij-verklaring', { defaultValue, type: 'html' }),
    co_signee_specificatie_aow: t('upload-cosignee-specificatie-aow', { defaultValue, type: 'html' }),
    co_signee_studenten_ov: t('upload-cosignee-studenten-ov', { defaultValue, type: 'html' }),
    co_signee_uittreksel_kamer_van_koophandel: t('upload-cosignee-uittreksel-kamer-van-koophandel', { defaultValue, type: 'html' }),
    co_signee_var_verklaring: t('upload-cosignee-var-verklaring', { defaultValue, type: 'html' }),
    co_signee_voorkant_rijbewijs: t('upload-cosignee-voorkant-rijbewijs', { defaultValue, type: 'html' }),
    co_signee_voorloop_contract: t('upload-cosignee-voorloop-contract', { defaultValue, type: 'html' }),
    co_signee_werkgeversverklaring: t('upload-cosignee-werkgeversverklaring', { defaultValue, type: 'html' }),
  };

  return types[key] || key;
};
