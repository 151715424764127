import React, { useState } from 'react';
import {
  FormFieldContainer, InputPrefix, Label, FieldError,
} from '@atoms/form';
import Input from './components';

interface Props {
  label: string;
  name: string;
  type?: string;
  disabled?: boolean;
  placeholder: string;
  min?: string;
  max?: string;
  prefix?: string | null | undefined;
  defaultValue?: string;
  fieldRef: any;
  onKeyDown?: (evt: any) => void;
  onBlur?: (evt: any) => void;
  error?: any;
}

export const InputRHFField: React.FC<Props> = ({
  placeholder,
  label,
  defaultValue = '',
  type = 'text',
  disabled = false,
  prefix,
  onKeyDown = () => {},
  onBlur = () => {},
  name,
  min,
  max,
  fieldRef,
  error,
}): JSX.Element => {
  const [touched, setTouched] = useState(false);
  const [pristine, setPristine] = useState(true);

  const handleOnBlur = (event: { target: HTMLInputElement; }) => {
    if (event.target.value !== '') {
      onBlur(event);
      setTouched(false);
      setPristine(false);
      return;
    }
    setTouched(true);
    setPristine(true);
  };

  return (
    <FormFieldContainer>
      <Input
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        type={type}
        name={name}
        id={name}
        min={min}
        max={max}
        hasPrefix={!!prefix}
        onKeyDown={onKeyDown}
        onBlur={handleOnBlur}
        ref={fieldRef}
        error={Boolean(error)}
        touched={touched}
        pristine={pristine}
        data-testid={name}
      />
      <Label htmlFor={name}>{label}</Label>
      {prefix && <InputPrefix className="input-prefix">{prefix}</InputPrefix>}
      {error?.message && (
      <>
        <FieldError message={error.message} />
      </>
      )}
    </FormFieldContainer>
  );
};
