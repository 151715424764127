import styled from 'styled-components';
import React from 'react';

type IllustrationProps = {
  large: boolean;
};

const Illustration = styled.img<IllustrationProps>`
  max-width: ${({ large }) => (large ? '25rem' : '7.5rem')};
  width: 100%;
`;

const Header = styled.header`
  margin-top: 1.5rem;
  padding: 0 0 2rem;
  text-align: center;
  width: 100%;
`;

interface Props {
  src: string;
  alt: string;
  large?: boolean;
}

export const CardImageHeader: React.FC<Props> = ({
  src,
  alt,
  large = false,
}) => (
  <Header>
    <Illustration alt={alt} src={src} large={large} />
  </Header>
);
