const iconMap: Record<string, string> = {
  pending_sales: 'circleClock',
  pending_finance: 'circleClock',
  documents_rejected: 'circleCross',
  quote_canceled: 'circleCross',
  cancelled: 'circleCross',
  quote_expired: 'circleCross',
  ready_for_contract: 'circleCheckmark',
  contract_signed: 'circleCheckmark',
  contract_ordered: 'circleCheckmark',
  contract_registered: 'circleCheckmark',
  credit_check_approved: 'circleCheckmark',
  credit_check_denied: 'circleCross',
  Signed: 'circleClock',
  Approved: 'circleCheckmark',
  Driving: 'circleCheckmark',
};

export const iconMapper = (key: string): string | null | undefined => iconMap[key] || null;

export default null;
