import React from 'react';
import Link from 'next/link';
import { StyledLink } from './link.styled';

interface Props {
  href?: string;
  target?: string;
  onClick?: (evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const SelectedLink: React.FC<Props> = ({
  children,
  href = '',
  ...rest
}) => {
  const as = /^(https|mailto|tel)?:(\/\/)?/.test(href || '') ? undefined : href;
  return (
    <Link href={href} as={as} passHref>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StyledLink {...rest}>{children}</StyledLink>
    </Link>
  );
};
export default SelectedLink;
