import React from 'react';
import styled from 'styled-components';
import { Box, CheckIcon, CheckInput } from '@atoms';

interface Props {
  className?: string;
  disabled?: boolean;
  onChange: (...args: Array<any>) => any;
  name: string;
  id?: string;
  checked: boolean;
  error?: boolean;
}

const CheckBox: React.FC<Props> = ({
  checked,
  className = '',
  disabled = false,
  error = false,
  id = '',
  name,
  onChange,
}) => (
  <div className={className}>
    <CheckInput
      disabled={disabled}
      type="checkbox"
      checked={checked}
      onChange={(e) => !disabled && onChange && onChange(e.target.checked)}
      name={name}
      id={id || name}
    />
    <Box checked={checked} disabled={disabled} error={error}>
      <CheckIcon icon="check" checked={checked} height="0.65em" error={error} />
    </Box>
  </div>
);

export default styled(CheckBox)`
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  margin-top: 0.1rem;
  margin-right: 0.5rem;
`;
