import { QuoteProgressRedirectStatus } from '@constants/quoteProgress';
import { CATCH_REDIRECT_BACK } from './constants';
import { Action, CatchRedirectBack } from './types';

const catchRedirectBack = (
  quoteNumber: string,
  redirectType: Action,
  redirectStatus: QuoteProgressRedirectStatus,
): CatchRedirectBack => ({
  type: CATCH_REDIRECT_BACK,
  quoteNumber,
  redirectType,
  redirectStatus,
});
export default catchRedirectBack;
