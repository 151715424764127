import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as StyledProps } from '@type';

export const Header = styled.header<StyledProps>`
  align-items: center;
  background-color: ${({ theme }) => theme.header.backgroundColor};
  display: flex;
  height: ${({ theme }) => theme.header.height};
  justify-content: space-between;
  padding: 0 1rem;
  position: fixed;
  width: calc(100% - 2rem);
  z-index: 2;
  ${media.sm(css`
    position: relative;
  `)}
`;
