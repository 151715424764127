import React from 'react';
import {
  ModalContainer, ModalContent, ModalHeader, ModalFooter,
} from '@atoms/modal';
import { MODAL_SIZES } from '@constants';
import { ModalSizes, TextAlign } from '@type';

interface Props {
  align?: TextAlign;
  closeOnOverlayClick?: boolean;
  iconComponent?: JSX.Element;
  isOpen: boolean;
  onClose?: (...args: Array<any>) => any;
  onConfirm?: (...args: Array<any>) => any;
  title?: string;
  size?: ModalSizes;
  hasPassedValidation?: boolean;
}

export const Modal: React.FC<Props> = ({
  align = TextAlign.Left,
  children,
  closeOnOverlayClick = false,
  iconComponent,
  isOpen,
  onClose,
  onConfirm,
  title,
  size = MODAL_SIZES.medium,
  hasPassedValidation,
}) => (
  <ModalContainer
    align={align}
    closeOnOverlayClick={closeOnOverlayClick}
    isOpen={isOpen}
    onClose={onClose}
    size={size}
  >
    <ModalHeader
      iconComponent={iconComponent}
      title={title}
    />
    <ModalContent>
      {children}
    </ModalContent>
    {onClose
    && (
    <ModalFooter
      onClose={onClose}
      onConfirm={onConfirm}
      hasPassedValidation={hasPassedValidation}
    />
    )}
  </ModalContainer>
);
