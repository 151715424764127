import React, { ReactElement } from 'react';
import { QuoteGridItem } from '@organisms/product/quote';
import { ProductGrid } from '@organisms/product/grid';
import { useI18n } from '@hooks/i18n';
import { Quote } from '@queries/quote/quoteList';
import { Panel } from '@atoms';
import { ProductState as ProductStateEnum } from '@constants';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';
import { QuoteFlows } from '@constants/quoteProgress';

interface Props {
  quotes: Array<Quote | null>;
  isDebtorChange: boolean | undefined;
}

const QuoteGrid: React.FC<Props> = ({
  quotes,
  isDebtorChange,
}) => {
  const { t } = useI18n();
  const content = {
    summary: t('quote-summary'),
    hiddenContent: t('quote-summary-hidden'),
  };

  const labelPreorderStatus = (quote:Quote) => {
    if (quote.state === ProductStateEnum.PendingSales) {
      return t('label-status-preorder-pending-sales');
    } if (quote.state === ProductStateEnum.PendingFinance) {
      return t('label-status-preorder-pending-finance');
    }
    return t('label-status-preorder');
  };

  const labelText = (quote:Quote) => (quote.preorder ? labelPreorderStatus(quote) : t('label-status'));

  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quotes[0]?.quoteNumber as string]?.isPreorder;

  const subtitle = quotes[0]?.quoteFlow !== QuoteFlows.Jlbe && quotes[0]?.state && !isPreorder
  && quotes[0]?.leaseItem?.package?.deliveryTime
    ? t('waiting-time-before-approval', { replace: { '%waitingTime%': `${quotes[0]?.leaseItem?.package?.deliveryTime}` } })
    : undefined;

  return (
    <ProductGrid className="quote-grid">
      {quotes && quotes?.reduce<ReactElement[]>((acc, quote) => {
        if (quote) {
          return [...acc, (
            <QuoteGridItem
              key={quote?.quoteNumber}
              label={labelText(quote)}
              imageUrl={quote?.leaseItem?.color?.imageUrl}
              title={`${quote?.leaseItem?.make} ${quote?.leaseItem?.model}`}
              costLabel={t('month-price')}
              costs={quote?.leaseItem?.recurringCosts}
              deliveryTime={quote?.leaseItem?.package?.deliveryTime}
              quoteNumber={quote?.quoteNumber}
              quoteState={quote?.state}
              progress={quote?.prospectProgress}
              subTitle={subtitle}
            >
              { quotes[0]?.quoteFlow !== QuoteFlows.Jlbe && !isDebtorChange
                && !quote.connectedToStock
                && !(quote.preorder && quote.state === ProductStateEnum.PendingFinance)
                && <Panel content={content} />}
            </QuoteGridItem>
          )];
        }
        return acc;
      }, [])}
    </ProductGrid>
  );
};

export default QuoteGrid;
