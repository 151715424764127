import styled, { css } from 'styled-components';
import media from '@utils/media';

export const ProductGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  li {
    margin-bottom: 1.5rem;
    margin-right: 0;
    width: 100%;
  }

  ${media.sm(css`
    li:nth-child(odd) {
      margin-right: 1.5rem;
      width: calc(50% - .75rem)
    }

    li:nth-child(even) {
      width: calc(50% - .75rem)
    }
  `)}

  ${media.md(css`
    li {
      margin-bottom: 2rem;
    }
    li:nth-child(odd) {
      margin-right: 0;
      width: 100%;
    }

    li:nth-child(even) {
      margin-right: 0;
      width: 100%;
    }
  `)}

  ${media.lg(css`
    li:nth-child(odd) {
      margin-right: 2rem;
      width: calc(50% - 1rem)
    }

    li:nth-child(even) {
      margin-right: 0;
      width: calc(50% - 1rem)
    }
  `)}
`;
