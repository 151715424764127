import React, {
  useReducer, createContext, useContext,
} from 'react';

interface PaginationModel {
  numberOfResults: number;
  numberOfPages: number;
  currentPage: number;
  first: number | null | undefined;
  last: number | null | undefined;
  after: number | null | undefined;
  before: number | null | undefined;
  dataSource: {
    edges: Array<any>;
    totalCount: number;
    pageInfo: {
      endCursor: string;
      hasNextPage: boolean;
      startCursor: string;
      hasPreviousPage: boolean;
    };
  };
}

const defaultState = {
  numberOfResults: 10,
  numberOfPages: 0,
  currentPage: 0,
  first: null,
  last: 10,
  after: null,
  before: null,
  dataSource: {
    edges: [],
    totalCount: 0,
    pageInfo: {
      endCursor: '',
      hasNextPage: false,
      startCursor: '',
      hasPreviousPage: false,
    },
  },
};

type Context = {
  state: Record<string, PaginationModel>;
  dispatch: (...args: Array<any>) => any;
};

interface Props {
  initialState: Record<string, PaginationModel>
}

const PaginationContext = createContext<Context>({
  state: { default: defaultState },
  dispatch: () => {},
});

const reducer = (state: Record<string, PaginationModel>, action: any) => {
  const { key, payload } = action;

  switch (action.type) {
    case 'SET_DATASOURCE':
    {
      const numberOfPages = Math.ceil((payload?.totalCount || 1) / state[key].numberOfResults);
      return {
        ...state,
        [key]: {
          ...state[key],
          numberOfPages,
          currentPage: state[key].currentPage ? state[key].currentPage : numberOfPages,
          dataSource: payload,
        },
      };
    }
    case 'NEXT':
    {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...payload,
          currentPage: ((state[key].currentPage + 1) > state[key].numberOfPages)
            ? state[key].numberOfPages
            : state[key].currentPage + 1,
        },
      };
    }
    case 'PREV':
      return {
        ...state,
        [key]: {
          ...state[key],
          ...payload,
          currentPage: (state[key].currentPage - 1) < 1 ? 1 : state[key].currentPage - 1,
        },
      };
    case 'LAST':
    {
      return {
        ...state,
        [key]: {
          ...state[key],
          ...payload,
          currentPage: state[key].numberOfPages,
        },
      };
    }
    case 'FIRST':
      return {
        ...state,
        [key]: {
          ...state[key],
          ...payload,
          currentPage: 1,
        },
      };
    default:
      return state;
  }
};

const useCursorPagination = () => useContext(PaginationContext);

const PaginationProvider: React.FC<Props> = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PaginationContext.Provider value={{
      state,
      dispatch,
    }}
    >
      {children}
    </PaginationContext.Provider>
  );
};

export { PaginationProvider, useCursorPagination };
