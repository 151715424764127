'no babel-plugin-flow-react-proptypes';

interface Arguments {
  message: string;
  url?: string;
  status: number;
  body?: any;
  headers?: any;
}

class FetchError extends Error {
  meta: Record<string, any>;

  status: number;

  constructor({
    message = '',
    status,
    ...rest
  }: Arguments) {
    super(message);

    this.name = 'FetchError';
    this.status = status;
    this.meta = rest;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

export default FetchError;
