import { gql } from '@apollo/client';

const QUOTE_DOCUMENTS = gql`
  query QuoteDocuments($quoteNumber: String!) {
    quotes(number: $quoteNumber) {
      id
      depositAmount
      documentRequests {
        id
        category
        title
        label
        description
        isRejected
      }
      leaseItem {
        id
        make
        model
        fuelType
        transmission
        color {
          id
          name
          imageUrl
        }
      }
    }
  }
`;

export interface QuoteDocumentsArgs {
  quoteNumber: string;
}

export interface QuoteDocumentsResult {
  quotes: Array<{
    id: string;
    depositAmount: number;
    documentRequests: Array<{
      id: string;
      title: string;
      label: string;
      description?: string;
      isRejected: boolean;
    }>;
    leaseItem: {
      id: string;
      make: string;
      model: string;
      fuelType: string;
      transmission: string;
      color: {
        name: string
        imageUrl: string;
      };
    };
  }>;
}

export default QUOTE_DOCUMENTS;
