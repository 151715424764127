import styled, { css } from 'styled-components';
import { media } from '@utils';

interface Props {
  columns: string;
  areas: Record<string, string>;
}

export const FieldsWrapper = styled.section<Props>`
  display: grid;
  grid-gap: 0 1rem;
  gap: 0 1rem;
  grid-template-columns: ${({ columns }) => columns};
  grid-template-areas: ${({ areas }) => areas.default};

  & > *:nth-child(1) { grid-area: a; }
  & > *:nth-child(2) { grid-area: b; }
  & > *:nth-child(3) { grid-area: c; }
  & > *:nth-child(4) { grid-area: d; }
  & > *:nth-child(5) { grid-area: e; }

  ${({ areas }) => areas.sm && media.sm(css`
    grid-template-areas: ${areas.sm};
  `)}
  ${({ areas }) => areas.md && media.md(css`
    grid-template-areas: ${areas.md};
  `)}
  ${({ areas }) => areas.lg && media.lg(css`
    grid-template-areas: ${areas.lg};
  `)}
  ${({ areas }) => areas.xl && media.xl(css`
    grid-template-areas: ${areas.xl};
  `)}
`;
