import React from 'react';
import styled, { css } from 'styled-components';
import {
  Divider, Key, KeyValue, Link, LinkButton, Value,
} from '@atoms';
import { media } from '@utils';
import { StyledComponentProps as StyledProps } from '@type';
import { useI18n } from '@hooks/i18n';

interface Props {
  label: string;
  value: (string | null | undefined) | (Array<string> | null | undefined);
  edit?: string | null;
  placeholder?: string;
  actionText?: string;
  extend?: boolean;
  note?: string | null;
  helper?: string;
}

const LinkContainer = styled.div`
  padding-left: 0.5rem;
  text-align: right;
  font-size: 1rem;
  grid-area: 1 / 3 / 2 / 4;
`;

const Note = styled.section<StyledProps>`
  grid-area: 3 / 1 / 4 / 4;
  color: ${({ theme }) => theme.keyValue.value.color};
  font-style: italic;
  ${media.md(css`
    grid-area: 2 / 2 / 3 / 4;
    padding-left: 0.5rem;
  `)}
`;

const KeyValueContainer = styled(KeyValue)`
  :not(.is-helper) {
    li:last-of-type {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
  
      & a {
        width: 100%;
      }
    }
  }
`;

const ContractLine: React.FC<Props> = ({
  label,
  value,
  edit = null,
  extend = false,
  placeholder = '',
  actionText = '',
  note = null,
  helper = null,
}) => {
  const { t } = useI18n();

  return (
    <>
      {!extend && (
      <>
        <KeyValue hasNote={Boolean(note)}>
          <Key>
            {label}
          </Key>
          <Value>
            {value || null}
          </Value>
          {edit && (
          <LinkContainer>
            <Link href={edit}>
              {t('edit-quote')}
            </Link>
          </LinkContainer>
          )}
          {note && <Note>{note}</Note>}
        </KeyValue>
        <Divider margin="0" />
      </>
      )}
      {edit && extend && (
      <>
        <KeyValueContainer className={helper ? 'is-helper' : ''}>
          <Key>
            {label}
          </Key>
          <Value listStyle>
            {placeholder}
            <LinkButton
              outline
              to={edit}
            >
              {actionText || `Select a ${label}`}
            </LinkButton>
            {helper}
          </Value>
        </KeyValueContainer>
        <Divider margin="0" />
      </>
      )}
    </>
  );
};

export default ContractLine;
