import styled from 'styled-components';
import { Heading } from '@atoms';
import { HeadingLevel, StyledComponentProps as Props } from '@type';
import { PreDeliveryState } from '@constants';

export default styled.section`
  align-items: middle;
  display: flex;

  & + a {
    margin-top: 1rem;
  }
`;

interface TitleProps extends Props {
  preDeliveryState: PreDeliveryState;
}

export const Title = styled(Heading).attrs({
  level: HeadingLevel.H5,
})<TitleProps>`
  color: ${({ theme, preDeliveryState }) => theme.quote.documents.title.color[preDeliveryState]};
  flex-grow: 1;
  margin-left: 1rem;
  align-self: center;
`;

export const Icon = styled.div<TitleProps>`
  color: ${({ theme, preDeliveryState }) => theme.quote.documents.title.color[preDeliveryState]};
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
`;

export const DocumentDescription = styled.section`
  font-size: 0.875rem;

  & + a {
    margin-top: 1rem;
  }
`;
