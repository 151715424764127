import React, { useReducer, useEffect } from 'react';
import { I18nContext, initialState } from './context';
import {
  I18nContextState, I18nAction, Translations, Languages,
  TranslationOptions, Language,
} from './types';

interface Props {
  languages: Languages;
}

const getTranslate = (translations: Translations) => (
  key: string,
  options?: TranslationOptions,
) => {
  let translation = translations[key] || key;

  if (translation === key && options?.defaultValue) {
    translation = options?.defaultValue || '';
  }
  if (options?.replace) {
    translation = translation.replace(/(%\w+%)/g, (match, k) => (options?.replace ? options.replace[k] : ''));
  }
  return translation;
};

export const I18nContextProvider: React.FC<Props> = ({
  languages,
  children,
}) => {
  const reducer = (state: I18nContextState, action: I18nAction) => {
    switch (action.type) {
      case 'initialise':
      {
        const translations = action.payload.languages.reduce(
          (acc: Translations, language: Language) => ({
            ...acc,
            [language.langCode]: language.translations,
          }), {} as Translations,
        );

        const langs = action.payload.languages.map((lang: Language) => ({
          langCode: lang.langCode,
          subTag: lang.subTag,
        }));

        return {
          ...state,
          langCode: action.payload.activeLanguage,
          t: getTranslate(translations[action.payload.activeLanguage]),
          translations,
          languages: langs,
        };
      }

      case 'setLanguage':
        return {
          ...state,
          langCode: action.payload,
          t: getTranslate(state.translations[action.payload]),
        };

      default:
        return { ...initialState };
    }
  };

  /* useReducer hook receives a reducer and an initialState to
  return the current state object with a dispatch method to
  dispatch actions. */
  const [state, dispatch] = useReducer(reducer, initialState);

  const setLanguage = (code: string = state.langCode) => dispatch({
    type: 'setLanguage',
    payload: code,
  });

  /* Store the initLang in the context */
  useEffect(() => {
    async function initialise() {
      await dispatch({
        type: 'initialise',
        payload: languages,
      });
    }
    initialise();
  }, [languages]);

  const dateFormat = (date: Date = new Date(), formattingOptions?: Intl.DateTimeFormatOptions) => {
    const dateFormatter = new Intl.DateTimeFormat(state.langCode, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      ...formattingOptions,
    });

    return dateFormatter.format(date);
  };

  const timeFormat = (date: Date = new Date(), formattingOptions?: Intl.DateTimeFormatOptions) => {
    const dateFormatter = new Intl.DateTimeFormat(state.langCode, {
      hour: 'numeric',
      minute: 'numeric',
      ...formattingOptions,
    });

    return dateFormatter.format(date);
  };

  const getMonths = () => Array(12).fill('').map((_, index) => {
    const date = new Date(2020, index, 1);
    return date.toLocaleString(state.langCode || 'default', { month: 'long' });
  });

  /* We're Providing state object (langCode and translate method
  in this case) and also the dispatch for the children components */
  return (
    <I18nContext.Provider value={{
      ...state,
      setLanguage,
      dateFormat,
      timeFormat,
      getMonths,
    }}
    >
      {children}
    </I18nContext.Provider>
  );
};
