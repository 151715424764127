import styled, { css } from 'styled-components';
import { StyledComponentProps as StyleProps } from '@type';
import { activeElementStyle } from '@atoms/form/sharedStyles';

interface OwnProps extends StyleProps {
  error: boolean;
  touched: boolean;
  disabled?: boolean;
}

export const OptionLabel = styled.label<OwnProps>`
  background-color: ${({ theme }) => theme.form.radioGroup.backgroundColor};
  border-color: ${({ theme, error, touched }) => (error && touched ? 'red' : theme.form.radioGroup.borderColor)};
  border-style: solid;
  border-width: 1px 0 1px 1px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.form.radioGroup.color};
  cursor: pointer;
  display: inline-block;
  height: 3.75rem;
  padding: 1rem;
  transition: all 0.3s ease;
  z-index: 0;

  ${({ disabled, theme }) => disabled && css`
    opacity: 0.7;
    background-color: ${theme.form.input.disabledBackgroundColor};
  `}

  &:first-of-type {
    border-radius: ${({ theme }) => `${theme.form.radioGroup.borderRadius} 0 0 ${theme.form.radioGroup.borderRadius}`};
  }

  &:last-of-type {
    border-radius: ${({ theme }) => ` 0 ${theme.form.radioGroup.borderRadius} ${theme.form.radioGroup.borderRadius} 0`};
    border-width: 1px;
  }

  &:hover {
    ${activeElementStyle}
  }
`;
