export const CANCELING: 'myjustlease/quote/CANCELING' = 'myjustlease/quote/CANCELING';
export const CANCEL_SUCCESS: 'myjustlease/quote/CANCEL_SUCCESS' = 'myjustlease/quote/CANCEL_SUCCESS';
export const CANCEL_FAILED: 'myjustlease/quote/CANCEL_FAILED' = 'myjustlease/quote/CANCEL_FAILED';
export const GET_CREDIT_CHECK_FORM_METADATA_SUCCESS: 'myjustlease/quote/GET_CREDIT_CHECK_FORM_METADATA_SUCCESS' = 'myjustlease/quote/GET_CREDIT_CHECK_FORM_METADATA_SUCCESS';
export const GET_CREDIT_CHECK_FORM_METADATA_FAIL: 'myjustlease/quote/GET_CREDIT_CHECK_FORM_METADATA_FAIL' = 'myjustlease/quote/GET_CREDIT_CHECK_FORM_METADATA_FAIL';
export const GET_CREDIT_CHECK_FORM_METADATA: 'myjustlease/quote/GET_CREDIT_CHECK_FORM_METADATA' = 'myjustlease/quote/GET_CREDIT_CHECK_FORM_METADATA';
export const SORT_FIELD_DIRECTION: 'myjustlease/quote/SORT_FIELD_DIRECTION' = 'myjustlease/quote/SORT_FIELD_DIRECTION';
export const GET_RDW_DATA: 'myjustlease/quote/GET_RDW_DATA' = 'myjustlease/quote/GET_RDW_DATA';
export const GET_RDW_DATA_SUCCESS: 'myjustlease/quote/GET_RDW_DATA_SUCCESS' = 'myjustlease/quote/GET_RDW_DATA_SUCCESS';
export const GET_RDW_DATA_FAIL: 'myjustlease/quote/GET_RDW_DATA_FAIL' = 'myjustlease/quote/GET_RDW_DATA_FAIL';
export const CLEAR_RDW: 'myjustlease/quote/CLEAR_RDW' = 'myjustlease/quote/CLEAR_RDW';
export const SAVE_FLOW_TYPE: 'myjustlease/quote/SAVE_FLOW_TYPE' = 'myjustlease/quote/SAVE_FLOW_TYPE';
export const SET_PREORDER_DATA: 'myjustlease/quote/SET_PREORDER_DATA' = 'myjustlease/quote/SET_PREORDER_DATA';

export default null;
