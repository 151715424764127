import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@atoms/button';
import { ButtonProps } from '@atoms/button/types';
import { rotate } from '@utils/animations';
import { StyledComponentProps as StyledProps } from '@type';

export interface Props extends ButtonProps {
  loading: boolean;
  variant?: string;
}

const StyledButton = styled(Button)<Props & StyledProps>`
  &::before,
  &::after {
    content: '';
    display: block;
    opacity: 0;
    transition: opacity 0.05s ease;
  }

  ${({ theme, loading }) => loading && css`
    background: currentColor;
    border-color: currentColor;
    color: ${theme.buttons.schemes.disabled.main} !important;

    &::before,
    &::after {
      background: transparent;
      border-color: transparent white;
      border-width: 3px;
      border-style: solid;
      border-radius: 50%;
      left: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform-origin: top left;
      transform:
        rotate(0deg)
        translate(-50%, -50%);
    }

    &::before {
      animation: ${rotate} 1.7s linear infinite -1s;
      height: 0.5rem;
      width: 0.5rem;
    }

    &::after {
      animation: ${rotate} 1s linear infinite 0.1s;
      height: 1.25rem;
      width: 1.25rem;
    }
  `}

  ${({ theme, loading, variant }) => loading && variant === 'link_text' && css`
    color: ${theme.buttons.schemes.disabled.main} !important;
    margin-right: 2rem;

    &::before,
    &::after {
      border-color: transparent orangered;
      border-width: 2px;
      left: calc(100% + 1rem);
      transform-origin: top left;
      transform:
        rotate(0deg)
        translate(50%, -50%);
    }

    &::before {
      height: 0.3rem;
      width: 0.3rem;
    }

    &::after {
      height: 0.8rem;
      width: 0.8rem;
    }
  `};
`;

const ActionButton: React.FC<Props> = ({
  loading = false,
  children,
  ...props
}) => (
  <StyledButton
    {...props} // eslint-disable-line react/jsx-props-no-spreading
    loading={loading}
  >
    {children}
  </StyledButton>
);

export default ActionButton;
