import React from 'react';
import { MODAL_SIZES } from '@constants';
import { useI18n } from '@hooks/i18n';
import { FontWeight } from '@type';
import { Modal } from '../default';
import { StyledSpan, StyledSubtitle } from './ChecklistModal.styled';

interface Props {
  title?: string,
  isOpen: boolean;
  closeModal: (...args: any[]) => any;
  onConfirm?: (...args: any[]) => any;
  hasPassedValidation?: boolean;
  subtitle?: string;
}

const ChecklistModal: React.FC<Props> = ({
  isOpen = false,
  closeModal,
  onConfirm,
  title = '',
  hasPassedValidation = false,
  children,
  subtitle,
}) => {
  const { t } = useI18n();
  return (
    <Modal
      onClose={closeModal}
      isOpen={isOpen}
      onConfirm={onConfirm}
      closeOnOverlayClick
      size={MODAL_SIZES.medium}
      title={title}
      hasPassedValidation={hasPassedValidation}
    >
      {subtitle && <StyledSubtitle>{subtitle}</StyledSubtitle>}
      <StyledSpan fontWeight={FontWeight.Bold}>{t('check-following-options')}</StyledSpan>
      {children}
    </Modal>
  );
};

export default ChecklistModal;
