import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@atoms';
import { StyledComponentProps } from '@type';

const PdfIconLink = styled.a<StyledComponentProps>`
  svg {
    fill: ${({ theme }) => theme.table.iconColor};
  }
`;

interface Props {
  href: string;
}

export const PdfIcon: React.FC<Props> = ({
  href,
}) => (
  <PdfIconLink href={href}>
    <SvgIcon width="1.5rem" height="1.5rem" icon="pdf" />
  </PdfIconLink>
);
