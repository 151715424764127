import styled, { css } from 'styled-components';
import media from '@utils/media';
import { FloatingAction } from '@atoms';
import { StyledComponentProps } from '@type';

interface StyledProps extends StyledComponentProps {
  show: boolean;
}

export const NavigationContainer = styled.nav<StyledProps>`
  background-color: ${({ theme }) => theme.navigation.backgroundColor};
  box-sizing: border-box;
  box-shadow: 0;
  color: ${({ theme }) => theme.navigation.color};
  height: 100%;
  min-width: ${({ theme }) => theme.navigation.minWidth};
  position: fixed;
  right: 100%;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease 0.2s;
  top: 0;
  z-index: ${({ theme }) => theme.navigation.zIndex};

  ${({ show }) => show && css`
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition:
      transform 0.3s ease 0.2s,
      box-shadow 0.3s ease;
  `};

  ${/* sc-sel */FloatingAction} {
    align-items: center;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    left: calc(100% + 1rem);
    position: absolute;

    .hamburger {
      height: 1.5rem;
      width: 1.5rem;
    }

    svg {
      fill: white;
    }

    ${media.md(css`
      display: none;
    `)}
  }

  ${({ theme }) => (css`
    ${media.md(css`
      box-shadow: none;
      display: block;
      height: calc(100vh - ${theme.header.height});
      left: 0;
      max-width: 20rem;
      position: relative;
      transition: transform 0s ease;
      transform: translateX(0);
    `)};
  `)}
`;

export const NavigationItemsContainer = styled.ul`
  margin: 0;
  padding: 0;
`;
