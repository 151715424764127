import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

export interface NavLinkProps {
  href: string;
  alias?: string;
  exact?: boolean;
  className?: string;
}

export const NavLink: React.FC<NavLinkProps> = ({
  href, children, exact = false, alias, className = '',
}) => {
  const { pathname } = useRouter();
  let classNames = className;
  if (exact) {
    classNames += href === pathname ? ' active' : '';
  } else {
    classNames += pathname.indexOf(href) > -1 ? ' active' : '';
  }

  return (
    <Link href={href} as={alias}>
      <a className={classNames}>{children}</a>
    </Link>
  );
};
