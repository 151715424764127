import React from 'react';
import styled from 'styled-components';
import { useI18n } from '@hooks/i18n';
import { FontWeight, StyledComponentProps as StyledProps, TextColor } from '@type';
import Price from '../price';

const DepositContainer = styled.div<StyledProps>`
  margin: 1rem 0;
  background-color: ${({ theme }) => theme.deposit.container.backgroundColor};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface Props {
  amount: number;
  className?: string;
}

export const DepositAmount: React.FC<Props> = ({
  amount,
  className = '',
}) => {
  const { t } = useI18n();

  return (
    <DepositContainer className={className}>
      <p>
        {`${t('deposit-to-pay')}:`}
      </p>
      <Price decimalDash amount={amount / 100} color={TextColor.Primary} fontSize="1.75rem" fontWeight={FontWeight.Bold} />
    </DepositContainer>
  );
};
