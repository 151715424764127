import styled from 'styled-components';
import { StyledComponentProps } from '@type';

export const StyleShowButton = styled.div<StyledComponentProps>`
  color: ${({ theme }) => theme.quote.subtitle.showButtonColor};
  margin-top: 1rem;
`;

export const StylePanel = styled.div<StyledComponentProps>`
  padding: 1rem;
  background-color: ${({ theme }) => theme.quote.subtitle.backgroundColor};

  h4 {
    margin-bottom: 0.5rem;
  }

  a {
    color: ${({ theme }) => theme.quote.subtitle.showButtonColor};
    text-decoration: none;
  }
`;
