import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import {
  CardContent,
  CardHeader,
  CardHighlight,
  LinkButton,
  P,
} from '@atoms';
import { useI18n } from '@hooks/i18n';
import { CUSTOMER_ACTIONS } from '@constants';
import { media } from '@utils';
import { Breakpoints } from '@utils/breakpoints';
import { CardScheme } from '@type';
import { Card } from '../card';
import { DashboardActions } from './types';

export type VisibilityTypes = Partial<Record<keyof Breakpoints | 'default', string>>;

const Wrapper = styled.div`
  > :last-child {
    margin-top: 1rem;
  }
`;

function getCardVisibility(visibilityOption: VisibilityTypes): FlattenSimpleInterpolation {
  const result: FlattenSimpleInterpolation = Object
    .entries(visibilityOption)
    .reduce(
      (acc, [key, value]) => {
        const breakpoint = key as (keyof VisibilityTypes);
        switch (breakpoint) {
          case 'default':
            return [
              ...acc,
            ].concat(css`
            display: ${value};
          `);

          default:
            return [
              ...acc,
            ].concat(css`
            ${media[breakpoint](css`
              display: ${value};
            `)}
          `);
        }
      },
      [] as FlattenSimpleInterpolation,
    );

  return result;
}

interface StyledCardsProps {
  visibility: VisibilityTypes;
}

export const StyledCard = styled(Card)<StyledCardsProps>`
  ${(props) => props.visibility && getCardVisibility(props.visibility)}
`;

export const StyledCardHighlight = styled(CardHighlight)`
  && {
    background-color: ${(props) => props.theme.customerAction.backgroundColor};
    border: 1px solid ${(props) => props.theme.customerAction.borderColor};
    margin-top: 0;
  }

  ${media.sm(css`
    padding: 1rem;
  `)}
`;

interface Props {
  actions: Array<DashboardActions>,
  visibility: VisibilityTypes,
}

export const CustomerActions = ({ actions, visibility }: Props) => {
  const { t } = useI18n();

  return (
    <StyledCard
      visibility={visibility}
      renderHeader={() => (
        <CardHeader>{t('customer-actions-card-title')}</CardHeader>
      )}
    >
      <CardContent>
        <Wrapper>
          {actions.map((action) => (
            action.type === CUSTOMER_ACTIONS.claimfree_upload && (
            <StyledCardHighlight scheme={CardScheme.Success} key={action.id}>
              <P>{action.message}</P>
              <LinkButton
                fullWidth
                to={`/contract/${action.id}/claimfree`}
                caret
              >
                {t('customer-actions-button-text')}
              </LinkButton>
            </StyledCardHighlight>
            )
          ))}
        </Wrapper>
      </CardContent>
    </StyledCard>
  );
};
