import React from 'react';
import styled, { css } from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';
import { InfoModal } from '../../modals';
import {
  StyledSvgIcon, ChildrenContainer, OptionLabel, PriceDifference,
} from './optionTypes.styled';

interface Props {
  checked: boolean;
  disabled?: boolean;
  groupId: string;
  id: string;
  included?: boolean;
  infoModal: string;
  name: string;
  onChange: (...args: Array<any>) => any;
  price: number;
}

interface LabelProps extends StyledProps {
  disabled: boolean;
  included: boolean;
}

const Label = styled.label<LabelProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 2rem;

  ${({ disabled, theme }) => (disabled && css`
    & ${/* sc-sel */PriceDifference},
    & ${/* sc-sel */OptionLabel} {
      color: ${theme.changeQuote.disabledColor};
      cursor: default;
    }
  `)}

  ${({ theme, included, disabled }) => (disabled && included && css`
    ${/* sc-sel */OptionLabel} {
      color: ${theme.changeQuote.includedColor};
    }

    ${/* sc-sel */PriceDifference} {
      color: ${theme.changeQuote.option.priceLabelColor};
    }
  `)}
`;

const Checkbox = styled.div<StyledProps>`
  align-items: center;
  border: ${({ theme }) => `${theme.divider.borderWidth} solid ${theme.changeQuote.option.radioButtonBorder}`};
  border-radius: 2px;
  display: flex;
  flex-shrink: 0;
  height: 1.125rem;
  justify-content: center;
  transition: all 0.2s ease 0.1s;
  width: 1.125rem;
  margin-right: 1rem;
`;

type InputProps = StyledProps & {
  included?: boolean;
};

const Input = styled.input<InputProps>`
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;

  &:checked + ${/* sc-sel */Label} > ${/* sc-sel */Checkbox} {
    background-color: ${({ theme }) => theme.changeQuote.selectedOption.radioButton};
    border-color: ${({ theme }) => theme.changeQuote.selectedOption.radioButton};
    transition: all 0.2s ease;

    ${/* sc-sel */StyledSvgIcon} {
      opacity: 1;
      transform: scale(1);
      transition: all 0.2s ease 0.1s;
    }
  }

  &:disabled + ${/* sc-sel */Label} > ${/* sc-sel */Checkbox} {
    background-color: ${({ theme, included }: InputProps) => (included ? theme.changeQuote.includedColor : 'transaparent')};
    border-color: ${({ theme, included }: InputProps) => (included ? theme.changeQuote.includedColor : 'transaparent')};

    &:hover {
      cursor: default;
    }

    ${/* sc-sel */StyledSvgIcon} {
      color: ${({ theme, included }: InputProps) => (included ? 'white' : theme.changeQuote.selectedOption.radioButton)};
    }
  }
`;

export const OptionCheckbox: React.FC<Props> = ({
  checked,
  children,
  disabled = false,
  groupId,
  id,
  included = false,
  infoModal,
  name,
  price = 0,
  onChange,
}) => (
  <>
    <Input
      id={`${groupId}-${id}`}
      type="checkbox"
      name={groupId}
      checked={checked}
      value={name}
      onChange={onChange}
      disabled={disabled}
      included={included}
    />
    <Label
      htmlFor={`${groupId}-${id}`}
      disabled={disabled}
      included={included}
    >
      <Checkbox>
        <StyledSvgIcon icon="check" />
      </Checkbox>
      <OptionLabel>{name}</OptionLabel>
      <PriceDifference amount={price / 100} displayZeroes displayCurrencySymbol />
      {infoModal && (
        <InfoModal
          title={name}
          size="small"
          description={infoModal}
        />
      )}
    </Label>
    {children && (
      <ChildrenContainer>{children}</ChildrenContainer>
    )}
  </>
);
