import React from 'react';
import { LinkButton } from '@atoms';
import styled from 'styled-components';
import { ProgressBar } from '@molecules';
import { useI18n } from '@hooks/i18n';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store/types';
import { SubTitle } from '../../title';

interface Props {
  progress: number;
  leaseNumber: string;
}

const StatusText = styled(SubTitle)`
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
`;

const Status: React.FC<Props> = ({
  progress,
  leaseNumber,
}: Props) => {
  const { t } = useI18n();
  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[leaseNumber]?.isPreorder;

  return (
    <>
      <ProgressBar percentage={progress} label={`% ${t('quote-status-title')}`} />
      <LinkButton to={`/quote/${leaseNumber}`} fullWidth caret>
        {isPreorder ? t('quote-finish-request-preorder') : t('quote-finish-request')}
      </LinkButton>
      <StatusText>{t('quote-status-cancel-info')}</StatusText>
    </>
  );
};

export default Status;
