import React, { useState } from 'react';
import { Button } from '@atoms';
import StyledWrapper from './buttonGroup.styled';

interface Props {
  texts: string[];
  values: any[];
  setSelectedValue: (value: any, id: string) => void;
  id: string,
}

const ButtonGroup: React.FC<Props> = ({
  texts = [],
  values = [],
  setSelectedValue,
  id,
}) => {
  const [value, setValue] = useState();
  const handleClick = (selectedVal: any, selectedId: string) => {
    setValue(selectedVal);
    setSelectedValue(selectedVal, selectedId);
  };
  return (
    <StyledWrapper>
      {texts.map((text, index) => (
        <Button
          key={id + text}
          onClick={() => handleClick(values[index], id)}
          outline={value !== values[index]}
        >
          {text}
        </Button>
      ))}
    </StyledWrapper>
  );
};

export default ButtonGroup;
