import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Heading } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { HeadingLevel, StyledComponentProps, TextColor } from '@type';

const Button = styled.button<StyledComponentProps>`
  color: ${({ theme }) => theme.pageHeader.backButton.color};
  margin: 0.5rem 0 1rem;
  background: none;
  border: 0;
  cursor: pointer;
  outline: none;

  ::before {
    content: '‹';
    margin-right: 0.3125rem;
  }
`;

interface HeaderProps extends StyledComponentProps {
  hideBackButton: boolean;
}

const Header = styled.header<HeaderProps>`
  margin-bottom: ${({ hideBackButton }) => (hideBackButton ? '1.5rem' : '0')};
`;

interface Props {
  title: string;
  subtitle?: string;
  backTitle?: string;
  to?: string;
  hideBackButton?: boolean;
}

export const PageHeader: React.FC<Props> = ({
  title,
  subtitle = '',
  backTitle = '',
  to = '',
  hideBackButton = false,
}) => {
  const { t } = useI18n();
  const { push, back } = useRouter();

  const handleBackClick = () => {
    if (to) {
      push(to);
    } else {
      back();
    }
  };

  return (
    <Header className="page-header" hideBackButton={hideBackButton}>
      <Heading level={HeadingLevel.H1}>{title}</Heading>
      {subtitle
      && (
      <Heading
        level={HeadingLevel.H3}
        color={TextColor.Sub}
      >
        {subtitle}
      </Heading>
      )}
      {!hideBackButton
      && (
      <Button
        onClick={() => handleBackClick()}
      >
        {backTitle || t('back-button-text')}
      </Button>
      )}
    </Header>
  );
};
