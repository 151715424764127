import styled, { css } from 'styled-components';
import { Heading } from '@atoms';
import { HeadingLevel, StyledComponentProps } from '@type';

export const Title = styled(Heading).attrs({
  level: HeadingLevel.H2,
})`
  height: 3.125rem;
  margin-bottom: 0.25rem;
  line-height: 1.5rem;
  overflow: hidden;

  /* stylelint-disable-next-line no-duplicate-selectors */
  & {
    font-size: 1.25rem;
  }

  &&::first-line {
    font-size: 1.5rem;
  }
`;

export const SubTitle = styled.div<StyledComponentProps>`
  color: ${({ theme }) => theme.quote.text.color};
  font-weight: ${({ theme }) => theme.quote.text.fontWeight};
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom};
  `}
`;

export const TitleContainer = styled.section<StyledComponentProps>`
  border-bottom: 1px solid ${({ theme }) => theme.divider.color};
  grid-area: 2/1/3/2;
  padding: ${(prop) => (prop.hasBoardingPadding ? '1rem 0' : '0px')}
`;
