import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
// import { IconContext } from '@context';
import SvgIcon from '@atoms/svgIcon';
import { StyledComponentProps as Props } from '@type';

const NavigationHeaderContainer = styled.section<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.navigation.header.backgroundColor};
  ${({ theme }) => theme.navigation.header.backgroundImage && css`
    background: ${theme.navigation.header.backgroundImage};
  `};
  display: flex;
  height: 3.75rem;
  justify-content: center;
  opacity: 1;

  ${media.md(css`
    display: none;
  `)}

  .logo {
    display: inline-block;
    height: 1.5rem;
    width: auto;
  }

  svg {
    width: 9.5rem;
    height: 1.5rem;

    path,
    rect,
    polygon {
      fill: ${({ theme }) => theme.navigation.header.color};
    }
  }
`;

const NavigationHeader = () => (
  <NavigationHeaderContainer>
    {/* <IconContext.Consumer>
      {({
        SvgIcon,
      }) => ( */}
    <SvgIcon icon="logo" />
    {/* )}
    </IconContext.Consumer> */}
  </NavigationHeaderContainer>
);

export default NavigationHeader;
