import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import withAbortController, { AbortControllerProps } from '@hocs/withAbortController';
import { HeaderNavigation } from '@molecules';
import { HeaderNavigationItem } from '@type/molecules';
import { useAuthentication } from '@hooks/authentication';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER_MENU_INFO } from '@queries/me';
import * as Sentry from '@sentry/nextjs';
import { EMPLOYMENT_TYPE } from '@queries/quote';
import { ApolloContext } from '@context';
import { Overlay } from '../overlay';

interface Props {
  menuItems: Array<HeaderNavigationItem>;
}

const generateLabel = (
  initials: string,
  lastName: string,
): string => {
  if (!initials) {
    return '';
  }
  const init = initials.replace(/[\\.\s]/g, '').split('').join('. ');

  return `${init} ${lastName || ''}`;
};

const UserNavigation: React.FC<Props & AbortControllerProps> = ({
  menuItems,
}) => {
  const { pathname, push } = useRouter();
  const {
    isAuthenticated,
    lastName,
    initials,
    isDebtorChange,
    accessToken,
  } = useAuthentication();
  const [isDebtorChangeLocal, setIsDebtorChangedLocal] = useState(isDebtorChange);
  const [open, setOpen] = useState(false);
  const [hasEmploymentType, setHasEmploymentType] = useState(false);

  const client = useContext(ApolloContext).leaseHub;
  const [getUserInfo, { data }] = useLazyQuery(GET_CUSTOMER_MENU_INFO, { client, fetchPolicy: 'no-cache' });
  const [getEmploymentType, {
    data: employmentData,
  }] = useLazyQuery(EMPLOYMENT_TYPE, { client, fetchPolicy: 'no-cache' });

  if (!isAuthenticated) {
    return null;
  }

  useEffect(() => {
    const fetchEmploymentType = async () => {
      await getEmploymentType();
      if (employmentData?.quotes) {
        const hasEmployment = employmentData?.quotes?.find(
          (item:{ prospect: { employmentType: string } }) => (item?.prospect?.employmentType),
        );

        setHasEmploymentType(!!hasEmployment);
      }
    };
    fetchEmploymentType();
  }, [data, accessToken]);

  const setUserInfo = async () => {
    try {
      await getUserInfo();
      if (data?.me) {
        setIsDebtorChangedLocal(data.me?.isDebtorChange);
      }
    } catch (err) {
      Sentry.captureException(new Error('Failed to get customer info'));
    }
  };

  useEffect(() => {
    if (accessToken) {
      setUserInfo();
    }
  }, [isDebtorChange, lastName, accessToken]);

  useEffect(() => {
    if (!hasEmploymentType && isDebtorChangeLocal && pathname !== '/profile/prospect/edit') {
      push('/profile/prospect/edit');
    }
  }, [isDebtorChangeLocal, hasEmploymentType, pathname]);

  return (
    <>
      {open && <Overlay onClick={() => setOpen(false)} />}

      {(isDebtorChangeLocal && !hasEmploymentType && pathname !== '/profile/prospect/edit')
        ? <></>
        : (
          <HeaderNavigation
            toggleNavigation={() => setOpen(!open)}
            menuItems={menuItems}
            open={open}
            label={generateLabel(initials, lastName)}
            icon="user"
          />
        )}
    </>
  );
};

export default withAbortController(UserNavigation);
