import styled from 'styled-components';
import React from 'react';
import { SvgIcon, ActionButton } from '@atoms';
import Price from '@organisms/product/components/price';
import { priceFormat } from '@utils';
import { useI18n } from '@hooks/i18n';
import { ButtonSchemeName } from '@constants/theme';
import { FooterBase } from '../footer';

const Footer = styled(FooterBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 0 0.25rem;
`;

const ButtonBar = styled.div`
  width: 100%;

  button {
    padding-left: 0;
    padding-right: 0;
    width: calc(50% - 0.25rem);
  }

  button + button {
    margin-left: 0.5rem;
  }
`;

const StatusText = styled(FooterBase)`
  display: flex;
  align-items: center;
  color: #727c8c;
  flex-wrap: nowrap;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 0 0.25rem;
  text-align: center;

  > ${
/* sc-sel */
  SvgIcon} {
    margin-right: 1rem;
    height: 1.75rem;
    width: 1.75rem;
  }
`;

const BidStatusAccepted = styled.span`
  color: green;
  font-weight: 600;
`;

const BidStatusDenied = styled.span`
  color: red;
  font-weight: 600;
`;

export const StatusOpen: React.FC = () => {
  const { t } = useI18n();

  return (
    <StatusText>
      <SvgIcon icon="circleClock" />
      <div>
        {t('interchange-bidblock-status-open')}
      </div>
    </StatusText>
  );
};

interface BidInProps {
  bid: {
    currentBid: number;
  };
  onBidAccept: () => void;
  onBidReject: () => void;
  acceptLoading: boolean;
  rejectLoading: boolean;
}

export const StatusBidIn: React.FC<BidInProps> = ({
  bid: {
    currentBid,
  },
  onBidAccept,
  onBidReject,
  acceptLoading = false,
  rejectLoading = false,
}) => {
  const { t } = useI18n();
  return (
    <Footer>
      <Price label={t('interchange-bidblock-our-offer')} currencySymbol price={currentBid} />
      <ButtonBar>
        <ActionButton
          scheme={ButtonSchemeName.Reject}
          onClick={onBidReject}
          disabled={rejectLoading}
          loading={rejectLoading}
        >
          {t('interchange-bidblock-button-reject')}
        </ActionButton>
        <ActionButton
          scheme={ButtonSchemeName.Accept}
          onClick={onBidAccept}
          disabled={acceptLoading}
          loading={acceptLoading}
        >
          {t('interchange-bidblock-button-accept')}
        </ActionButton>
      </ButtonBar>
    </Footer>
  );
};

interface Bid {
  bid: {
    currentBid: number;
  };
}

export const StatusAccepted: React.FC<Bid> = ({
  bid,
}) => {
  const { t } = useI18n();

  return (
    <Footer>
      {`${t('Bod')} ${priceFormat((bid.currentBid && bid.currentBid / 100) || 0, { currency: true })}`}
      <BidStatusAccepted>Geaccepteerd</BidStatusAccepted>
    </Footer>
  );
};

export const StatusDenied: React.FC<Bid> = ({
  bid,
}) => {
  const { t } = useI18n();

  return (
    <Footer>
      {`${t('Bod')} ${priceFormat((bid.currentBid && bid.currentBid / 100) || 0, { currency: true })}`}
      <BidStatusDenied>Geweigerd</BidStatusDenied>
    </Footer>
  );
};
