import React from 'react';
import { FileInput, UploadButton as StyledUploadButton } from './styled';

interface Props {
  label: string;
  processing: boolean;
  onChange: (...args: Array<any>) => any;
  linkStyle: boolean;
}

export const UploadButton: React.FC<Props> = ({
  label,
  onChange,
  processing,
  linkStyle,
}) => (
  <StyledUploadButton processing={processing} linkStyle={linkStyle}>
    <FileInput disabled={processing} onChange={onChange} />
    {label}
  </StyledUploadButton>
);
