import React from 'react';
import styled, { css } from 'styled-components';
import { UnorderedList } from '@atoms/list';
import { SvgIcon } from '@atoms';
import { StyledComponentProps, TextColor } from '@type';

interface Props {
  icon: string;
  iconColor?: TextColor;
  divider?: boolean;
}

interface StyledProps extends StyledComponentProps {
  divider: boolean;
  iconColor: TextColor;
}

export const IconList = styled(UnorderedList)`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin: 0;
  }
`;

const Item = styled.li<StyledProps>`
  padding: 0.5rem 0 0.5rem 2rem;
  position: relative;

  ${({ theme, divider }) => divider && css`
    border-top: 1px solid ${theme.divider.color};
    padding: 0.75rem 0 0.75rem 2rem;
  `}

  & > div {
    color: ${({ theme, iconColor }) => theme.list.icon.color[iconColor || 'default']};
    height: 1rem;
    left: 0.25rem;
    position: absolute;
    top: 0.75rem;
    width: 1rem;

    ${({ divider }) => divider && css`
      height: 1.5rem;
      left: 0;
      top: 0.75rem;
      width: 1.5rem;
    `}
  }
`;

export const IconItem: React.FC<Props> = ({
  children,
  icon,
  divider = false,
  iconColor = TextColor.Default,
}) => (
  <Item divider={divider} iconColor={iconColor}>
    <SvgIcon icon={icon} />
    {children}
  </Item>
);
