import { GetTranslation } from '@hooks/i18n';

const getDeliveryTimeText = (
  t: GetTranslation,
  deliveryTime: number,
  prefix?: boolean,
) => (
  `${prefix ? `${t('quote-delivery-time')}:` : ''} ${t('quote-approximately')} ${deliveryTime} ${t('quote-delivery-time-weeks')}`
);

export default getDeliveryTimeText;
