import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';
import { Select, Label } from '@atoms/form';

const activeStyle = css<StyledComponentProps>`
  & + ${/* sc-sel */Label} {
    color: ${({ theme }) => theme.form.label.color};
    font-size: 0.8em;
    top: 0.5rem;
    z-index: 1;
  }
`;

export const SelectStyled = styled(Select)`
  padding-top: 1.1rem;

  ${activeStyle}
`;
