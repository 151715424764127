import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';

const widthInUrl = (url: string, width: number): string => url.replace('__width__', width.toString());

interface Props {
  alt?: string;
  className?: string;
  url: string;
  placeholder?: string | null;
  width?: number;
}

const StyledImage = styled.img`
  max-width: 100%;
`;

const Image: React.FC<Props> = ({
  alt = '',
  className = '',
  url,
  placeholder = null,
  width = 600,
}) => {
  const src = widthInUrl(url, width || 600);

  return (
    <StyledImage
      alt={alt}
      className={className}
      src={src}
      onError={(evt: SyntheticEvent<HTMLImageElement, Event>) => {
        // eslint-disable-next-line no-param-reassign
        (evt.target as HTMLImageElement).src = placeholder || '';
      }}
    />
  );
};

export default Image;
