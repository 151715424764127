import React, { Fragment } from 'react';
import {
  CardActions, CardContent, CardHeader, Key, KeyValue, LinkButton, Value,
} from '@atoms';
import { useI18n } from '@hooks/i18n';
import { Card } from '@molecules';

interface Props {
  listData: Record<string, string>;
}

export const UserData: React.FC<Props> = ({
  listData,
}) => {
  const { t } = useI18n();

  return (
    <Card
      renderHeader={() => <CardHeader>{t('my-profile-page-my-data-title')}</CardHeader>}
      renderFooter={() => (
        <CardActions>
          <LinkButton outline to="/profile/edit">
            {t('edit')}
          </LinkButton>
        </CardActions>
      )}
    >
      <CardContent>
        {Object.entries(listData).map(([key, value]) => (
          <Fragment key={key}>
            {value && (
            <KeyValue>
              <Key>
                {key}
              </Key>
              <Value>
                {value}
              </Value>
            </KeyValue>
            )}
          </Fragment>
        ))}
      </CardContent>
    </Card>
  );
};
