const setDocumentId = (document: any) => (
  {
    ...document,
    id: `${document.id}-${document.category}`,
  }
);

/**
 * This method will get the last 2 documentRequests for each label
 *
 * @todo lobby with backend to move this logic to the backend :)
 */
function getLastTwoDocumentsGroupedByLabel<T>(
  documentRequests: Array<T & { label: string; }>,
): Array<T> {
  // first group the documents based on the label
  const grouped = documentRequests.reduce((acc, document) => ({
    ...acc,
    [document.label]: [
      ...(acc[document.label] || []),
      setDocumentId(document),
    ],
  }), {} as Record<string, Array<T>>);

  return Object.values<Array<T>>(grouped).reduce((acc, values) => {
    if (values.length < 2) {
      return [...acc, ...values];
    }

    return [...acc, ...values.slice(values.length - 2, values.length)];
  }, []);
}

export { getLastTwoDocumentsGroupedByLabel };
