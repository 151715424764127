import React from 'react';
import { CardContent, CardHeader, P } from '@atoms';
import { Card } from '@molecules';
import { HeadingLevel, TextColor } from '@type';
import ProcessStep from './processStep';

interface Props {
  title: string;
  description: string;
  steps: Array<{
    title: string;
    description: string;
    dashed?: boolean;
    icon?: boolean;
  }>;
}

const ProcessCard: React.FC<Props> = ({
  title,
  description,
  steps,
}) => (
  <Card renderHeader={() => <CardHeader level={HeadingLevel.H4}>{title}</CardHeader>}>
    <CardContent>
      <P subText color={TextColor.Sub}>{description}</P>
      {steps.map((step, index) => (
        <ProcessStep
          number={(index + 1).toString()}
          title={step.title}
          description={step.description}
          dashed={step.dashed || false}
          icon={step.icon || false}
          key={step.title}
        />
      ))}
    </CardContent>
  </Card>
);

export default ProcessCard;
