import styled, { css } from 'styled-components';
import media from '@utils/media';

interface Props {
  fullWidth?: boolean;
}

export default styled.section<Props>`
  display: flex;
  flex-direction: column;
  padding-top: 1rem;

  button,
  a {
    &:last-of-type {
      margin-top: 1rem;
    }
  }

  ${({ fullWidth }) => fullWidth && media.lg(css`
      flex-direction: row;

      button,
      a {
        &:last-of-type {
          margin-left: 1rem;
          margin-top: 0;
        }
      }
    `)};
`;
