import styled from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';

interface Props extends StyledProps {
  htmlFor: string;
}

export const Label = styled.label<Props>`
  color: ${({ theme }) => theme.form.label.color};
  left: 1rem;
  position: absolute;
  top: 1.2rem;
  transition: all 0.3s ease;
`;
