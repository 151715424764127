import React from 'react';
import { CardWithList } from '@molecules';
import { useI18n } from '@hooks/i18n';
import { TextColor } from '@type';

export const ContactDetails = () => {
  const { t } = useI18n();
  const telephoneNumber = t('contact-telephone-number')/* The phone number to contact us */;
  const whatsappNumber = t('contact-whatsapp-number')/* The whatsapp number to contact us */;
  const whatsappNumberLabel = t('contact-whatsapp-label')/* The whatsapp label to contact us */;
  const streetName = t('contact-street-name')/* The street we are located at (Eendrachtlaan) */;
  const streetNumber = t('contact-street-number')/* The housenumber */;
  const town = t('contact-town')/* The city wherer we are located (Utrecht) */;

  const contactItems = [{
    usp: telephoneNumber,
    icon: 'contactPhone',
    iconColor: TextColor.Secondary,
    url: `tel:${telephoneNumber}`,
  }, {
    usp: `${streetName} ${streetNumber}, ${town}`,
    icon: 'contactMarker',
    iconColor: TextColor.Secondary,
    url: `https://www.google.com/maps?q=${streetName}+${streetNumber},+${town}`,
  }];

  if (whatsappNumber !== 'contact-whatsapp-number') {
    contactItems.splice(0, 0, {
      usp: whatsappNumberLabel,
      icon: 'contactWhatsapp',
      iconColor: TextColor.Secondary,
      url: `https://wa.me/+${whatsappNumber}`,
    });
  }

  return <CardWithList title={{ text: t('direct-contact') }} isLinkList items={contactItems} />;
};
