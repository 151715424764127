import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@atoms';
import { InfoModal } from '@molecules/modals';
import { StyledComponentProps as StyledProps } from '@type';
import { Header, Title } from './components';

const CircleCheckmark = styled(SvgIcon)<StyledProps>`
  color: ${({ theme }) => theme.uploadItem.statusIcon.colorSuccess};
  margin-left: 0.5rem;
`;

interface StatusIconProps extends StyledProps {
  icon: string;
}

const StatusIcon = styled(SvgIcon)<StatusIconProps>`
  color: ${({ theme, icon }) => (
    icon === 'circleCross'
      ? theme.uploadItem.statusIcon.colorError
      : theme.uploadItem.statusIcon.colorSuccess
  )};
`;

interface Props {
  title: string;
  enabledUpload: boolean;
  description: string;
  groupCompleted: boolean;
  successfulUpload: boolean;
  isRejected: boolean;
}

export const UploadHeader: React.FC<Props> = ({
  title,
  enabledUpload,
  description,
  groupCompleted,
  isRejected,
  successfulUpload,
}) => (
  <Header
    hasUploads={false}
  >
    <Title>
      {title}
    </Title>
    {enabledUpload && (
    <>
      <InfoModal
        title={title}
        size="small"
        description={description}
        showInfoButton
      />
      {successfulUpload && (
      <CircleCheckmark
        width="1.25rem"
        height="1.25rem"
        icon="circleCheckmark"
      />
      )}
    </>
    )}
    {!enabledUpload && !groupCompleted
    && (
    <StatusIcon
      width="1.25rem"
      height="1.25rem"
      icon={isRejected ? 'circleCross' : 'circleCheckmark'}
    />
    )}
  </Header>
);
