export function routerMatch<P>(path: string, match: string): P {
  const matches = match.split('/') || [];
  matches.shift();
  const pathValues = path.split('/') || [];
  pathValues.shift();
  let valid = true;

  // @ts-ignore
  return matches.reduce((acc, item, index) => {
    if (item[0] !== ':') {
      if (item !== pathValues[index]) {
        valid = false;
      }
      return acc;
    }

    if (!valid) {
      return {};
    }

    if (pathValues && pathValues[index]) {
      return {
        ...acc,
        [item.replace(/:|\?/g, '')]: pathValues[index],
      };
    }
    return acc;
  }, {});
}
