import React from 'react';
import { SvgIcon, FloatingAction } from '@atoms';
import { NavigationContainer, NavigationItemsContainer, NavHeader } from './components';
import { MainNavigation } from './mainNavigation';

interface Props {
  showNavigation: boolean;
  openNavigation: (...args: Array<any>) => any;
}

export const Navigation: React.FC<Props> = ({
  showNavigation,
  openNavigation,
}) => (
  <NavigationContainer show={showNavigation}>
    <NavHeader />
    <NavigationItemsContainer>
      <MainNavigation />
    </NavigationItemsContainer>
    <FloatingAction
      hide={showNavigation}
      onClick={(evt) => {
        evt.stopPropagation();
        openNavigation();
      }}
    >
      <SvgIcon icon="hamburger" />
    </FloatingAction>
  </NavigationContainer>
);
