import styled from 'styled-components';
import { StyledComponentProps } from '@type';

export const InputPrefix = styled.div<StyledComponentProps>`
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.form.prefixedInput.border};
  bottom: 0;
  color: ${({ theme }) => theme.form.prefixedInput.color};
  display: flex;
  font-size: ${({ theme }) => theme.form.input.fontSize};
  height: 1.15rem;
  justify-content: center;
  left: -3.1rem;
  line-height: 1;
  opacity: 0;
  position: absolute;
  top: 1.85rem;
  transition:
    left 0.3s ease,
    opacity 0.15s ease;
  width: 2rem;
  z-index: 1;
`;
