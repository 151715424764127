import React, { Fragment } from 'react';
import styled from 'styled-components';
import {
  CardHighlight, Divider, Heading, Key, KeyValue, Value,
} from '@atoms';
import { CardScheme, HeadingLevel } from '@type';

const ProductSpecs = styled.section`
  hr:last-of-type {
    display: none;
  }
`;

const ProductSpecsPair = styled(KeyValue)`
  padding: 0.75rem 0;
`;

interface Props {
  title: string;
  productSpecs: {
    [key: string]: string | Array<string>;
  };
}

const ProductSpecification: React.FC<Props> = ({
  title,
  productSpecs,
}) => (
  <CardHighlight scheme={CardScheme.Accent}>
    <Heading level={HeadingLevel.H2}>
      {title}
    </Heading>
    <ProductSpecs>
      {productSpecs && Object.entries(productSpecs).map(([key, value]) => (
        <Fragment key={key}>
          <ProductSpecsPair>
            <Key>
              {key}
            </Key>
            <Value>
              {value}
            </Value>
          </ProductSpecsPair>
          <Divider margin="0" />
        </Fragment>
      ))}
    </ProductSpecs>
  </CardHighlight>
);

export default ProductSpecification;
