import { FetchError } from '../fetchData';

export interface FetchDataArguments {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';
  endpoint: string;
  body?: string | FormData;
  headers?: Record<string, any>;
  signal?: AbortSignal;
}

export class FetchAbortedError extends Error {}

async function postFormData<T>({
  endpoint,
  method = 'GET',
  body,
  headers = {},
  signal,
}: FetchDataArguments): Promise<T> {
  const reqHeaders = new Headers(headers);

  try {
    const response = await fetch(endpoint, {
      method,
      headers: reqHeaders,
      body: body || undefined,
      signal,
    });

    if (response.status >= 400) {
      throw new FetchError({
        message: 'Error fetching data from the API',
        status: response.status,
        url: endpoint,
        body,
        headers: JSON.stringify(headers),
      });
    }

    const json = await response.json();

    if (json && json.error) {
      throw new FetchError({
        message: 'Error parsing the JSON response',
        status: 500,
        url: endpoint,
        body,
        headers: JSON.stringify(headers),
      });
    }

    return json;
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new FetchAbortedError();
    }
    throw error;
  }
}

export default postFormData;
