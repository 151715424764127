import React from 'react';
import styled from 'styled-components';
import {
  Divider, Price, Heading, CardContent, Footer, Toaster,
} from '@atoms';
import { Card, PriceSummaryFooter } from '@molecules';
import getDeliveryTimeText from '@utils/getDeliveryTimeText';
import { CardScheme, HeadingLevel, StyledComponentProps } from '@type';
import { useI18n } from '@hooks/i18n';
import { InfoModal } from '@molecules/modals';
import { CURRENT_QUOTE_NUMBER } from '@constants/localStorageKeys';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';
import { QuoteFlows } from '@constants/quoteProgress';

const QuoteDetails = styled.section<StyledComponentProps>`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.card.defaultText};
`;
const CostsRow = styled(QuoteDetails)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 0.75rem;
`;

interface Props {
  deliveryTime: number;
  depositAmount: number;
  recurringCosts: number;
  discount: number;
  quoteFlow?: string;
  oneOffCosts: Array<{
    name: string;
    price: number;
  }>;
}

const PriceSummary: React.FC<Props> = ({
  deliveryTime,
  oneOffCosts,
  depositAmount,
  recurringCosts,
  discount,
  quoteFlow,
}) => {
  const { t } = useI18n();
  const quoteNumber = String(localStorage.getItem(CURRENT_QUOTE_NUMBER));
  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quoteNumber]?.isPreorder;

  return (
    <Card renderFooter={() => (
      <Footer scheme={CardScheme.Contrast}>
        <PriceSummaryFooter
          monthlyPriceLabel={t('monthly-price')}
          taxLabel={t('including-tax')}
          totalPrice={recurringCosts}
          discount={discount}
        />
      </Footer>
    )}
    >
      <CardContent>
        <QuoteDetails>
          <Heading level={HeadingLevel.H4}>{`${t('quote-delivery-time')}:`}</Heading>
          {getDeliveryTimeText(t, deliveryTime)}
          {t('deliverydate-info-title') !== 'deliverydate-info-title' && (
            <InfoModal
              title={t('deliverydate-info-title')}
              description={t('deliverydate-info-description')}
              showInfoButton
            />
          )}
        </QuoteDetails>
        { !isPreorder
          && (
          <>
            <Divider />
            <Heading level={HeadingLevel.H4}>{`${t('one-off-costs')}:`}</Heading>
            { quoteFlow !== QuoteFlows.Jlbe
            && (
            <CostsRow>
              {!depositAmount && depositAmount !== 0 ? <Toaster>{t('deposit-not-determined')}</Toaster> : (
                <>
                  <p>{`${t('deposit-title')}:`}</p>
                  <Price amount={depositAmount / 100} decimalDash displayCurrencySymbol />
                </>
              )}
            </CostsRow>
            )}
          </>
          )}
        {oneOffCosts && oneOffCosts.map(({
          name,
          price,
        }) => (
          <CostsRow key={name}>
            <p>{`${name}:`}</p>
            <Price amount={price / 100} decimalDash displayCurrencySymbol />
          </CostsRow>
        ))}
      </CardContent>
    </Card>
  );
};

export default PriceSummary;
