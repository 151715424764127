import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ApolloContext } from '@context';
import QUOTE_DIGITAL_SIGNING_URI, { GetDigitalSigningUriVars, GetDigitalSigningUriResult } from '@queries/quote/quoteDigitalSigningUri';
import { PageLoader } from '@molecules';
import { AgreementToDo, AgreementDone } from './components';

interface Props {
  quoteNumber: string;
  digitalSigningUri: string | null;
  refetchQuoteStatus?: () => Promise<any>;
}

const Agreement: React.FC<Props> = ({
  quoteNumber,
  digitalSigningUri,
  refetchQuoteStatus = () => new Promise((resolve) => resolve),
}) => {
  const client = useContext(ApolloContext).leadHub;
  const {
    data,
    loading,
    error,
  } = useQuery<GetDigitalSigningUriResult, GetDigitalSigningUriVars>(QUOTE_DIGITAL_SIGNING_URI, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      quoteNumber,
    },
  });

  useEffect(() => {
    if (
      data?.quotes[0].digitalSigningUri !== undefined
      && data?.quotes[0].digitalSigningUri !== digitalSigningUri
    ) {
      refetchQuoteStatus();
    }
  }, [data]);

  if (loading) {
    return <PageLoader />;
  }
  if (error) {
    return <span>{error}</span>;
  }

  return (
    data?.quotes[0].digitalSigningUri
      ? (
        <AgreementToDo
          id={data.quotes[0].id}
          quoteNumber={data.quotes[0].quoteNumber}
          digitalSigningUri={data.quotes[0].digitalSigningUri}
        />
      )
      : <AgreementDone />
  );
};

export default Agreement;
