export enum ChangeContractLine {
  Accessoires = 'change-accessoires',
  Claimfree = 'change-package',
  Color = 'change-color',
  Duration = 'change-duration',
  ExtraBundle = 'change-extra-bundle',
  Extras = 'change-extras',
  Mileage = 'change-mileage',
  Package = 'change-package',
  Prolongation = 'change-prolongation',
}
