import styled from 'styled-components';
import { StyledComponentProps as Props } from '@type';

export default styled.span`
  display: flex;
  float: right;
`;

export const InfoButton = styled.button<Props>`
  background: none;
  border: none;
  color: ${({ theme }) => theme.uploadItem.infoColor.main};
  cursor: pointer;
  display: inline-block;
  height: 1.25rem;
  padding: 0;
  margin: 0;
  width: 1.25rem;

  &:active,
  &:focus {
    outline: none;
  }

  .info {
    height: auto;
  }
`;
