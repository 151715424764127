import styled, { css } from 'styled-components';
import media from '@utils/media';

interface ToggleButtonProps {
  open?: boolean;
  smallScreenOnly?: boolean;
  isLinkButton: boolean;
}

const ToggleButton = styled.button<ToggleButtonProps>`
  background-color: transparent;
  background-image: url('/images/chevron.svg');
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-width: 0;
  height: 1rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform-origin: center center;
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0)')};
  ${({ isLinkButton }) => isLinkButton && css`
    transform: rotate(-90deg);
  `}
  width: 1rem;
  outline: none;
  margin-top: 0.3rem;

  ${({ smallScreenOnly }) => smallScreenOnly && css`
   ${media.sm(css`
      display: none;
  `)}
  `}
`;

export default ToggleButton;
