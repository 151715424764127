import React, { useState } from 'react';
import {
  Heading, Price, Button, ActionButton, Span, Divider, ButtonBar,
} from '@atoms';
import { useI18n } from '@hooks/i18n';
import { MODAL_SIZES } from '@constants';
import {
  FontWeight, HeadingLevel, TextAlign, TextColor,
} from '@type';
import { Addendum } from '@type/graphql';
import { ButtonSchemeName, Justify } from '@constants/theme';
import { MessageType } from '@constants/messageStack';
import { useMessageStack } from '@hooks/messageStack';
import { ExtrasList, ExtrasListItem, StyledLabelAgreement } from './addendumConfirmationModal.styled';
import { getModalTranslations, Translations, TranslationGroupes } from './translations';
import { Modal } from '../default';
import Checkbox from '../../form/checkbox';

interface Props {
  onSignAddendum: () => void;
  onCancelAddendum: () => void;
  contractAddendum: Addendum;
  contractPrice: number;
  translationGroup: TranslationGroupes;
}

export const AddendumConfirmationModal: React.FC<Props> = ({
  contractAddendum,
  onSignAddendum,
  onCancelAddendum,
  contractPrice,
  translationGroup,
}) => {
  const { t, dateFormat } = useI18n();
  const { infoMessage } = useMessageStack();
  const [checked, setChecked] = useState(false);
  const translations: Translations = getModalTranslations(t, translationGroup);

  if (!contractAddendum) {
    infoMessage(translations.error, MessageType.Error);
    return null;
  }

  const {
    addedExtras,
    removedExtras,
    expectedStartDate,
    costsRemoved,
    costsAdded,
  } = contractAddendum;

  return (
    <Modal
      align={TextAlign.Left}
      size={MODAL_SIZES.medium}
      closeOnOverlayClick={false}
      isOpen
      title={translations.modalTitle}
    >
      {removedExtras.length > 0 && (
      <>
        <Heading level={HeadingLevel.H4}>{translations.oldExtras}</Heading>
        <ExtrasList>
          {removedExtras.map(({
            name,
            price,
          }) => (
            <ExtrasListItem key={name}>
              <Span
                fontWeight={FontWeight.SemiBold}
              >
                {name}

              </Span>
              <Price
                color={TextColor.Sub}
                amount={parseInt(price, 10) / 100}
                displayZeroes
                displayCurrencySymbol
                postfix={t('per-month')}
              />
            </ExtrasListItem>
          ))}
        </ExtrasList>
        <Divider />
      </>
      )}
      {addedExtras.length > 0 && (
      <>
        <Heading level={HeadingLevel.H4}>{translations.newExtras}</Heading>
        <ExtrasList>
          {addedExtras.map(({
            name,
            price,
          }) => (
            <ExtrasListItem key={name}>
              <Span
                fontWeight={FontWeight.SemiBold}
              >
                {name}

              </Span>
              <Price
                color={TextColor.Sub}
                amount={parseInt(price, 10) / 100}
                displayZeroes
                displayCurrencySymbol
                postfix={t('per-month')}
              />
            </ExtrasListItem>
          ))}
        </ExtrasList>
        <Divider />
      </>
      )}
      <Heading level={HeadingLevel.H4}>{translations.newTotal}</Heading>
      <ExtrasList>
        <ExtrasListItem>
          <Span
            fontWeight={FontWeight.SemiBold}
          >
            {`${translations.newAmountPer} ${dateFormat(new Date(expectedStartDate))}`}

          </Span>
          <Price
            color={TextColor.Sub}
            amount={(contractPrice - costsRemoved + costsAdded) / 100}
            displayZeroes
            displayCurrencySymbol
            postfix={t('per-month')}
          />
        </ExtrasListItem>
      </ExtrasList>
      <StyledLabelAgreement htmlFor="agreement">
        <Checkbox
          name="agreement"
          checked={checked}
          onChange={() => setChecked((prevChecked) => !prevChecked)}
        />
        {translations.agreement}
      </StyledLabelAgreement>
      <ButtonBar justify={Justify.FlexEnd}>
        <Button
          outline
          onClick={onCancelAddendum}
        >
          {t('cancel')}
        </Button>
        <ActionButton
          disabled={!checked}
          caret
          scheme={ButtonSchemeName.Default}
          loading={false}
          onClick={onSignAddendum}
        >
          {translations.sign}
        </ActionButton>
      </ButtonBar>
    </Modal>
  );
};
