import React, { useState } from 'react';
import { SvgIcon } from '@atoms';
import { ModalSizes } from '@type';
import { MODAL_SIZES } from '@constants';
import { Modal } from '../default';
import ModalWrapper, { InfoButton } from './infoModal.styled';

interface Props {
  title?: string;
  size?: ModalSizes;
  description: string;
  showInfoButton?: boolean;
}

const InfoModal: React.FC<Props> = ({
  title = '',
  size = MODAL_SIZES.medium,
  description = '',
  showInfoButton = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <ModalWrapper>
      {showInfoButton && description !== '' && (
      <InfoButton onClick={openModal} type="button">
        <SvgIcon icon="questionMark" width="1.25rem" height="1.25rem" />
      </InfoButton>
      )}
      <Modal isOpen={isOpen} onClose={closeModal} closeOnOverlayClick title={title} size={size}>
        <div
          dangerouslySetInnerHTML={{ __html: description }} // eslint-disable-line react/no-danger
        />
      </Modal>
    </ModalWrapper>
  );
};

export default InfoModal;
