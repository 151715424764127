import React from 'react';
import styled from 'styled-components';
import { StyledComponentProps } from '@type';
import { useCursorPagination } from '@hooks/useCursorPagination';
import { PaginationButton } from './components';

const StyledPageNumber = styled.span<StyledComponentProps>`
  padding-right: 0.6rem;
  padding-left: 0.3rem;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.pagination.color.main};
`;

interface Props {
  stateKey: string;
}

const Pagination: React.FC<Props> = ({
  stateKey: key,
}) => {
  const {
    state: {
      [key]: {
        numberOfResults,
        numberOfPages,
        dataSource,
        currentPage,
      },
    },
    dispatch,
  } = useCursorPagination();

  return (
    <>
      <PaginationButton
        arrow="<<"
        disabled={!dataSource?.pageInfo?.hasPreviousPage}
        onClick={() => dispatch({
          type: 'LAST',
          key,
          payload: {
            before: null,
            after: null,
            last: null,
            first: numberOfResults,
          },
        })}
      />
      <PaginationButton
        arrow="<"
        disabled={!dataSource?.pageInfo?.hasPreviousPage}
        onClick={() => dispatch({
          type: 'NEXT',
          key,
          payload: {
            before: dataSource?.pageInfo?.startCursor,
            after: null,
            first: null,
            last: numberOfResults,
          },
        })}
      />
      <StyledPageNumber>{`${currentPage}/${numberOfPages}`}</StyledPageNumber>
      <PaginationButton
        arrow=">"
        disabled={!dataSource?.pageInfo?.hasNextPage}
        onClick={() => dispatch({
          type: 'PREV',
          key,
          payload: {
            before: null,
            after: dataSource?.pageInfo?.endCursor,
            first: numberOfResults,
            last: null,
          },
        })}
      />
      <PaginationButton
        arrow=">>"
        disabled={!dataSource?.pageInfo?.hasNextPage}
        onClick={() => dispatch({
          type: 'FIRST',
          key,
          payload: {
            before: null,
            after: null,
            last: numberOfResults,
            first: null,
          },
        })}
      />
    </>
  );
};
export default Pagination;
