import React, { useReducer } from 'react';
import { FormDataContext, initialState } from './context';
import { FormDataAction, FormDataState } from './types';

export const FormDataProvider: React.FC<{}> = ({ children }) => {
  const reducer = (state: FormDataState, action: FormDataAction) => {
    switch (action.type) {
      case 'setMetaValue':
        return {
          ...state,
          formMeta: {
            ...state.formMeta,
            ...action.payload,
          },
        };

      case 'setFormValue': case 'setValues':
        return {
          ...state,
          formData: {
            ...state.formData,
            ...action.payload,
          },
        };

      case 'reset': default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const setFormDataValue = (key: string, value: string) => dispatch({
    type: 'setFormValue',
    payload: { [key]: value },
  });

  const setFormMetaValue = (key: string, value: string) => dispatch({
    type: 'setMetaValue',
    payload: { [key]: value },
  });

  const setFormDataValues = (data: {
    [key: string]: any;
  }) => dispatch({
    type: 'setValues',
    payload: { ...data },
  });

  const resetFormData = () => dispatch({
    type: 'reset',
    payload: null,
  });

  return (
    <FormDataContext.Provider value={{
      ...state,
      setFormDataValue,
      setFormDataValues,
      setFormMetaValue,
      resetFormData,
    }}
    >
      {children}
    </FormDataContext.Provider>
  );
};
