import styled, { css } from 'styled-components';
import { TextColor, StyledComponentProps, TextAlign } from '@type';

interface Props extends StyledComponentProps {
  marginBottom?: string;
  subText?: boolean;
  themeColor?: TextColor;
  textAlign?: TextAlign;
  width?: string;
}

export default styled.p<Props>`
  margin-bottom: ${({ marginBottom }) => (marginBottom || '0.5rem')};
  ${({ subText }) => subText && css`
    font-size: 0.875rem;
  `}
  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}
  ${({ width }) => width && css`
    width: ${width};
  `}
  ${({ themeColor, theme }) => themeColor && css`
    color: ${theme.span.color[themeColor]};
  `}
`;
