import React from 'react';
import YouTube, { Options } from 'react-youtube';
import styled from 'styled-components';
import { useI18n } from '@hooks/i18n';
import { P, Heading, CardContent } from '@atoms';
import { HeadingLevel } from '@type';
import { Card } from '../card';

interface Props {
  videoId: string;
  width: number;
}

const Video = styled(YouTube)`
  max-height: 100%;
  max-width: 100%;
`;

export const YoutubePlayer: React.FC<Props> = ({
  videoId,
  width,
}) => {
  const { t } = useI18n();
  const options: Options = {
    width: width.toString(),
    height: ((width / 16) * 9).toString(),
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
    },
  };

  return (
    <Card>
      <CardContent>
        <Heading level={HeadingLevel.H2}>{t('youtube-player-title')}</Heading>
        <P marginBottom="1.7rem" subText>{t('youtube-player-subtitle')}</P>
        <Video videoId={videoId} opts={options} />
      </CardContent>
    </Card>
  );
};
