import React from 'react';
import UploadedDocument from '../uploadedDocument';
import { StyledDocumentsList } from './styled';

interface Document {
  id: number;
  type: string;
  deleteUri: string | null | undefined;
  downloadUri: string | null | undefined;
  originalName: string;
}

interface Props {
  documents: Array<Document>;
  onDeleteComplete: (...args: Array<any>) => any;
}

const DocumentsList: React.FC<Props> = (({
  documents,
  onDeleteComplete,
}) => (
  <StyledDocumentsList>
    {documents
    && documents.map((document) => (
      <UploadedDocument
        key={document.id}
        onDeleteComplete={onDeleteComplete}
        deleteEnabled={document.deleteUri !== null}
        {...document} // eslint-disable-line react/jsx-props-no-spreading
      />
    ))}
  </StyledDocumentsList>
));

export default DocumentsList;
