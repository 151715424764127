import React from 'react';
import Link from 'next/link';
import { isMultiLanguage, hasProfile } from '@flags';
import { useAuthentication } from '@hooks/authentication';
import { useI18n, GetTranslation } from '@hooks/i18n';
import { SvgIcon } from '@atoms';

import { UserNavigation, Header, LanguageSelector } from './components';

const getMenuItems = (t: GetTranslation) => {
  const menuItems = [];
  if (hasProfile()) {
    menuItems.push({
      to: '/profile',
      label: t('user-profile'),
    });
  }
  menuItems.push({
    to: '/logout',
    label: t('user-logout'),
  });

  return menuItems;
};

interface Props {
  themeName: string;
}

export const SiteHeader = ({ themeName }: Props) => {
  const { t } = useI18n();
  const { isAuthenticated } = useAuthentication();

  return (
    <Header>
      <Link href="/" as="/">
        <a>
          <SvgIcon icon={themeName === 'jlbe' ? 'logo' : 'jlLogo'} width="9.25rem" height="1.473rem" />
        </a>
      </Link>
      {(!isAuthenticated && isMultiLanguage()) && <LanguageSelector />}
      {isAuthenticated && (
        <UserNavigation menuItems={getMenuItems(t)} />
      )}
    </Header>
  );
};
