import styled from 'styled-components';

export const ExtrasList = styled.ul`
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
`;

export const ExtrasListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

export const StyledLabelAgreement = styled.label`
  display: block;
  margin-bottom: 1.5rem;
  user-select: none;
`;
