import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';
import { TextArea, Label } from '@atoms/form';

const activeStyle = css<StyledComponentProps>`
  padding-top: 1.8rem;

  & + ${/* sc-sel */Label} {
    color: ${({ theme }) => theme.form.label.color};
    font-size: 0.8em;
    top: 0.5rem;
  }
`;

export const TextAreaStyled = styled(TextArea)`
  &::placeholder {
    color: transparent;
    transition: color 0.3s ease;
  }

  &:not(:empty),
  &:focus {
    ${activeStyle}
  }
`;
