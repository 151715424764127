import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { upload as uploadDocument, isUploading, isUploadingAvailable } from '@store/uploads';
import { GlobalState } from '@store';
import { getEnv } from '@utils';
import { Toaster } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { useAuthentication } from '@hooks/authentication';
import { TextColor } from '@type';
import { MessageType } from '@constants/messageStack';
import { useMessageStack } from '@hooks/messageStack';
import * as Sentry from '@sentry/nextjs';
import UploadItem from './uploadItem';

const StyledToaster = styled(Toaster)`
  margin-bottom: 1rem;
`;

interface Props {
  title: string;
  id: number;
  description: string;
  hasUploads: boolean;
  onUploadComplete: (id: number) => void;
  groupCompleted: boolean;
  isRejected: boolean;
  rejectionReason: string | null;
  uploadUri: string | null;
  uploads: Array<{
    id: number;
    type: string;
    deleteUri: string | null;
    downloadUri: string | null;
    originalName: string;
  }>;
  fetchingUploadItem: boolean;
}

const UploadItemContainer: React.FC<Props> = (props) => {
  const { accessToken } = useAuthentication();
  const { infoMessage } = useMessageStack();
  const dispatch = useDispatch();
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [successfulUpload, setSuccessfulUpload] = useState(false);
  const { t } = useI18n();
  const maxFileSize: number = Number(getEnv('NEXT_PUBLIC_MYENV_MAX_SINGLE_UPLOAD_SIZE'));
  const {
    onUploadComplete,
    id,
    uploadUri,
    groupCompleted,
    fetchingUploadItem,
  } = props;
  const cosigneeToken = useSelector((state: GlobalState) => state.cosignee.token);

  const uploading = useSelector((state: GlobalState) => isUploading(state, id));
  const uploadAvailable = useSelector(isUploadingAvailable);

  const handleChange = async (event: { target: HTMLInputElement }) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const fileSize = files[0].size;

      if (fileSize > maxFileSize) {
        setShowSizeWarning(true);
      } else {
        setShowSizeWarning(false);
        try {
          if (!uploadUri) {
            return;
          }
          await dispatch(uploadDocument(id, files[0], uploadUri, accessToken, cosigneeToken));
          setSuccessfulUpload(true);
        } catch (error) {
          infoMessage(error.errorCode, MessageType.Error);
          setShowSizeWarning(true);
          setSuccessfulUpload(false);
          Sentry.captureException(error);
        }
      }
      await onUploadComplete(id);
    }
  };

  const isProcessing = () => (
    (uploading || !uploadAvailable || fetchingUploadItem) && !showSizeWarning
  );

  return (
    <>
      <UploadItem
      // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        successfulUpload={successfulUpload}
        processing={isProcessing()}
        groupCompleted={groupCompleted}
        onChange={handleChange}
        enabledUpload={uploadUri !== null}
      />
      {showSizeWarning && (
      <StyledToaster color={TextColor.Error}>
        {t('maximum-upload-size-warning', {
          replace: {
            '%size%': (maxFileSize / 1000000).toString(),
          },
        })}
      </StyledToaster>
      )}
    </>
  );
};

export default UploadItemContainer;
