import styled, { css } from 'styled-components';
import { media } from '@utils';

const stackedItems = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-items: stretch;

  &.postponed-enabled {
    gap: 1rem;
    grid-template-rows: auto;

    & > button {
      grid-area: initial;
      justify-self: stretch;
    }

    & > a {
      grid-area: initial;
      justify-self: stretch;
    }

    & > div {
      grid-area: initial;
      justify-self: stretch;
    }
  }

  .payment {
    height: initial;
  }
`;

const justifiedItems = css`
  align-items: start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;

  &.postponed-enabled {
    gap: 1rem;
    grid-template-rows: repeat(2, auto);

    & > a {
      grid-area: 1/2/2/3;
      justify-self: end;

      &:first-of-type {
        grid-area: initial;
        justify-self: start;
      }
    }

    & > div {
      grid-area: 2/1/3/2;
      justify-self: stretch;
    }
  }
`;

export const ActionBar = styled.section`
  ${stackedItems}

  ${media.lg(css`
    ${justifiedItems}
  `)}
`;

export const PaymentInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;
