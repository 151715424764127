import {
  InMemoryCache, ApolloClient, ServerError, ApolloLink,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GetTranslation } from '@hooks/i18n';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

export const apolloCache = new InMemoryCache({
  resultCaching: true,
  typePolicies: {
    CreditCheckData: {
      keyFields: [],
      merge: true,
    },
    Contract: {
      merge: true,
    },
    DocumentRequestCategory: {
      keyFields: ['label'],
    },
    DocumentRequest: {
      keyFields: ['id', 'category'],
    },
    LeaseItem: {
      keyFields: false as false,
    },
    LeaseItemExtra: {
      merge: true,
      keyFields: ((object) => `${object.__typename}:${object?.id || '0'}-${object?.pdbExtraId || '0'}`),
    },
    LeaseItemColor: {
      merge: true,
    },
    LeaseItemPackage: {
      merge: true,
    },
    Invoice: {
      merge: true,
      keyFields: ['invoiceNumber'],
    },
    DamageReport: {
      merge: true,
    },
    Employment: {
      merge: true,
      keyFields: ['name'],
    },
    QuarterMileage: {
      merge: true,
      keyFields: ['quarterStartDate'],
    },
    Redemption: {
      merge: true,
      keyFields: ['date, price'],
    },
    YearlyMileage: {
      merge: true,
      keyFields: ['startDate'],
    },
    User: {
      merge: true,
      keyFields: [],
    },
  },
});

/**
 * Link to handle errors
 * For now this will log the user off when a 401 occures
 */
const authErrorLink = (
  logout: () => void,
  isAuthenticated: boolean,
) => onError(({
  networkError,
}) => {
  if (networkError && isAuthenticated) {
    const { statusCode } = networkError as ServerError;

    if (statusCode === 401 || statusCode === 403) {
      console.log('error in graphql without auth');
      logout();
    }
  }
});

const getAuthString = (accessToken: string, cosigneeToken?: string) => {
  if (accessToken) {
    return `Bearer ${accessToken}`;
  }
  if (cosigneeToken) {
    return `cosignee-bearer ${cosigneeToken}`;
  }

  return '';
};
/**
 * Link to append token on the GraphQL request
 */
const authLink = (accessToken: string, cosigneeToken?: string) => setContext((_, {
  headers,
}) => {
  const authString = getAuthString(accessToken, cosigneeToken);
  return {
    headers: {
      ...headers,
      authorization: authString,
    },
  };
});

const COSIGNEE_PATH_NAME = 'welcome';
const QUERY_PARAM_DELIMITER = '=';

const retrieveToken = (cosigneeToken:string | undefined): string => {
  if (cosigneeToken) {
    return cosigneeToken;
  }

  if (window.location.pathname.includes(COSIGNEE_PATH_NAME)) {
    return window.location.search.split(QUERY_PARAM_DELIMITER)?.[1] ?? '';
  }
  return '';
};

export const createApolloClient = async (
  url: string,
  logout: () => void,
  accessToken: string,
  isAuthenticated: boolean,
  t: GetTranslation,
  cosigneeToken?: string,
) => {
  if (typeof window !== 'undefined') {
    await persistCache({
      cache: apolloCache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
  }

  const cosigneeTokenLocal = retrieveToken(cosigneeToken);
  return new ApolloClient({
    ssrMode: true,
    // @ts-ignore
    link: ApolloLink.from([
    // @ts-ignore
      authErrorLink(logout, isAuthenticated, t),
      authLink(accessToken, cosigneeTokenLocal),
      // @ts-ignore
      createUploadLink({
        uri: url,
      }),
    ]),
    cache: apolloCache,
  });
};
