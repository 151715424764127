import { gql } from '@apollo/client';

const EMPLOYMENT_TYPE = gql`
query employmentType{
  quotes {
    prospect {
      employmentType
    }
  }
}
`;
export default EMPLOYMENT_TYPE;
