import styled, { css } from 'styled-components';
import media from '@utils/media';

const ArrowIcon = styled.div`
  ::after {
    content: '›';
  }

  transform: rotate(90deg);
  font-size: 20px;
  width: 1rem;
  margin-left: 0.5rem;
  justify-content: center;
  display: none;
  ${media.sm(css`
    display: flex;
  `)};
`;

export default ArrowIcon;
