import React, { useState } from 'react';
import styled from 'styled-components';
import { SvgIcon, Button } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { StyledComponentProps as StyledProps, ModalSizes } from '@type';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';
import CancelButton from './button';
import { Modal } from '../default';

interface Props {
  size: ModalSizes;
  title: string;
  quoteNumber: string;
  cancelUri: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Description = styled.span<StyledProps>`
  text-align: center;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.modal.description};
`;

const BackButton = styled(Button)`
  margin-top: 1rem;
`;

export const CancelQuoteModal: React.FC<Props> = ({
  size,
  title,
  quoteNumber,
  cancelUri,
}) => {
  const { t } = useI18n();
  const [isOpen, setIsOpen] = useState(false);

  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quoteNumber]?.isPreorder;

  const cancelQuoteButtonText = isPreorder
    ? t('quote-cancel-request-preorder')
    : t('quote-cancel-request');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Button outline onClick={openModal}>
        {cancelQuoteButtonText}
      </Button>
      <Modal
        onClose={closeModal}
        isOpen={isOpen}
        closeOnOverlayClick
        size={size}
        title={title}
        iconComponent={<SvgIcon icon="cancelcontract" width="5rem" height="5rem" />}
      >
        <Container>
          <Description>{t('cancel-modal-description')}</Description>
          <CancelButton quoteNumber={quoteNumber} uri={cancelUri} />
          <BackButton fullWidth onClick={closeModal} outline>{t('back-to-quote-summary')}</BackButton>
        </Container>
      </Modal>
    </>
  );
};
