import React from 'react';
import styled from 'styled-components';

const BasicTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

interface Props {
  head: JSX.Element;
  body: JSX.Element;
}

const Table: React.FC<Props> = ({
  head,
  body,
}) => (
  <BasicTable>
    <thead>
      {head}
    </thead>
    <tbody>
      {body}
    </tbody>
  </BasicTable>
);

export default Table;
