import React from 'react';
import { TransitionGroup } from 'react-transition-group';
import {
  Wrapper, Message, Transition,
} from '@atoms';
import { StackMessage } from '@hooks/messageStack/types';
import { Icon } from './svg';

interface Props {
  messages: Record<string, StackMessage>;
  onClose: (id: string | number) => void;
}

export const MessageStack: React.FC<Props> = ({
  messages,
  onClose,
}) => (
  <Wrapper className="message-stack">
    <TransitionGroup>
      {Object.entries(messages).map(([key, message]) => (
        <Transition key={key}>
          <Message
            className="message-stack-message"
            type={message.type}
            onClick={() => onClose(key)}
          >
            {message.message}
            <div className="message-icon">
              <Icon type={message.type} />
            </div>
          </Message>
        </Transition>
      ))}
    </TransitionGroup>
  </Wrapper>
);
