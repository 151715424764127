import styled from 'styled-components';
import { CardContent } from '@atoms';

export const Content = styled(CardContent).attrs({
  as: 'article',
})<{ isAutoHeightContent?: boolean }>`
  display: grid;
  grid-template-rows: 10rem ${(props) => (props.isAutoHeightContent ? 'auto' : '6.25rem')} 1fr;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;
