import { QuoteProgressRedirectStatus } from '@constants/quoteProgress';
import { GlobalState } from '../types';
import { Action } from './types';

export const getStatus = (
  state: GlobalState,
  quoteNumber: string,
  type: Action,
): QuoteProgressRedirectStatus | null => (
  state.quoteFlow[quoteNumber] && state.quoteFlow[quoteNumber][type]
    ? state.quoteFlow[quoteNumber][type]
    : null
);

export const getHasEnabledButton = (
  state: GlobalState,
): boolean => state.nextButton.shouldEnableButton;
