import React from 'react';
import styled from 'styled-components';
import { HeadingLevel } from '@type';
import Heading from '../../heading';
import ToggleButton from '../../toggleButton';

const LinkHeader = styled.header`
  display: flex;
  justify-content: space-between;

  > :first-child {
    margin: 0;
  }
`;

interface Props {
  level?: HeadingLevel;
  onClick: (...args: Array<any>) => any;
}

export const LinkCardHeader: React.FC<Props> = ({
  children,
  level = HeadingLevel.H4,
  onClick,
}) => (
  <LinkHeader onClick={onClick}>
    <Heading level={level}>{children}</Heading>
    <ToggleButton isLinkButton type="button">&nbsp;</ToggleButton>
  </LinkHeader>
);
