import React, { useState, useEffect, useContext } from 'react';
import CURRENT_QUOTE, {
  CurrentQuoteResponse, DocumentRequestsCategory, QUOTE_FLOW_TYPE, QuoteTypeResponse,
  CurrentQuoteArgs, CURRENT_OCKTO_QUOTE, CURRENT_DEFAULT_QUOTE, CURRENT_DEFAULT_QUOTE_BE,
} from '@queries/quote/currentQuote';
import { QuoteFlows } from '@constants/quoteProgress';
import { CURRENT_QUOTE_NUMBER } from '@constants/localStorageKeys';
import { useI18n } from '@hooks/i18n';
import { ApolloContext } from '@context';
import { useQuery } from '@apollo/client';
import { FlowTypes } from '@organisms/quoteFlow/creditCheck/types';
import { UploadGroup } from '../uploadGroup';

interface Props {
  quoteNumber: string;
  refetchQuoteStatusQuery: () => void;
  variant?: FlowTypes | undefined;
  quoteData?: CurrentQuoteResponse | undefined;
}

const DocumentsUpload: React.FC<Props> = ({
  quoteNumber,
  refetchQuoteStatusQuery,
  variant,
  quoteData,
}) => {
  const [
    documentRequestsCategories, setDocumentRequestsCategories,
  ] = useState<Array<DocumentRequestsCategory>>();
  const client = useContext(ApolloContext).leadHub;
  const [uploadItemIds, setUploadItemIds] = useState<Array<number>>([]);
  const { t } = useI18n();
  const [quoteFlowType, setQuoteFlowType] = useState<QuoteFlows>(QuoteFlows.Default);
  const [quoteQuery, setQuoteQuery] = useState(CURRENT_DEFAULT_QUOTE);
  // XXX QUICK FIX. has to pass the prop from parent or run refetch query instead
  const determineQuoteQuery = (flowType: QuoteFlows) => {
    if (flowType === QuoteFlows.JlnlOckto) {
      return CURRENT_OCKTO_QUOTE;
    }

    if (flowType === QuoteFlows.Jlbe) {
      return CURRENT_DEFAULT_QUOTE_BE;
    }

    if (QuoteFlows.Default === flowType) {
      return CURRENT_DEFAULT_QUOTE;
    }

    return CURRENT_QUOTE;
  };

  const { data: quoteTypeData } = useQuery<QuoteTypeResponse, CurrentQuoteArgs>(
    QUOTE_FLOW_TYPE, {
      client,
      variables: {
        number: quoteNumber as string,
      },
    },
  );

  useEffect(() => {
    if (quoteTypeData?.quotes?.[0].quoteFlow) {
      setQuoteFlowType(quoteTypeData?.quotes?.[0].quoteFlow);
    }
  }, [quoteTypeData]);

  useEffect(() => {
    setQuoteQuery(determineQuoteQuery(quoteFlowType));
  }, [quoteFlowType]);

  const { data, loading, refetch } = useQuery<CurrentQuoteResponse>(quoteQuery, {
    client,
    fetchPolicy: 'network-only',
    variables: { number: quoteNumber },
  });

  useEffect(() => {
    localStorage.setItem(CURRENT_QUOTE_NUMBER, quoteNumber);
  }, []);

  const refetchData = async (itemId: number) => {
    setUploadItemIds([...uploadItemIds, itemId]);
    await refetch();
    setUploadItemIds([...uploadItemIds.filter((item) => item !== itemId)]);
    refetchQuoteStatusQuery?.();
  };

  useEffect(() => {
    const localQuotes = data?.quotes ?? quoteData?.quotes;
    if (localQuotes) {
      setDocumentRequestsCategories(
        localQuotes[0].documentRequestsCategories,
      );
    }
  }, [data, quoteData]);

  if (loading && !documentRequestsCategories) {
    return <></>;
  }

  const documentCategories: Record<string, string> = {
    general: t('upload-document-group-general-title'),
    cosignee: t('upload-document-group-cosignee-title'),
  };

  return (
    <>
      {documentRequestsCategories && documentRequestsCategories.filter(({
        label,
      }) => label !== 'deposit').map(({
        title,
        label,
        documentRequests,
      }) => (
        <UploadGroup
          uploadItemIds={uploadItemIds}
          key={label}
          title={documentCategories[label] || title}
          onUploadComplete={refetchData}
          documentRequests={documentRequests}
          variant={variant}
        />
      ))}
    </>
  );
};

export default DocumentsUpload;
