import React from 'react';
import styled from 'styled-components';
import { CardContent } from '@atoms';
import { Card } from '@molecules';
import { StyledComponentProps as StyledProps } from '@type';
import { useI18n } from '@hooks/i18n';
import CheckmarkTitle from '@atoms/checkmarkTitle';

const InfoText = styled.p<StyledProps>`
  color: ${({ theme }) => theme.deposit.text.generalInfoColor};
`;

export const AuthorisationDone = () => {
  const { t } = useI18n();

  return (
    <Card renderHeader={() => <CheckmarkTitle successColor showIcon>{t('authorisation-done-title')}</CheckmarkTitle>}>
      <CardContent>
        <InfoText>{t('authorisation-done-description')}</InfoText>
      </CardContent>
    </Card>
  );
};
