import styled, { css } from 'styled-components';
import media from '@utils/media';
import { rotate } from '@utils/animations';
import { StyledComponentProps } from '@type';
import { UPLOAD_MIME_TYPES } from '@constants';

export const FileInput = styled.input.attrs({
  type: 'file',
  accept: UPLOAD_MIME_TYPES,
})`
  clip: rect(0, 0, 0, 0);
  height: 0;
  position: absolute;
  width: 0;
`;

interface Props extends StyledComponentProps {
  processing: boolean;
  linkStyle: boolean;
}

export const UploadButton = styled.label<Props>`
  border: 1px solid ${({ theme }) => theme.uploadButton.borderColor.main};
  border-radius: 4px;
  color: ${({ theme }) => theme.uploadButton.color.main};
  cursor: pointer;
  display: block;
  line-height: 2.5rem;
  position: relative;
  text-align: center;
  transition:
    color 0.3s ease,
    border-color 0.3s ease;
  width: 100%;
  ${({ linkStyle }) => !linkStyle && media.md(css`
    margin-left: 1rem;
    padding: 0 1rem;
    white-space: nowrap;
    width: auto;
  `)}
  ${({ linkStyle }) => linkStyle && css`
    border: none;
    display: inline-block;
    font-size: 0.875rem;
    line-height: 1.5;
    width: auto;
  `}

  &:hover {
    border-color: ${({ theme }) => theme.uploadButton.borderColor.hover};
    color: ${({ theme }) => theme.uploadButton.color.hover};
    ${({ linkStyle }) => linkStyle && css`
      text-decoration: underline;
    `}
  }

  &::before,
  &::after {
    content: '';
    display: block;
    opacity: 0;
    transition: opacity 0.05s ease;
  }

  ${({ processing }) => processing && css`
    &::before,
    &::after {
      background: transparent;
      border-style: solid;
      border-radius: 50%;
      opacity: 1;
      position: absolute;
      top: 50%;
    }

    &::before {
      animation: ${rotate} 1.7s linear infinite -1s;
    }

    &::after {
      animation: ${rotate} 1s linear infinite 0.1s;
    }
  `};
  ${({ theme, processing, linkStyle }) => processing && !linkStyle && css`
    background: currentColor;
    border-color: currentColor;
    color: ${theme.buttons.schemes.disabled.main} !important;

    &::before,
    &::after {
      border-color: transparent white;
      border-width: 3px;
      left: 50%;
      transform-origin: top left;
      transform:
        rotate(0deg)
        translate(-50%, -50%);
    }

    &::before {
      height: 0.5rem;
      width: 0.5rem;
    }

    &::after {
      height: 1.25rem;
      width: 1.25rem;
    }
  `}
  ${({ theme, processing, linkStyle }) => processing && linkStyle && css`
    color: ${theme.buttons.schemes.disabled.main} !important;

    &::before,
    &::after {
      border-color: transparent orangered;
      border-width: 2px;
      left: calc(100% + 1rem);
      transform-origin: top left;
      transform:
        rotate(0deg)
        translate(50%, -50%);
    }

    &::before {
      height: 0.3rem;
      width: 0.3rem;
    }

    &::after {
      height: 0.8rem;
      width: 0.8rem;
    }
  `};
`;

export default null;
