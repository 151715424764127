import { gql } from '@apollo/client';
import { ProductState } from '@constants';

export interface Quote {
  quoteNumber: string;
  preorder?: boolean;
  quoteFlow: string;
  cancelUri?: string;
  state: ProductState;
  prospectProgress: number;
  leaseItem: {
    make: string;
    model: string;
    fuelType: string;
    recurringCosts: number;
    color: {
      name: string,
      imageUrl: string;
    };
    package: {
      power: string;
      engine: string;
      deliveryTime: number;
    };
  };
  connectedToStock?: boolean;
}

export interface QuoteList {
  quotes: Array<Quote | null>;
}

export interface QuoteFlowList {
  quotes: Array<Quote | null>;
}

export const QUOTE_FLOW_QUERY = gql`query QuoteFlowList {
  quotes {
    quoteFlow
  }
}`;

export const QUOTE_LIST_WITH_PREORDER_QUERY = gql`query QuoteListWithPreorderInfo {
  quotes {
    id
    state,
    prospectProgress,
    quoteNumber
    cancelUri,
    preorder,
    quoteFlow
    leaseItem { # TODO move this to a fragment? https://www.apollographql.com/docs/react/advanced/fragments.html#colocating-fragments
      id
      recurringCosts,
      make
      model
      color {
        id
        name
        imageUrl
      }
      package {
        id
        name
        engine
        deliveryTime
      }
    },
    connectedToStock
  }
}`;

export const QUOTE_LIST_QUERY = gql`query QuoteList {
  quotes {
    id
    state,
    prospectProgress,
    quoteNumber
    cancelUri,
    quoteFlow
    leaseItem { # TODO move this to a fragment? https://www.apollographql.com/docs/react/advanced/fragments.html#colocating-fragments
      id
      recurringCosts,
      make
      model
      color {
        id
        name
        imageUrl
      }
      package {
        id
        name
        engine
        deliveryTime
      }
    },
    connectedToStock
  }
}`;

export default { QUOTE_LIST_QUERY, QUOTE_FLOW_QUERY, QUOTE_LIST_WITH_PREORDER_QUERY };
