import { useContext } from 'react';
import { MessageStackContext } from './context';
import { UseMessageStack } from './types';

export const useMessageStack = (): UseMessageStack => {
  const { messages, infoMessage, hideMessage } = useContext(MessageStackContext);

  return {
    messages,
    infoMessage,
    hideMessage,
  };
};
