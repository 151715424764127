import React, { useState } from 'react';
import { OptionInput, OptionLabel } from './components';

interface Props {
  defaultChecked?: boolean;
  label: string;
  name: string;
  value: string;
  onChange: (...args: Array<any>) => any;
  validate?: ((...args: Array<any>) => any)[];
}

export const OptionField: React.FC<Props> = ({
  defaultChecked = false,
  label,
  name,
  validate = [],
  onChange,
  ...input
}) => {
  const [value] = useState(input.value);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(false);

  const validateValue = (newValue: string) => {
    const result = validate.map(
      (rule: (...args: Array<any>) => any) => rule(newValue),
    ).find((message) => message);

    setError(result);
  };

  const onBlur = () => {
    setTouched(true);
    validateValue(value);
  };

  return (
    <>
      <OptionInput
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type="radio"
        id={`${name}-${value}`}
        defaultChecked={defaultChecked}
      />
      <OptionLabel error={Boolean(error)} touched={touched} htmlFor={`${name}-${value}`}>
        {label}
      </OptionLabel>
    </>
  );
};
