import React from 'react';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';

const Transition: React.FC = ({
  children,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <CSSTransition {...props} timeout={500} classNames="message">
    {children}
  </CSSTransition>
);

export default styled(Transition)`
  &.message-enter {
    transform: translateY(120%);

    &.message-enter-active {
      transition: transform 0.3s ease 0.1s;
      transform: translateY(0);
    }
  }

  &.message-exit {
    transform: translateX(0);

    &.message-exit-active {
      transform: translateX(-200%);
      transition: transform 800ms ease-in;
    }
  }
`;
