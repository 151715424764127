import React, { useContext, useEffect, useState } from 'react';
import {
  Price, Heading, CardHighlight, Toaster,
} from '@atoms';
import { ThemedList } from '@molecules';
import { useI18n } from '@hooks/i18n';
import { HeadingLevel } from '@type';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { CurrentQuoteArgs, QUOTE_FLOW_TYPE, QuoteTypeResponse } from '@queries/quote/currentQuote';
import { ApolloContext } from '@context';
import { QuoteFlows } from '@constants/quoteProgress';

interface Props {
  deposit: number | null;
  documents: Array<string>;
}

const RequestedDocumentsBlock: React.FC<Props> = ({
  deposit,
  documents,
}) => {
  const { t } = useI18n();
  const { quoteNumber } = useRouter().query;
  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const client = useContext(ApolloContext).leadHub;
  const [quoteFlowType, setQuoteFlowType] = useState<QuoteFlows>(QuoteFlows.Default);
  const isPreorder = quotesInfo[quoteNumber as string]?.isPreorder;

  const { data: quoteTypeData } = useQuery<QuoteTypeResponse, CurrentQuoteArgs>(
    QUOTE_FLOW_TYPE, {
      client,
      variables: {
        number: quoteNumber as string,
      },
    },
  );

  useEffect(() => {
    if (quoteTypeData?.quotes?.[0].quoteFlow) {
      setQuoteFlowType(quoteTypeData?.quotes?.[0].quoteFlow);
    }
  }, [quoteTypeData]);

  return (
    <CardHighlight>
      {documents.length > 0
        && (
          <>
            <Heading level={HeadingLevel.H4}>{t('list-subtitle')}</Heading>
            <ThemedList ordered>
              {documents.map((document) => (
                <li key={t(document)}>
                  {t(document)}
                </li>
              ))}
            </ThemedList>
          </>
        )}
      { !isPreorder && quoteFlowType === QuoteFlows.JlnlB2B && (
      <>
        <Heading level={HeadingLevel.H4}>{t('price-subtitle')}</Heading>
        {deposit === null ? <Toaster>{t('deposit-not-determined')}</Toaster>
          : (
            <>
              {`${t('deposit')}: `}
              <Price amount={deposit} decimalDash displayCurrencySymbol />
            </>
          )}
      </>
      )}
    </CardHighlight>
  );
};

export default RequestedDocumentsBlock;
