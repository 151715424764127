import React, { ComponentType } from 'react';

export interface AbortControllerProps {
  getAbortSignal: (key: string) => Record<string, any>;
  abort: (key: string) => void;
  abortAll: () => void;
}

const withAbortController = <P extends object>(
  WrappedComponent: ComponentType<P & AbortControllerProps>,
) => (
    (props: P) => {
      const abortControllers: Record<string, any> = {};

      const getAbortSignal = (key: string): Record<string, any> => {
        let abortController = abortControllers[key];
        if (!abortController && typeof AbortController !== 'undefined') {
          abortControllers[key] = new AbortController();
          abortController = abortControllers[key];
        }
        return abortController?.signal;
      };

      const abort = (key: string): void => {
        const abortController = abortControllers[key];
        if (abortController) {
          abortController.abort();
        }
      };

      const abortAll = (): void => {
        Object.values(abortControllers).forEach((abortController) => {
          abortController.abort();
        });
      };

      return (
        <WrappedComponent
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          getAbortSignal={getAbortSignal}
          abort={abort}
          abortAll={abortAll}
        />
      );
    }
  );
export default withAbortController;
