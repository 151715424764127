import React from 'react';
import styled, { css } from 'styled-components';
import { IconList, IconItem } from '@molecules/iconList';
import media from '@utils/media';
import {
  Heading, Link, CardHeader, CardContent,
} from '@atoms';
import { HeadingLevel, TextColor } from '@type';
import { Card } from '../card';

const CardStyle = styled(Card)`
  ul {
    columns: 1;
    ${media.lg(css` columns: 2; `)};
    ${media.xl(css` columns: 1; `)};
  }
`;

export interface ListItem {
  icon: string;
  iconColor: TextColor;
  usp: string;
  url: string;
  target?: string;
}

interface ItemListProps {
  items: Array<ListItem>;
}

interface Props extends ItemListProps {
  title?: {
    text: string;
    iconSize?: string;
    iconLeft?: string;
    iconRight?: string;
  } | null | undefined;
  subtitle?: string;
  isLinkList?: boolean;
}

const ItemList: React.FC<Props> = ({ items }) => (
  <IconList>
    {items.map((item) => (
      <IconItem key={item.usp} icon={item.icon} iconColor={item.iconColor}>
        {item.usp}
      </IconItem>
    ))}
  </IconList>
);

const LinkItemList: React.FC<Props> = ({ items }) => (
  <IconList>
    {items.map((item) => (
      <IconItem key={item.usp} icon={item.icon} divider iconColor={item.iconColor}>
        <Link target={item.target} href={item.url}>{item.usp}</Link>
      </IconItem>
    ))}
  </IconList>
);

export const CardWithList: React.FC<Props> = ({
  title = null,
  subtitle = '',
  items,
  isLinkList = false,
}) => (
  <CardStyle renderHeader={() => title && (
  <CardHeader leftIcon={title.iconLeft} rightIcon={title.iconRight} iconSize={title.iconSize}>
    {title.text}
  </CardHeader>
  )}
  >
    <CardContent>
      {subtitle && <Heading level={HeadingLevel.H4}>{subtitle}</Heading>}
      {isLinkList ? <LinkItemList items={items} /> : <ItemList items={items} />}
    </CardContent>
  </CardStyle>
);
