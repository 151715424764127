import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as Props } from '@type';

export const Key = styled.div<Props>`
  color: ${({ theme }) => theme.keyValue.key.color};
  font-size: 1em;
  font-weight: 600;
  vertical-align: top;
  grid-area: 1 / 1 / 2 / 3;
  ${media.md(css`
    grid-area: 1 / 1 / 2 / 2;
  `)}
`;
