import uiSettings from './reducers';

import {
  receivedUiSettings, setUiSetting, toggleUiBooleanSetting, toggleUiStringSetting,
} from './actions';
import { getUiSetting, getUiSettingOrDefault } from './selectors';

export {
  getUiSetting,
  receivedUiSettings,
  setUiSetting,
  getUiSettingOrDefault,
  toggleUiBooleanSetting,
  toggleUiStringSetting,
};

export default uiSettings;
