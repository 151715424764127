import styled from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';

export const HighLight = styled.div<StyledProps>`
  text-align: center;
  line-height: 1.75em;
  border-radius: 0.4em;
  left: 1rem;
  top: 1rem;
  position: absolute;
  background-color: ${({ theme }) => theme.product.label.backgroundColor};
  color: ${({ theme }) => theme.product.label.color};
  min-width: 8em;
  font-size: 0.75rem;
  padding: 0.25rem;
  z-index: 1;
`;
