import React from 'react';
import { ArrowIcon, SvgIcon } from '@atoms';
import { NavigationButton, ButtonLabel } from './components';

interface Props {
  label: string;
  icon?: string | null | undefined;
  active: boolean;
  onClick: (...args: Array<any>) => any;
}

const HeaderNavigationButton: React.FC<Props> = ({
  onClick,
  label,
  icon = null,
  active,
}) => (
  <NavigationButton onClick={onClick} active={active}>
    {icon && <SvgIcon icon={icon} width="1.3rem" height="1.3rem" />}
    <ButtonLabel>{label}</ButtonLabel>
    <ArrowIcon />
  </NavigationButton>
);

export default HeaderNavigationButton;
