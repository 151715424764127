import styled from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';

export const StyledLink = styled.a<StyledProps>`
  color: ${({ theme }) => theme.html.anchor.color.main};
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
  transition: color 0.3s ease;

  &::after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    transition: width 0.3s ease;
    width: 0%;
  }

  &:hover {
    color: ${({ theme }) => theme.html.anchor.color.hover};

    &::after {
      width: 100%;
    }
  }

  &:active {
    color: ${({ theme }) => theme.html.anchor.color.active};
  }

  &:visited {
    color: ${({ theme }) => theme.html.anchor.color.main};
  }
`;
