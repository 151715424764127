import { QuoteAction, TokenAction, State } from '@store/cosignee/types';
import { SET_QUOTE_NUMBER, SET_TOKEN } from './constants';

const initialState: State = {
  quoteNumber: '',
  token: '',
};

type Action = QuoteAction | TokenAction;

const orderReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SET_QUOTE_NUMBER: {
      return {
        ...state,
        quoteNumber: action.payload,
      };
    }
    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default:
      return state;
  }
};

export default orderReducer;
