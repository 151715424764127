import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import SvgIcon from '@atoms/svgIcon';
import { StyledButtonProps as StyledProps, ButtonProps, LinkButtonProps } from './types';

const determineScheme = (props: StyledProps) => {
  if (props.disabled) {
    return props.theme.buttons.schemes.disabled;
  }
  if (props.scheme) {
    return props.theme.buttons.schemes[props.scheme];
  }
  return props.theme.buttons.schemes.default;
};

const variantProps = (props: StyledProps) => {
  const scheme = determineScheme(props);

  return css`
    background-color: ${props.outline ? scheme.contrast : scheme.main};
    border-color: ${scheme.main};
    color: ${props.outline ? scheme.main : scheme.contrast};

    &:hover {
      background-color: ${props.outline ? scheme.contrast : scheme.hover};
      border-color: ${scheme.hover};
      color: ${props.outline ? scheme.hover : scheme.contrast};
    }

    &:active {
      background-color: ${props.outline ? scheme.contrast : scheme.hover};
      border-color: ${scheme.hover};
      color: ${props.outline ? scheme.hover : scheme.contrast};
    }

    &:disabled {
      background-color: ${scheme.main};
      border-color: ${scheme.main};
      color: ${scheme.contrast};
    }
  `;
};

const buttonStyle = (props: StyledProps) => css`
  border-radius: ${props.theme.buttons.borderRadius};
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${props.theme.buttons.fontSize};
  font-weight: ${props.theme.buttons.fontWeight};
  line-height: 1.5rem;
  padding: 0.875em 2em;
  position: relative;
  outline: none;
  transition: all 0.3s ease;
  ${props.fullWidth && css`
    display: block;
    width: 100%;
  `};

  ${variantProps(props)}

  & > div {
    display: inline-block;
    flex-shrink: 0;
    margin: -0.25em 0 -0.25em 0.5em;
    ${props.caret && css`
      margin: -0.1em 0 -0.1em 0.5em;
    `}
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Anchor: React.FC<LinkButtonProps> = ({
  children,
  fullWidth,
  ...props
}) => (
  <a
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {children}
  </a>
);

const InternalLink: React.FC<LinkButtonProps> = ({
  children,
  fullWidth,
  href,
  ...props
}) => (
  <Link href={href || ''} passHref>
    <a
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {children}
    </a>
  </Link>
);

export const StyledLinkComponent: React.FC<LinkButtonProps> = ({
  caret,
  outline,
  fullWidth,
  to = '',
  ...props
}) => {
  const StyledButtonComponent = /^https?:\/\//.test(to) ? Anchor : InternalLink;
  return (
    <StyledButtonComponent
      href={to}
      {...props} // eslint-disable-line react/jsx-props-no-spreading
    >
      {props.children}
      {caret && <SvgIcon icon="arrow" height="0.8rem" width="0.8rem" />}
    </StyledButtonComponent>
  );
};

export const LinkButton = styled(StyledLinkComponent).attrs((props) => ({
  as: props.disabled ? 'span' : null,
}))<StyledProps>`
  ${(props) => buttonStyle(props)}
  ${(props) => (props.disabled ? css`
      cursor: not-allowed;
    ` : null)}
  display: inline-block;
  text-align: center;
  text-decoration: none;
`;

export const ButtonComponent: React.FC<ButtonProps> = ({
  caret,
  outline,
  fullWidth,
  type = 'button',
  ...props
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    {...props} // eslint-disable-line react/jsx-props-no-spreading
  >
    {props.children}
    {caret && <SvgIcon icon="arrow" height="0.8rem" width="0.8rem" />}
  </button>
);

ButtonComponent.defaultProps = {
  type: 'submit',
};

export const Button = styled(ButtonComponent)<StyledProps & ButtonProps>`
  ${(props) => buttonStyle(props)}
`;
