import React from 'react';
import { Card } from '@molecules';
import { LinkButton } from '@atoms';
import { ProductState as ProductStateType, ProductState as ProductStateEnum } from '@constants';
import {
  Content, Title, TitleContainer, SubTitle, Price, Image, ProductState, FooterBase,
} from './components';

interface Props {
  contractNumber: string;
  imageUrl: string;
  title: string;
  subTitle: string;
  costLabel: string;
  costs: number;
  link: string;
  buttonText: string;
  deliveryTime: number;
  state: ProductStateType;
}

const hasDeliveryTime = (
  deliveryTime: number = -1,
  state: ProductStateType,
): boolean => (
  (!isNaN(deliveryTime)) // eslint-disable-line no-restricted-globals
  && deliveryTime >= 0
  && state === ProductStateEnum.ReadyForContract
);

export const ContractGridItem: React.FC<Props> = ({
  imageUrl,
  title,
  subTitle,
  costLabel,
  costs,
  link,
  buttonText,
  deliveryTime,
  state,
  contractNumber,
}) => (
  <Card as="li">
    <Content>
      <Image backgroundImage={imageUrl || '/images/car-placeholder.svg'} />
      <TitleContainer>
        <Title>{title}</Title>
        {!hasDeliveryTime(deliveryTime, state) && (
          <SubTitle>{subTitle}</SubTitle>
        )}
        {hasDeliveryTime(deliveryTime, state) && (
          <LinkButton fullWidth to={link}>{buttonText}</LinkButton>
        )}
      </TitleContainer>
      <FooterBase>
        {!hasDeliveryTime(deliveryTime, state) && <Price label={costLabel} price={costs} />}
        <ProductState
          leaseNumber={contractNumber}
          productState={state}
          deliveryTime={deliveryTime}
          hasDeliveryTime={hasDeliveryTime(deliveryTime, state)}
          link={link}
          buttonText={buttonText}
        />
      </FooterBase>
    </Content>
  </Card>
);
