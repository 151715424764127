import { getEnv } from '@utils';
import { createContext } from 'react';
import { I18nContextState } from './types';

export const initialState: I18nContextState = {
  langCode: getEnv('NEXT_PUBLIC_MYENV_DEFAULT_LANGUAGE') || 'NL-nl',
  t: (key: string) => key,
  setLanguage: () => {},
  languages: [],
  translations: {},
  dateFormat: () => '',
  timeFormat: () => '',
  getMonths: () => [],
};

export const I18nContext = createContext<I18nContextState>(initialState);
