import { gql } from '@apollo/client';
import { ProductState } from '@constants';

export interface GetDepositUriResult {
  quotes: [{
    id: string;
    quoteNumber: string;
    depositAmount: number;
    paymentUri: string | null;
    depositPaid: boolean;
    state: ProductState;
  }];
}

export interface GetDepositUriVars {
  quoteNumber: string;
}

const QUOTE_DEPOSIT_URI = gql`
  query GetSepaUri($quoteNumber: String) {
    quotes(number: $quoteNumber) {
      id
      quoteNumber
      depositAmount
      paymentUri
      depositPaid
      state
    }
  }
`;

export default QUOTE_DEPOSIT_URI;
