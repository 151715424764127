import styled from 'styled-components';
import { FontWeight, StyledComponentProps, TextAlign } from '@type';

interface Props extends StyledComponentProps {
  fontWeight?: FontWeight;
  color?: string;
  textAlign?: TextAlign;
}

export const TableCell = styled.td<Props>`
  color: ${({ theme, color }) => color || theme.card.defaultText};
  font-size: 0.875rem;
  font-weight: ${({ fontWeight }) => fontWeight || FontWeight.Normal};
  padding: 0.6rem;
  text-align: ${({ textAlign }) => textAlign || 'left'};

  &:first-of-type {
    padding-left: 1.25rem;
  }

  &:last-of-type {
    box-sizing: border-box;
    padding-right: 1.25rem;
  }
`;

export const TableHeaderCell = styled.th`
  text-align: left;
  font-weight: ${FontWeight.Light};
  padding: 0.6rem;

  &:first-of-type {
    padding-left: 1.25rem;
  }
`;
