import {
  createStore, compose, applyMiddleware, Store,
} from 'redux';
import thunk from 'redux-thunk';

// @ts-ignore
declare let __DEVTOOLS__: string; // eslint-disable-line

export type MakeStore = (
  initialState?: any
) => Store<any, any>;

const makeStore = (
  rootReducer: any,
  middleware: Array<any> = [],
): MakeStore => (initialState = {}) => {
  const middlewares = [
    thunk, // routerMiddleware(history),
    ...middleware,
  ];

  const composers: Array<any> = [];

  /* eslint-disable no-underscore-dangle */
  // @ts-ignore
  // if (__DEVTOOLS__ && typeof window !== 'undefined'
  //      && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  //   // @ts-ignore
  //   composers = composers.concat([window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()]);
  // }

  /* eslint-enable no-underscore-dangle */
  return createStore(
    rootReducer(),
    initialState,
    compose(applyMiddleware(...middlewares), ...composers),
  );
};

export default makeStore;
