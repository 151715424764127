import {
  UPLOAD_SUCCESS, UPLOAD_FAILED, UPLOADING, DELETE_FAILED, DELETING,
} from './constants';
import {
  UploadSuccess, UploadFailed, Uploading, DeleteFailed, Deleting, Uploads,
} from './types';

export type State = Uploads;

type Action = UploadSuccess | UploadFailed | Uploading | DeleteFailed | Deleting;

const initialState: State = {};

const uploads = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case UPLOAD_SUCCESS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          uploading: false,
        },
      };
    case UPLOADING:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          uploading: true,
        },
      };
    case UPLOAD_FAILED:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          uploading: false,
        },
      };
    case DELETING:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          deleting: true,
        },
      };
    case DELETE_FAILED:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          deleting: false,
        },
      };
    default:
      return state;
  }
};

export default uploads;
