import { createContext } from 'react';
import { AuthContextState } from './types';

export const initialState: AuthContextState = {
  isAuthenticated: false,
  loading: false,
  error: null,
  passwordReset: false,
  impersonated: false,
  accessToken: '',
  lastName: '',
  initials: '',
  isDebtorChange: false,
  login: () => {},
  register: () => {},
  impersonate: () => {},
  logout: () => {},
  resetPassword: () => {},
  setUser: () => {},
  hasUserData: false,
  updateCredentials: () => {},
  credentials: { username: '', password: '' },
  setHasApolloClient: () => {},
  hasApolloClient: false,
};

export const AuthContext = createContext<AuthContextState>(initialState);
