import React from 'react';
import styled, { css } from 'styled-components';
import { CardHeader } from '@atoms';

interface Props {
  className?: string;
  showIcon?: boolean;
  successColor?: boolean;
  iconRight?: string;
}

const StyledTitle = styled(CardHeader).attrs((props: Props) => ({
  successColor: props.successColor || false,
}))<Props>`
  ${({ successColor }) => successColor && css`
    h2 {
      color: ${({ theme }) => theme.checkmarkTitle.text.color};
    }

    svg {
      fill: ${({ theme }) => theme.checkmarkTitle.text.color};
    }
  `}
`;

const CheckmarkTitle: React.FC<Props> = ({
  showIcon,
  children,
  className,
  successColor,
}) => (
  <StyledTitle successColor={successColor} className={className} iconRight={showIcon ? 'circleCheckmark' : ''}>
    {children}
  </StyledTitle>
);

export default CheckmarkTitle;
