import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@atoms';
import { Link } from './link';

interface Props {
  href: string;
  alias?: string;
  icon: string;
  exact?: boolean;
}

const NavItemWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin-right: 1.65rem;
`;

const NavigationItem: React.FC<Props> = ({
  icon,
  children,
  href,
  alias,
  exact = false,
}) => (
  <NavItemWrapper>
    <Link href={href} alias={alias} exact={exact}>
      <StyledSvgIcon width="1.3rem" icon={icon} elementType="span" />
      {children}
    </Link>
  </NavItemWrapper>
);

export default NavigationItem;
