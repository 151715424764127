import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  checked: boolean;
  disabled?: boolean;
  error?: boolean | null | undefined;
}

export const Box = styled.div<Props>`
  font-size: 1.125rem;
  width: 1em;
  height: 1em;
  background: #fff;
  border-radius: 0.125em;
  border-style: solid;
  border-color: ${({ theme, error }) => (error ? theme.form.checkbox.borderColorError : theme.form.checkbox.borderColor)};
  border-width: ${({ error }) => (error ? '2px' : '1px')};
  padding: 0.1rem;
  box-sizing: border-box;
  margin-right: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ theme, checked }) => checked && css`
    border-color: transparent;
    background-color: ${theme.form.checkbox.selected};
  `}
  ${({ theme, disabled }) => disabled && css`
    background: ${theme.form.checkbox.disabledBackgroundColor};
  `}
`;
