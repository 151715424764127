import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  marginBottom?: string;
}

export default styled.section<Props>`
  ${({ marginBottom }) => marginBottom && css`
    margin-bottom: ${marginBottom};
  `}

  p {
    color: ${({ theme }) => theme.html.paragraph.color};
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: 600;
  }

  a {
    color: ${({ theme }) => theme.html.anchor.color.main};
    display: inline-block;
    position: relative;
    text-decoration: none;
    transition: color 0.3s ease;

    &::after {
      background-color: currentColor;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      transition: width 0.3s ease;
      width: 0%;
    }

    &:hover {
      color: ${({ theme }) => theme.html.anchor.color.hover};

      &::after {
        width: 100%;
      }
    }

    &:active {
      color: ${({ theme }) => theme.html.anchor.color.active};
    }
  }
`;
