import { OptionsResponse, ExtrasResponse } from '@type/graphql';
import { FilterFn } from '../filters';

type MapPdbExtraId<T> = (extra: T) => T;
export const mapPdbExtraIdToId: MapPdbExtraId<ExtrasResponse> = (extra) => ({
  ...extra,
  id: extra.pdbExtraId ? extra.pdbExtraId.toString() : '0',
});
export const mapOptionPdbExtraIdToId: MapPdbExtraId<OptionsResponse> = (extra) => ({
  ...extra,
  id: extra.pdbExtraId.toString(),
});

export const mapPdbExtraId = ({
  pdbExtraId,
}: ExtrasResponse) => pdbExtraId.toString();

type SortNameFn<T> = (a: T, b: T) => number;
export const sortNameFn: SortNameFn<OptionsResponse> = (a, b) => a.name.localeCompare(b.name);
export const sortExtrasNameFn: SortNameFn<ExtrasResponse> = (a, b) => a.name.localeCompare(b.name);

type MapAll<T> = (extras: Array<T>, filterFn: FilterFn<T>, sort: boolean) => Array<T>;
export const mapExtras: MapAll<ExtrasResponse> = (extras, filterFn, sort = true) => {
  const filteredExtras = extras.filter(filterFn).map(mapPdbExtraIdToId);
  if (!sort) {
    return filteredExtras;
  }
  return filteredExtras.sort(sortExtrasNameFn);
};

export const mapOptions: MapAll<OptionsResponse> = (extras, filterFn, sort = true) => {
  const filteredExtras = extras.filter(filterFn).map(mapOptionPdbExtraIdToId);

  if (sort) {
    return filteredExtras.sort(sortNameFn);
  }
  return filteredExtras;
};

type MapAvailable<T> = (availableExtras: Array<T>, sort: boolean) => Array<T>;
export const mapAvailableExtras: MapAvailable<ExtrasResponse> = (
  availableExtras = [],
  sort = true,
) => {
  const filteredAvailableExtras = (availableExtras ?? []).map(mapPdbExtraIdToId);
  if (sort) {
    return filteredAvailableExtras.sort(sortExtrasNameFn);
  }
  return filteredAvailableExtras;
};

export const mapAvailableOptions: MapAvailable<OptionsResponse> = (
  availableExtras = [],
  sort = true,
) => {
  const filteredAvailableExtras = (
    availableExtras ?? []
  ).filter((extra) => !extra.isInContract).map(mapOptionPdbExtraIdToId);
  if (sort) {
    return filteredAvailableExtras.sort(sortNameFn);
  }
  return filteredAvailableExtras;
};

type MapAvailableProlongations<T> = (availableProlongations: Array<T>) => Array<T>;
// eslint-disable-next-line max-len
export const mapAvailableProlongations: MapAvailableProlongations<ExtrasResponse> = (availableProlongations) => availableProlongations.map(mapPdbExtraIdToId);

type MapIds<T> = (extras: Array<T>, filterFn: FilterFn<T>) => Array<string>;
export const mapExtraIds: MapIds<ExtrasResponse> = (extras, filterFn) => (
  extras.filter(filterFn).map(mapPdbExtraId)
);
