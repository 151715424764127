import React, { useState } from 'react';
import { FormFieldContainer, Label, FieldError } from '@atoms/form';
import { TextAreaStyled } from './textArea.styled';

interface Props {
  placeholder: string;
  label: string;
  name: string;
  disabled?: boolean;
  onKeyDown?: (...args: Array<any>) => any;
  defaultValue?: string;
  fieldRef: any;
  error?: {
    message: string;
  };
}

export const TextAreaRHFField: React.FC<Props> = ({
  placeholder,
  label,
  defaultValue = '',
  disabled = false,
  onKeyDown = () => {},
  name,
  fieldRef,
  error,
}) => {
  const [touched, setTouched] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [value, setValue] = useState('');

  const onBlur = (evt: { target: HTMLTextAreaElement }) => {
    if (evt.target.value !== '') {
      setTouched(false);
      setPristine(false);
      return;
    }
    setTouched(true);
    setPristine(true);
  };

  const handleOnKeyDown = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { value: newValue } = evt.target as HTMLTextAreaElement;
    setValue(newValue);
    if (onKeyDown) {
      onKeyDown(evt);
    }
  };

  return (
    <FormFieldContainer>
      <TextAreaStyled
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue || value}
        name={name}
        id={name}
        onKeyDown={handleOnKeyDown}
        ref={fieldRef}
        error={Boolean(error)}
        onBlur={onBlur}
        touched={touched}
        pristine={pristine}
      />
      <Label htmlFor={name}>{label}</Label>
      {error && <FieldError message={error.message} />}
    </FormFieldContainer>
  );
};
