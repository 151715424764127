import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps, CardScheme } from '@type';

interface StyledProps extends StyledComponentProps {
  scheme: CardScheme;
}

export const Footer = styled.footer<StyledProps>`
  margin-top: ${({ scheme }) => (scheme === 'default' ? '0' : '1rem')};
  padding: 1rem;
  ${({ scheme }) => css`
    ${media.sm(css`
      margin-top: ${scheme === 'default' ? '0' : '1.5rem'};
      padding: 1.5rem;
    `)}
  `}

  ${({ theme, scheme }) => css`
    background-color: ${theme.card.schemes[scheme].backgroundColor};
    color: ${theme.card.schemes[scheme].color};
  `}
`;
