import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { CardContent, DepositAmount, Toaster } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { StyledComponentProps as StyledProps, TextColor } from '@type';
import { QuoteProgressRedirectTypes, QuoteProgressRedirectStatus } from '@constants/quoteProgress';
import { getStatus } from '@store/quoteFlow';
import { GlobalState } from '@store';
import CheckmarkTitle from '@atoms/checkmarkTitle';
import { ProductState } from '@constants';
import { Card } from '../card';
import { DepositAction } from '../depositAction';

const InfoText = styled.p<StyledProps>`
  color: ${({ theme }) => theme.deposit.text.generalInfoColor};
`;

const ToasterContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

interface Props {
  quoteNumber: string;
  amount: number;
  paymentUri?: string;
  productState: ProductState;
  isPaid: boolean;
}

export const DepositToPay: React.FC<Props> = ({
  quoteNumber,
  paymentUri = '',
  amount,
  productState,
  isPaid,
}) => {
  const { t } = useI18n();
  const depositState = useSelector((state: GlobalState) => (
    getStatus(state, quoteNumber, QuoteProgressRedirectTypes.Deposit)
  ));

  return (
    <Card renderHeader={() => <CheckmarkTitle showIcon={false}>{t('deposit-title')}</CheckmarkTitle>}>
      <CardContent>
        {amount ? (
          <>
            <InfoText>{t('deposit-to-pay-status-description')}</InfoText>
            <DepositAmount amount={amount} />
            {depositState === QuoteProgressRedirectStatus.Failed && (
            <ToasterContainer>
              <Toaster color={TextColor.Error}>
                {t('deposit-failed')}
              </Toaster>
            </ToasterContainer>
            )}
            {depositState === QuoteProgressRedirectStatus.Cancelled && (
            <ToasterContainer>
              <Toaster color={TextColor.Error}>
                {t('deposit-cancelled')}
              </Toaster>
            </ToasterContainer>
            )}
            <DepositAction
              quoteNumber={quoteNumber}
              paymentUri={paymentUri}
              productState={productState}
              isPaid={isPaid}
            />
          </>
        ) : <Toaster>{t('deposit-undetermined')}</Toaster>}
      </CardContent>
    </Card>
  );
};
