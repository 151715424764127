import styled, { css } from 'styled-components';
import SvgIcon from '../../../svgIcon';
import { AtomProps as Props } from '../types';

export const CheckIcon = styled(SvgIcon)<Props>`
  visibility: hidden;
  ${({ theme, checked }) => checked && css`
    visibility: visible;
    color: ${theme.form.checkbox.iconColor};
  `}
`;
