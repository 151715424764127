import React from 'react';
import styled from 'styled-components';
import { Price, Span } from '@atoms';
import { TextColor } from '@type';
import { PriceProps } from '@atoms/price';

interface Props {
  monthlyPriceLabel: string;
  taxLabel: string;
  discount: number;
  totalPrice: number;
}

const DiscountedPrice = styled(Price)<PriceProps>`
  text-decoration: line-through;
`;

const TotalPrice = styled(Price)`
  color: currentColor;
  font-weight: 600;
  padding-left: 1rem;
`;

const Container = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PriceSummaryFooter: React.FC<Props> = ({
  monthlyPriceLabel,
  taxLabel,
  discount,
  totalPrice,
}) => (
  <Container>
    <div>
      <p>{`${monthlyPriceLabel}:`}</p>
      <Span fontSize="0.875rem" color={TextColor.SubLight}>{taxLabel}</Span>
    </div>
    <Container>
      {discount !== 0 && (
        <DiscountedPrice
          fontSize="0.875rem"
          color={TextColor.SubLight}
          amount={(totalPrice + discount) / 100}
          decimalDash
          displayCurrencySymbol
        />
      )}
      <TotalPrice
        fontSize="1.875rem"
        amount={totalPrice / 100}
        decimalDash
      />
    </Container>
  </Container>
);

export default PriceSummaryFooter;
