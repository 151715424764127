import React from 'react';
import styled, { css } from 'styled-components';
import { TheIcon } from './assets';

interface Props {
  className?: string;
  icon: string;
  elementType?: string;
  overrideIcons?: {
    [key: string]: any;
  } | null | undefined;
}

interface IconProps {
  className?: string;
  icon: string;
  width?: string;
  elementType?: string;
  height?: string;
  size?: string;
}

const SvgIcon: React.FC<Props> = ({
  icon,
  className = '',
  elementType = 'div',
  // overrideIcons,
}) => (
  React.createElement(
    elementType,
    {
      className: `${icon} ${className}`,
    },
    <TheIcon icon={icon} />,
  )
);

const StyledIcon = styled(SvgIcon)<IconProps>`
  fill: currentColor;

  ${({ width, height, size }) => css`
    width: ${width || '100%'};
    height: ${height || '1em'};
    ${size && css`font-size: ${size};`}
  `}

  > svg {
    display: block;
  }
`;

export default StyledIcon;
