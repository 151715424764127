import styled from 'styled-components';
import React from 'react';
// import { IconContext } from '@context';
import SvgIcon from '@atoms/svgIcon';

const IconContainer = styled.div`
  svg,
  div {
    display: inline-block;
    height: 5rem;
    width: auto;
  }
`;

interface IconProps {
  icon: string;
}

export const UploadIcon: React.FC<IconProps> = ({ icon }) => (
  <IconContainer>
    {/* <IconContext.Consumer>
      {({
        SvgIcon,
      }) => ( */}
    <SvgIcon icon={icon} />
    {/* )}
    </IconContext.Consumer> */}
  </IconContainer>
);
