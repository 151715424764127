import { OptionsResponse } from '@type/graphql';

const getMultipleSelectPrice = (
  activeBundleIds: Array<number>,
  quotePrice: number,
  options: Array<OptionsResponse>,
  currentOptions: Array<OptionsResponse>,
): number => {
  const activeBundles = [
    ...options.filter(
      (item) => activeBundleIds.includes(parseInt(item.id, 10)),
    ),
    ...(currentOptions
      ? currentOptions.filter((item) => activeBundleIds.includes(parseInt(item.id, 10)))
      : []
    ),
  ];

  const add = (a: number, b: number): number => a + b;
  const basePrice = quotePrice - options.map((item) => item.price).reduce(add, 0);
  const sumOfActiveBundles = activeBundles.map((item) => item.price).reduce(add, 0);

  if (!activeBundleIds) {
    return basePrice;
  }
  return basePrice + sumOfActiveBundles;
};

export default getMultipleSelectPrice;
