export enum Justify {
  FlexStart = 'flex-start',
  FlexEnd = 'flex-end',
  Center = 'center',
  SpaceBetween = 'space-between',
  SpaceAround = 'space-around',
  SpaceEvenly = 'space-evenly',
}

export enum FlexDirection {
  Row = 'row',
  Column = 'column',
}

export interface UnicodeMap {
  latin: string,
  latinExt: string,
}

export interface Font {
  name: string;
  locals: Array<string>;
  unicodeRange: Array<keyof UnicodeMap>;
  urls: {
    woff2: string;
    woff: string;
  };
  weight: number;
}

export enum ButtonSchemeName {
  Default = 'default',
  Disabled = 'disabled',
  Reject = 'reject',
  Accept = 'accept',
  Transparent = 'transparent',
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small',
}

export enum ButtonVariant {
  Default = 'default',
  Ghost = 'ghost',
  Link = 'link',
  Link_text = 'link_text',
  Hero = 'hero',
}
