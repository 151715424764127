import { gql } from '@apollo/client';

const GET_CUSTOMER_LOCALE = gql`
  query me {
    me {
      locale
    }
  }
`;

export interface CustomerLocaleResponse {
  me: {
    locale: string;
  };
}

export default GET_CUSTOMER_LOCALE;
