import {
  GET_RDW_DATA, GET_RDW_DATA_SUCCESS, GET_RDW_DATA_FAIL, CLEAR_RDW,
} from './constants';
import {
  GetRDWData, GetRDWDataSuccess, GetRDWDataFail, ClearRDW,
} from './types';

type Action = GetRDWDataFail | GetRDWData | GetRDWDataSuccess | ClearRDW;

const initialState = {};

const quotes = (state: any = initialState, action: Action) => {
  switch (action.type) {
    case GET_RDW_DATA:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_RDW_DATA_SUCCESS:
    {
      return {
        ...state,
        data: action.payload ? action.payload : null,
        loading: false,
      };
    }
    case CLEAR_RDW:
    {
      return {
        ...state,
        data: null,
      };
    }
    case GET_RDW_DATA_FAIL:
    {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default quotes;
