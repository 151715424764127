import React from 'react';
import StyledHeader, { Title, IconBar } from './styled';

interface Props {
  title: string | null | undefined;
  iconComponent?: JSX.Element;
}

export const ModalHeader: React.FC<Props> = ({
  title,
  iconComponent,
}) => (
  <StyledHeader>
    <Title underIcon={Boolean(iconComponent)}>
      {title}
    </Title>
    {iconComponent && <IconBar>{iconComponent}</IconBar>}
  </StyledHeader>
);
