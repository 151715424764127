import type {
  QuoteFlowSidebarsConstant,
  EdrState,
} from '@type/quoteProgress';

export enum QuoteProgressRedirectTypes {
  Deposit = 'deposit',
  Sepa = 'sepa',
  Signing = 'signing',
}

export enum QuoteProgressRedirectStatus {
  Success = 'success',
  Failed = 'failed',
  Cancelled = 'cancelled',
}

export enum QuoteFlows {
  Default = 'tblg_quote_flow',
  Edr = 'edr_quote_flow',
  Rental = 'rental_quote_flow',
  Jlbe = 'jlbe_quote_flow',
  JlnlB2B = 'jlnl_b2b_quote_flow',
  JlnlOckto = 'jlnl_ockto_quote_flow',
}

export enum QuoteFlowSteps {
  QuoteDetail = 'quoteDetail',
  CreditCheck = 'creditCheck',
  EdrCheck = 'edrCheck',
  Documents = 'documents',
  SignContract = 'signContract',
  Composed = 'composed',
}

export enum QuoteFlowElements {
  Deposit = 'deposit',
  Contract = 'contract',
  CreditCheck = 'creditCheck',
  Sepa = 'sepa',
  Documents = 'documents',
  NextStepButton = 'nextStepButton',
  EdrCheck = 'edrCheck',
  Revocation = 'revocation',
  FinishQuoteButton = 'finishQuoteButton',
  OcktoCheck = 'ocktoCheck',
  InviteCosigneeButton = 'inviteCosigneeButton',
}

export const QUOTE_FLOW_SIDEBARS: QuoteFlowSidebarsConstant = {
  deliveryProcess: 'deliveryProcess',
  creditCheckUspsIcon: 'creditCheckUspsIcon',
  creditCheckUsps: 'creditCheckUsps',
  creditCheckVideo: 'creditCheckVideo',
  ocktoCheck: 'ocktoCheck',
};

export const EDR_NOT_STARTED: 'notStarted' = 'notStarted';
export const EDR_INITIALISED: 'requestInitiated' = 'requestInitiated';
export const EDR_DID_NOT_LOGIN: 'customerDidNotLogin' = 'customerDidNotLogin';
export const EDR_LOGGED_IN: 'customerLoggedIn' = 'customerLoggedIn';
export const EDR_WAITING_DOCUMENTS: 'waitingForDocuments' = 'waitingForDocuments';
export const EDR_DOCUMENTS_UPLOADED: 'documentsUploaded' = 'documentsUploaded';
export const EDR_PROGRESS_ANALYST: 'requestInProgressAnalist' = 'requestInProgressAnalist';
export const EDR_REVOKED: 'requestRevoked' = 'requestRevoked';
export const EDR_EXPIRED: 'requestExpired' = 'requestExpired';
export const EDR_FINISHED_BY_EDR: 'requestFinishedByEdr' = 'requestFinishedByEdr';
export const EDR_APPROVED_BY_TBLG: 'requestApprovedByTblg' = 'requestApprovedByTblg';
export const EDR_DENIED_BY_TBLG: 'requestDeniedByTblg' = 'requestDeniedByTblg';

export const EDR_STATE: Record<EdrState, string> = {
  [EDR_NOT_STARTED]: 'Not available',
  [EDR_INITIALISED]: 'Aangevraagd',
  [EDR_DID_NOT_LOGIN]: 'Consument heeft nog niet ingelogd',
  [EDR_LOGGED_IN]: 'Consument heeft ingelogd',
  [EDR_WAITING_DOCUMENTS]: 'In afwachting van documenten',
  [EDR_DOCUMENTS_UPLOADED]: 'Documenten geupload',
  [EDR_PROGRESS_ANALYST]: 'Aanvraag in behandeling analist',
  [EDR_REVOKED]: 'Aanvraag ingetrokken',
  [EDR_EXPIRED]: 'Aanvraag verlopen',
  [EDR_FINISHED_BY_EDR]: 'Aanvraag afgerond',
  [EDR_APPROVED_BY_TBLG]: 'Aanvraag goedgekeurd',
  [EDR_DENIED_BY_TBLG]: 'Aanvraag afgekeurd',
};

export const REDIRECT_URI: Record<QuoteProgressRedirectTypes, string> = {
  [QuoteProgressRedirectTypes.Deposit]: 'DEPOSIT_REDIRECT_URI',
  [QuoteProgressRedirectTypes.Sepa]: 'SEPA_REDIRECT_URI',
  [QuoteProgressRedirectTypes.Signing]: 'SIGN_CONTRACT_REDIRECT_URI',
};

export const EMPLOYMENT_TYPE_LIST_FOR_EMPLOYMENT_DATE = [1, 2, 4];
export const DEFAULT_EDR_STATES = [EDR_STATE[EDR_NOT_STARTED], EDR_STATE[EDR_REVOKED]];
