import { gql } from '@apollo/client';
import { OptionsResponse, ExtrasResponse } from '@type/graphql';
import { ProductState } from '@constants';
import { mustReorderOnEdit } from '@flags';

const QUOTE_DETAIL_QUERY = gql`
  query CurrentQuote($number: String!) {
    quotes(number: $number) {
      id
      quoteNumber
      depositAmount
      cancelUri
      state
      quoteFlow
      ${mustReorderOnEdit() ? 'reorderUrl' : ''}
      leaseItem {
        id
        make
        model
        fuelType
        recurringCosts
        deliveryTime
        discount
        transmission
        numberOfDoors
        mileage {
          id
          name
        }
        duration {
          id
          name
        }
        color {
          id
          name
          imageUrl
        }
        package {
          id
          name
          power
        }
        extras {
          id
          price
          recurring
          name
          groupType
          inBundle
        }
        extraBundle {
          id
          price
          name
          groupType
          recurring
          extras {
            name
            id
          }
        }
      }
      availableClaimFree {
        name
      }
      availableKilometerBundles {
        name
      }
      availableContractDurations {
        name
      }
      availableExtraBundles {
        name
      }
      availableContractExtras {
        name
      }
      availableAccessories {
        name
      }
      availableColors {
        name
      }
      availablePackages {
        name
      }
      connectedToStock
    }
  }
`;

export interface QuoteDetailType {
  quoteNumber: string;
  depositAmount: number;
  cancelUri: string;
  reorderUrl: string;
  state: ProductState;
  quoteFlow?: string;
  leaseItem: {
    make: string;
    model: string;
    fuelType: string;
    recurringCosts: number;
    deliveryTime: number;
    discount: number;
    transmission: string;
    numberOfDoors: string;
    duration: {
      name: string;
    };
    mileage: {
      name: string;
    };
    color: {
      name: string;
      imageUrl: string;
    };
    package: {
      name: string;
      power: string;
    };
    extras: Array<ExtrasResponse>;
    extraBundle: OptionsResponse;
  };
  availableClaimFree: Array<ExtrasResponse>;
  availableKilometerBundles: Array<ExtrasResponse>;
  availableContractDurations: Array<ExtrasResponse>;
  availableExtraBundles: Array<OptionsResponse>;
  availableContractExtras: Array<ExtrasResponse>;
  availableAccessories: Array<ExtrasResponse>;
  availablePackages: Array<ExtrasResponse>;
  availableColors: Array<ExtrasResponse>;
  connectedToStock: boolean;
}

export interface QuoteDetailResponse {
  quotes: Array<QuoteDetailType>;
}

export default QUOTE_DETAIL_QUERY;
