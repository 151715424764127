import styled from 'styled-components';
import { StyledComponentProps as StyleProps } from '@type';
import { activeElementStyle } from '@atoms/form/sharedStyles';
import { OptionLabel } from './optionLabel.styled';

export const OptionInput = styled.input<StyleProps>`
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  display: none;

  &:checked + ${
/* sc-sel */
  OptionLabel} {
    background-color: ${({ theme }) => theme.form.radioGroup.backgroundColorActive};
    border-color: ${({ theme }) => theme.form.radioGroup.borderColorActive};
    color: ${({ theme }) => theme.form.radioGroup.colorActive};
  }

  &:focus + ${
/* sc-sel */
  OptionLabel} {
    ${activeElementStyle}
  }
`;
