import styled, { css } from 'styled-components';
import media from '@utils/media';

interface KeyValueProps {
  hasNote?: boolean;
  isHelper?: boolean;
}

export const KeyValue = styled.div<KeyValueProps>`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: 40% 1fr auto;
  ${({ hasNote }) => (
    hasNote ? css`grid-template-rows: auto auto auto` : css`grid-template-rows: auto auto`
  )}

  ${({ hasNote }) => css`
    ${media.md(css`
      ${hasNote ? css`grid-template-rows: auto auto` : css`grid-template-rows: auto`}
    `)}
  `}
  padding: 1.3rem 0;
`;
