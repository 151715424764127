import React from 'react';
import { Html, CardHeader, CardContent } from '@atoms';
import { Card } from '@molecules';
import { dayDiff } from '@utils';
import { useI18n } from '@hooks/i18n';

interface Props {
  startDate: string;
}

export const NoInvoiceCard: React.FC<Props> = ({
  startDate,
}) => {
  const { t } = useI18n();
  const initial = dayDiff(new Date(), new Date(startDate));
  const title = t('invoices-no-invoices-title');
  const description = initial > 60 ? t('invoices-no-invoices-missing-description', { type: 'html' }) : t('invoices-no-invoices-description', { type: 'html' });

  return (
    <Card renderHeader={() => <CardHeader>{title}</CardHeader>}>
      <CardContent>
        <Html dangerouslySetInnerHTML={{ __html: description }} />
      </CardContent>
    </Card>
  );
};
