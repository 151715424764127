import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUpload, isDeleting } from '@store/uploads';
import { GlobalState } from '@store';
import { useAuthentication } from '@hooks/authentication';
import { MessageType } from '@constants/messageStack';
import { useMessageStack } from '@hooks/messageStack';
import { DocumentAction } from './document';

interface Props {
  id: number;
  originalName: string;
  onDeleteComplete: (...args: Array<any>) => any;
  deleteEnabled: boolean;
  deleteUri: string | null | undefined;
}

const UploadedDocument: React.FC<Props> = ({
  onDeleteComplete,
  deleteEnabled,
  originalName,
  deleteUri,
  id,
}) => {
  const {
    accessToken,
  } = useAuthentication();
  const dispatch = useDispatch();
  const deleting = useSelector((state: GlobalState) => isDeleting(state, id));
  const { infoMessage } = useMessageStack();

  const handleDelete = async () => {
    if (!deleteUri) {
      return;
    }

    try {
      await dispatch(deleteUpload(id, deleteUri, accessToken));
    } catch (error) {
      if (error.errorCode) {
        infoMessage(error.errorCode, MessageType.Error);
      }
    }

    await onDeleteComplete();
  };

  return (
    <DocumentAction
      deleting={deleting}
      originalName={originalName}
      deleteEnabled={deleteEnabled}
      deleteDocument={handleDelete}
    />
  );
};

export default UploadedDocument;
