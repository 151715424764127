import { combineReducers } from 'redux';
import cosignee from '@store/cosignee/reducers';
import uiSettings from './ui';
import registration from './register';
import uploads from './uploads';
import quotes from './quotes';
import quoteFlow from './quoteFlow';
import rdwData from './rdwData';
import { resetReducer } from './reset';
import device from './device';
import nextButton from './nextButton';

const reducer = () => combineReducers({
  quoteFlow,
  quotes,
  registration,
  uiSettings,
  uploads,
  rdwData,
  device,
  cosignee,
  nextButton,
});

export default resetReducer(reducer);
