import React from 'react';
import styled, { css } from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';
import media from '@utils/media';

const ProgressTitle = styled.div<StyledProps>`
  color: ${({ theme }) => theme.card.defaultText};
  font-weight: 700;
  font-size: 1.5em;

  ${media.md(css`
    font-size: 2.2em;
  `)}
`;

const CalendarSubtext = styled.span<StyledProps>`
  color: ${({ theme }) => theme.bundleStatus.textColorDark};
`;

const CalendarIllustration = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.bundleStatus.calendarBackground};
  border-color: ${({ theme }) => theme.bundleStatus.brandColor};
  border-width: 1.25em 0 0;
  border-style: solid;
  display: inline-block;
  padding: 0 2em 1em 2em;
  position: relative;
  text-align: center;

  &::before,
  &::after {
    background-color: ${({ theme }) => theme.bundleStatus.textColorDarkest};
    content: '';
    display: block;
    height: 0.75em;
    position: absolute;
    top: -1.65em;
    width: 0.75em;
  }

  &::before {
    left: 10%;
  }

  &::after {
    right: 10%;
  }
`;

interface Props {
  value: number;
  label: string;
}

export const Calendar: React.FC<Props> = ({
  value,
  label,
}) => (
  <CalendarIllustration>
    <ProgressTitle>{value}</ProgressTitle>
    <CalendarSubtext>{label}</CalendarSubtext>
  </CalendarIllustration>
);
