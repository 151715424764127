import { gql } from '@apollo/client';

export interface GetSepaUriResult {
  quotes: [{
    id: string;
    quoteNumber: string;
    sepaUri: string | null;
  }];
}

export interface GetSepaUriVars {
  quoteNumber: string;
}

const QUOTE_SEPA_URI = gql`
  query GetSepaUri($quoteNumber: String) {
    quotes(number: $quoteNumber) {
      id
      quoteNumber
      sepaUri
    }
  }
`;

export default QUOTE_SEPA_URI;
