import styled, { css } from 'styled-components';
import React from 'react';
import { OrderedList, UnorderedList } from '@atoms/list';
import { StyledComponentProps as StyledProps } from '@type';

interface Props {
  ordered?: boolean;
}

const sharedStyles = css`
  list-style: none;
  padding-left: 1rem;
  position: relative;
`;

export const OrderedThemedList = styled(OrderedList)<StyledProps>`
  ${sharedStyles}
  counter-reset: items;
  padding-left: 2rem;

  > li {
    counter-increment: items;
    position: relative;

    &::before {
      border: 2px solid ${({ theme }) => theme.list.numbered.color};
      border-radius: 50%;
      box-sizing: border-box;
      color: ${({ theme }) => theme.list.numbered.color};
      content: counter(items);
      display: block;
      font-size: 0.875em;
      font-weight: 600;
      height: 1.5rem;
      left: -2rem;
      line-height: 1.2rem;
      overflow: hidden;
      position: absolute;
      text-align: center;
      width: 1.5rem;
    }
  }
`;

export const UnorderedThemedList = styled(UnorderedList)<StyledProps>`
  ${sharedStyles}

  & > li::before {
    background: ${({ theme }) => theme.list.dot.color};
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 0.5rem;
    margin: 0 0.5rem 0.125rem -1rem;
    width: 0.5rem;
  }
`;

export const ThemedList: React.FC<Props> = ({
  ordered,
  children,
}) => (
  <>
    {!ordered && <UnorderedThemedList>{children}</UnorderedThemedList>}
    {ordered && <OrderedThemedList>{children}</OrderedThemedList>}
  </>
);

ThemedList.defaultProps = {
  ordered: false,
};
