import { requestRDWData, clearRDW } from './actions';
import quotes from './reducers';
import { getRdwData, isLoading, hasError } from './selectors';

export {
  requestRDWData,
  clearRDW,
  getRdwData,
  isLoading,
  hasError,
};

export default quotes;
