import styled, { css } from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';
import media from '@utils/media';

interface CardComponentProps extends StyledProps {
  hasFooter?: boolean;
  hasHeader?: boolean;
}

export const CardComponent = styled.article<CardComponentProps>`
  background-color: ${({ theme }) => theme.card.backgroundColor};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  margin-bottom: 1rem;
  overflow: hidden;
  position: relative;
  width: 100%;

  ${({ hasFooter }) => !hasFooter && css`
    padding-bottom: 1rem;
    ${media.sm(css`
      padding-bottom: 1.5rem;
    `)}
  `}

  ${({ hasHeader }) => !hasHeader && css`
    padding-top: 1.5rem;
  `}
`;
