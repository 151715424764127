import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as StyledProps } from '@type';
import { OptionLabel, PriceDifference } from './optionTypes.styled';

interface Props {
  groupId: string;
  onChange: (...args: Array<any>) => any;
  checked: boolean;
  id: string;
  name: string;
  price: number;
  hexcode?: string;
}

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  ${media.sm(css`
    padding: 0 1rem;
  `)};
  border: 2px solid transparent;

  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  ${(props: StyledProps) => css`
    &:checked + ${
  /* sc-sel */
  Label} {
      background-color: ${props.theme.changeQuote.selectedOption.labelBackground};
      border-color: ${props.theme.changeQuote.selectedOption.labelBorder};
      border-radius: 4px;
    }

    &:checked + ${
/* sc-sel */
  Label} > ${
/* sc-sel */
  OptionLabel} {
      color: ${props.theme.changeQuote.selectedOption.labelText};
    }

    &:checked + ${
/* sc-sel */
  Label} > ${
/* sc-sel */
  PriceDifference} {
      color: ${props.theme.changeQuote.selectedOption.labelText};
    }
  `}
`;

interface ColorBlockProps {
  hexcode: string;
}

const ColorBlock = styled.div<ColorBlockProps>`
  flex-shrink: 0;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  background-color: #${({ hexcode }) => hexcode};
`;

export const OptionColor: React.FC<Props> = ({
  price = 0,
  id,
  name,
  groupId,
  onChange,
  checked,
  hexcode = '#ffffff',
}) => (
  <>
    <Input id={`${groupId}-${id}`} type="radio" name={groupId} checked={checked} value={name} onChange={onChange} />
    <Label htmlFor={`${groupId}-${id}`}>
      <ColorBlock hexcode={hexcode} />
      <OptionLabel>{name}</OptionLabel>
      <PriceDifference amount={price / 100} displayZeroes displayCurrencySymbol />
    </Label>
  </>
);
