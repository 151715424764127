import { createContext } from 'react';
import { FormDataState } from './types';

export const initialState: FormDataState = {
  setFormDataValue: () => {},
  setFormDataValues: () => {},
  setFormMetaValue: () => {},
  formData: {},
  formMeta: {},
  resetFormData: () => {},
};

export const FormDataContext = createContext<FormDataState>(initialState);
