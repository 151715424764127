import React from 'react';
import { useI18n } from '@hooks/i18n';
import { TextColor } from '@type';
import { CardWithList, ListItem } from '../cardWithList';

export const InformationContact = () => {
  const { t } = useI18n();
  const telephoneNumber = t('contact-telephone-number')/* The phone number to contact us */;
  const streetName = t('contact-street-name')/* The street we are located at (Eendrachtlaan) */;
  const streetNumber = t('contact-street-number')/* The housenumber */;
  const town = t('contact-town')/* The city wherer we are located (Utrecht) */;

  const contactItems: Array<ListItem> = [{
    usp: telephoneNumber,
    icon: 'contactPhone',
    iconColor: TextColor.Secondary,
    url: `tel:${telephoneNumber}`,
  }, {
    usp: `${streetName} ${streetNumber}, ${town}`,
    icon: 'contactMarker',
    iconColor: TextColor.Secondary,
    url: `https://www.google.com/maps?q=${streetName}+${streetNumber},+${town}`,
    target: '_blank',
  }];

  return <CardWithList title={{ text: t('direct-contact') }} isLinkList items={contactItems} />;
};
