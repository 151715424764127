import { GlobalState } from '../types';
import { State } from './types';

const getUploads = (state: GlobalState): State => state.uploads;

export const isUploadingAvailable = (state: GlobalState): boolean => {
  // @ts-ignore Does not know navigator.connection
  if (!navigator.connection || !navigator.connection.saveData) {
    return true;
  }
  return !Object.values(getUploads(state)).some((upload): boolean => upload.uploading || false);
};

export const isUploading = (state: GlobalState, id: number): boolean => {
  if (getUploads(state)[id]) {
    return getUploads(state)[id].uploading || false;
  }
  return false;
};

export const isDeleting = (state: GlobalState, id: number): boolean => {
  if (getUploads(state)[id]) {
    return getUploads(state)[id].deleting || false;
  }
  return false;
};

export default null;
