import React, { useState } from 'react';
import { FieldError, FormFieldContainer, Label } from '@atoms/form';
import { SelectStyled } from './select.styled';

interface Props {
  label: string;
  name: string;
  onChange: (...args: Array<any>) => any;
  disabled?: boolean;
  value?: string;
  validate?: ((...args: Array<any>) => any)[];
}

export const SelectField: React.FC<Props> = ({
  label,
  disabled = false,
  children,
  name,
  validate = [],
  onChange,
  ...input
}) => {
  const [value, setValue] = useState(input.value || '');
  const [touched, setTouched] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [valid, setValid] = useState(true);

  const validateValue = (newValue: any) => {
    const result = validate.map(
      (rule: (...args: Array<any>) => any) => rule(newValue),
    ).find((message) => message);

    setValid(!result);
    setError(result);
  };

  const inputChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    evt.persist();
    const newValue = evt.target.value;
    validateValue(newValue);
    if (!pristine) {
      setPristine(true);
    }
    setValue(newValue);
    onChange(evt);
  };

  const onBlur = () => {
    setTouched(true);
    validateValue(value);
  };

  return (
    <FormFieldContainer>
      <SelectStyled
        // eslint-disable-next-line
        {...input}
        label=""
        value={value}
        disabled={disabled}
        error={touched && Boolean(error)}
        valid={touched && !pristine && valid}
        id={name}
        onBlur={onBlur}
        onChange={inputChange}
      >
        {children}
      </SelectStyled>
      <Label htmlFor={name}>{label}</Label>
      {touched && error && <FieldError message={error} />}
    </FormFieldContainer>
  );
};
