import { RECEIVED_UI_SETTINGS, SET_UI_SETTING, TOGGLE_UI_SETTING } from './constants';
import {
  ReceivedUiSettings, SetUiSetting, ToggleUiSetting, UiSettings,
} from './types';
import { getUiSetting } from './selectors';

export const setUiSetting: SetUiSetting = (key: string, value: any) => ({
  type: SET_UI_SETTING,
  payload: {
    key,
    value,
  },
});

export const receivedUiSettings: ReceivedUiSettings = (settings: UiSettings) => ({
  type: RECEIVED_UI_SETTINGS,
  payload: {
    settings,
  },
});

export const toggleUiBooleanSetting: ToggleUiSetting = (key: string) => ({
  type: TOGGLE_UI_SETTING,
  payload: {
    key,
  },
});

export const toggleUiStringSetting = (key: string, value: any) => (
  dispatch: (...args: Array<any>) => any,
  getState: (...args: Array<any>) => any,
) => {
  const state = getState();
  const currentValue = getUiSetting(state, key);
  if (value === currentValue) {
    dispatch(setUiSetting(key, ''));
  } else {
    dispatch(setUiSetting(key, value));
  }
};

export default null;
