import React, { useEffect } from 'react';
import { useI18n } from '@hooks/i18n';
import { getEnv } from '@utils';
import { REDIRECT_URI, QuoteProgressRedirectTypes } from '@constants/quoteProgress';
import { ProductState } from '@constants';
import { hasPostponedDeposit } from '@flags';
import {
  LinkButton, Span, SvgIcon,
} from '@atoms';
import { TextColor } from '@type';
import { ActionBar, PaymentInfo } from './styled';

interface Props {
  quoteNumber: string;
  paymentUri: string;
  productState?: ProductState;
  isPaid?: boolean;
}

const depositUrl = ({ paymentUri, quoteNumber }: Props) => {
  const returnUrl = encodeURIComponent(
    (getEnv('NEXT_PUBLIC_MYENV_PAYMENT_RETURN_URL') as string, '').replace('%quoteNumber%', quoteNumber),
  );
  return `${getEnv('NEXT_PUBLIC_MYENV_PAYMENT_URL')}${paymentUri}?return_url=${returnUrl}`;
};

export const DepositAction: React.FC<Props> = ({
  quoteNumber,
  paymentUri,
  productState,
  isPaid,
}) => {
  const { t } = useI18n();

  useEffect(() => {
    const redirectUrl = (hasPostponedDeposit() && productState === ProductState.ApprovedFinance)
      ? `/quote/${quoteNumber}/progress/finished/contracted`
      : document.location.pathname;

    sessionStorage.setItem(
      REDIRECT_URI[QuoteProgressRedirectTypes.Deposit],
      redirectUrl,
    );
  }, []);

  const thankYouPage = () => {
    switch (productState) {
      case ProductState.Contracted:
        return 'contracted';
      case ProductState.PendingSales:
        switch (isPaid) {
          case false:
            return 'pending';
          default:
            return '';
        }
      default:
        return '';
    }
  };

  return (
    <>
      {!hasPostponedDeposit() || productState === ProductState.ApprovedFinance
        ? (
          <ActionBar>
            <PaymentInfo>
              <Span color={TextColor.Sub} fontSize="0.8rem">{t('redirect-paymentmethod')}</Span>
              <SvgIcon width="5.75rem" icon="payment" />
            </PaymentInfo>
            <LinkButton caret to={depositUrl({ paymentUri, quoteNumber })}>
              {t('pay-deposit-paymentmethod')}
            </LinkButton>
          </ActionBar>
        ) : (
          <ActionBar className="postponed-enabled">
            <PaymentInfo>
              <Span color={TextColor.Sub} fontSize="0.8rem">{t('redirect-paymentmethod')}</Span>
              <SvgIcon width="5.75rem" icon="payment" />
            </PaymentInfo>
            <LinkButton outline to={depositUrl({ paymentUri, quoteNumber })}>
              {t('pay-deposit-paymentmethod')}
            </LinkButton>
            <LinkButton caret to={`/quote/${quoteNumber}/progress/finished/${thankYouPage()}`}>
              {t('pay-deposit-postpone-payment')}
            </LinkButton>
          </ActionBar>
        )}
    </>
  );
};
