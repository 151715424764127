import React from 'react';
import styled, { css } from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';
import {
  StyledSvgIcon, ChildrenContainer, OptionLabel, PriceDifference,
} from './optionTypes.styled';

interface Props {
  groupId: string;
  onChange: (...args: Array<any>) => any;
  checked: boolean;
  id: string;
  name: string;
  price: number;
}

const Label = styled.label`
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  column-gap: 1rem;
  cursor: pointer;
  min-height: 4rem;
  padding: 1rem;
  transition:
    border 0.3s ease,
    background-color 0.3s ease;

  &:hover {
    border-color: ${({
    theme,
  }: StyledProps) => theme.changeQuote.selectedOption.labelBorder};
  }
`;

const RadioButton = styled.div`
  align-items: center;
  border: ${(props: StyledProps) => props.theme.divider.borderWidth} solid ${(props: StyledProps) => props.theme.changeQuote.option.radioButtonBorder};
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  height: 1.375rem;
  justify-content: center;
  margin-right: 1rem;
  transition: all 0.2s ease 0.1s;
  width: 1.375rem;
`;

const RadioButtonIcon = styled(StyledSvgIcon)`
  fill: white;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  ${(props: StyledProps) => css`
    &:checked + ${
  /* sc-sel */
  Label} {
      background-color: ${props.theme.changeQuote.selectedOption.labelBackground};
      border-color: ${props.theme.changeQuote.selectedOption.labelBorder};
    }

    &:checked + ${
/* sc-sel */
  Label} > ${
/* sc-sel */
  OptionLabel} {
      color: ${props.theme.changeQuote.selectedOption.labelText};
    }

    &:checked + ${
/* sc-sel */
  Label} > ${
/* sc-sel */
  PriceDifference} {
      color: ${props.theme.changeQuote.selectedOption.labelText};
    }

    &:checked + ${
/* sc-sel */
  Label} > ${
/* sc-sel */
  RadioButton} {
      background-color: ${props.theme.changeQuote.selectedOption.radioButton};
      border-color: ${props.theme.changeQuote.selectedOption.radioButton};

      ${
/* sc-sel */
  RadioButtonIcon} {
        opacity: 1;
        transform: scale(1);
        transition: all 0.2s ease 0.1s;
      }
    }
  `}
`;

export const OptionRadio: React.FC<Props> = ({
  checked,
  children,
  groupId,
  id,
  name,
  price,
  onChange,
}) => (
  <>
    <Input id={`${groupId}-${id}`} type="radio" name={groupId} checked={checked} value={name} onChange={onChange} />
    <Label htmlFor={`${groupId}-${id}`}>
      <RadioButton><RadioButtonIcon icon="check" /></RadioButton>
      <OptionLabel>{name}</OptionLabel>
      <PriceDifference amount={price ? (price / 100) : 0} displayZeroes displayCurrencySymbol />
    </Label>
    {children && <ChildrenContainer>{children}</ChildrenContainer>}
  </>
);
