import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps, CardScheme } from '@type';
import { useI18n } from '@hooks/i18n';
import Price from '@atoms/price';
import { Footer } from '../footer';

interface StyledProps extends StyledComponentProps {
  scheme: CardScheme;
}

const SubText = styled.p<StyledProps>`
  color: ${({ theme, scheme }) => theme.card.schemes[scheme].subColor};
  font-size: 0.875rem;
`;

const TextContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    ${media.sm(css`
    padding-bottom: 0.5rem;
  `)};
    padding-bottom: 1rem;
    color: ${({ theme, scheme }) => theme.card.schemes[scheme].color};
  }

  span {
    font-size: 1.5rem;
    color: ${({ theme, scheme }) => theme.card.schemes[scheme].priceColor};
    font-weight: bold;
    align-self: flex-start;
    line-height: 0.8;
    ${media.sm(css`
      font-size: 1.75rem;
      align-self: center;
    `)};
  }
`;

interface Props {
  price: number;
  scheme?: CardScheme;
}

export const PriceFooter: React.FC<Props> = ({
  price,
  scheme = CardScheme.Accent,
}) => {
  const { t } = useI18n();

  return (
    <Footer scheme={scheme}>
      <TextContainer scheme={scheme}>
        <p>{t('lease-rate')}</p>
        <Price className="test-quote-price" amount={price / 100} decimalDash />
      </TextContainer>
      <SubText scheme={scheme}>{t('lease-rate-description')}</SubText>
    </Footer>
  );
};
