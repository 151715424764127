import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { PageLoader } from '@molecules';
import { ApolloContext } from '@context';
import QUOTE_SEPA_URI, { GetSepaUriResult, GetSepaUriVars } from '@queries/quote/quoteSepaUri';
import { AuthorisationDone } from './authorisationDone';
import AuthorisationToDo from './authorisationToDo';

interface Props {
  quoteNumber: string;
}

export const Authorisation: React.FC<Props> = ({
  quoteNumber,
}) => {
  const client = useContext(ApolloContext).leadHub;
  const {
    data, loading, error,
  } = useQuery<GetSepaUriResult, GetSepaUriVars>(QUOTE_SEPA_URI, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      quoteNumber,
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <span>{error.message}</span>;
  }

  return (
    data?.quotes[0].sepaUri
      ? <AuthorisationToDo quoteNumber={quoteNumber} />
      : <AuthorisationDone />
  );
};
