import React from 'react';
import styled from 'styled-components';
import {
  ButtonBar, LinkButton, CardContent, CardHeader, CardActions, SvgIcon,
} from '@atoms';
import { Image } from '@organisms/product/components';
import {
  Card, ContractLine, ProductSpecification, CancelQuoteModal,
} from '@molecules';
import { CURRENT_QUOTE_NUMBER } from '@constants/localStorageKeys';
import { useI18n } from '@hooks/i18n';
import { MappedData } from '@type';
import { Justify } from '@constants/theme';
import { mustReorderOnEdit } from '@flags';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';

interface Props {
  data: MappedData;
  make: string;
  model: string;
  productSpecs: {
    [key: string]: string | Array<string>;
  };
  imageUrl: string;
  quoteNumber: string;
  reorderUrl?: string;
  cancelUri: string;
  nextStep: string;
}

const CardContentStyled = styled(CardContent)`
  hr:last-of-type {
    display: none;
  }
`;

export const QuoteDetail: React.FC<Props> = ({
  quoteNumber,
  cancelUri,
  data,
  make,
  model,
  productSpecs,
  imageUrl,
  reorderUrl,
  nextStep,
}) => {
  const { t } = useI18n();
  localStorage.setItem(CURRENT_QUOTE_NUMBER, quoteNumber);

  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quoteNumber]?.isPreorder;

  const checkYourOrder = isPreorder
    ? t('check-your-order-preorder')
    : t('check-your-order');

  const completeOrder = isPreorder
    ? t('complete-order-preorder')
    : t('complete-order');

  return (
    <>
      <Card
        renderHeader={() => <CardHeader>{checkYourOrder}</CardHeader>}
        renderFooter={mustReorderOnEdit() && reorderUrl
          ? () => (
            <CardActions>
              <LinkButton target="_blank" to={reorderUrl}>
                {t('quote-detail-reorder-button')}
                {' '}
                <SvgIcon icon="launch" height="1.25rem" width="1.25rem" />
              </LinkButton>
            </CardActions>
          )
          : undefined}
      >
        <CardContentStyled>
          <Image backgroundImage={imageUrl} />
          <ProductSpecification title={`${make} ${model}`} productSpecs={productSpecs} />
          {data.map(({
            label,
            value,
            edit,
            extend,
            placeholder,
            actionText,
            note,
          }) => (
            <ContractLine
              key={label}
              label={label}
              value={value}
              edit={edit}
              extend={extend}
              placeholder={placeholder}
              actionText={actionText}
              note={note}
            />
          ))}
        </CardContentStyled>
      </Card>
      <ButtonBar justify={Justify.FlexEnd}>
        <CancelQuoteModal
          title={t('cancel-modal-title')}
          size="small"
          quoteNumber={quoteNumber}
          cancelUri={cancelUri}
        />
        <LinkButton to={`/quote/${quoteNumber}/progress${nextStep}`} caret>
          {completeOrder}
        </LinkButton>
      </ButtonBar>
    </>
  );
};
