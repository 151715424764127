import styled, { css } from 'styled-components';
import { HeadingLevel, StyledComponentProps as StyledProps } from '@type';
import SvgIcon from '../../svgIcon';
import Heading from '../../heading';

interface TitleProps extends StyledProps {
  underIcon: boolean;
}

export const Title = styled(Heading).attrs({
  level: HeadingLevel.H2,
})<TitleProps>`
  margin: 0 0 1.5rem;

  ${({ underIcon }) => underIcon && css`
    font-size: 1.75rem;
    margin: 0 0 1rem 0;
    width: 100%;
    text-align: center;
  `}
`;

export const IconBar = styled.div<StyledProps>`
  width: 100%;

  ${/* sc-selector */SvgIcon} {
    display: block;
    height: ${({ theme }) => theme.modal.svgIcon.height};
    margin: ${({ theme }) => theme.modal.svgIcon.margin};
    vertical-align: bottom;
    width: 9rem;
  }
`;

export default styled.header<StyledProps>`
  align-items: flex-end;
  background: ${({ theme }) => theme.modal.backgroundColor};
  border-radius: ${({ theme }) => `
    ${theme.modal.borderRadius}
    ${theme.modal.borderRadius}
    0
    0
  `};
  color: ${({ theme }) => theme.modal.headerColor};
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap-reverse;
  padding: 2rem 2rem 0;
`;
