export type FetchDataArguments = {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS';
  endpoint: string;
  body?: (string | {
    [key: string]: any;
  }) | null | undefined;
  headers?: {
    [key: string]: any;
  };
  noError?: boolean;
};

type Arguments = {
  errorCode?: string | null | undefined;
  status: number;
  message?: string;
  meta?: {
    [key: string]: any;
  };
};

export class FetchError extends Error {
  meta: Record<string, any>;

  status: number;

  errorCode: string | null | undefined;

  response: any;

  constructor({
    message = '',
    status,
    errorCode,
    ...rest
  }: Arguments) {
    super(message);

    this.name = 'FetchError';
    this.errorCode = errorCode;
    this.status = status;
    this.meta = rest;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }
  }
}

type Response = {
  errorCode: string | null | undefined;
};

async function fetchData({
  endpoint,
  method = 'GET',
  body,
  headers = {},
  noError = false,
}: FetchDataArguments): Promise<any> {
  const reqHeaders = new Headers(headers);
  reqHeaders.append('Accept', 'application/json');

  const response = await fetch(endpoint, {
    method,
    headers: reqHeaders,
    body: (body || undefined) as any,
  });

  const json: Response = await response.json();

  if ((response.status >= 400 && !noError) || response.status >= 500) {
    throw new FetchError({
      // @ts-ignore
      response: json,
      errorCode: json.errorCode ? json.errorCode : null,
      status: response.status,
      url: endpoint,
      body,
      headers: JSON.stringify(headers),
    });
  }

  return json;
}

export default fetchData;
