import { ComponentType } from 'react';
import { Languages } from '@hooks/i18n/types';
import { Theme } from '@type/theme';
import { FlattenSimpleInterpolation } from 'styled-components';

export interface ThemeFiles {
  globalCss: FlattenSimpleInterpolation;
  theme: Theme;
}

export interface LanguagesFiles {
  languages: Languages;
}

export interface ComponentFiles {
  labelIcons: Record<string, React.Component>;
  Favicons: ComponentType<any>;
}

const files = async (env: string) => {
  const theme = await import(`./${env}/theme`) as ThemeFiles;
  const languages = await import(`./${env}/locales`) as LanguagesFiles;
  const components = await import(`./${env}/components`) as ComponentFiles;

  return {
    theme,
    languages: languages.languages,
    components: components.labelIcons,
  };
};

export default files;
