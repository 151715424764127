import React from 'react';
import { CardContent, PriceFooter } from '@atoms';
import {
  Card, CarInformationHeader, ContractLine,
} from '@molecules';
import { formatLicense } from '@utils';
import { useI18n } from '@hooks/i18n';
import { mapContractDetails } from '@utils/mappers';

interface Props {
  contract: any;
  contractNumber: string;
}

export const ContractDetail: React.FC<Props> = ({
  contract,
  contractNumber,
}) => {
  const { t, dateFormat } = useI18n();
  const carInfo = mapContractDetails(contract, contractNumber as string, t, dateFormat);
  const { leaseItem } = contract;

  return (
    <Card renderFooter={() => <PriceFooter price={leaseItem.recurringCosts} />}>
      <CardContent>
        <CarInformationHeader
          imageUrl={leaseItem.color.imageUrl}
          title={`${leaseItem.make} ${leaseItem.model}`}
          subtitle={`${leaseItem.package.engine} ${leaseItem.package.power} ${leaseItem.fuelType}`}
          note={`${formatLicense(leaseItem.licensePlate)}`}
        />
        {carInfo.map(({
          actionText,
          edit,
          extend,
          label,
          placeholder,
          value,
          helper,
        }) => (
          <ContractLine
            key={label}
            extend={extend}
            label={label}
            value={value}
            edit={edit}
            placeholder={placeholder}
            actionText={actionText}
            helper={helper}
          />
        ))}
      </CardContent>
    </Card>
  );
};
