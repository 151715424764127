import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as Props } from '@type';

export const StyledPage = styled.section<Props>`
  box-sizing: border-box;
  flex-grow: 1;
  height: auto;
  min-height: calc(100vh - ${({ theme }) => theme.header.height});
  margin-top: ${({ theme }) => `calc(${theme.header.height} + 1rem)`};
  overflow-y: auto;
  padding: 1rem 1rem 2rem;
  ${({ theme }) => css`
    ${media.sm(css`
      height: calc(100vh - ${theme.header.height});
      margin-top: 0;
      padding: 2rem 2rem 2rem;
    `)}
  `}
  ${media.md(css`
    padding: 2rem 4rem 2rem;
  `)}
  ${media.xl(css`
    padding: 2rem 4rem 2rem;
  `)}
`;
