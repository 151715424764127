import React, { useState } from 'react';
import {
  FormFieldContainer, Label, FieldError, PasswordButton, PasswordLengthIndicator,
} from '@atoms/form';
import { determineStrength } from '@utils';
import { Stages } from '@type';
import { Input } from './components';

interface Props {
  placeholder: string;
  label: string;
  name: string;
  disabled?: boolean;
  onKeyDown?: (...args: Array<any>) => any;
  showToggleButton?: boolean;
  showStrengthBar?: boolean;
  defaultValue?: string;
  fieldRef: any;
  error?: {
    message: string;
  };
}

export const PasswordRHFField: React.FC<Props> = ({
  placeholder,
  label,
  defaultValue = '',
  disabled = false,
  onKeyDown = () => {},
  name,
  fieldRef,
  error,
  showStrengthBar = false,
  showToggleButton = false,
}) => {
  const [touched, setTouched] = useState<boolean>(false);
  const [pristine, setPristine] = useState<boolean>(true);
  const [stage, setStage] = useState<Stages | null>(null);
  const [show, setShow] = useState<boolean>(false);

  const togglePassword = () => {
    setShow(!show);
  };

  const onChange = (evt: { target: HTMLInputElement }) => {
    if (evt.target.value === '') {
      setPristine(true);
    } else {
      setPristine(false);
    }
  };

  const onBlur = () => {
    setTouched(true);
  };

  const onKeyUp = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const { value: val } = evt.target as HTMLInputElement;
    if (showStrengthBar) {
      setStage(determineStrength(val));
    }
  };

  return (
    <FormFieldContainer>
      <Input
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue}
        type={show ? 'text' : 'password'}
        name={name}
        id={label}
        onKeyDown={onKeyDown}
        ref={fieldRef}
        error={Boolean(error)}
        onChange={onChange}
        onBlur={onBlur}
        touched={touched}
        pristine={pristine}
        onKeyUp={onKeyUp}
        hasPrefix={false}
      />
      <Label htmlFor={label}>{label}</Label>
      {showStrengthBar && stage && <PasswordLengthIndicator stage={stage} />}
      {showToggleButton && (
        <PasswordButton
          error={touched && Boolean((error?.message))}
          toggle={togglePassword}
          showPassword={show}
        />
      )}
      {error && <FieldError message={error.message} />}
    </FormFieldContainer>
  );
};
