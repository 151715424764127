import { CATCH_REDIRECT_BACK } from './constants';
import { CatchRedirectBack, State } from './types';

const initialState: State = {};

const quoteFlow = (state: State = initialState, action: CatchRedirectBack) => {
  switch (action.type) {
    case CATCH_REDIRECT_BACK:
      return {
        ...state,
        [action.quoteNumber]: {
          ...state[action.quoteNumber],
          [action.redirectType]: action.redirectStatus,
        },
      };
    default:
      return state;
  }
};

export default quoteFlow;
