import React from 'react';
import priceFormat from '@utils/priceFormat';
import { TextColor, FontWeight } from '@type';
import { Span } from '../span';

export interface PriceProps {
  className?: string;
  amount: number;
  decimalDash?: boolean;
  displayZeroes?: boolean;
  displayCurrencySymbol?: boolean;
  displayCurrencyText?: boolean;
  displayPrefix?: boolean;
  postfix?: string;
  color?: TextColor;
  fontWeight?: FontWeight;
  fontSize?: string;
}

const Price: React.FC<PriceProps> = ({
  className,
  amount,
  decimalDash,
  displayZeroes,
  displayCurrencySymbol,
  displayCurrencyText,
  displayPrefix,
  postfix,
  color,
  fontWeight,
  fontSize,
}) => {
  const price = priceFormat(amount, {
    displayZeroes,
    decimalDash,
    currency: displayCurrencySymbol,
    currencyText: displayCurrencyText,
    prefix: displayPrefix,
  });

  return (
    <Span color={color} fontSize={fontSize} fontWeight={fontWeight} className={className}>
      {`${price} ${postfix !== undefined ? postfix : ''}`}
    </Span>
  );
};

export default Price;
