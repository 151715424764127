import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';
import { Input, Label } from '@atoms/form';

interface Props extends StyledComponentProps {
  error: boolean;
  valid?: boolean;
  pristine: boolean;
  disabled: boolean;
  touched: boolean;
  hasPrefix?: boolean;
  type: string;
}

const activeStyle = css<StyledComponentProps>`
  padding-top: 1.1rem;

  & + ${/* sc-sel */Label} {
    color: ${({ theme }) => theme.form.label.color};
    font-size: 0.8em;
    top: 0.5rem;
  }
`;

export default styled(Input)<Props>`
  &::placeholder {
    color: transparent;
    transition: color 0.3s ease;
  }

  ${({ pristine }) => !pristine && css`
    ${activeStyle}
  `}

  &:focus {
    ${activeStyle}
  }
`;
