import styled from 'styled-components';
import { Price, SvgIcon } from '@atoms';
import { StyledComponentProps as StyledProps } from '@type';

export const OptionLabel = styled.span`
  color: ${(props: StyledProps) => props.theme.changeQuote.option.descriptionLabelColor};
  flex-grow: 1;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PriceDifference = styled(Price)`
  color: ${(props: StyledProps) => props.theme.changeQuote.option.priceLabelColor};
  white-space: nowrap;
`;

export const StyledSvgIcon = styled(SvgIcon)`
  fill: white;
  height: auto;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease;
  width: 0.75rem;
`;

export const ChildrenContainer = styled.section`
  padding-left: 3rem;
`;
