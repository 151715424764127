import React from 'react';
import { useLabelIcons } from '@hooks/labelIcons/useLabelIcons';
import Arrow from './arrow.svg';
import Bundelstatus from './bundel_status.svg';
import Bundlehistory from './bundle-history.svg';
import Buyoff from './buy-off.svg';
import Cancelcontract from './cancel_contract.svg';
import Car from './car.svg';
import Check from './check.svg';
import Chevron from './chevron.svg';
import CircleCheckmark from './circle-checkmark.svg';
import CircleClock from './circle-clock.svg';
import CircleCross from './circle-cross.svg';
import ContactEmail from './contact-email.svg';
import ContactMarker from './contact-marker.svg';
import ContactPhone from './contact-phone.svg';
import ContactWhatsapp from './contact-whatsapp.svg';

import Cross from './cross.svg';
import CustomerService from './customer_service.svg';
import Damage from './damage.svg';
import Damagefree from './damage_free.svg';
import Dashboard from './dashboard.svg';
import Discount from './discount.svg';
import Download from './download.svg';
import Eye from './eye.svg';
import EyeBlocked from './eye-blocked.svg';
import FaqAlgemeen from './faq-algemeen.svg';
import FaqBestellingenlevering from './faq-bestellingenlevering.svg';
import FaqLeasecontract from './faq-leasecontract.svg';
import FaqLeasen from './faq-leasen.svg';
import FaqOnderhoud from './faq-onderhoud.svg';
import FaqVoorwaarden from './faq-voorwaarden.svg';

import Gift from './gift.svg';
import GridView from './grid_view.svg';
import Hamburger from './hamburger.svg';
import Invoice from './invoice.svg';
import Interchange from './interchange.svg';
import Kmcorrection from './km-correction.svg';
import Launch from './launch.svg';
import ListView from './list_view.svg';
import Locationpin from './location-pin.svg';
import Logo from './logo.svg';
import JlLogo from './jl-logo.svg';
import Maintenance from './maintenance.svg';
import PartyPopper from './party_popper.svg';
import Payment from './payment.svg';
import Pdf from './pdf.svg';
import PreDelivery from './pre-delivery.svg';
import QuestionMark from './question-mark.svg';
import Recommend from './recommend.svg';
import Reward from './reward.svg';
import Savings from './savings.svg';
import StarsLicensePlate from './stars-license-plate.svg';
import ShoppingBag from './shopping-bag.svg';
import Upgrade from './upgrade.svg';
import User from './user.svg';
import EdrStatusFailed from './edr-status-failed.svg';
import EdrStatusSuccess from './edr-status-success.svg';
import EdrStatusPending from './edr-status-pending.svg';
import MyLeez from './myLeez-logo.svg';

const Icons: Record<string, React.Component> = {
  bundlehistory: Bundlehistory,
  kmcorrection: Kmcorrection,
  locationpin: Locationpin,
  arrow: Arrow,
  bundelstatus: Bundelstatus,
  buyoff: Buyoff,
  cancelcontract: Cancelcontract,
  car: Car,
  check: Check,
  chevron: Chevron,
  circleCheckmark: CircleCheckmark,
  circleClock: CircleClock,
  circleCross: CircleCross,
  contactEmail: ContactEmail,
  contactMarker: ContactMarker,
  contactPhone: ContactPhone,
  contactWhatsapp: ContactWhatsapp,
  cross: Cross,
  customerService: CustomerService,
  damage: Damage,
  damagefree: Damagefree,
  dashboard: Dashboard,
  discount: Discount,
  download: Download,
  eye: Eye,
  faqAlgemeen: FaqAlgemeen,
  faqBestellingenlevering: FaqBestellingenlevering,
  faqLeasecontract: FaqLeasecontract,
  faqLeasen: FaqLeasen,
  faqOnderhoud: FaqOnderhoud,
  faqVoorwaarden: FaqVoorwaarden,
  eyeBlocked: EyeBlocked,
  gift: Gift,
  gridView: GridView,
  hamburger: Hamburger,
  invoice: Invoice,
  interchange: Interchange,
  launch: Launch,
  listView: ListView,
  logo: Logo,
  jlLogo: JlLogo,
  maintenance: Maintenance,
  partyPopper: PartyPopper,
  payment: Payment,
  pdf: Pdf,
  questionMark: QuestionMark,
  recommend: Recommend,
  reward: Reward,
  savings: Savings,
  shoppingBag: ShoppingBag,
  starsLicensePlate: StarsLicensePlate,
  upgrade: Upgrade,
  user: User,
  preDelivery: PreDelivery,
  edrStatusFailed: EdrStatusFailed,
  edrStatusSuccess: EdrStatusSuccess,
  edrStatusPending: EdrStatusPending,
  myLeez: MyLeez,
};

export const iconLabels = Object.keys(Icons);

interface Props {
  icon: string;
}

export const TheIcon: React.FC<Props> = ({ icon }) => {
  const { labelIcons } = useLabelIcons();
  const AllIcons = {
    ...Icons,
    ...labelIcons,
  };
  const Icon: React.Component = AllIcons[icon];
  // @ts-ignore
  return <Icon />;
};
