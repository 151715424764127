import { useContext } from 'react';
import { I18nContext } from './context';
import { UseI18n } from './types';

export const useI18n = (): UseI18n => {
  const {
    t,
    langCode,
    setLanguage,
    dateFormat,
    timeFormat,
    getMonths,
  } = useContext(I18nContext);

  return {
    t,
    langCode,
    setLanguage,
    dateFormat,
    timeFormat,
    getMonths,
  };
};
