import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';
import { activeElementStyle } from '../sharedStyles';
import { Label } from '../label';

export interface InputProps extends StyledComponentProps {
  error: boolean;
  pristine: boolean;
  disabled: boolean;
  touched: boolean;
  hasPrefix: boolean;
  type: string;
}

export const focusStyle = css<InputProps>`
  outline: 0;

  &::placeholder {
    color: ${({ theme }) => theme.form.placeholderColor};
    transition: color 0.3s ease;
  }

  & ~ .input-prefix {
    left: 0.25rem;
    opacity: 1;
  }
`;

export const inputStyles = css<InputProps>`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.form.input.borderColor};
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
  box-sizing: border-box;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: ${(props) => props.theme.form.input.fontSize};
  height: 3.75rem;
  line-height: 1.1;
  padding: 0 1rem;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;
  ${({ error, theme }) => error && css`
    border-radius: ${theme.form.input.borderRadius} ${theme.form.input.borderRadius} 0 0;
    border-bottom-color: ${theme.form.input.borderColorError};
  `}
  ${({ hasPrefix }) => hasPrefix && css`
    padding-left: 3rem;
  `}
  ${({ disabled, theme }) => disabled && css`
    opacity: 0.7;
    background-color: ${theme.form.input.disabledBackgroundColor};
  `}

  ${({ pristine }) => !pristine && css`
    &:not([type="password"]) {
      ${focusStyle}
    }
  `}
  ${({ touched }) => touched && css`
    &[type="number"] {
      ${focusStyle}
    }
  `}

  &:active,
  &:hover {
    ${activeElementStyle}
    outline: 0;
  }

  &:focus {
    ${activeElementStyle}
    outline: 0;

    &::-ms-value {
      background: transparent;
      color: inherit;
    }
  }

  &:not(:placeholder-shown) ~ .input-prefix {
    left: 0.25rem;
    opacity: 1;
  }

  & + ${
/* sc-sel */
  Label} {
    z-index: 1;
  }

  &::-ms-clear {
    display: none;
  }

  &:not([value=""]),
  &:focus {
    ${focusStyle}
  }
`;

export const Input = styled.input<InputProps>`
  ${inputStyles}
`;
