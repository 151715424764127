import styled, { css } from 'styled-components';
import media from '@utils/media';

interface CardActionsProps {
  center?: boolean;
}

export const CardActions = styled.footer<CardActionsProps>`
  display: flex;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-end')};
  flex-flow: wrap;
  padding: 1rem;
  ${media.sm(css`
    padding: 1.5rem;
  `)};

  a,
  button {
    align-self: center;
    flex-shrink: 0;
    width: 100%;
    margin: 0 0 1rem;

    ${media.lg(css`
      width: auto;
      margin: 0 0 0 1rem;

      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        margin-left: 0;
      }
   `)}
  }
`;
