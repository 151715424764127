import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  hide?: boolean;
}

export default styled.button<Props>`
  background-color: ${({ theme }) => theme.floatingAction.backgroundColor};
  ${({ theme }) => theme.floatingAction.backgroundImage && css`
    background: ${theme.floatingAction.backgroundImage};
  `};
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.floatingAction.boxShadow};
  color: ${({ theme }) => theme.floatingAction.color};
  height: 3.5rem;
  transition:
    visibility 0.3s ease,
    opacity 0.3s ease;
  width: 3.5rem;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  opacity: ${({ hide }) => (hide ? 0 : 1)};

  &,
  &:focus,
  &:active {
    outline: none;
  }

  > div {
    height: 1.5rem;
    margin: 0 auto;
    width: 1.5rem;
  }

  svg {
    fill: currentColor;
  }
`;
