import React from 'react';
import { PreDeliveryState } from '@constants';
import { Divider } from '@atoms';
import { Documents } from './documents';

interface Props {
  preDeliveryState: PreDeliveryState;
}

const Signed: React.FC<Props> = ({
  preDeliveryState,
}) => (
  <>
    <Divider />
    <Documents preDeliveryState={preDeliveryState} />
  </>
);

export default Signed;
