import { gql } from '@apollo/client';

export interface CustomerMenuInfo {
  me: {
    initials: string;
    lastName: string;
    isDebtorChange: boolean;
  };
}

const GET_CUSTOMER_MENU_INFO = gql`
  query UserMenuInfo {
    me {
      initials,
      lastName,
      isDebtorChange,
      locale,
    }
  }
`;

export default GET_CUSTOMER_MENU_INFO;
