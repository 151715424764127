import { GlobalState } from '../types';
import { State } from './types';

export const getQuotes = (state: GlobalState): State => state.quotes;

export const getQuote = (state: GlobalState, quoteNumber: string) => getQuotes(state)[quoteNumber];

export const isCancelling = (state: GlobalState, quoteNumber: string): boolean => {
  const quote = getQuote(state, quoteNumber);
  if (quote) {
    return (quote.canceling || false) as boolean;
  }
  return false;
};

export default null;
