import { GlobalState } from '../types';
import { RESET } from './constants';

const reducer = ((
  rootReducer: (...args: Array<any>) => any,
) => (state: void | GlobalState, action: any): any => {
  let currentState: void | GlobalState = state;
  if (action && action.type === RESET) {
    currentState = undefined;
  }
  return rootReducer(currentState, action);
});

export default reducer;
