import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  disabled?: boolean;
}

export const CheckInput = styled.input<Props>`
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;

  ${({ theme, disabled }) => disabled && css`
    background: ${theme.form.checkbox.disabledBackgroundColor};
  `};
`;
