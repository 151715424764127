import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import { Toaster, UploadButton } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { TextColor } from '@type';
import { DocumentsList, UploadHeader } from './components';

interface StyledUploadItemProps {
  hasUploads: boolean;
}
const StyledUploadItem = styled.article<StyledUploadItemProps>`
  padding: 1rem 0;

  ${({ hasUploads }) => !hasUploads && media.md(css`
    align-items: center;
    display: flex;
    flex-direction: row;
  `)}
`;

interface Props {
  title: string;
  id: number;
  description: string;
  hasUploads: boolean;
  isRejected: boolean;
  rejectionReason: string | null | undefined;
  groupCompleted: boolean;
  successfulUpload: boolean;
  uploads: Array<{
    id: number;
    type: string;
    deleteUri: string | null | undefined;
    downloadUri: string | null | undefined;
    originalName: string;
  }>;
  enabledUpload: boolean;
  onChange: (...args: Array<any>) => any;
  onUploadComplete: (...args: Array<any>) => any;
  processing: boolean;
}

const UploadItem: React.FC<Props> = ({
  hasUploads,
  title,
  uploads,
  description,
  id,
  onChange,
  onUploadComplete,
  isRejected,
  rejectionReason,
  enabledUpload,
  groupCompleted,
  successfulUpload,
  processing,
}) => {
  const { t } = useI18n();

  return (
    <StyledUploadItem hasUploads={hasUploads}>
      <UploadHeader
        title={title}
        successfulUpload={successfulUpload}
        enabledUpload={enabledUpload}
        description={description}
        groupCompleted={groupCompleted}
        isRejected={isRejected}
      />
      {hasUploads && !isRejected && (
        <DocumentsList
          documents={uploads}
          onDeleteComplete={() => onUploadComplete(id)}
        />
      )}
      {!isRejected && enabledUpload && (
        <UploadButton
          label={t('upload-file')}
          onChange={onChange}
          processing={processing}
          linkStyle={hasUploads}
        />
      )}
      {isRejected && rejectionReason && (
        <Toaster color={TextColor.Error}>{rejectionReason}</Toaster>
      )}
    </StyledUploadItem>
  );
};

export default UploadItem;
