import { PreDeliveryState } from '@constants';

const checkPreDeliveryState = (
  state: PreDeliveryState,
) => (
  (
    state === PreDeliveryState.Signed
    || state === PreDeliveryState.Approved
    || state === PreDeliveryState.Driving
  )
  && true
);

export default checkPreDeliveryState;
