import { gql } from '@apollo/client';

const UPDATE_CUSTOMER_BL = gql`
  mutation UpdateCustomer(
    $address: String!
    $city: String!
    $houseNumber: String!
    $houseNumberAddition: String!
    $zipCode: String!
    $province: String!
    $phone: String!
    $phone2: String!
    $customerNumber: Int!
    $cocNumber: String
    $companyName: String
  ) {
    updateCustomer(
      address: $address
      city: $city
      houseNumber: $houseNumber
      houseNumberAddition: $houseNumberAddition
      zipCode: $zipCode
      province: $province
      phone: $phone
      phone2: $phone2
      customerNumber: $customerNumber
      cocNumber: $cocNumber
      companyName: $companyName
    ) {
      firstName
      initials
      lastName
      birthDate
      address
      houseNumber
      houseNumberAddition
      city
      zipCode
      province
      phone
      phone2
      email
      cocNumber
      companyName
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $address: String!
    $city: String!
    $houseNumber: String!
    $houseNumberAddition: String!
    $zipCode: String!
    $province: String!
    $phone: String!
    $phone2: String!
    $customerNumber: Int!
  ) {
    updateCustomer(
      address: $address
      city: $city
      houseNumber: $houseNumber
      houseNumberAddition: $houseNumberAddition
      zipCode: $zipCode
      province: $province
      phone: $phone
      phone2: $phone2
      customerNumber: $customerNumber
    ) {
      firstName
      initials
      lastName
      birthDate
      address
      houseNumber
      houseNumberAddition
      city
      zipCode
      province
      phone
      phone2
      email
    }
  }
`;

export default UPDATE_CUSTOMER_BL;
