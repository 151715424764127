import type { ModalSizes } from '@type';

export enum ProductState {
  Created = 'created',
  Cancelled = 'cancelled',
  CreditDeclined = 'credit_declined',
  CreditFailed = 'credit_failed',
  QuoteCanceled = 'quote_canceled',
  QuoteExpired = 'quote_expired',
  PendingProspect = 'pending_prospect',
  DocumentsRejected = 'documents_rejected',
  PendingSales = 'pending_sales',
  PendingFinance = 'pending_finance',
  ApprovedFinance = 'approved_finance',
  ReadyForContract = 'ready_for_contract',
  Contracted = 'contracted',
  ReadyForCreditCheck = 'ready_for_credit_check',
  CreditCheckApproved = 'credit_check_approved',
  CreditCheckDenied = 'credit_check_denied',
  // Belgium specific statusses for now
  ContractSigned = 'contract_signed',
  ContractOrdered = 'contract_ordered',
  ContractRegistered = 'contract_registered',
  ContractDelivered = 'contract_delivered',
  ContractReturned = 'contract_returned',
  ContractEnded = 'contract_ended',
  ContractCanceled = 'contract_canceled',
}

export const CUSTOMER_ACTIONS = {
  claimfree_upload: 'claimfree_upload',
};

export const LOGIN_STRENGTH = {
  weak: 'weak',
  acceptable: 'acceptable',
  strong: 'strong',
};

export const UPLOAD_MIME_TYPES = '.pdf, .doc, .docx, .jpg, .jpeg, .png';

export enum PreDeliveryState {
  Requested = 'Requested',
  Allowed = 'Allowed',
  Signed = 'Signed',
  Approved = 'Approved',
  Driving = 'Driving',
  Returned = 'Returned',
}

export enum PurchaseState {
  Allowed = 'Allowed',
  NotAllowed = 'NotAllowed',
  AlreadyPurchased = 'AlreadyPurchased',
}

export const MODAL_SIZES: Record<ModalSizes, ModalSizes> = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

export const MODAL_NO_QUOTE_CONTRACT: 'no_quote_no_contract' = 'no_quote_no_contract';

export const CLIENT_SERVICE_NUMBER = '030 850 1500';

export const ENTITIES = {
  JLNL: 'justlease-nl',
  JLBE: 'justlease-be',
  JLBL: 'justlease-nl-b2b',
};
