const preventFloatNumber = ((event: KeyboardEvent): boolean => {
  const { key } = event;
  const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete'];

  if (!allowedKeys.includes(key)) {
    event.preventDefault();
    return false;
  }

  return true;
});

export default preventFloatNumber;
