// to be able to use a non-acf-column, Breakpoints were set to 'any' instead of 'number'
export interface Breakpoints {
  sm: number;
  smDown: number,
  md: number;
  lg: number;
  xl: number;
}

const pxToEm = (px: number, rem: number = 16) => px / rem;

export const breakpointsPx: Breakpoints = {
  sm: 576,
  smDown: 575,
  md: 768,
  lg: 992,
  xl: 1200,
};

// eslint-disable-next-line max-len
export const breakpointKeys: Array<keyof Breakpoints> = Object.keys(breakpointsPx) as Array<keyof Breakpoints>;

const breakpointsEm = breakpointKeys
  .reduce((acc, key) => ({
    ...acc,
    [key]: pxToEm(breakpointsPx[key]),
  }), {} as Breakpoints);

export default breakpointsEm;
