import { Rating } from '@constants/ratings';
import { GetTranslation } from '@hooks/i18n/types';

export const ratingTranslations = (t: GetTranslation) => (key: Rating): string => {
  const types = {
    [Rating.Excellent]: t('rating-excellent'),
    [Rating.Good]: t('rating-good'),
    [Rating.Satisfactory]: t('rating-satisfactory'),
    [Rating.Mediocre]: t('rating-mediocre'),
    [Rating.Bad]: t('rating-bad'),
  };

  return types[key] || key;
};
