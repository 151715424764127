import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useQuery } from '@apollo/client';
import { Page } from '@atoms/page';
import { useAuthentication } from '@hooks/authentication';
import { withSetActiveUserLanguage } from '@hocs';
import { GET_CUSTOMER_MENU_INFO } from '@queries/me';
import labels from '@labels';
import { LabelIconsProvider } from '@hooks/labelIcons/provider';
import { getEnv } from '@utils';
import { useI18n } from '@hooks/i18n';
import { useDispatch } from 'react-redux';
import { setDevice } from '@store/device/actions';
import { ApolloContext } from '@context';
import { EMPLOYMENT_TYPE } from '@queries/quote';
import { useRouter } from 'next/router';
import { SiteHeader } from '../siteHeader';
import Container from './main.styled';
import Navigation from '../navigation';

interface Props {
  children: React.ReactNode;
  themeName?: string;
}

const Content = styled.section`
  display: flex;
`;

const Main: React.FC<Props> = ({ children, themeName }) => {
  const { isAuthenticated, setUser } = useAuthentication();
  const { setLanguage } = useI18n();
  const [icons, setIcons] = useState<any>();
  const [customerInfo, setCustomerInfo] = useState<any>();
  const client = useContext(ApolloContext).leaseHub;
  const { data } = useQuery(GET_CUSTOMER_MENU_INFO, { client, fetchPolicy: 'no-cache' });
  const [getEmploymentType, {
    data: employmentData,
  }] = useLazyQuery(EMPLOYMENT_TYPE, { client, fetchPolicy: 'no-cache' });
  const [hasEmploymentType, setHasEmploymentType] = useState(false);
  const { pathname, push } = useRouter();

  useEffect(() => {
    async function getLabel() {
      const label = await labels(getEnv('NEXT_PUBLIC_MYENV_ENVIRONMENT', '') as string);
      setIcons(label.components);
    }
    getLabel();
  }, []);

  useEffect(() => {
    const fetchEmploymentType = async () => {
      await getEmploymentType();
    };
    fetchEmploymentType();
  }, [data]);

  useEffect(() => {
    if (employmentData?.quotes) {
      const hasEmployment = employmentData?.quotes?.find(
        (item:{ prospect: { employmentType: string } }) => (item?.prospect?.employmentType),
      );

      setHasEmploymentType(!!hasEmployment);
    }
  }, [employmentData]);

  useEffect(() => {
    if (!hasEmploymentType && data?.me?.isDebtorChange && (pathname !== '/profile/prospect/edit' && !pathname.includes('/login'))) {
      push('/profile/prospect/edit');
    }
  }, [data, hasEmploymentType, pathname]);

  useEffect(() => {
    if (data) {
      setCustomerInfo(data);
    }
  }, [data]);

  useEffect(() => {
    if (customerInfo) {
      setUser(customerInfo.me);
      setLanguage((customerInfo.me.locale || getEnv('NEXT_PUBLIC_MYENV_DEFAULT_LANGUAGE', '')).replace('_', '-'));
    }
  }, [customerInfo]);

  const dispatch = useDispatch();
  const setDeviceInStore = () => dispatch(setDevice());
  useEffect(() => {
    setDeviceInStore();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setDeviceInStore);
    return () => {
      window.removeEventListener('resize', setDeviceInStore);
    };
  });

  return (
    <LabelIconsProvider icons={icons}>
      <Container>
        <SiteHeader themeName={themeName ?? 'jlnl'} />
        <Content>
          {isAuthenticated && <Navigation />}
          <Page>
            {children}
          </Page>
        </Content>
      </Container>
    </LabelIconsProvider>
  );
};

export default withSetActiveUserLanguage(Main);
