import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  bgImage: string | null;
  dragOver: boolean;
}

export const RemoveImageButton = styled.button<StyledComponentProps>`
  background-color: ${({ theme }) => theme.imageUploadField.removeButton.backgroundColor};
  border: none;
  bottom: -0.125rem;
  color: ${({ theme }) => theme.imageUploadField.removeButton.color};
  font-size: 1rem;
  font-weight: 600;
  left: -0.125rem;
  transition: padding 0.3s ease;
  padding: 1rem 0;
  position: absolute;
  width: calc(100% + 0.125rem);
`;

export const UploadText = styled.span<StyledComponentProps>`
  color: ${({ theme }) => theme.imageUploadField.text.color};
  font-weight: 600;
`;

export const UploadArea = styled.label<Props>`
  align-content: center;
  background-color: ${({ theme, bgImage }) => (bgImage ? 'transparent' : theme.imageUploadField.uploadArea.backgroundColor)};
  background-image: ${({ bgImage }) => (bgImage ? `url(${bgImage})` : 'none')};
  border-radius: ${({ theme }) => theme.imageUploadField.uploadArea.borderRadius};
  border: 0 solid transparent;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 10.75rem;
  min-width: 12.5rem;
  overflow: hidden; /* Needed for hiding the input */
  position: relative;
  transition:
    background-color 0.3s ease,
    border-color 0.3s ease;
  text-align: center;
  ${({ bgImage }) => bgImage && css`
    background-image: url(${bgImage});
    background-size: cover;
    background-position: center;
  `}

  ${({ theme, dragOver }) => dragOver && css`
    background-color: ${theme.imageUploadField.uploadArea.activeBackgroundColor};
    border-color: ${theme.imageUploadField.uploadArea.activeBorderColor};
    border-width: 0.125rem;
  `}

  &:hover {
    background-color: ${({ theme }) => theme.imageUploadField.uploadArea.activeBackgroundColor};
    border-color: ${({ theme }) => theme.imageUploadField.uploadArea.activeBorderColor};
    border-width: 0.125rem;
  }

  /*
   * Needed to do some weird stuff. When the actual input is in the visible area, that part triggers the drag-leave.
   * By using top: -2rem, this element get's out of the visible area and will not trigger anything
   */
  input {
    bottom: 0;
    box-sizing: border-box;
    cursor: pointer;
    left: 0;
    opacity: 0;
    position: absolute;
    top: -2rem;
    width: 100%;
  }
`;
