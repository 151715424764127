import flattenError, { FlatError } from '@utils/flattenError';
import { compose } from 'redux';
import { getEnv, postFormData } from '@utils';
import {
  UPLOAD_SUCCESS, UPLOAD_FAILED, UPLOADING, DELETE_SUCCESS, DELETE_FAILED, DELETING,
} from './constants';

import {
  UploadSuccess, UploadFailed, Uploading, DeleteSuccess, DeleteFailed, Deleting,
} from './types';

const uploading = (id: number): Uploading => ({
  type: UPLOADING,
  id,
});

const deleting = (id: number): Deleting => ({
  type: DELETING,
  id,
});

export const uploadSuccess = (id: number, data: Record<string, any>): UploadSuccess => ({
  type: UPLOAD_SUCCESS,
  data,
  id,
});

export const deleteSuccess = (id: number, data: {
  [key: string]: any;
}): DeleteSuccess => ({
  type: DELETE_SUCCESS,
  data,
  id,
});

const uploadFailed = (id: number) => (err: FlatError | null | undefined): UploadFailed => ({
  type: UPLOAD_FAILED,
  id,
  err,
});

const deleteFailed = (id: number) => (err: FlatError | null | undefined): DeleteFailed => ({
  type: DELETE_FAILED,
  id,
  err,
});

const upload = (
  id: number,
  file: string | Blob,
  uri: string,
  token: string,
  cosigneeToken?: string,
) => async (dispatch: (...args: Array<any>) => any) => {
  dispatch(uploading(id));

  const endpoint = `${getEnv('NEXT_PUBLIC_MYENV_UPLOAD_URL')}${uri}`;

  const body = new FormData();
  body.append('upload', file);

  const headers = {
    authorization: token ? `Bearer ${token}` : `cosignee-bearer ${cosigneeToken}`,
  };

  let responseData: Record<string, any>;
  try {
    responseData = await postFormData({
      endpoint,
      method: 'POST',
      body,
      headers,
    });
  } catch (error) {
    compose(dispatch, uploadFailed(id), flattenError)(error);

    throw error;
  }

  dispatch(uploadSuccess(id, responseData));
};

export const deleteUpload = (
  id: number,
  uri: string,
  token: string,
) => async (dispatch: (...args: Array<any>) => any) => {
  dispatch(deleting(id));

  const endpoint = `${getEnv('NEXT_PUBLIC_MYENV_UPLOAD_URL')}${uri}`;

  const headers = {
    authorization: `Bearer ${token}`,
  };

  let responseData: Record<string, any>;
  try {
    responseData = await postFormData({
      endpoint,
      method: 'DELETE',
      headers,
    });
  } catch (error) {
    compose(dispatch, deleteFailed(id), flattenError)(error);

    throw error;
  }

  dispatch(deleteSuccess(id, responseData));
};

export default upload;
