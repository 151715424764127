import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  error: boolean;
  pristine: boolean;
  disabled: boolean;
  touched: boolean;
  value?: string;
}

const focusStyle = css<Props>`
  outline: 0;

  &::placeholder {
    color: ${({ theme }) => theme.form.placeholderColor};
    transition: color 0.3s ease;
  }
`;

export const textAreaStyles = css<Props>`
  border: 1px solid ${({ theme }) => theme.form.input.borderColor};
  font-family: "Open Sans", Arial, sans-serif;
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
  box-sizing: border-box;
  display: block;
  font-size: ${({ theme }) => theme.form.input.fontSize};
  height: 10.75rem;
  line-height: 1.1;
  margin: 0;
  padding: 1rem;
  position: relative;
  transition: all 0.3s ease;
  resize: vertical;
  width: 100%;

  ${({ theme, error }) => error && css`
    border-radius: ${theme.form.input.borderRadius} ${theme.form.input.borderRadius} 0 0;
    border-bottom-color: ${theme.form.input.borderColorError};
  `}
  ${({ theme, disabled }) => disabled && css`
    background-color: ${theme.form.input.disabledBackgroundColor};
  `}
  ${({ pristine }) => !pristine && css`
    ${focusStyle}
  `}
  ${({ touched, value }) => (touched || value) && css`
    ${focusStyle}
  `}

  &:focus {
    ${focusStyle}
  }
`;

// this is a workaround because the password fields from listo are not the same as MJL
export const TextArea = styled.textarea`
  ${textAreaStyles}
`;
