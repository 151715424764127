import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink as RouterLink } from '@atoms/navLink';

const styledLink = css`
  text-decoration: none;
  list-style: none;
  display: flex;
  height: 1.65rem;
  margin: 0.9rem 1.375rem;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &:visited {
    color: inherit;
  }

  &:link {
    color: inherit;
  }
`;

const LinkOutside = styled.a`
  ${styledLink}
`;

const Span = styled.span`
  ${styledLink}
`;

const StyledLink = styled(RouterLink)`
  ${styledLink}

  &.active {
    opacity: 1;
  }
`;

interface Props {
  href: string;
  alias?: string;
  exact: boolean;
}

export const Link: React.FC<Props> = ({
  href,
  alias,
  children,
  exact,
}: any) => {
  // It is a simple element with nothing to link to
  if (!href) return <Span>{children}</Span>;
  // It is intended to be an external link
  if (/^https|mailto|tel?:\/\//.test(href)) {
    return (
      <LinkOutside href={href}>
        {children}
      </LinkOutside>
    );
  }

  // Finally, it is an internal link
  return (
    <StyledLink href={href} alias={alias} exact={exact}>
      {children}
    </StyledLink>
  );
};
