import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useRouter } from 'next/router';
// import { setSearchParam } from '@store/router';
import { HeaderNavigation } from '@molecules';
import { HeaderNavigationItem } from '@type/molecules';
import { useI18n, GetTranslation } from '@hooks/i18n';
import { Overlay } from '../overlay';

const getLanguages = (
  t: GetTranslation,
  setLanguage: (language: string) => void,
  updateUrl: (language: string) => void,
): HeaderNavigationItem[] => [{
  callback: () => {
    setLanguage('nl-BE');
    updateUrl('nl-BE');
  },
  label: t('language-dutch'),
}, {
  callback: () => {
    setLanguage('fr-BE');
    updateUrl('fr-BE');
  },
  label: t('language-french'),
}];

const LanguageSelector: React.FC<{}> = () => {
  const [open, setOpen] = useState(false);
  // const dispatch = useDispatch();
  // const { push } = useRouter();
  const { t, langCode, setLanguage } = useI18n();

  // const updateUrl = (language: string) => {
  //   dispatch(setSearchParam(push, 'lang', language));
  // };

  return (
    <>
      {open && (
        <Overlay onClick={() => setOpen(false)} />
      )}

      <HeaderNavigation
        open={open}
        toggleNavigation={() => setOpen(!open)}
        label={langCode}
        menuItems={getLanguages(t, setLanguage, () => {})}
      />
    </>
  );
};

export default LanguageSelector;
