import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import Link from 'next/link';
import { StyledComponentProps } from '@type';

export const Navigation = styled.ul<StyledComponentProps>`
  color: ${({ theme }) => theme.headerNavigation.color.active};
  background-color: ${({ theme }) => theme.headerNavigation.backgroundColor.active};
  box-sizing: border-box;
  left: 0;
  margin: 0;
  opacity: 0;
  padding: 0 1.25rem;
  position: absolute;
  top: ${({ theme }) => theme.header.height};
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: ${({ theme }) => theme.headerNavigation.zIndex};

  ${media.sm(css`
    top: auto;
    border-radius: 0 0 4px 4px;
  `)};

  &.entered {
    visibility: visible;
    opacity: 1;
  }

  &.exited {
    visibility: hidden;
  }
`;

export const NavigationItem = styled.li`
  border-top: 1px solid currentColor;
  box-sizing: border-box;
  height: 3.5rem;
  line-height: 1.5rem;
  list-style: none;
  padding: 1rem 0;

  &:first-of-type {
    border-top: none;
  }

  ${media.sm(css`
    &:first-of-type {
      border-top: 1px solid currentColor;
    }
  `)};
`;

const navigationLinkStyle = css`
  text-decoration: none;
  position: relative;

  &::after {
    background-color: currentColor;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    transition: width 0.3s ease;
    width: 0%;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }

  &:visited,
  &:link {
    color: inherit;
  }
`;

interface NextLinkProps {
  href: string;
}

const StyledLink = styled.a<NextLinkProps>`
  ${navigationLinkStyle}
`;

export const NavigationLink: React.FC<NextLinkProps> = ({ href, children }) => (
  <Link href={href} passHref>
    {/* @ts-ignore => Complains about missing 'href' which is true due to Next Link */}
    <StyledLink>{children}</StyledLink>
  </Link>
);

export const NavigationAction = styled.span`
  ${navigationLinkStyle}
`;
