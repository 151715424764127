import styled from 'styled-components';
import { Span } from '@atoms';

export const StyledSpan = styled(Span)`
    display: block;
    margin-bottom: 1rem;
`;

export const StyledSubtitle = styled(Span)`
    display: block;
    margin-bottom: 1rem;
`;
