import React, { useContext } from 'react';
import {
  DepositPaid, DepositPromotion, DepositToPay, PageLoader,
} from '@molecules';
import { ApolloContext } from '@context';
import { useQuery } from '@apollo/client';
import QUOTE_DEPOSIT_URI, { GetDepositUriVars, GetDepositUriResult } from '@queries/quote/quoteDepositUri';
import { QuoteFlows } from '@constants/quoteProgress';

interface Props {
  quoteNumber: string;
  quoteFlow?: string;
}

export const Deposit: React.FC<Props> = ({
  quoteNumber,
  quoteFlow,
}) => {
  const client = useContext(ApolloContext).leadHub;
  const {
    data, loading, error,
  } = useQuery<GetDepositUriResult, GetDepositUriVars>(QUOTE_DEPOSIT_URI, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      quoteNumber,
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  if (error || !data) {
    return <span>{error?.message}</span>;
  }

  const {
    depositAmount,
    paymentUri,
    depositPaid,
    state,
  } = data?.quotes[0];

  return (
    <>
      {depositPaid && depositAmount > 0 && <DepositPaid />}
      {depositPaid && depositAmount === 0 && quoteFlow !== QuoteFlows.Jlbe && <DepositPromotion />}
      {!depositPaid && (
      <DepositToPay
        amount={depositAmount}
        quoteNumber={quoteNumber}
        paymentUri={paymentUri || ''}
        productState={state}
        isPaid={depositPaid}
      />
      )}
    </>
  );
};
