import { gql } from '@apollo/client';
import { Quote } from '@type/graphql';

export interface QuoteStepStatusResult {
  quotes: Array<Quote>
}

export interface QuoteStepStatusArgs {
  quoteNumber: string;
}

const QUOTE_STEP_STATUS = gql`
  query QuoteNavigationStatus($quoteNumber: String!) {
    quotes(number: $quoteNumber) {
      id
      state
      quoteNumber
      creditCheckStatus
      digitalSigningUri
      sepaUri
      depositPaid
      quoteFlow
      prospectProgress
      documentRequestsCategories {
        id
        label
        title
        documentRequests {
          id
          category
          title
          description
          uploadUri
          required
          hasUploads
          isRejected
          label
          rejectionReason
          uploads {
            id
            type
            downloadUri
            deleteUri
            originalName
          }
        }
      }
    }
  }
`;

export default QUOTE_STEP_STATUS;
