import { GetTranslation } from '@hooks/i18n';
import { filterByGroupType, filterByAccountingCode } from '@utils/filters';
import { isCarculatorConnected, isProlongationsEnabled } from '@flags';
import { MappedData } from '@type';
import { ExtrasResponse, Contract } from '@type/graphql';
import { mapExtras } from './extrasMapper';
import { getEnv } from '../getEnv';

export const mapContractDetails = (
  {
    expectedEndDate,
    canEditContract,
    availableContractExtras,
    availableKilometerBundles,
    availableProlongations,
    orderNewCarAllowed,
    leaseItem: {
      mileage,
      duration,
      odometerReading,
      package: {
        name,
      },
      extras,
    },
  }: Contract,
  contractNumber: string,
  t: GetTranslation,
  dateFormat: (date: Date, options: Record<string, string>) => string,
): MappedData => {
  const hasProlongations = () => {
    if (isProlongationsEnabled()) {
      return (availableProlongations || []).length > 0;
    }
    if (!isProlongationsEnabled() && canEditContract) {
      return (mapExtras(extras, filterByAccountingCode('PROLONGATION'), false) || []).length > 0;
    }
    return false;
  };

  const canEditQuoteDetails = (option: null | Array<any>) => (option || []).length > 0;

  return [{
    label: t('end-date'),
    value: expectedEndDate ? dateFormat(new Date(expectedEndDate), {
      day: 'numeric',
      month: '2-digit',
      year: 'numeric',
    }) : null,
  }, {
    label: t('current-odometer-reading'),
    value: odometerReading ? odometerReading.toString() : '',
    edit: canEditContract ? `/contract/${contractNumber}/bundlestatus/odometer` : null,
  }, {
    label: t('package-type'),
    value: name,
  }, {
    label: t('monthly-miles'),
    value: mileage.name,
    edit: canEditQuoteDetails(availableKilometerBundles) && canEditContract ? `/contract/${contractNumber}/change/mileage` : '',
  }, {
    label: t('duration'),
    value: duration.name,
  }, {
    label: t('prolongation'),
    value: mapExtras(extras, filterByGroupType('contract'), false)
      .filter((extra) => extra.accountingCode.indexOf('PROLONGATION') === 0)
      .map((extra: ExtrasResponse) => extra.name),
    edit: hasProlongations() ? `/contract/${contractNumber}/change/prolongation` : null,
    placeholder: t('prolongation-not-selected'),
    actionText: t('prolongation-not-selected-button'),
    extend: hasProlongations(),
  }, {
    label: t('order-new-car-label'),
    value: [],
    edit: getEnv('NEXT_PUBLIC_CARCULATOR_URL'),
    placeholder: t('order-new-car-description'),
    actionText: t('order-new-car-button-text'),
    extend: isCarculatorConnected(orderNewCarAllowed),
  }, {
    label: t('accessories'),
    value: mapExtras(extras, filterByGroupType('extra'), false).map((extra) => extra.name),
    extend: extras.filter((extra: any) => extra.groupType === 'extra').length <= 0,
  }, {
    label: t('extra-insurances'),
    value: mapExtras(extras, filterByGroupType('contract'), false)
      .filter((extra) => !extra.inBundle && extra.accountingCode.indexOf('PROLONGATION') !== 0)
      .map((extra) => extra.name),
    edit: canEditQuoteDetails(availableContractExtras) && canEditContract ? `/contract/${contractNumber}/change/extras` : null,
    placeholder: t('extra-insurances-not-selected'),
    actionText: t('extra-insurances-not-selected-button'),
    extend: ((availableContractExtras || []).length > 0 && extras.filter((extra: any) => (!extra.inBundle && extra.groupType === 'contract' && extra.accountingCode.indexOf('PROLONGATION') === -1)).length <= 0),
  }].filter(({
    value,
    extend,
  }: any) => {
    if (Array.isArray(value)) {
      return value.length > 0 || extend;
    }
    return !!value;
  });
};
