import React from 'react';
import { FieldError, Label, FormFieldContainer } from '@atoms/form';
import { useFormContext } from 'react-hook-form';
import { SelectStyled } from './select.styled';

interface Props {
  name: string;
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  fieldRef: any;
  error?: {
    message: string;
  };
}

export const SelectRHFField: React.FC<Props> = ({
  label,
  name,
  disabled = false,
  fieldRef,
  children,
  error,
  defaultValue = '',
}) => {
  const { watch, setValue } = useFormContext();
  const selectedValue = watch(name);
  const setSelectedValue = (event: { target: HTMLSelectElement; }) => {
    setValue(name, event.target.value);
  };

  return (
    <FormFieldContainer>
      <SelectStyled
        disabled={disabled}
        label={label}
        id={name}
        name={`${name}-select`}
        error={Boolean(error)}
        defaultValue={defaultValue}
        value={selectedValue}
        onChange={setSelectedValue}
        data-testid={name}
      >
        {children}
      </SelectStyled>
      <Label htmlFor={label}>{label}</Label>
      <input type="hidden" name={name} ref={fieldRef} />
      {error && <FieldError message={error.message} />}
    </FormFieldContainer>
  );
};
