import styled from 'styled-components';

export default styled.div`
    display: flex;

    button {
        border-radius: 0px;
    }

    button:first-child {
        border-right: none;
    }
`;
