import React from 'react';
import { FontWeight } from '@type/globals';
import { StyledComponentProps } from '@type';
import styled from 'styled-components';

export const ProgressContainer = styled.div<StyledComponentProps>`
  background-color: ${({ theme }) => theme.quote.progress.backgroundColor};
  box-sizing: border-box;
  color: ${({ theme }) => theme.quote.progress.color};
  font-weight: ${({ theme }) => theme.quote.progress.fontWeight};
  height: 2.75rem;
  line-height: 2.75rem;
  margin-bottom: 1rem;
  padding: 0 1.5rem;
  position: relative;
  border-radius: 4px;
`;

interface StyledProgressProps extends StyledComponentProps {
  percentage: number;
}

export const Progress = styled.div<StyledProgressProps>`
  background-color: ${({ theme }) => theme.quote.progress.statusColor};
  color: ${({ theme }) => theme.quote.progress.color};
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  width: ${({ percentage }) => percentage}%;
  max-width: 100%;
  border-radius: 4px;
`;

const ProgressText = styled.div`
  position: relative;
  text-align: center;
  font-weight: ${FontWeight.Normal};
`;

interface Props {
  percentage: number;
  label: string;
}

export const ProgressBar: React.FC<Props> = ({
  percentage,
  label,
}) => {
  const cappedPercentage = percentage > 100 ? 100 : percentage;
  return (
    <ProgressContainer>
      <Progress percentage={cappedPercentage} />
      <ProgressText>
        {cappedPercentage}
        {label}
      </ProgressText>
    </ProgressContainer>
  );
};
