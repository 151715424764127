import React from 'react';
import styled from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props {
  className?: string;
  message: string;
  general?: boolean;
}

interface StyledProps extends StyledComponentProps {
  general: boolean;
}

const ErrorMessage = styled.div.attrs<StyledProps>((props) => ({
  general: props.general || false,
}))<StyledProps>`
  background-color: ${({ theme }) => theme.form.error.backgroundColor};
  border-radius: ${({ theme, general }) => (general ? theme.form.error.borderRadiusGeneral : theme.form.error.borderRadius)};
  color: ${({ theme }) => theme.form.error.color};
  font-size: 0.875rem;
  padding: 0.125rem 1rem 0.25rem;
`;

export const FieldError: React.FC<Props> = ({
  className = '',
  message,
  general = false,
}) => (
  <ErrorMessage data-testid="errorMessage" className={className} general={general}>
    {message}
  </ErrorMessage>
);
