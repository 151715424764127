import React from 'react';
import { MessageStack } from '@molecules';
import { useMessageStack } from '@hooks/messageStack';

const Messages: React.FC = () => {
  const { messages, hideMessage } = useMessageStack();

  const onClose = (id: React.ReactText) => {
    hideMessage(id as string);
  };

  return <MessageStack onClose={onClose} messages={messages} />;
};

export default Messages;
