import { gql } from '@apollo/client';

const CHANGE_QUOTE = gql`
  mutation ChangeQuote($quote: QuoteInput) {
    changeQuote(quote: $quote) {
      id
    }
  }
`;

export default CHANGE_QUOTE;
