import {
  CANCEL_FAILED, CANCEL_SUCCESS, CANCELING, GET_CREDIT_CHECK_FORM_METADATA,
  GET_CREDIT_CHECK_FORM_METADATA_SUCCESS, GET_CREDIT_CHECK_FORM_METADATA_FAIL, SORT_FIELD_DIRECTION,
  SAVE_FLOW_TYPE, SET_PREORDER_DATA,
} from './constants';
import {
  Quotes, Canceling, CancelFailed, CancelSuccess, GetCreditCheckMetaData,
  GetCreditCheckMetaDataSuccess, GetCreditCheckMetaDataFail, SortFieldDirection, SaveFlowType,
  SetPreorderData,
} from './types';

export type State = Quotes;

type Action = Canceling | CancelFailed | CancelSuccess | GetCreditCheckMetaData |
GetCreditCheckMetaDataSuccess | GetCreditCheckMetaDataFail | SortFieldDirection |
SaveFlowType | SetPreorderData;

const initialState: State = {};

const quotes = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case CANCEL_SUCCESS:
      return {
        ...state,
        [action.quoteNumber]: {
          ...state[action.quoteNumber],
          canceling: false,
        },
      };
    case CANCELING:
      return {
        ...state,
        [action.quoteNumber]: {
          ...state[action.quoteNumber],
          canceling: true,
        },
      };
    case CANCEL_FAILED:
      return {
        ...state,
        [action.quoteNumber]: {
          ...state[action.quoteNumber],
          canceling: false,
        },
      };
    case GET_CREDIT_CHECK_FORM_METADATA:
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    case GET_CREDIT_CHECK_FORM_METADATA_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: false,
        success: true,
        loading: false,
      };
    case GET_CREDIT_CHECK_FORM_METADATA_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
        loading: false,
      };
    case SORT_FIELD_DIRECTION:
      return {
        ...state,
        sort: {
          ...action.payload,
        },
      };
    case SAVE_FLOW_TYPE:
      return {
        ...state,
        quoteFlow: action.payload,
      };
    case SET_PREORDER_DATA:
      return {
        ...state,
        [action.quoteNumber]: {
          ...state[action.quoteNumber],
          isPreorder: action.isPreorder,
        },
      };
    default:
      return state;
  }
};

export default quotes;
