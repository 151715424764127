import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import { SvgIcon, CardContent } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { StyledComponentProps as StyledProps } from '@type';
import CheckmarkTitle from '@atoms/checkmarkTitle';
import { Card } from '../card';

const PromotionContainer = styled.section<StyledProps>`
  background-color: ${({ theme }) => theme.deposit.container.backgroundColor};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.sm(css`
    flex-direction: row;
  `)}
`;

const DepositWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1.25rem 0 0;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const InfoText = styled.p<StyledProps>`
  color: ${({ theme }) => theme.deposit.text.generalInfoColor};
`;

const StyledSvgIcon = styled(SvgIcon)`
  width: 5.5rem;
  height: 5.5rem;
  margin: 0 0 1.25rem;
  ${media.sm(css`
    margin: 0 1.25rem 0 0;
  `)}
`;

const Amount = styled.span<StyledProps>`
  color: ${({ theme }) => theme.deposit.payComplete.color};
  font-weight: bold;
  font-size: 1rem;
`;

const DepositTag = styled.span`
  font-weight: bold;
`;

export const DepositPromotion: React.FC = () => {
  const { t } = useI18n();

  return (
    <Card renderHeader={() => <CheckmarkTitle showIcon={false}>{t('deposit-title')}</CheckmarkTitle>}>
      <CardContent>
        <PromotionContainer>
          <StyledSvgIcon icon="partyPopper" />
          <InfoContainer>
            <InfoText>{t('deposit-checked-description')}</InfoText>
            <DepositWrapper>
              <DepositTag>
                {`${t('deposit-tag')}: `}
              </DepositTag>
              <Amount>
                &euro; 0.00
              </Amount>
            </DepositWrapper>
          </InfoContainer>
        </PromotionContainer>
      </CardContent>
    </Card>
  );
};
