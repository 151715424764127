import styled, { css } from 'styled-components';
import { FormFieldContainer, FieldsWrapper } from '@atoms/form';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  error: boolean;
}

export const DateFieldContainer = styled(FormFieldContainer)<Props>`
  border: 1px solid ${({ theme }) => theme.form.input.borderColor};
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 2fr 4fr 3fr;
  grid-template-rows: auto;
  margin-bottom: 0;

  ${({ error, theme }) => error && css`
    border-bottom-color: ${theme.form.input.borderColorError};
    border-radius: ${theme.form.input.borderRadius} ${theme.form.input.borderRadius} 0 0;
  `};

  select {
    background-size: 0.75rem 0.75rem;
    border: none;
    background-position: right 0.25rem bottom 0.8rem;
    background-color: transparent;
  }

  select:first-of-type {
    border-radius: 0;
    border-right: 0;
    text-align: right;
  }

  select:nth-of-type(2) {
    border-radius: 0;
    border-left: 0;

    border-right: 0;
    text-align: center;
  }

  select:last-of-type {
    border-radius: 0;
    border-left: 0;
  }
`;

export const DateWrapper = styled(FieldsWrapper)<Props>`
  margin-bottom: 1rem;

  > input[type="text"] {
    border: none;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
  }
`;
