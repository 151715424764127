import React from 'react';
import { Divider, Heading } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { OptionsResponse } from '@type/graphql';
import { HeadingLevel } from '@type';
import { OptionCheckbox } from '../optionTypes/optionCheckbox';

interface Props {
  optionTitle: string;
  activeSelection: {
    [key: string]: boolean;
  };
  options: Array<OptionsResponse>;
  groupId: string;
  onChange: (optionId: string) => void;
  isQuote?: boolean;
  disabled?: boolean;
  divider?: boolean;
}

export const CheckBoxGroup: React.FC<Props> = ({
  activeSelection,
  groupId,
  isQuote = true,
  onChange,
  options,
  optionTitle,
  divider = false,
  disabled = false,
  children,
}) => {
  const { t } = useI18n();

  return (
    <>
      {divider && <Divider />}
      <Heading level={HeadingLevel.H4}>{optionTitle}</Heading>
      {activeSelection && options.map(({
        id,
        price,
        name,
        inBundle = false,
      }) => (
        <OptionCheckbox
          name={name}
          key={id}
          checked={activeSelection[id]}
          disabled={!isQuote || disabled || inBundle}
          groupId={groupId}
          id={id}
          included={disabled || inBundle}
          infoModal={disabled ? t('extra-from-bundle') : ''}
          price={price}
          onChange={() => onChange(id)}
        >
          {children}
        </OptionCheckbox>
      ))}
    </>
  );
};
