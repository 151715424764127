import React, { useState } from 'react';
import {
  FormFieldContainer, InputPrefix, Label, FieldError,
} from '@atoms/form';
import Input from './components';

interface Props {
  placeholder: string;
  label: string;
  name: string;
  value?: string;
  type?: string;
  prefix?: string;
  disabled?: boolean;
  onChange: (...args: Array<any>) => any;
  validate?: ((...args: Array<any>) => any)[];
}

export const InputField: React.FC<Props> = ({
  label,
  name,
  placeholder,
  prefix,
  disabled = false,
  type = 'text',
  validate = [],
  onChange,
  ...input
}) => {
  const [value, setValue] = useState(input.value || '');
  const [touched, setTouched] = useState(false);
  const [pristine, setPristine] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [valid, setValid] = useState(true);

  const validateValue = (newValue: string) => {
    const result = validate.map(
      (rule: (...args: Array<any>) => any) => rule(newValue),
    ).find((message) => message);

    setValid(!result);
    setError(result);
  };

  const inputChange = async (evt: { target: HTMLInputElement }) => {
    const newValue = evt.target.value;
    await validateValue(newValue);
    if (!pristine) {
      setPristine(true);
    }
    setValue(newValue);
    onChange(newValue);
  };

  const onBlur = () => {
    setTouched(true);
    validateValue(value);
  };

  return (
    <FormFieldContainer>
      <Input
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...input}
        placeholder={placeholder}
        error={touched && Boolean(error)}
        type={type}
        id={name}
        disabled={disabled}
        hasPrefix={!!prefix}
        onChange={inputChange}
        value={value}
        pristine={pristine}
        touched={touched}
        onBlur={onBlur}
      />
      <Label htmlFor={name}>{label}</Label>
      {prefix && <InputPrefix className="input-prefix">{prefix}</InputPrefix>}
      {touched && !valid && error && <FieldError message={error} />}
    </FormFieldContainer>
  );
};

InputField.defaultProps = {
  type: 'text',
  prefix: '',
  value: '',
  validate: [],
};
