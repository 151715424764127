import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetTranslation } from '@hooks/i18n';
import { getEnv } from '@utils/getEnv';
import { createApolloClient } from './createApolloClient';

type LogoutCallback = () => void;

const leadHub = async (
  logout: LogoutCallback = () => {},
  accessToken: string = '',
  isAuthenticated: boolean,
  t: GetTranslation,
  cosigneeToken?: string,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  const client = await (
    createApolloClient(
      getEnv('NEXT_PUBLIC_MYENV_GRAPHQL_LEADHUB_URL') as string,
      logout,
      accessToken,
      isAuthenticated,
      t,
      cosigneeToken,
    )
  );

  return client;
};

const leaseHub = async (
  logout: LogoutCallback = () => {},
  accessToken: string = '',
  isAuthenticated: boolean,
  t: GetTranslation,
  cosigneeToken?: string,
): Promise<ApolloClient<NormalizedCacheObject>> => {
  const client = await (
    createApolloClient(
      getEnv('NEXT_PUBLIC_MYENV_GRAPHQL_LEASEHUB_URL') as string,
      logout,
      accessToken,
      isAuthenticated,
      t,
      cosigneeToken,
    )
  );
  return client;
};

export { leadHub, leaseHub };
