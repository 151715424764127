import { $ElementType } from 'utility-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { getUiSetting, setUiSetting } from '@store/ui';
import { useI18n } from '@hooks/i18n';
import {
  Divider, Button, Heading, CardActions, CardContent,
} from '@atoms';
import { PageHeader, Card } from '@molecules';
import { optionMap } from '@molecules/changeItem';
import { OptionType } from '@molecules/changeItem/types';
import { OptionsResponse } from '@type/graphql';
import { HeadingLevel } from '@type';
import { ChangeContractLine } from '@constants/changeQuoteContract';
import { translation } from './translations';

type ListItem = $ElementType<OptionsResponse | OptionsResponse, 'extras'>;

interface Props {
  availableOptions: Array<OptionsResponse>;
  availableOptionsTitle: string;
  cardTitle?: string;
  currentOption: OptionsResponse | null;
  currentOptionTitle: string;
  groupName: ChangeContractLine;
  isContract?: boolean;
  itemNumber: string;
  optionType: OptionType;
  renderOptionExtra?: null | ((list: ListItem) => JSX.Element);
  renderSubmitButton: (activeSelection: string) => JSX.Element;
}

export const ChangeItemSingleSelect: React.FC<Props> = ({
  groupName,
  itemNumber,
  currentOption,
  availableOptions,
  availableOptionsTitle,
  cardTitle = '',
  currentOptionTitle,
  isContract = false,
  optionType,
  renderOptionExtra = null,
  renderSubmitButton,
}) => {
  const { t } = useI18n();
  const getTranslation = translation(t);
  const { push, back } = useRouter();
  const dispatch = useDispatch();
  const groupId = `${groupName}-${itemNumber}`;
  const activeSelection = useSelector((state) => getUiSetting(state, groupId)) as string;
  const currentActive = activeSelection === (currentOption && currentOption.id);

  useEffect(() => {
    if (currentOption) {
      dispatch(setUiSetting(groupId, currentOption.id));
    }
  }, []);

  const toggleChosenOption = (optionId: string) => {
    if (optionType === 'checkbox') {
      dispatch(setUiSetting(groupId, optionId));
    } else {
      dispatch(setUiSetting(groupId, optionId === activeSelection ? 0 : optionId));
    }
  };
  const OptionComponent = optionMap[optionType];

  return (
    <>
      {cardTitle && <PageHeader title={cardTitle} />}
      <Card renderFooter={() => (
        <CardActions>
          <Button
            outline
            onClick={() => {
              if (window.history.length) {
                back();
                return;
              }
              push(isContract ? `/contract/${itemNumber}` : `/quote/${itemNumber}`);
            }}
          >
            {t('cancel')}
          </Button>
          {renderSubmitButton(activeSelection)}
        </CardActions>
      )}
      >
        <CardContent>
          {currentOption && (
          <>
            <Heading level={HeadingLevel.H4}>{currentOptionTitle}</Heading>
            <OptionComponent
              name={currentOption.name}
              id={currentOption.id}
              hexcode={currentOption.hexcode || ''}
              checked={currentActive}
              groupId={groupId}
              onChange={() => toggleChosenOption(currentOption.id)}
              price={0}
              infoModal=""
            >
              {renderOptionExtra && renderOptionExtra(currentOption.extras)}
            </OptionComponent>
          </>
          )}
          <Divider />
          <Heading level={HeadingLevel.H4}>{availableOptionsTitle}</Heading>
          {availableOptions && availableOptions.length > 0 ? availableOptions.map((option) => (
            <OptionComponent
              name={option.name}
              id={option.id}
              hexcode={option.hexcode}
              checked={activeSelection === option.id}
              groupId={groupId}
              key={option.id}
              onChange={() => toggleChosenOption(option.id)}
              price={option.price - ((currentOption && currentOption.price) || 0)}
              infoModal=""
            >
              {renderOptionExtra && renderOptionExtra(option.extras)}
            </OptionComponent>
          )) : <span>{getTranslation(groupName)}</span>}
        </CardContent>
      </Card>
    </>
  );
};
