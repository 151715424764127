import React, { useEffect, useContext, ComponentType } from 'react';
import { ApolloContext } from '@context';
import { useRouter } from 'next/router';
import { useI18n } from '@hooks/i18n';
import { useAuthentication } from '@hooks/authentication';
import GET_CUSTOMER_LOCALE, { CustomerLocaleResponse } from '@queries/me/getLocale';
import { useLazyQuery } from '@apollo/client';
import { getEnv } from '@utils';

const withSetActiveUserLanguage = <P extends object>(WrappedComponent: ComponentType<P>) => (
  (props: P) => {
    const { query } = useRouter();
    const { isAuthenticated, hasUserData } = useAuthentication();
    const client = useContext(ApolloContext).leadHub;
    const { langCode, setLanguage } = useI18n();
    const [getLocale, { data }] = useLazyQuery<CustomerLocaleResponse>(
      GET_CUSTOMER_LOCALE,
      { client },
    );

    useEffect(() => {
      if (isAuthenticated && hasUserData) {
        getLocale();
      }
    }, [hasUserData]);

    useEffect(() => {
      if (query?.lang) {
        const langParam = (query?.lang as string) || getEnv('NEXT_PUBLIC_MYENV_DEFAULT_LANGUAGE') || '';
        const language = langParam.replace('_', '-');
        if (language !== langCode) {
          setLanguage(language);
        }
      }

      if (isAuthenticated) {
        if (data?.me?.locale) {
          const lang = data.me.locale.replace('_', '-');
          if (lang !== langCode) {
            setLanguage(lang);
          }
        }
      }
    }, [data, langCode, setLanguage]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedComponent {...props as P} />;
  }
);
export default withSetActiveUserLanguage;
