import React from 'react';
import { getEnv } from '@utils';
import Cookies from 'js-cookie';
import { LOCALSTORAGE_TOKEN_KEY } from '@constants/cookie';

const downloadPDF = (uri: string, pdfName: string) => async (
  evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => {
  evt.preventDefault();
  const token = Cookies.get(LOCALSTORAGE_TOKEN_KEY);
  if (!token) return;
  // Retrieve PDF
  const response = await fetch(`${getEnv('NEXT_PUBLIC_MYENV_LEADHUB_URL')}/${uri}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  // Create blob
  const blob = await response.blob();
  const newBlob = new Blob([blob], {
    type: 'application/pdf',
  });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.setAttribute('download', `${pdfName}.pdf`);

  if (typeof link.download === 'undefined') {
    link.setAttribute('target', '_blank');
  }
  const {
    body,
  } = document;
  // For Flow
  if (body) {
    body.appendChild(link);
    link.click();
    body.removeChild(link);
  }

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

export default downloadPDF;
