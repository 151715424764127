import React from 'react';
import styled from 'styled-components';
import { Image as Img } from '@atoms';

interface Props {
  backgroundImage: string;
}

const Header = styled.header`
  grid-area: 1/1/2/2;
  align-items: center;
  display: flex;
  justify-content: center;

  > img {
    max-height: 100%;
    width: auto;
  }
`;

export const Image: React.FC<Props> = ({
  backgroundImage,
}) => (
  <Header>
    <Img width={300} url={backgroundImage} placeholder="/images/car-placeholder.svg" />
  </Header>
);
