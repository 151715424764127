import { leadHub, leaseHub } from '@utils/apolloClients';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContext } from 'react';

type ContextType = {
  leaseHub: ApolloClient<NormalizedCacheObject>;
  leadHub: ApolloClient<NormalizedCacheObject>;
};

const leadhubClient = async () => {
  const client = await leadHub(() => {}, '', false, (t) => t);
  return client;
};
const leasehubClient = async () => {
  const client = await leaseHub(() => {}, '', false, (t) => t);
  return client;
};

const initialContext = {
  leadHub: leadhubClient(),
  leaseHub: leasehubClient(),
};
// eslint-disable-next-line
const Context = createContext<ContextType>(initialContext as unknown as  ContextType);

export default Context;
