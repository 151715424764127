import React, { useState, useEffect } from 'react';
import { DateFieldBase } from './dateField.base';

interface Props {
  onChange: (val: string) => void;
  label: string;
  name: string;
  value: string;
  disabled?: boolean;
  validate?: Array<(val: string) => string | true>;
  min?: string | null;
  max?: string | null;
}

interface ErrorProps {
  message: string;
}

export const DateField: React.FC<Props> = ({
  onChange,
  label,
  name,
  disabled = false,
  validate = [],
  min = null,
  max = null,
  value,
}) => {
  const [date, setDate] = useState('');
  const [error, setError] = useState<ErrorProps | undefined>(undefined);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const validateValue = (newValue: string) => {
    if (!/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(newValue)) {
      setError(undefined);
      return;
    }

    const result = validate.map(
      (rule: (...args: Array<any>) => any) => rule(newValue),
    ).find((message) => message);

    if (typeof result === 'string') {
      setError({ message: result });
    } else {
      setError(undefined);
    }
  };

  const dateChange = async (val: string) => {
    await validateValue(val);
    setDate(val);
    onChange(val);
  };

  return (
    <DateFieldBase
      label={label}
      name={name}
      value={date}
      onChange={dateChange}
      disabled={disabled}
      error={error}
      min={min}
      max={max}
    />
  );
};
