export enum CardScheme {
  Default = 'default',
  Accent = 'accent',
  Contrast = 'contrast',
  Success = 'success',
}

export enum TextAlign {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export enum HeadingLevel {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
}

export enum TextColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Error = 'error',
  Sub = 'sub',
  SubLight = 'subLight',
  Default = 'default',
}

export enum FontWeight {
  Light = 300,
  Normal = 400,
  SemiBold = 600,
  Bold = 800,
}
// Imagetype used fior uploadfields like in intrechange and damages
export interface ImageType {
  base64: string;
  imageId: number;
  name: string;
  type: string;
}

export type ImageListType = Record<string, ImageType>;

export type ImagesType = {
  photos: ImageListType;
};
