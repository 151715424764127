const regex = /(\d)([A-Z])|([A-Z])(\d)/g;

export const formatLicense = (
  license: string = '',
): string => ((license ?? '').toUpperCase().replace(regex, (...args) => {
  if (args[1] !== undefined) {
    return `${args[1]}-${args[2]}`;
  }
  if (args[3] !== undefined) {
    return `${args[3]}-${args[4]}`;
  }
  return '';
}));
