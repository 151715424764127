import React from 'react';
import {
  Container,
  ProgressText,
  Svg,
  Circle,
  InnerCircle,
} from './components';

interface Props {
  percentage: number;
  children: any;
  radius: number;
  color?: string;
}

// We might want to make the circle more dynamic later on for now these are static
const CX = 40;
const CY = 40;

const ProgressCircle: React.FC<Props> = ({
  percentage,
  children,
  radius = 37,
  color,
}) => {
  const offset = Math.ceil(Math.PI * radius * 2);
  const progressCircle = offset * (percentage / 100);
  const firstCircle = percentage <= 100 ? progressCircle : offset;
  const outerCircle = percentage > 100 ? progressCircle - offset : 0;
  return (
    <Container>
      <Svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 80 80">
        <InnerCircle cx={CX} cy={CY} r={radius} />
        <Circle
          cx={CX}
          cy={CY}
          r={radius}
          strokeDasharray={offset}
          strokeDashoffset={offset - firstCircle}
          color={color}
        />
        {percentage > 100 && (
          <Circle
            cx={CX}
            cy={CY}
            r={radius}
            strokeDasharray={offset}
            strokeDashoffset={offset - outerCircle}
            color="#ff0000"
          />
        )}
      </Svg>
      <ProgressText>
        {children}
      </ProgressText>
    </Container>
  );
};

export default ProgressCircle;
