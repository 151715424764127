import React from 'react';
import styled from 'styled-components';
import { LoadingAnimation } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { Select } from '@atoms/form';

const LoadingAnimationComponent = styled(LoadingAnimation)`
  margin: 0 auto;
`;

interface Props {
  issuers: Array<{
    [key: string]: any;
  }>;
  changeIssuer: (...args: Array<any>) => any;
}

export const ChooseBank: React.FC<Props> = ({
  issuers,
  changeIssuer,
}) => {
  const { t } = useI18n();
  if (issuers.length > 0) {
    return (
      <Select name="choose-bank" label={t('choose-your-bank')} onChange={changeIssuer}>
        <option value="" key="key-0">{t('choose-your-bank')}</option>
        {issuers.map((option) => <option value={option.id} key={option.id}>{option.name}</option>)}
      </Select>
    );
  }
  return <LoadingAnimationComponent />;
};
