import { ExtrasResponse, OptionsResponse } from '@type/graphql';

export type FilterFn<T> = (extra: T) => boolean;

export type GroupTypeFilter<T> = (groupType: string) => FilterFn<T>;

export type AccountingCodeFilter<T> = (accountingCode: string) => FilterFn<T>;

export const filterByNotGroupType: GroupTypeFilter<ExtrasResponse> = (
  groupType: string,
) => (extra: ExtrasResponse) => extra.groupType !== groupType;

export const filterByGroupType: GroupTypeFilter<ExtrasResponse> = (
  groupType: string,
) => (extra: ExtrasResponse) => extra.groupType === groupType;

export const filterOptionsByGroupType: GroupTypeFilter<OptionsResponse> = (
  groupType: string,
) => (extra: OptionsResponse) => extra.groupType === groupType;

export const filterByAccountingCode: AccountingCodeFilter<ExtrasResponse> = (
  accountingCode: string,
) => (extra: ExtrasResponse) => extra.accountingCode === accountingCode;

export const filterOptionsByAccountingCode: AccountingCodeFilter<OptionsResponse> = (
  accountingCode: string,
) => (extra: OptionsResponse) => extra.accountingCode === accountingCode;
