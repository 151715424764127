import React from 'react';
import { CardContent } from '@atoms';
import { useI18n } from '@hooks/i18n';
import CheckmarkTitle from '@atoms/checkmarkTitle';
import { Card } from '../card';

export const DepositPaid: React.FC = () => {
  const { t } = useI18n();

  return (
    <Card renderHeader={() => <CheckmarkTitle successColor showIcon>{t('deposit-title')}</CheckmarkTitle>}>
      <CardContent>
        {t('deposit-payment-complete')}
      </CardContent>
    </Card>
  );
};
