import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as StyledProps, CardScheme } from '@type';

interface CardHighlightProps extends StyledProps {
  scheme?: CardScheme;
}

export const CardHighlight = styled.div<CardHighlightProps>`
  background-color: ${({ theme, scheme }) => theme.card.schemes[scheme || CardScheme.Accent].backgroundColor};
  border-radius: ${({ theme }) => theme.card.borderRadius};
  color: ${({ theme, scheme }) => theme.card.schemes[scheme || CardScheme.Default].color};
  padding: 1rem;
  ${media.sm(css`
    padding: 1.5rem;
  `)};
`;
