import { gql } from '@apollo/client';

export const UPDATE_CREDIT_CHECK_DATA_BE = gql`mutation
updateCreditCheckBL(
  $outstandingLoans: Int
  $quoteNumber: String!
  $dateOfEmployment: DateTime
  $incomeNetMonthly: Int!
  $housingCostType: Int!
  $housingCostGrossMonthly: Int!
  $maritalStatus: Int!
  $children: Int!
  $coSigner: Boolean!
  $coSigneeHasOwnAddress: Boolean
  $coSigneeZipcode: String
  $coSigneeHouseNumber: String
  $coSigneeAddress: String
  $coSigneeCity: String
  $coSigneeProvince: String
  $coSigneePhone: String
  $coSigneeEmail: String
  $coSigneeGender: String
  $coSigneeInitials: String
  $coSigneeFirstName: String
  $coSigneeLastName: String
  $coSigneeDateOfBirth: Date
  $coSigneeIncomeType: Int
  $coSigneeMonthlyNetIncome: Int
){
  updateCreditCheck(
  outstandingLoans: $outstandingLoans
  quoteNumber: $quoteNumber
  dateOfEmployment: $dateOfEmployment
  incomeNetMonthly: $incomeNetMonthly
  housingCostType: $housingCostType
  housingCostGrossMonthly: $housingCostGrossMonthly
  maritalStatus: $maritalStatus
  children: $children
  coSigner: $coSigner
  coSigneeHasOwnAddress: $coSigneeHasOwnAddress
  coSigneeZipcode: $coSigneeZipcode
  coSigneeHouseNumber: $coSigneeHouseNumbers
  coSigneeAddress: $coSigneeAddress
  coSigneeCity: $coSigneeCity
  coSigneeProvince: $coSigneeProvince
  coSigneePhone: $coSigneePhone
  coSigneeEmail: $coSigneeEmail
  coSigneeGender: $coSigneeGender
  coSigneeInitials: $coSigneeInitials
  coSigneeFirstName: $coSigneeFirstName
  coSigneeLastName: $coSigneeLastName
  coSigneeDateOfBirth: $coSigneeDateOfBirth
  coSigneeIncomeType: $coSigneeIncomeType
  coSigneeMonthlyNetIncome: $coSigneeMonthlyNetIncome
) {
    success
    message
  }
}
`;

const UPDATE_CREDIT_CHECK_DATA = gql`mutation
updateCreditCheck(
  $quoteNumber: String!
  $dateOfEmployment: DateTime
  $incomeNetMonthly: Int!
  $outstandingLoans: Int
  $studentLoansMonthly: Int
  $studentLoansTotal: Int
  $studentLoansBefore2015: Boolean
  $housingCostType: Int!
  $housingCostGrossMonthly: Int!
  $maritalStatus: Int!
  $children: Int!
  $coSigner: Boolean!
  $coSigneeHasOwnAddress: Boolean
  $coSigneeZipcode: String
  $coSigneeHouseNumber: String
  $coSigneeAddress: String
  $coSigneeCity: String
  $coSigneeProvince: String
  $coSigneePhone: String
  $coSigneeEmail: String
  $coSigneeGender: String
  $coSigneeInitials: String
  $coSigneeFirstName: String
  $coSigneeLastName: String
  $coSigneeDateOfBirth: Date
  $coSigneeIncomeType: Int
  $coSigneeMonthlyNetIncome: Int
  $coSigneeStudentLoansMonthly: Int
  $coSigneeStudentLoansTotal: Int
  $coSigneeStudentLoansBefore2015: Boolean
){
  updateCreditCheck(
  quoteNumber: $quoteNumber
  dateOfEmployment: $dateOfEmployment
  incomeNetMonthly: $incomeNetMonthly
  outstandingLoans: $outstandingLoans
  studentLoansMonthly: $studentLoansMonthly
  studentLoansTotal: $studentLoansTotal
  studentLoansBefore2015: $studentLoansBefore2015
  housingCostType: $housingCostType
  housingCostGrossMonthly: $housingCostGrossMonthly
  maritalStatus: $maritalStatus
  children: $children
  coSigner: $coSigner
  coSigneeHasOwnAddress: $coSigneeHasOwnAddress
  coSigneeZipcode: $coSigneeZipcode
  coSigneeHouseNumber: $coSigneeHouseNumber
  coSigneeAddress: $coSigneeAddress
  coSigneeCity: $coSigneeCity
  coSigneeProvince: $coSigneeProvince
  coSigneePhone: $coSigneePhone
  coSigneeEmail: $coSigneeEmail
  coSigneeGender: $coSigneeGender
  coSigneeInitials: $coSigneeInitials
  coSigneeFirstName: $coSigneeFirstName
  coSigneeLastName: $coSigneeLastName
  coSigneeDateOfBirth: $coSigneeDateOfBirth
  coSigneeIncomeType: $coSigneeIncomeType
  coSigneeMonthlyNetIncome: $coSigneeMonthlyNetIncome
  coSigneeStudentLoansMonthly: $coSigneeStudentLoansMonthly
  coSigneeStudentLoansTotal: $coSigneeStudentLoansTotal
  coSigneeStudentLoansBefore2015: $coSigneeStudentLoansBefore2015
) {
    success
    message
  }
}
`;

export interface UpdateCreditCheckVars {
  quoteNumber: string;
  coSigner: boolean;
  dateOfEmployment: string | null;
  incomeNetMonthly: number;
  outstandingLoans?: number;
  studentLoansMonthly?: number | null;
  studentLoansTotal?: number | null;
  studentLoansBefore2015?: boolean | null;
  housingCostType: number;
  housingCostGrossMonthly: number;
  maritalStatus: number;
  children: number;
  coSigneeHasOwnAddress: boolean | null;
  coSigneeZipcode: string | null;
  coSigneeCity: string | null;
  coSigneePhone: string | null;
  coSigneeEmail: string | null;
  coSigneeGender: string | null;
  coSigneeAddress: string | null;
  coSigneeProvince: string | null;
  coSigneeInitials: string | null;
  coSigneeLastName: string | null;
  coSigneeFirstName: string | null;
  coSigneeIncomeType: number | null;
  coSigneeHouseNumber: string | null;
  coSigneeDateOfBirth: string | null;
  coSigneeMonthlyNetIncome: number | null;
  coSigneeStudentLoansMonthly?: number | null;
  coSigneeStudentLoansTotal?: number | null;
  coSigneeStudentLoansBefore2015?: boolean | null
}

export interface UpdateCreditCheckResult {
  updateCreditCheck: {
    status: string;
    success: boolean;
  };
}

export default UPDATE_CREDIT_CHECK_DATA;
