import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@utils';
import {
  TextAlign,
  HeadingLevel,
  TextColor,
} from '@type';

interface Props {
  level: HeadingLevel;
  color?: TextColor;
  textAlign?: TextAlign;
  className?: string;
}

export const getHeading = (
  level: HeadingLevel,
  color = TextColor.Primary,
  textAlign = TextAlign.Left,
) => styled[level]`
    color: ${({ theme }) => theme.heading[level].color[color]};
    font-size: ${({ theme }) => theme.heading[level].fontSize.sm};
    font-weight: ${({ theme }) => theme.heading[level].fontWeight};
    margin: ${({ theme }) => theme.heading[level].margin};
    text-align: ${textAlign};
    ${({ theme }) => css`
      ${media.md(css`
        font-size: ${theme.heading[level].fontSize.md};
      `)}
    `}
  `;

const Heading: React.FC<Props> = ({
  level,
  color,
  textAlign,
  className,
  children,
}) => {
  const HeadingComponent = getHeading(level, color, textAlign);

  return <HeadingComponent className={className}>{children}</HeadingComponent>;
};

export default Heading;
