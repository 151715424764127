import React from 'react';
import { useI18n } from '@hooks/i18n';
import CheckmarkTitle from '@atoms/checkmarkTitle';
import { CardContent, P } from '@atoms';
import { Card } from '@molecules';
import { useSelector } from 'react-redux';
import { GlobalState } from '@store';
import { useRouter } from 'next/router';

export const AgreementDone = () => {
  const { t } = useI18n();
  const { quoteNumber } = useRouter().query;
  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quoteNumber as string]?.isPreorder;

  return (
    <Card renderHeader={() => <CheckmarkTitle successColor showIcon>{isPreorder ? t('agreement-title-preorder') : t('agreement-title')}</CheckmarkTitle>}>
      <CardContent>
        <P>{isPreorder ? t('agreement-signed-description-preorder') : t('agreement-signed-description')}</P>
      </CardContent>
    </Card>
  );
};
