import styled from 'styled-components';
import { StyledComponentProps } from '@type';

export default styled.hr<StyledComponentProps>`
    width: 1px;
    border: none;
    border-left: ${({ theme }) => `${theme.divider.borderWidth} solid ${theme.divider.color}`};
    height: 2rem;
    margin: 0 1rem;
`;
