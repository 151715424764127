import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useI18n } from '@hooks/i18n';
import { getStatus } from '@store/quoteFlow';
import {
  REDIRECT_URI, QuoteProgressRedirectTypes, QuoteProgressRedirectStatus,
} from '@constants/quoteProgress';
import { useAuthentication } from '@hooks/authentication';
import { PageLoader, Card } from '@molecules';
import {
  P, Link, List, Toaster, Button, CardHeader, CardContent, CardActions, CardHighlight,
} from '@atoms';
import { TextColor } from '@type';
import { GlobalState } from '@store';
import { getEnv } from '@utils';
import downloadPDF from '@utils/downloadPDF/downloadPdf';

const ToasterContainer = styled.div`
  margin-top: 1rem;
`;

interface Props {
  id: string;
  quoteNumber: string;
  digitalSigningUri: string;
}

export const AgreementToDo: React.FC<Props> = ({
  id,
  quoteNumber,
  digitalSigningUri,
}: Props) => {
  const { t } = useI18n();
  const failed: boolean = useSelector((state: GlobalState) => (
    getStatus(
      state,
      quoteNumber,
      QuoteProgressRedirectTypes.Signing,
    ) === QuoteProgressRedirectStatus.Failed
  ));
  const { accessToken } = useAuthentication();
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quoteNumber]?.isPreorder;

  useEffect(() => {
    sessionStorage.setItem(
      REDIRECT_URI[QuoteProgressRedirectTypes.Signing],
      document.location.pathname,
    );
  }, []);

  const signAgreement = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${getEnv('NEXT_PUBLIC_MYENV_LEADHUB_URL')}${digitalSigningUri}`, {
        headers: {
          Authorization: `Bearer ${accessToken || ''}`,
        },
      });

      if (response.status >= 400) {
        setLoading(false);
        setHasError(true);

        return;
      }

      const json = await response.json();

      window.location.href = json.redirectToUri;
    } catch (error) {
      setLoading(false);
      setHasError(true);
    }
  };

  return (
    <>
      {loading && <PageLoader />}
      <Card
        renderHeader={() => <CardHeader>{isPreorder ? t('agreement-title-preorder') : t('agreement-title')}</CardHeader>}
        renderFooter={() => (
          <CardActions>
            <Button onClick={signAgreement} caret>
              {isPreorder ? t('sign-agreement-preorder') : t('sign-agreement')}
            </Button>
          </CardActions>
        )}
      >
        <CardContent>
          <P>{isPreorder ? t('agreement-status-description-preorder') : t('agreement-status-description')}</P>
          <CardHighlight>
            <List ordered>
              <li>
                <Link onClick={downloadPDF(`api/contracts/${id}/pdf/raw`, quoteNumber)} href={document.location.pathname}>
                  {t('steps-help-list-step-1-link-label')}
                </Link>
                {' '}
                {isPreorder ? t('steps-help-list-step-1-sentence-preorder') : t('steps-help-list-step-1-sentence')}
              </li>
              <li>{isPreorder ? t('steps-help-list-step-2-preorder') : t('steps-help-list-step-2')}</li>
              <li>{isPreorder ? t('steps-help-list-step-3-preorder') : t('steps-help-list-step-3')}</li>
              <li>
                {isPreorder ? t('steps-help-list-step-4-sentence-preorder') : t('steps-help-list-step-4-sentence')}
                {' '}
                <Link href="/contact/">
                  {isPreorder ? t('steps-help-list-step-4-link-label-preorder') : t('steps-help-list-step-4-link-label')}
                </Link>
              </li>
            </List>
          </CardHighlight>
          {hasError && (
          <ToasterContainer>
            <Toaster color={TextColor.Error}>
              {t('agreement-signing-error')}
            </Toaster>
          </ToasterContainer>
          )}
          {failed && (
          <ToasterContainer>
            <Toaster color={TextColor.Error}>
              {t('agreement-signing-failed')}
            </Toaster>
          </ToasterContainer>
          )}
        </CardContent>
      </Card>
    </>
  );
};
