import React from 'react';
import { SvgIcon } from '@atoms';
import { iconMapper } from '@utils';
import { useI18n } from '@hooks/i18n';
import { PreDeliveryState } from '@constants';
import DocumentStatus, { Title, Icon, DocumentDescription } from './documents.styled';

interface Props {
  preDeliveryState: PreDeliveryState;
}

export const Documents: React.FC<Props> = ({
  preDeliveryState,
}) => {
  const { t } = useI18n();
  const translations: Record<string, string> = {
    Signed: t('signed'),
    Approved: t('approved'),
    Driving: t('driving'),
    Signed_description: t('signed_description', { defaultValue: null }),
    Approved_description: t('approved_description', { defaultValue: null }),
    Driving_description: t('driving_description', { defaultValue: null }),
  };

  function getTranslation(key: string) {
    return translations[key];
  }
  const description = getTranslation(`${preDeliveryState}_description`);
  const icon = iconMapper(preDeliveryState);

  return (
    <>
      <DocumentStatus>
        <Icon preDeliveryState={preDeliveryState}>
          {icon && <SvgIcon icon={icon} />}
        </Icon>
        <Title preDeliveryState={preDeliveryState}>
          {getTranslation(preDeliveryState)}
        </Title>
      </DocumentStatus>
      {description && (
      <DocumentDescription>
        {description}
      </DocumentDescription>
      )}
    </>
  );
};
