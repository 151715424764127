import React, { Fragment } from 'react';
import { Divider, CardContent, Link } from '@atoms';
import CheckmarkTitle from '@atoms/checkmarkTitle';
import { Card, UploadItem } from '@molecules';
import { getLastTwoDocumentsGroupedByLabel } from '@utils';
import { uploadTranslations } from '@translations/uploads';
import { useI18n } from '@hooks/i18n';
import downloadPDF from '@utils/downloadPDF/downloadPdf';
import { QuoteFlows } from '@constants/quoteProgress';
import { FlowTypes } from '@organisms/quoteFlow/creditCheck/types';
import { useRouter } from 'next/router';

interface Upload {
  id: number;
  type: string;
  downloadUri: string | null;
  deleteUri: string | null;
  originalName: string;
}

interface DocumentRequest {
  id: number;
  category: string;
  title: string;
  description: string | null;
  uploadUri: string | null;
  required: boolean;
  hasUploads: boolean;
  isRejected: boolean;
  label: string;
  rejectionReason: string;
  uploads: Array<Upload>;
}

interface Props {
  title: string;
  onUploadComplete: (id: number) => void;
  documentRequests: Array<DocumentRequest>;
  uploadItemIds: Array<number>;
  variant?: FlowTypes | undefined;
}

export const UploadGroup: React.FC<Props> = ({
  title,
  documentRequests,
  onUploadComplete,
  uploadItemIds,
  variant,
}) => {
  const { quoteNumber } = useRouter().query;
  const { t } = useI18n();
  const translations = uploadTranslations(t);
  const groupCompleted = documentRequests.every(
    (request) => (request.hasUploads && !request.isRejected),
  );
  return (
    <Card renderHeader={() => (
      <CheckmarkTitle successColor={groupCompleted} showIcon={groupCompleted}>
        {title}
      </CheckmarkTitle>
    )}
    >
      <CardContent>
        { variant === QuoteFlows.JlnlB2B && (
          <Link
            onClick={downloadPDF(`api/contract/${quoteNumber}/sepa.pdf`, 'SEPA-form')}
            href={document.location.pathname}
          >
            {t('documents-download-sepa-form')}
          </Link>
        )}
        {documentRequests && getLastTwoDocumentsGroupedByLabel(documentRequests).map(
          (request, index) => (
            <Fragment key={`${request.id}-${request.category}`}>
              {index > 0 && <Divider margin="0" />}
              <UploadItem
                fetchingUploadItem={uploadItemIds.includes(request.id)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                {...request}
                description={
                    request.description?.length
                      ? request.description
                      : translations(request.category, request.label, request.description || '')
                  }
                groupCompleted={groupCompleted}
                onUploadComplete={onUploadComplete}
                key={`${request.id}-${request.category}`}
              />
            </Fragment>
          ),
        )}
      </CardContent>
    </Card>
  );
};
