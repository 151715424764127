import React, { useState, useEffect } from 'react';
import { Label, FieldError } from '@atoms/form';
import { range } from '@utils/range';
import { getDateYear, getNbDaysMonth } from '@utils/dates';
import { useI18n } from '@hooks/i18n';
import { SelectStyled } from '../selectField/select.styled';
import { DateFieldContainer, DateWrapper } from './dateField.styled';

const prefill = (value: number): string => {
  const result = value.toString();
  if (value < 10) {
    return `0${result}`;
  }
  return result;
};

interface Props {
  label: string;
  name: string;
  disabled?: boolean;
  min?: string | null;
  max?: string | null;
  error?: {
    message: string;
  };
  value?: string;
  onChange?: (...args: Array<any>) => any;
}

export const DateFieldBase: React.FC<Props> = ({
  label,
  disabled = false,
  name,
  error = null,
  min = null,
  max = null,
  value,
  onChange = () => {},
}) => {
  const {
    getMonths,
  } = useI18n();
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [days, setDays] = useState<number>(31);
  const [currentValue, setCurrentValue] = useState('');

  const setValues = (val?: string) => {
    if (!val) {
      return;
    }
    const date = (val || '').split('-');
    setYear(date[0]);
    setMonth(date[1]);
    setDay(date[2]);
  };

  useEffect(() => {
    setValues(value);
  }, [value]);

  useEffect(() => {
    const newDays = getNbDaysMonth(parseInt(month, 10), parseInt(year, 10));
    if (newDays < parseInt(day, 10)) {
      setDay(newDays.toString());
    }
    setDays(newDays || 31);
  }, [month, year]);

  useEffect(() => {
    setValues(value);
  }, []);

  const returnValue = (y = '', m = '', d = '') => {
    const date = `${y}-${m}-${d}`;
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(date)) {
      setCurrentValue(date);
      onChange(date);
      return;
    }

    if (currentValue !== '') {
      setCurrentValue('');
      onChange('');
    }
  };

  const setDateDay = (evt: { target: HTMLSelectElement }) => {
    const newDay = evt.target.value || '';
    setDay(newDay);
    returnValue(year, month, newDay);
  };

  const setDateMonth = (evt: { target: HTMLSelectElement }) => {
    const newMonth = evt.target.value || '';
    setMonth(newMonth);
    returnValue(year, newMonth, day);
  };

  const setDateYear = (evt: { target: HTMLSelectElement }) => {
    const newYear = evt.target.value || '';
    setYear(newYear);
    returnValue(newYear, month, day);
  };

  return (
    <DateWrapper
      error={Boolean(error)}
      columns="1fr"
      areas={{
        default: error ? '"a" "b"' : '"a"',
      }}
    >
      <DateFieldContainer error={Boolean(error)} data-testid={name}>
        <SelectStyled
          disabled={disabled}
          id={`${name}-day`}
          name={`${name}-day`}
          error={Boolean(error)}
          onChange={setDateDay}
          value={day}
          data-test={`${name}-day`}
        >
          <option value="">...</option>
          {range(1, days + 1).map((d) => <option key={d} value={prefill(d)}>{d}</option>)}
        </SelectStyled>
        <Label htmlFor={`${name}-day`}>{label}</Label>
        <SelectStyled
          disabled={disabled}
          id={`${name}-month`}
          name={`${name}-month`}
          error={Boolean(error)}
          onChange={setDateMonth}
          value={month}
          data-test={`${name}-month`}
        >
          <option value="">...</option>
          {getMonths().map(
            (mth, index) => <option key={mth} value={prefill(index + 1)}>{mth}</option>,
          )}
        </SelectStyled>
        <SelectStyled
          disabled={disabled}
          id={`${name}-year`}
          name={`${name}-year`}
          error={Boolean(error)}
          onChange={setDateYear}
          value={year}
          data-test={`${name}-year`}
        >
          <option value="">...</option>
          {range(
            (min
              ? getDateYear(new Date(min))
              : getDateYear() - 110
            ),
            (max
              ? getDateYear(new Date(max)) + 1
              : getDateYear() + 6
            ),
          ).sort((a, b) => (a > b ? -1 : 1))
            .map((d) => (
              <option key={d} value={prefill(d)}>{d}</option>
            ))}
        </SelectStyled>
      </DateFieldContainer>
      {error && <FieldError message={error.message} />}
    </DateWrapper>
  );
};
