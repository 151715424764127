import { REGISTER_SUCCESS, REGISTER_FAILED, REGISTERING } from './constants';
import {
  RegisterSuccess, RegisterFailed, Registering, State,
} from './types';

const initialState: State = {
  registering: false,
};

const register = (
  state: State = initialState,
  action: RegisterSuccess | RegisterFailed | Registering,
) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        registering: false,
      };
    case REGISTERING:
      return {
        ...state,
        registering: true,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        registering: false,
      };
    default:
      return state;
  }
};

export default register;
