interface FormatOptions {
  decimalDash?: boolean;
  displayZeroes?: boolean;
  currency?: boolean;
  currencyText?: boolean;
  prefix?: boolean;
}

const priceFormat = (num: number | string, options: FormatOptions = {}): string => {
  if (typeof num === 'number' && isNaN(num)) { // eslint-disable-line no-restricted-globals
    throw new Error('Parameter 1 must be a numeric value');
  }

  const mergedOptions = {
    displayZeroes: false,
    decimalDash: true,
    currency: false,
    currencyText: false,
    prefix: false,
    ...options,
  };

  const {
    displayZeroes,
    decimalDash,
    currency,
    currencyText,
    prefix,
  } = mergedOptions;
  let price = parseFloat(num as string).toFixed(2);

  if (currency) {
    // Charcode 0x20AC equals the euro sign. This is needed to prevent encoding hell
    // More on this: https://shripadk.github.io/react/docs/jsx-gotchas.html
    price = `${String.fromCharCode(0x20AC)} ${price}`;
  }

  if (currencyText) {
    price = `${price} euro`;
  }

  if (prefix) {
    let prefixText = '+';

    if (Number(num) < 0) {
      prefixText = '-';
      price = price.replace('-', '');
    }

    price = `${prefixText} ${price}`;
  }

  if (+num % 1 === 0 && !displayZeroes) {
    return price.replace('.00', `${decimalDash ? ',-' : ''}`);
  }

  return price.replace('.', ',');
};

export default priceFormat;
