import React from 'react';
import styled from 'styled-components';
import { Price } from '@atoms';
import { StyledComponentProps } from '@type';
import { SubTitle } from './title';

const StyledPrice = styled(Price)<StyledComponentProps>`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.quote.price.fontWeight};
  color: ${({ theme }) => theme.quote.price.color};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* stylelint-disable declaration-block-no-redundant-longhand-properties */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
`;

interface Props {
  price: number;
  label: string;
  currencySymbol?: boolean;
}

const ProductPrice: React.FC<Props> = ({
  label,
  price,
  currencySymbol = false,
}) => (
  <Container>
    <SubTitle>
      {`${label} :`}
    </SubTitle>
    {' '}
    <StyledPrice amount={price / 100} displayCurrencySymbol={currencySymbol} decimalDash />
  </Container>
);

export default ProductPrice;
