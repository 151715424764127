import { getEnv } from '@utils/getEnv';

export const isPreDeliveryEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_PREDELIVERY') === 'true');

export const isRedemptionEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_REDEMPTION') === 'true');

export const isPurchaseStateEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_PURCHASE') === 'true');

export const isClaimfreeUploadEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_CLAIMFREE') === 'true');

export const isInterchangeEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_SELL_MY_CAR') === 'true');

export const isDamageReportEnabled = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_DAMAGE_REPORTS') === 'true';

export const isInsuranceCardEnabled = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_INSURANCE_CARD') === 'true';

export const isQualityMarkEnabled = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_QUALITY_MARK') === 'true';

export const isBundleStatusEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_BUNDLE_STATUS') === 'true');

export const isIntakeManualEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_INTAKE_MANUAL') === 'true');

export const isAdditionalConditionsEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_ADDITIONAL_CONDITIONS') === 'true');

export const isCoverageProvisionEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_COVERAGE_PROVISION') === 'true');

export const isPrivacyStatementEnabled = (allowed: boolean = true): boolean => (!!allowed && getEnv('NEXT_PUBLIC_MYENV_FLAG_PRIVACY_STATEMENT') === 'true');

export const isCustomerServiceEnabled = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_CUSTOMER_SERVICE') === 'true';

export const isRidesEnabled = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_RIDES') === 'true';

export const isProlongationsEnabled = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_PROLONGATIONS') === 'true';

export const sendCreditCheckByGraphQl = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_CREDITCHECK_BY_GRAPHQL') === 'true';

export const hasProfile = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_PROFILE') === 'true';

export const isMultiLanguage = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_MULTILANGUAGE') === 'true';

export const hasPostponedDeposit = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_POSTPONED_DEPOSIT') === 'true';

export const mustReorderOnEdit = (): boolean => getEnv('NEXT_PUBLIC_MYENV_FLAG_REORDER_ON_EDIT') === 'true';

export const isCarculatorConnected = (available: boolean = true): boolean => (!!available && getEnv('NEXT_PUBLIC_MYENV_FLAG_CARCULATOR_CONNECT') === 'true');

export const hasChangeMileageContact = (): boolean => (getEnv('NEXT_PUBLIC_MYENV_FLAG_CHANGE_MILEAGE_CONTACT') === 'true');

export const hasOcktoQuoteFlowEnabled = (): boolean => (getEnv('NEXT_PUBLIC_MYENV_FLAG_OCKTO_QUOTE_FLOW') === 'true');
