import React from 'react';
import { LinkButton } from '@atoms';

interface Props {
  link: string;
  buttonText: string;
}

const Contracted: React.FC<Props> = ({
  link,
  buttonText,
}: Props) => <LinkButton fullWidth to={link}>{buttonText}</LinkButton>;

export default Contracted;
