import React from 'react';
import { P, Heading } from '@atoms';
import { IconList, IconItem } from '@molecules';
import { useI18n } from '@hooks/i18n';
import { HeadingLevel, TextColor } from '@type';

const Highlights = () => {
  const { t } = useI18n();

  return (
    <>
      <Heading level={HeadingLevel.H2}>{t('highlight-title')}</Heading>
      <P marginBottom="1.5rem">{t('highlight-description')}</P>
      <IconList>
        <IconItem icon="check" iconColor={TextColor.Success}>{t('highlight-1')}</IconItem>
        <IconItem icon="check" iconColor={TextColor.Success}>{t('highlight-2')}</IconItem>
        <IconItem icon="check" iconColor={TextColor.Success}>{t('highlight-3')}</IconItem>
        <IconItem icon="check" iconColor={TextColor.Success}>{t('highlight-4')}</IconItem>
      </IconList>
    </>
  );
};

export default Highlights;
