import React from 'react';
import { ActionButton } from '@atoms';
import { useI18n } from '@hooks/i18n';
import Document, { Title } from './styled';

interface Props {
  originalName: string;
  deleting: boolean;
  deleteDocument: (...args: Array<any>) => any;
  deleteEnabled: boolean;
}

export const DocumentAction: React.FC<Props> = ({
  originalName,
  deleting = false,
  deleteDocument,
  deleteEnabled,
}) => {
  const { t } = useI18n();

  return (
    <Document>
      <Title>
        {originalName}
      </Title>
      {deleteEnabled && (
      <ActionButton
        loading={deleting}
        disabled={deleting}
        onClick={deleteDocument}
        variant="link_text"
      >
        {t('delete-file')}
      </ActionButton>
      )}
    </Document>
  );
};
