import React from 'react';
import styled from 'styled-components';
import { Calendar, Heading } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { HeadingLevel, TextColor } from '@type';

interface Props {
  deliveryTime: number;
}

const CalendarContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;

  & > h5 {
    padding-bottom: 0.5rem;
  }
`;

export const DeliveryProgress: React.FC<Props> = ({
  deliveryTime,
}) => {
  const { t } = useI18n();

  return (
    <CalendarContainer>
      <Heading level={HeadingLevel.H5} color={TextColor.Sub}>{t('product-block-aspected-delivery-time')}</Heading>
      {deliveryTime < 999 && <Calendar value={deliveryTime} label={t('weeks')} />}
    </CalendarContainer>
  );
};
