import styled, { css } from 'styled-components';
import { FieldError } from '@atoms';
import { media } from '@utils';
import { StyledComponentProps as StyleProps } from '@type';
import { OptionLabel } from '../optionField/components';

interface RadioGroupProps {
  hasLabel: boolean;
}

export const RadioGroupContainer = styled.section<RadioGroupProps>`
  display: grid;
  grid-template: auto auto auto / 1fr;
  grid-template-areas: "label" "choices" "error";
  column-gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;

  ${({ hasLabel }) => (hasLabel ? media.sm(css`
      grid-template: auto auto / 1fr auto;
      grid-template-areas:
        "label choices"
        ". error";
    `) : media.sm(css`
      grid-template: auto 1fr / 1fr;
      grid-template-areas:
        "choices"
        "error";
    `))}
`;

export const Label = styled.label`
  grid-area: label;
  align-self: center;
`;

interface ChoicesProps extends StyleProps {
  hasError: boolean;
}

export const Options = styled.section<ChoicesProps>`
  grid-area: choices;
  ${({ hasError, theme }) => (hasError && css`
    ${
  /* sc-sel */
    OptionLabel} {
      &:first-of-type {
        border-radius: ${theme.form.radioGroup.borderRadius} 0 0;
      }

      &:last-of-type {
        border-radius: 0 ${theme.form.radioGroup.borderRadius} 0 0;
      }
    }
  `)}
`;

export const Error = styled(FieldError)`
  grid-area: error;
`;
