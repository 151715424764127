import { LOGIN_STRENGTH } from '@constants';
import { Stages } from '@type';

export const getLengthPerStage = (stage: Stages) => {
  switch (stage) {
    default: case LOGIN_STRENGTH.weak:
      return '33%';
    case LOGIN_STRENGTH.acceptable:
      return '66%';
    case LOGIN_STRENGTH.strong:
      return '100%';
  }
};
