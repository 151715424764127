import styled from 'styled-components';

export default styled.li`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  flex-grow: 1;
`;
