import React from 'react';
import styled from 'styled-components';
import { PreDeliveryState as PreDeliveryStateEnum } from '@constants';
import { ButtonBar, Button } from '@atoms';

import { Signed, Approved, Driving } from './components';

interface Props {
  preDeliveryState?: PreDeliveryStateEnum;
  submitButtonText?: string;
  onClick?: (...args: Array<any>) => any;
}

const Footer = styled.footer`
  grid-area: 3/1/4/2;
`;

export const PreDeliveryState: React.FC<Props> = ({
  preDeliveryState = PreDeliveryStateEnum.Requested,
  onClick = () => {},
  submitButtonText = '',
}) => {
  switch (preDeliveryState) {
    case PreDeliveryStateEnum.Signed:
      return (
        <Footer>
          <Signed preDeliveryState={PreDeliveryStateEnum.Signed} />
        </Footer>
      );
    case PreDeliveryStateEnum.Approved:
      return (
        <Footer>
          <Approved preDeliveryState={PreDeliveryStateEnum.Approved} />
        </Footer>
      );
    case PreDeliveryStateEnum.Driving:
      return (
        <Footer>
          <Driving preDeliveryState={PreDeliveryStateEnum.Driving} />
        </Footer>
      );
    default:
      return (
        <ButtonBar>
          <Button type="button" onClick={onClick} caret fullWidth>
            {submitButtonText}
          </Button>
        </ButtonBar>
      );
  }
};
