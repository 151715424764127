import { gql } from '@apollo/client';

export interface GetDigitalSigningUriResult {
  quotes: [{
    id: string;
    quoteNumber: string;
    digitalSigningUri: string | null;
  }]
}

export interface GetDigitalSigningUriVars {
  quoteNumber: string;
}

const QUOTE_DIGITAL_SIGNING_URI = gql`
  query GetDigitalSigningUri($quoteNumber: String) {
    quotes(number: $quoteNumber) {
      id
      quoteNumber
      digitalSigningUri
    }
  }
`;

export default QUOTE_DIGITAL_SIGNING_URI;
