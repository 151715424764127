import React from 'react';
import { ModalSizes, TextAlign } from '@type';
import { ModalContainer as StyledModalContainer, ModalFlexWrapper, overlayStyle } from './styled';

interface Props {
  align: TextAlign;
  closeOnOverlayClick: boolean;
  isOpen: boolean;
  onClose?: (...args: Array<any>) => any;
  size: ModalSizes;
}

export const ModalContainer: React.FC<Props> = ({
  align,
  children,
  size,
  isOpen,
  onClose,
  closeOnOverlayClick,
}) => (
  <StyledModalContainer
    align={align}
    isOpen={isOpen}
    onRequestClose={onClose}
    shouldCloseOnOverlayClick={closeOnOverlayClick}
    size={size}
    style={overlayStyle}
    appElement={document.getElementById('modal-area') || undefined}
  >
    <ModalFlexWrapper>{children}</ModalFlexWrapper>
  </StyledModalContainer>
);
