import React, { useEffect, useState } from 'react';
import App from 'next/app';
import type { AppContext } from 'next/app';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import labels from '@labels';
import { I18nContextProvider, useI18n } from '@hooks/i18n';
import { AuthContextProvider, useAuthentication } from '@hooks/authentication';
import { ApolloContext } from '@context';
import { leadHub, leaseHub } from '@utils/apolloClients';
import { PageContainer } from '@atoms/page/pageContainer';
import store from '@store/store';
import { Main, MessageStack } from '@organisms';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { getEnv } from '@utils';
import ErrorBoundary from '@hocs/errorBoundary';
import { token } from '@store/cosignee/selectors';
import { GlobalState } from '@store';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { LOCALSTORAGE_TOKEN_KEY } from '@constants/cookie';

let GlobalStyle: any;

function setGlobalStyle(style: any) {
  GlobalStyle = createGlobalStyle`
    ${style}
  `;
}

const COSIGNEE_PATH_NAME = 'welcome';

const ApolloContextProvider: React.FC = ({
  children,
}) => {
  const { t } = useI18n();
  const { logout, accessToken, isAuthenticated } = useAuthentication();
  const [leadHubClient, setLeadHubClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const [leaseHubClient, setLeaseHubClient] = useState<ApolloClient<NormalizedCacheObject>>();
  const { query, pathname } = useRouter();
  const { token: loginToken } = query;
  const cosigneeToken = useSelector((state: GlobalState) => token(state));
  const {
    impersonate, login, credentials, setHasApolloClient,
  } = useAuthentication();

  useEffect(() => {
    const getClients = async () => {
      let localToken;
      if (!pathname.includes(COSIGNEE_PATH_NAME)) {
        if (loginToken) {
          localToken = await impersonate(loginToken);
        } else if (credentials) {
          localToken = await login(credentials);
        }
      }

      if (pathname.includes(COSIGNEE_PATH_NAME) && cosigneeToken) {
        setLeadHubClient(await leadHub(
          logout,
          '',
          isAuthenticated,
          t,
          cosigneeToken,
        ));
        setLeaseHubClient(await leaseHub(
          logout,
          '',
          isAuthenticated,
          t,
          cosigneeToken,
        ));
      } else {
        const TOKEN = localToken ?? accessToken ?? Cookies.get(LOCALSTORAGE_TOKEN_KEY);
        setLeadHubClient(await leadHub(
          logout,
          TOKEN,
          isAuthenticated,
          t,
          cosigneeToken,
        ));
        setLeaseHubClient(
          await leaseHub(
            logout,
            TOKEN,
            isAuthenticated,
            t,
            cosigneeToken,
          ),
        );
      }

      setHasApolloClient(true);
    };
    getClients();
  }, [credentials, cosigneeToken]);

  if (!leadHubClient || !leaseHubClient) {
    setHasApolloClient(false);
    return null;
  }
  return (
    <ApolloContext.Provider value={{
      leadHub: leadHubClient,
      leaseHub: leaseHubClient,
    }}
    >
      <ApolloProvider client={leaseHubClient}>
        {children}
      </ApolloProvider>
    </ApolloContext.Provider>
  );
};

const ClientOnly: React.FC = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return <div>{children}</div>;
};

const MyEnv = ({
  Component, pageProps, languages, theme,
}: any) => {
  setGlobalStyle(theme.globalCss);
  const { t } = useI18n();

  return (
    <>
      <GlobalStyle />
      <ReduxProvider store={store}>
        <AuthContextProvider>
          <I18nContextProvider languages={languages}>
            <ApolloContextProvider>
              <ThemeProvider theme={(theme.theme as any)}>
                <ClientOnly>
                  <PageContainer>
                    <Main themeName={theme.theme.name}>
                      <ErrorBoundary errorText={t('page-error')}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    </Main>
                    <MessageStack />
                  </PageContainer>
                </ClientOnly>
              </ThemeProvider>
            </ApolloContextProvider>
          </I18nContextProvider>
        </AuthContextProvider>
      </ReduxProvider>
    </>
  );
};

MyEnv.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const label = await labels(getEnv('NEXT_PUBLIC_MYENV_ENVIRONMENT') as string);

  return {
    ...appProps,
    ...label,
  };
};

export default MyEnv;
