import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { ActionButton } from '@atoms';
import { ApolloContext } from '@context';
import { useI18n } from '@hooks/i18n';
import { cancelQuote, isCancelling } from '@store/quotes';
import { useAuthentication } from '@hooks/authentication';
import { GlobalState } from '@store';
import { MessageType } from '@constants/messageStack';
import { useMessageStack } from '@hooks/messageStack';

interface Props {
  quoteNumber: string;
  uri: string;
}

const CancelButton: React.FC<Props> = ({
  quoteNumber,
  uri,
}) => {
  const { t } = useI18n();
  const dispatch = useDispatch();
  const cancelling = useSelector((state: GlobalState) => isCancelling(state, quoteNumber));
  const { infoMessage } = useMessageStack();
  const { accessToken } = useAuthentication();
  const { push } = useRouter();
  const { leadHub } = useContext(ApolloContext);

  const quotesInfo = useSelector((state:GlobalState) => state.quotes);
  const isPreorder = quotesInfo[quoteNumber]?.isPreorder;

  const cancelQuoteButtonText = isPreorder
    ? t('cancel-quote-preorder')
    : t('cancel-quote');

  const handleOnClick = async () => {
    try {
      dispatch(cancelQuote(quoteNumber, uri, accessToken));
      infoMessage(t('quote-cancel-success'), MessageType.Success);
    } catch (err) {
      console.error(err);
      infoMessage(t('quote-cancel-failure'), MessageType.Error);
    }

    await leadHub?.resetStore();
    push('/');
  };

  return (
    <ActionButton
      disabled={cancelling}
      loading={cancelling}
      fullWidth
      onClick={handleOnClick}
    >
      {cancelQuoteButtonText}
    </ActionButton>
  );
};

export default CancelButton;
