import styled from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  margin?: string | null | undefined;
  color?: string | null | undefined;
}

const Divider = styled.hr<Props>`
  border-color: ${({ theme, color }) => (color || theme.divider.color)};
  border-width: ${({ theme }) => theme.divider.borderWidth} 0 0;
  border-style: solid;
  margin: ${({ theme, margin }) => (margin || theme.divider.margin)};
  width: 100%;
`;

export default Divider;
