import React from 'react';
import styled, { css } from 'styled-components';
import { Transition } from 'react-transition-group';
import media from '@utils/media';
import { HeaderNavigationButton, HeaderNavigationItems } from './components';

interface Props {
  open: boolean;
  label: string;
  icon?: string | null | undefined;
  menuItems: Array<any>; // @todo this should be the type of the component
  toggleNavigation: (...args: Array<any>) => any;
}

const HeaderNavigationContainer = styled.nav`
  padding-bottom: 0.8rem;
  position: initial;
  margin-top: 1.3rem;

  ${media.sm(css`
    position: relative;
  `)};
`;

const HeaderNavigation: React.FC<Props> = ({
  open,
  label,
  icon = null,
  menuItems,
  toggleNavigation,
}) => (
  <HeaderNavigationContainer>
    <HeaderNavigationButton onClick={toggleNavigation} label={label} icon={icon} active={open} />
    <Transition in={open} timeout={{ enter: 0, exit: 350 }}>
      {(state: string) => (
        <HeaderNavigationItems
          className={state}
          onClick={toggleNavigation}
          menuItems={menuItems}
        />
      )}
    </Transition>
  </HeaderNavigationContainer>
);

export default HeaderNavigation;
