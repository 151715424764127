import React from 'react';
import { ProductState } from '@constants';
import { Divider, LinkButton } from '@atoms';
import { useI18n } from '@hooks/i18n';
import { Documents } from './documents';

interface Props {
  leaseNumber: string;
  productState: ProductState;
}

const DocumentsRejected: React.FC<Props> = ({
  leaseNumber,
  productState,
}) => {
  const { t } = useI18n();

  return (
    <>
      <Divider />
      <Documents productState={productState} />
      <LinkButton to={`/quote/${leaseNumber}`} fullWidth caret>
        {t('quote-modify-request')}
      </LinkButton>
    </>
  );
};

export default DocumentsRejected;
