import React, { useState } from 'react';
import { OptionInput, OptionLabel } from './components';

interface Props {
  defaultChecked?: boolean;
  label: string;
  name: string;
  value: string;
  error?: boolean;
  onChange?: () => void;
  fieldRef: any;
  disabled?: boolean;
}

export const OptionRHFField: React.FC<Props> = ({
  defaultChecked = false,
  fieldRef,
  label,
  error = false,
  name,
  value,
  disabled,
  onChange = () => {},
}) => {
  const [touched, setTouched] = useState(false);

  const onBlur = () => {
    setTouched(true);
  };
  return (
    <>
      <OptionInput
        value={value}
        onBlur={onBlur}
        type="radio"
        name={name}
        id={`${name}-${value}`}
        defaultChecked={defaultChecked}
        onChange={onChange}
        ref={fieldRef}
        disabled={disabled}
      />
      <OptionLabel
        error={error || false}
        touched={touched}
        htmlFor={`${name}-${value}`}
        disabled={disabled}
      >
        {label}
      </OptionLabel>
    </>
  );
};
