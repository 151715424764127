import styled, { css } from 'styled-components';
import media from '@utils/media';
import { MessageType } from '@constants/messageStack';
import { StyledComponentProps } from '../../../../types';

interface Props {
  type: MessageType;
}

export const Message = styled.div<StyledComponentProps & Props>`
  background-color: ${({ theme }) => theme.messageStack.message.backgroundColor};
  box-sizing: border-box;
  box-shadow:
    0 0 0 2px ${({ theme }) => theme.messageStack.message.backgroundColor},
    0 1px 10px -2px rgba(0, 0, 0, 0.75);
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem;
  position: relative;
  margin: 0.75rem;
  width: calc(100vw - 1.5rem);
  border-radius: 0.25rem;

  &::after {
    background-color: ${({ theme, type }) => theme.messageStack.message.type[type]};
    border-radius: 0.25rem;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 0.5rem;
  }

  &:hover {
    cursor: pointer;
  }

  &.show {
    bottom: 0;
  }

  .message-icon {
    flex-shrink: 0;
    height: 1.5rem;
    width: 1.5rem;
  }

  svg {
    fill: ${({ theme, type }) => theme.messageStack.message.type[type]};
  }

  ${media.lg(css`
    margin: 1.25rem;
    width: 25rem;
  `)};
`;
