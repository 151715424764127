import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { NavItem, NavButtonItem } from '@organisms/navigation/components';
import { LinkButton } from '@atoms';
import { StyledComponentProps } from '@type';
import { useI18n } from '@hooks/i18n';
import { isCustomerServiceEnabled, isBundleStatusEnabled, isInterchangeEnabled } from '@flags';
import { ProductState } from '@constants';
import { ButtonSchemeName } from '@constants/theme';
import { routerMatch } from '@utils/routes';
import CONTRACT_NAVIGATION, { ContractNavigationArgs, ContractNavigationResult } from '@queries/contract/contractNavigation';
import { ApolloContext } from '@context';
import { useLazyQuery } from '@apollo/client';
import { ContextNavigationHeader } from '@molecules/contextNavigationHeader';
import { getEnv } from '@utils';

const StyledContextMenu = styled.li`
  background-color: ${(props: StyledComponentProps) => props.theme.navigation.context.backgroundColor};

  ul {
    margin: 0;
    padding: 0;
  }
`;

interface ContractMatch {
  contractNumber?: string;
  childRoute?: string;
}
interface QuoteMatch {
  quoteNumber?: string;
  childRoute?: string;
}

export const MainNavigation = () => {
  const { t } = useI18n();
  const { asPath } = useRouter();
  const matchContract = routerMatch<ContractMatch>(asPath, '/contract/:contractNumber/:childRoute?');
  const matchQuote = routerMatch<QuoteMatch>(asPath, '/quote/:quoteNumber/:childRoute?');
  const [exactPath, setExactPath] = useState(false);
  const [contractNumber, setContractNumber] = useState<any>();
  const [contract, setContract] = useState<any>();
  const { leaseHub } = useContext(ApolloContext);
  const [getContractData, { data }] = useLazyQuery<
  ContractNavigationResult, ContractNavigationArgs
  >(
    CONTRACT_NAVIGATION, {
      client: leaseHub,
    },
  );

  useEffect(() => {
    if (contractNumber !== matchContract.contractNumber) {
      setContractNumber(matchContract.contractNumber);
      if (matchContract.contractNumber) {
        getContractData({
          variables: { contractNumber: matchContract.contractNumber },
        });
      }
    }
    setExactPath(true);
    if (['damages', 'bundlestatus', 'invoices'].indexOf(matchContract.childRoute || '') > -1) {
      setExactPath(false);
    }
  }, [matchContract]);

  useEffect(() => {
    if (data?.contracts?.items?.[0]) {
      setContract(data?.contracts?.items?.[0]);
    }
  }, [data]);

  return (
    <>
      <NavItem icon="dashboard" href="/dashboard">
        {t('mijn-overzicht')}
      </NavItem>
      {contractNumber && (
      <StyledContextMenu>
        <ul>
          {contract?.leaseItem && (
            <ContextNavigationHeader leaseItem={contract.leaseItem} />
          )}
          <NavItem icon="car" href="/contract/[contractNumber]" alias={`/contract/${contractNumber}/`} exact>
            {t('my-subscription')}
          </NavItem>
          {(
            contract?.state === ProductState.Contracted
            || contract?.state === ProductState.ContractDelivered
          ) && (
          <>
            {isBundleStatusEnabled() && (
            <NavItem
              icon="bundelstatus"
              href="/contract/[contractNumber]/bundlestatus"
              alias={`/contract/${contractNumber}/bundlestatus/`}
              exact={exactPath}
            >
              {t('bundle-status-title')}
            </NavItem>
            )}
            <NavItem
              icon="invoice"
              href="/contract/[contractNumber]/invoices"
              alias={`/contract/${contractNumber}/invoices/`}
              exact={exactPath}
            >
              {t('invoices')}
            </NavItem>
            <NavItem
              icon="maintenance"
              href="/contract/[contractNumber]/damages"
              alias={`/contract/${contractNumber}/damages/`}
              exact={exactPath}
            >
              {t('damages-and-maintenance')}
            </NavItem>
          </>
          )}
          {isInterchangeEnabled(contract?.state !== ProductState.Contracted) && (
            <NavItem
              icon="interchange"
              href="/contract/[contractNumber]/interchange"
              alias={`/contract/${contractNumber}/interchange/`}
              exact={exactPath}
            >
              {t('interchange')}
            </NavItem>
          )}
        </ul>
      </StyledContextMenu>
      )}
      {isCustomerServiceEnabled() ? (
        <NavItem icon="customerService" href="/customer-service">
          {t('customer-service')}
        </NavItem>
      ) : (
        <NavItem icon="customerService" href="/contact">
          {t('contact')}
        </NavItem>
      )}
      {isInterchangeEnabled(Boolean((matchQuote?.quoteNumber))) && (
      <NavItem icon="interchange" href={`/quote/${matchQuote?.quoteNumber}/interchange`}>
        {t('interchange')}
      </NavItem>
      )}
      <NavButtonItem>
        <LinkButton
          fullWidth
          scheme={ButtonSchemeName.Transparent}
          outline
          target="_blank"
          to={getEnv('NEXT_PUBLIC_MYENV_SITE_URL', '') as string}
        >
          {t('redirect-to-website')}
        </LinkButton>
      </NavButtonItem>
    </>
  );
};
