import React from 'react';
import styled from 'styled-components';
import { Image } from '@atoms';
import { formatLicense } from '@utils';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  padding: 1rem 0;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.7);
`;

const StyledImage = styled(Image)`
  max-height: 3.75rem;
  max-width: 33%;
`;

const StyledInfo = styled.div`
  padding-left: 1rem;

  small {
    color: rgba(255, 255, 255, 0.7);
    font-weight: bold;
  }
`;

interface Props {
  leaseItem: {
    make: string;
    model: string;
    color: {
      imageUrl: string;
    };
    licensePlate: string;
  };
}

export const ContextNavigationHeader: React.FC<Props> = ({ leaseItem }) => {
  const {
    make,
    model,
    color: {
      imageUrl,
    },
    licensePlate,
  } = leaseItem;
  return (
    <StyledContainer>
      <StyledImage url={imageUrl.replace('__width__', '150')} alt={`${model}`} placeholder="/images/car-placeholder-light.svg" />
      <StyledInfo>
        <p>
          {`${make} ${model}`}
        </p>
        <small>{formatLicense(licensePlate)}</small>
      </StyledInfo>
    </StyledContainer>
  );
};
