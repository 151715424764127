export const getUiSetting = <T>(state: any, uiSetting: string): T => {
  const { uiSettings } = state;
  const setting = uiSettings[uiSetting];
  return (setting !== undefined && setting) as T;
};

export const getUiSettingOrDefault = (state: any, uiSetting: string, defaultValue?: boolean) => {
  const {
    uiSettings,
  } = state;
  if (defaultValue && uiSettings[uiSetting] === undefined) {
    return defaultValue;
  }

  return getUiSetting(state, uiSetting);
};
