import { Theme } from '@type/theme';
import { LOGIN_STRENGTH } from '@constants';
import { Stages } from '@type';

export const getStageColor = (stage: Stages, theme: Theme) => {
  switch (stage) {
    default: case LOGIN_STRENGTH.weak:
      return theme.form.password.stages.weak;
    case LOGIN_STRENGTH.acceptable:
      return theme.form.password.stages.acceptable;
    case LOGIN_STRENGTH.strong:
      return theme.form.password.stages.strong;
  }
};
