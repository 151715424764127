import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledLink } from '@atoms/link/link.styled';
import { Justify, FlexDirection } from '@constants/theme';

interface Props {
  justify?: Justify;
  direction?: FlexDirection;
}

const ButtonBar = styled.section.attrs((props: Props) => ({
  justify: props.justify || Justify.FlexStart,
  direction: props.direction || FlexDirection.Row,
}))<Props>`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ direction }) => direction}; /* stylelint-disable-line */
  justify-content: ${({ justify }) => justify}; /* stylelint-disable-line */
  margin-bottom: 1rem;
  width: 100%;

  ${/* sc-sel */ StyledLink} {
    align-self: ${({ direction, justify }) => (direction === 'row' ? 'flex-start' : justify)};
  }

  a + a,
  button + a,
  a + button,
  button + button {
    ${({ direction }) => css<Props>`
      ${media.sm(css`
        margin-left: ${direction === 'row' ? '1rem' : 0};
      `)}
    `}
  }

  && > a {
    align-self: center;
  }
`;

export default ButtonBar;
