import React from 'react';
import { SvgIcon } from '@atoms';
import { iconMapper } from '@utils';
import { useI18n } from '@hooks/i18n';
import { ProductState } from '@constants';
import DocumentStatus, { Title, Icon, DocumentDescription } from './documents.styled';

interface Props {
  productState: ProductState;
}

export const Documents: React.FC<Props> = ({
  productState,
}) => {
  const { t } = useI18n();
  const translations: Record<string, string> = {
    pending_sales: t('pending_sales'),
    pending_finance: t('pending_finance'),
    pending_delivery: t('pending_delivery'),
    ready_for_contract: t('ready_for_contract'),
    contract_signed: t('ready_for_contract'),
    contract_ordered: t('ready_for_contract'),
    contract_registered: t('ready_for_contract'),
    documents_rejected: t('documents_rejected'),
    quote_canceled: t('quote_canceled'),
    quote_expired: t('quote-expired'),
    credit_check_approved: t('quote-credit-check-approved'),
    credit_check_denied: t('quote-credit-check-denied'),
    quote_expired_description: t('quote-expired-description', { defaultValue: null }),
    pending_sales_description: t('pending_sales_description', { defaultValue: null }),
    pending_finance_description: t('pending_finance_description', { defaultValue: null }),
    pending_delivery_description: t('pending_delivery_description', { defaultValue: null }),
    documents_rejected_description: t('documents_rejected_description', { defaultValue: null }),
    quote_canceled_description: t('quote_canceled_description', { defaultValue: null }),
    credit_check_approved_description: t('quote-credit-check-approved-description', { defaultValue: null }),
    credit_check_denied_description: t('quote-credit-check-denied-description', { defaultValue: null }),
  };

  function getTranslation(key: string) {
    return translations[key];
  }
  const description = getTranslation(`${productState}_description`);
  const icon = iconMapper(productState);

  return (
    <>
      <DocumentStatus>
        <Icon productState={productState}>
          {icon && <SvgIcon icon={icon} />}
        </Icon>
        <Title productState={productState}>
          {getTranslation(productState)}
        </Title>
      </DocumentStatus>
      {description && (
      <DocumentDescription>
        {description}
      </DocumentDescription>
      )}
    </>
  );
};
