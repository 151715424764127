import React from 'react';
import {
  StatusOpen, StatusBidIn, StatusAccepted, StatusDenied,
} from './states';

enum BidEnum {
  BidOpen = 'Open',
  BidIn = 'BidIn',
  BidAccepted = 'Accepted',
  BidDenied = 'Denied',
}

interface Props {
  bid: any;
  onBidAccept: () => void;
  onBidReject: () => void;
  acceptLoading: boolean;
  rejectLoading: boolean;
}

export const BidState: React.FC<Props> = ({
  bid,
  onBidAccept,
  onBidReject,
  acceptLoading,
  rejectLoading,
}) => {
  const {
    status,
  } = bid;
  switch (status) {
    case BidEnum.BidOpen:
      return <StatusOpen />;
    case BidEnum.BidIn:
      return (
        <StatusBidIn
          bid={bid}
          onBidAccept={onBidAccept}
          onBidReject={onBidReject}
          acceptLoading={acceptLoading}
          rejectLoading={rejectLoading}
        />
      );
    case BidEnum.BidAccepted:
      return <StatusAccepted bid={bid} />;
    case BidEnum.BidDenied:
      return <StatusDenied bid={bid} />;
    default:
      return <StatusOpen />;
  }
};
