import styled, { css } from 'styled-components';
import { StyledComponentProps } from '@type';
import { activeElementStyle } from '../sharedStyles';

interface Props extends StyledComponentProps {
  error?: boolean;
  valid?: boolean;
  disabled?: boolean;
  label?: string;
}

export const SelectContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
`;

export const selectStyles = css<Props>`
  appearance: none;
  background-color: white;
  background-image: url('/images/chevron.svg');
  background-position: right 0.8em center;
  background-repeat: no-repeat;
  font-family: "Open Sans", Arial, sans-serif;
  background-size: 1rem 1rem;
  border: 1px solid ${({ theme }) => theme.form.input.borderColor};
  border-radius: ${({ theme }) => theme.form.input.borderRadius};
  box-sizing: border-box;
  cursor: pointer;
  font-size: ${({ theme }) => theme.form.input.fontSize};
  height: 3.75rem;
  line-height: 1.1;
  padding: 0 1rem 0 0.75rem;
  position: relative;
  transition: all 0.3s ease;
  width: 100%;
  ${({ theme, error }) => error && css`
    border-radius: ${theme.form.input.borderRadius} ${theme.form.input.borderRadius} 0 0;
    border-bottom-color: ${theme.form.input.borderColorError};
  `}
  ${({ theme, disabled }) => disabled && css`
    background-color: ${theme.form.input.disabledBackgroundColor};
  `}

  &:active,
  &:hover {
    ${activeElementStyle}
    outline: 0;
  }

  &:focus {
    ${activeElementStyle}
    outline: 0;

    &::-ms-value {
      background: transparent;
      color: inherit;
    }
  }

  &::-ms-expand {
    background: transparent;
    border: none;
  }
`;

// this is a workaround because the password fields from listo are not the same as MJL
export const Select = styled.select<Props>`
  ${selectStyles}
`;
