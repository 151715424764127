import React from 'react';
import styled from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props {
  arrow: string;
  onClick: (...args: Array<any>) => any;
  disabled: boolean;
}

const Button = styled.button<StyledComponentProps>`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: ${({ theme }) => theme.pagination.borderRadius};
  background-color: ${({ theme }) => theme.pagination.backgroundColor.main};
  border: solid 0.0625rem ${({ theme }) => theme.pagination.borderColor.main};
  cursor: pointer;
  font-size: 0.875rem;
  color: ${({ theme }) => theme.pagination.color.main};
  font-weight: bold;
  margin-right: 0.3rem;
  transition-duration: 0.3s;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease;

  &:hover {
    background-color: ${({ theme }) => theme.pagination.backgroundColor.hover};
    border-color: ${({ theme }) => theme.pagination.borderColor.hover};
    color: ${({ theme }) => theme.pagination.color.hover};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.pagination.backgroundColor.disabled};
  }
`;

const PaginationButton: React.FC<Props> = ({
  arrow,
  onClick,
  disabled,
}) => (
  <Button disabled={disabled} onClick={onClick}>
    {arrow}
  </Button>
);

export default PaginationButton;
