import styled from 'styled-components';
import { StyledComponentProps as StyledProps } from '../../../../types';

export const Wrapper = styled.div<StyledProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.messageStack.zIndex};
`;
