import styled from 'styled-components';
import { rotate } from '@utils/animations';
import { StyledComponentProps } from '@type';

export default styled.section<StyledComponentProps>`
  background: white;
  color: ${({ theme }) => theme.loader.forgroundColor};
  overflow: hidden;
  height: 4rem;
  position: relative;
  width: 4rem;
  margin: 2rem auto;

  &::before,
  &::after {
    content: '';
    background: transparent;
    border-color: transparent ${({ theme }) => theme.loader.forgroundColor};
    border-width: 0.2rem;
    border-style: solid;
    border-radius: 50%;
    display: block;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 50%;
    transition: opacity 0.05s ease;
    transform-origin: top left;
    transform:
      rotate(0deg)
      translate(-50%, -50%);
  }

  &::before {
    animation: ${rotate} 1.7s linear infinite -1s;
    height: 70%;
    width: 70%;
  }

  &::after {
    animation: ${rotate} 1s linear infinite 0.5s;
    height: 1.25rem;
    width: 1.25rem;
  }
`;
