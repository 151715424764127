import { gql } from '@apollo/client';
import { QuoteFlows } from '@constants/quoteProgress';
import { EdrState } from '@type/quoteProgress';

export const QUOTE_FLOW_TYPE = gql`
  query QuoteFlowType($number: String!) {
    quotes(number: $number) {
      quoteFlow
    }
  }
`;

export const CURRENT_OCKTO_QUOTE = gql`
query CurrentOcktoQuote($number: String!) {
  quotes(number: $number) {
    id
    state
    quoteNumber
    creditCheckStatus
    digitalSigningUri
    sepaUri
    depositPaid
    quoteFlow
    prospectProgress
    depositAmount
    paymentUri
    coSigner
    cosigneeInvitationEmailStatus
    coSigneeFlowFinished
    ocktoStatus
    documents {
      id
      type
      downloadUri
    }
    documentRequestsCategories {
      id
      label
      title
      documentRequests {
        id
        category
        title
        description
        uploadUri
        required
        hasUploads
        isRejected
        label
        rejectionReason
        uploads {
          id
          type
          downloadUri
          deleteUri
          originalName
        }
      }
    }
  }
}`;

const CURRENT_QUOTE = gql`
query CurrentQuote($number: String!) {
  quotes(number: $number) {
    id
    state
    quoteNumber
    creditCheckStatus
    digitalSigningUri
    sepaUri
    depositPaid
    quoteFlow
    prospectProgress
    depositAmount
    paymentUri
    coSigner
    documents {
      id
      type
      downloadUri
    }
    documentRequestsCategories {
      id
      label
      title
      documentRequests {
        id
        category
        title
        description
        uploadUri
        required
        hasUploads
        isRejected
        label
        rejectionReason
        uploads {
          id
          type
          downloadUri
          deleteUri
          originalName
        }
      }
    }
  }
}`;

export const CURRENT_DEFAULT_QUOTE_BE = gql`
query CurrentDefaultQuoteBE($number: String!) {
  quotes(number: $number) {
    id
    state
    quoteNumber
    creditCheckStatus
    digitalSigningUri
    sepaUri
    depositPaid
    quoteFlow
    prospectProgress
    depositAmount
    paymentUri
    documents {
      id
      type
      downloadUri
    }
    documentRequestsCategories {
      id
      label
      title
      documentRequests {
        id
        category
        title
        description
        uploadUri
        required
        hasUploads
        isRejected
        label
        rejectionReason
        uploads {
          id
          type
          downloadUri
          deleteUri
          originalName
        }
      }
    }
    isSelfInsured
  }
}`;

export const IS_PREORDER = gql`
query preorderInformation($number: String!) {
  quotes(number: $number) {
    preorder
  }
}
`;

export const GET_QUOTE_FLOW = gql`
query GetQuoteFlow($number: String!) {
  quotes(number: $number) {
    quoteFlow
  }
}`;

// DEFAULT has no coSigner field
export const CURRENT_DEFAULT_QUOTE = gql`
query CurrentDefaultQuote($number: String!) {
  quotes(number: $number) {
    id
    state
    quoteNumber
    creditCheckStatus
    digitalSigningUri
    sepaUri
    preorder
    depositPaid
    quoteFlow
    prospectProgress
    depositAmount
    paymentUri
    documents {
      id
      type
      downloadUri
    }
    documentRequestsCategories {
      id
      label
      title
      documentRequests {
        id
        category
        title
        description
        uploadUri
        required
        hasUploads
        isRejected
        label
        rejectionReason
        uploads {
          id
          type
          downloadUri
          deleteUri
          originalName
        }
      }
    }
  }
}`;

export interface DocumentRequestsCategory {
  id: string;
  label: string;
  title: string;
  documentRequests: Array<{
    id: number;
    category: string;
    title: string;
    description: string | null;
    uploadUri: string | null;
    required: boolean;
    hasUploads: boolean;
    isRejected: boolean;
    label: string;
    rejectionReason: string;
    uploads: Array<{
      id: number;
      type: string;
      downloadUri: string | null;
      deleteUri: string | null;
      originalName: string;
    }>;
  }>;
}

export interface CurrentQuote {
  id: number;
  state: string;
  quoteNumber: string;
  depositAmount: number;
  paymentUri: string | null;
  depositPaid: boolean;
  prospectProgress: number;
  digitalSigningUri: string | null;
  sepaUri: string | null;
  quoteFlow: QuoteFlows;
  edrStatus: EdrState;
  preorder?: boolean;
  cosigneeInvitationEmailStatus?: boolean;
  documents: Array<{
    id: number;
    type: string;
    downloadUri: string | null;
  }>;
  documentRequestsCategories: Array<DocumentRequestsCategory>;
  coSigner?: boolean;
  ocktoStatus?: string;
  coSigneeFlowFinished?: boolean;
  isSelfInsured?: boolean;
}

export interface CurrentQuoteResponse {
  quotes: [CurrentQuote]
}

export interface QuoteTypeResponse {
  quotes: [
    { quoteFlow: QuoteFlows },
  ]
}

export interface CurrentQuoteArgs {
  number: string;
}

export default CURRENT_QUOTE;
