import { GetTranslation } from '@hooks/i18n';

export enum TranslationGroupes {
  Mileage = 'mileage',
  ContractExtras = 'contract-extras',
  Accessories = 'accessories',
  Prolongation = 'prolongation',
}

export interface Translations {
  modalTitle: string;
  oldExtras: string;
  newExtras: string;
  newTotal: string;
  newAmountPer: string;
  agreement: string;
  sign: string;
  error: string;
}

export const getModalTranslations = (
  t: GetTranslation,
  group: TranslationGroupes,
): Translations => {
  const translations: Record<TranslationGroupes, Translations> = {
    [TranslationGroupes.Mileage]: {
      modalTitle: t('change-mileage-modal-title'),
      oldExtras: t('change-mileage-modal-old-bundle'),
      newExtras: t('change-mileage-modal-new-bundle'),
      newTotal: t('change-mileage-modal-new-total'),
      newAmountPer: t('change-mileage-modal-new-amount-per-date'),
      agreement: t('change-mileage-modal-agreement'),
      sign: t('change-mileage-modal-sign'),
      error: t('change-mileage-error'),
    },
    [TranslationGroupes.ContractExtras]: {
      modalTitle: t('change-contract-extras-modal-title'),
      oldExtras: t('change-contract-extras-modal-old-bundle'),
      newExtras: t('change-contract-extras-modal-new-bundle'),
      newTotal: t('change-contract-extras-modal-new-total'),
      newAmountPer: t('change-contract-extras-modal-new-amount-per-date'),
      agreement: t('change-contract-extras-modal-agreement'),
      sign: t('change-contract-extras-modal-sign'),
      error: t('change-contract-extras-error'),
    },
    [TranslationGroupes.Accessories]: {
      modalTitle: t('change-accessories-modal-title'),
      oldExtras: t('change-accessories-modal-old-bundle'),
      newExtras: t('change-accessories-modal-new-bundle'),
      newTotal: t('change-accessories-modal-new-total'),
      newAmountPer: t('change-accessories-modal-new-amount-per-date'),
      agreement: t('change-accessories-modal-agreement'),
      sign: t('change-accessories-modal-sign'),
      error: t('change-accessories-error'),
    },
    [TranslationGroupes.Prolongation]: {
      modalTitle: t('change-prolongation-modal-title'),
      oldExtras: t('change-prolongation-modal-old-bundle'),
      newExtras: t('change-prolongation-modal-new-bundle'),
      newTotal: t('change-prolongation-modal-new-total'),
      newAmountPer: t('change-prolongation-modal-new-amount-per-date'),
      agreement: t('change-prolongation-modal-agreement'),
      sign: t('change-prolongation-modal-sign'),
      error: t('change-prolongation-error'),
    },
  };

  return translations[group];
};
