import React from 'react';
import { Card } from '@molecules';
import { ProductState as ProductStateType } from '@constants';
import {
  Content, Title, TitleContainer, SubTitle, HighLight, Price, ProductState, Image, FooterBase,
} from './components';

interface Props {
  label: string;
  imageUrl: string;
  title: string;
  subTitle?: string;
  costLabel: string;
  costs: number;
  quoteNumber: string;
  quoteState: ProductStateType;
  deliveryTime: number;
  progress: number;
  children: React.ReactNode;
}

export const QuoteGridItem: React.FC<Props> = ({
  label,
  imageUrl,
  title,
  subTitle,
  costLabel,
  costs,
  quoteNumber,
  quoteState,
  deliveryTime,
  progress,
  children,
}) => (
  <Card as="li">
    <HighLight>{label}</HighLight>
    <Content isAutoHeightContent>
      <Image backgroundImage={imageUrl} />
      <TitleContainer hasBoardingPadding marginBottom="1rem">
        <Title>{title}</Title>
        {subTitle && <SubTitle marginBottom="1rem">{subTitle}</SubTitle>}
        {children}
      </TitleContainer>
      <FooterBase>
        <Price label={costLabel} price={costs} />
        <ProductState
          leaseNumber={quoteNumber}
          productState={quoteState}
          deliveryTime={deliveryTime}
          progress={progress}
        />
      </FooterBase>
    </Content>
  </Card>
);
