import React from 'react';
import styled, { css } from 'styled-components';
import { Image, Heading, Divider } from '@atoms';
import { media } from '@utils';
import { HeadingLevel, TextColor } from '@type';

interface Props {
  imageUrl: string;
  title: string;
  subtitle: string;
  note: string;
}

const Container = styled.header`
  display: flex;
  flex-direction: column;
  ${media.sm(css`
    flex-direction: column;
    align-items: left;
    min-height: 7rem;
  `)}
  ${media.md(css`
    flex-flow: wrap;
    align-items: center;
    min-height: 7rem;
  `)}
`;

const StyledImage = styled(Image)`
  max-width: 17rem;
  ${media.md(css`
    padding-right: 2rem;
  `)}
`;

const CarInformationHeader: React.FC<Props> = ({
  imageUrl,
  title,
  subtitle,
  note,
}) => (
  <Container>
    <StyledImage url={imageUrl} alt={title} placeholder="/images/car-placeholder.svg" />
    <div>
      <Heading level={HeadingLevel.H2}>
        {title}
      </Heading>
      <Heading level={HeadingLevel.H4} color={TextColor.Sub}>
        {subtitle}
      </Heading>
      <Heading level={HeadingLevel.H4} color={TextColor.Sub}>
        {!note ? '' : note}
      </Heading>
    </div>
    <Divider margin="1rem 0 0" />
  </Container>
);

export default CarInformationHeader;
