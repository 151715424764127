import PasswordValidator from 'password-validator';
import { LOGIN_STRENGTH } from '@constants';

const schemaWeak = new PasswordValidator();
schemaWeak
  .is()
  .min(8)
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

const schemaAcceptable = new PasswordValidator();
schemaAcceptable
  .is()
  .min(8)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces();

const schemaStrong = new PasswordValidator();
schemaStrong.is().min(10).has().uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .symbols()
  .has()
  .not()
  .spaces();

export const determineStrength = (password: string) => {
  if (schemaStrong.validate(password)) {
    return LOGIN_STRENGTH.strong;
  }

  if (schemaAcceptable.validate(password)) {
    return LOGIN_STRENGTH.acceptable;
  }

  if (schemaWeak.validate(password)) {
    return LOGIN_STRENGTH.weak;
  }

  return LOGIN_STRENGTH.weak;
};
