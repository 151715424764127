import React, { useEffect, useState } from 'react';
import { Navigation } from './navigation';

const NavigationContainer: React.FC = () => {
  const [open, setOpen] = useState(false);

  const removeListener = () => {
    const {
      body,
    } = document;
    if (body) {
      body.removeEventListener('click', closeNavigation); // eslint-disable-line @typescript-eslint/no-use-before-define
    }
  };

  const closeNavigation = () => {
    setOpen(false);
    removeListener();
  };

  useEffect(() => () => {
    removeListener();
  }, []);

  const openNavigation = () => {
    setOpen(true);
    const {
      body,
    } = document;
    if (body) {
      body.addEventListener('click', closeNavigation);
    }
  };

  return <Navigation showNavigation={open} openNavigation={openNavigation} />;
};

export default NavigationContainer;
