import React from 'react';
import SvgIcon from '@atoms/svgIcon';
import Button from './button.styled';

interface Props {
  error: boolean;
  showPassword: boolean;
  toggle: (...args: Array<any>) => any;
}

export const PasswordButton: React.FC<Props> = ({
  toggle,
  showPassword,
  error,
}) => (
  <Button onClick={toggle} error={error}>
    <SvgIcon icon={showPassword ? 'eyeBlocked' : 'eye'} />
  </Button>
);
