import styled, { css } from 'styled-components';
import { Input, InputPrefix, Label } from '@atoms/form';
import { InputProps } from '@atoms/form/input';

const activeStyle = css<InputProps>`
  padding-top: 1.1rem;

  & + ${
/* sc-sel */
  Label} {
    color: ${({ theme }) => theme.form.label.color};
    font-size: 0.8em;
    top: 0.5rem;
  }
`;

export default styled(Input)<InputProps>`
  &::placeholder {
    color: transparent;
    transition: color 0.3s ease;
  }

  &[type="time"],
  &:not([value=""]),
  &:focus {
    ${activeStyle};
    background-color: transparent;
  }

  ${/* sc-sel */InputPrefix} + & {
    padding-left: 3rem;
  }

  &:not(:placeholder-shown) {
    ${activeStyle}
  }

  ${({ pristine }) => !pristine && css`
    &:not([type="password"]) {
      ${activeStyle}
    }
  `}
  ${({ touched }) => touched && css`
    &[type="number"] {
      ${activeStyle}
    }
  `}
`;
