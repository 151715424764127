import React, { useReducer } from 'react';
import { MessageType } from '@constants/messageType';
import { timeout } from '@utils';
import { MessageStackContext } from './context';
import { MessageStackAction, StackMessage } from './types';

export const MessageStackProvider: React.FC<{}> = ({ children }) => {
  const reducer = (state: Record<string, StackMessage>, action: MessageStackAction) => {
    switch (action.type) {
      case 'showMessage':
        return {
          ...state,
          [action.payload.message]: {
            ...(action.payload as StackMessage),
          },
        };
      case 'hideMessage': {
        const nextState = { ...state };
        delete nextState[action.payload.message];
        return nextState;
      }
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {});

  const showMessage = (message: string, type: MessageType) => dispatch({
    type: 'showMessage',
    payload: { message, type },
  });

  const hideMessage = (message: string) => dispatch({
    type: 'hideMessage',
    payload: { message },
  });

  const infoMessage = async (
    message: string,
    type: MessageType,
    duration: number = 3000,
  ) => {
    showMessage(message, type);
    await timeout(duration);
    hideMessage(message);
  };

  return (
    <MessageStackContext.Provider value={{
      messages: state,
      infoMessage,
      hideMessage,
    }}
    >
      {children}
    </MessageStackContext.Provider>
  );
};
