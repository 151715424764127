import styled, { css } from 'styled-components';
import { StyledComponentProps as StyledProps } from '@type';
import ButtonBar from '@atoms/buttonBar';
import media from '@utils/media';
import SvgIcon from '../../svgIcon';

export const IconBar = styled.div<StyledProps>`
  width: 100%;

  ${/* sc-selector */SvgIcon} {
    display: block;
    height: ${({ theme }) => theme.modal.svgIcon.height};
    margin: ${({ theme }) => theme.modal.svgIcon.margin};
    vertical-align: bottom;
    width: 9rem;
  }
`;

export const Button = styled.button<StyledProps>`
  background: none;
  border: none;
  color: ${({ theme }) => theme.modal.closeButton.main};
  cursor: pointer;
  flex-grow: 1;
  font-size: ${({ theme }) => theme.modal.fontSize};
  font-weight: 400;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.modal.closeButton.hover};
  }

  ${/* sc-selector */SvgIcon} {
    display: inline-block;
    height: 1rem;
    margin: 0 0 0 0.25rem;
    vertical-align: bottom;
    width: 1rem;
  }
`;

export default styled.header<StyledProps>`
  align-items: flex-end;
  background: ${({ theme }) => theme.modal.backgroundColor};
  border-radius: ${({ theme }) => `
    ${theme.modal.borderRadius}
    ${theme.modal.borderRadius}
    0
    0
  `};
  color: ${({ theme }) => theme.modal.headerColor};
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap-reverse;
  padding: 2rem 2rem 0;
`;
export const StyledButtonBar = styled(ButtonBar)`
  margin-bottom: 0;

  ${media.smDown(css`
    justify-content: space-between;
  `)}
`;
