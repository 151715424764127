import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Button } from '@atoms';
import { InputField } from '@molecules/form';

interface Props {
  placeholder: string;
  buttonText: string;
  label: string;
  handleSubmit: (...args: Array<any>) => any;
}

const StyledInput = styled(InputField)`
  border-radius: 0.25rem 0 0 0.25rem;
`;

const StyledButton = styled(Button)`
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
  padding: 0 2rem;
`;

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;

  > div {
    margin-bottom: 0;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const SearchBar: React.FC<Props> = ({
  placeholder,
  buttonText,
  label,
  handleSubmit,
}) => {
  const { query } = useRouter();
  const [input, setInput] = useState<string>((query.query || '') as string);

  return (
    <StyledForm onSubmit={(e) => handleSubmit(e, input)}>
      <Wrapper>
        <StyledInput
          type="text"
          name="searchbar"
          placeholder={placeholder}
          value={input}
          onChange={setInput}
          label={label}
        />
        <StyledButton
          disabled={input === ''}
          type="submit"
        >
          {buttonText}
        </StyledButton>
      </Wrapper>
    </StyledForm>
  );
};
export default SearchBar;
