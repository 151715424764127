import styled, { css } from 'styled-components';
import { StyledComponentProps } from '../../../../../types';

interface Props extends StyledComponentProps {
  active?: boolean;
}

const NavButtonItem = styled.li<Props>`
  bottom: 1.5rem;
  list-style: none;
  display: block;
  padding: 0 1.5rem;
  margin-top: 1.8rem;
  position: absolute;
  box-sizing: border-box;
  width: 100%;

  a {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  ${({ active }) => !active && css`
    opacity: 0.5;
  `}

  &:hover {
    opacity: 1;
  }
`;

export default NavButtonItem;
