import React from 'react';
import {
  Label, Error, RadioGroupContainer, Options,
} from './radioGroup.styled';

interface Props {
  label?: string;
  testId?: string | null;
  error?: {
    message: string;
  };
}

export const RadioGroup: React.FC<Props> = ({
  children,
  label = '',
  testId,
  error,
}) => (
  <RadioGroupContainer data-testid={testId} hasLabel={Boolean(label)}>
    {/* eslint-disable-next-line */}
    {label && <Label>{label}</Label>}
    <Options hasError={Boolean(error)}>
      {children}
    </Options>
    {error && <Error message={error.message} />}
  </RadioGroupContainer>
);
