import { gql } from '@apollo/client';
import { ProductState } from '@constants';

export interface ContractNavigationResult {
  contracts: {
    items: [{
      state: ProductState;
      id: string;
      leaseItem: {
        id: string;
        licensePlate: string;
        make: string;
        model: string;
        color: {
          id: string;
          imageUrl: string;
        }
      };
    }]
  }
}

export interface ContractNavigationArgs {
  contractNumber: string;
}

const CONTRACT_NAVIGATION = gql`
  query ContractNavigation($contractNumber: String) {
    contracts(number: $contractNumber) {
      items {
        id
        state
        leaseItem {
          id
          licensePlate
          make
          model
          color {
            id
            name
            imageUrl
          }
        }
      }
    }
  }
`;

export default CONTRACT_NAVIGATION;
