import React, { Fragment } from 'react';
import { ProductGrid, ContractGridItem, PreDeliveryGridItem } from '@organisms/product';
import { useI18n } from '@hooks/i18n';
import { checkPreDeliveryState, formatLicense } from '@utils';
import { DashboardActions } from '@molecules/customerActions/types';
import { Contract } from '@queries/contract/contractList';

interface Props {
  addActions?: (actions: Array<DashboardActions>) => void;
  contracts: Array<Contract>;
}

const ContractsGridContainer: React.FC<Props> = ({
  contracts,
}) => {
  const { t } = useI18n();

  const preCarImages: Record<string, string> = {
    A: '/images/segment-a.svg',
    B: '/images/segment-b.svg',
    C: '/images/segment-c.svg',
  };

  return (
    <ProductGrid className="test-contract-grid">
      {contracts && contracts.map(({
        contractNumber,
        preDelivery,
        state,
        leaseItem: {
          make,
          model,
          licensePlate,
          recurringCosts,
          deliveryTime = -1,
          color: {
            imageUrl,
          },
        },
      }) => (
        <Fragment key={contractNumber}>
          <ContractGridItem
            state={state}
            contractNumber={contractNumber}
            link={`/contract/${contractNumber}`}
            title={`${make} ${model}`}
            subTitle={`${licensePlate ? `${formatLicense(licensePlate)} | ` : ''}${contractNumber}`}
            imageUrl={imageUrl}
            costs={recurringCosts}
            costLabel={t('month-price')}
            deliveryTime={deliveryTime as number}
            buttonText={t('contract-view')}
          />
          {checkPreDeliveryState((preDelivery?.state)) && preDelivery.car && (
            <PreDeliveryGridItem
              name={preDelivery.car.name}
              description={preDelivery.car.description}
              price={preDelivery.car.price}
              carImage={preCarImages[preDelivery.car.carClass]}
              priceLabel={t('predelivery-price')}
              segmentTitle={`Segment ${preDelivery.car.carClass}`}
              preDeliveryState={preDelivery.state}
            />
          )}
        </Fragment>
      ))}
    </ProductGrid>
  );
};

export const ContractsGrid = ContractsGridContainer;
