import styled, { css } from 'styled-components';
import { StyledComponentProps, Stages } from '@type';
import { LOGIN_STRENGTH } from '@constants';
import { getStageColor, getLengthPerStage } from '@utils';

interface Props extends StyledComponentProps{
  stage: Stages;
}

export const PasswordLengthIndicator = styled.div<Props>`
  height: 0.4rem;
  background-color: ${({ stage, theme }) => getStageColor(stage, theme)};
  position: absolute;
  bottom: 0;
  width: ${({ stage }) => getLengthPerStage(stage)};
  border-bottom-left-radius: 3px;
  ${({ stage }) => stage === LOGIN_STRENGTH.strong && css`
    border-bottom-right-radius: 3px;
  `}
  z-index: 2;
`;

export const IndicatorLabel = styled.div<Props>`
  font-size: 0.75rem;
  color: ${({ stage, theme }) => getStageColor(stage, theme)};
`;
