import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import { fetchData, getEnv } from '@utils';
import { reset } from '@store/reset';
import { LOCALSTORAGE_TOKEN_KEY } from '@constants/cookie';
import { AuthContext, initialState } from './context';
import { AuthResponse, ResponseData, User } from './types';

interface DispatchProps {
  resetStore: (...args: Array<any>) => any;
}

const storeToken = (token: string, expiration: number = 86400) => {
  Cookies.set(LOCALSTORAGE_TOKEN_KEY, token, {
    domain: getEnv('NEXT_PUBLIC_MYENV_AUTH_COOKIE_DOMAIN') as string,
    expires: expiration / 3600,
    sameSite: 'strict',
  });

  document.cookie = `${LOCALSTORAGE_TOKEN_KEY}=${token}`;
};

const deleteToken = () => {
  Cookies.remove(LOCALSTORAGE_TOKEN_KEY, {
    domain: getEnv('NEXT_PUBLIC_MYENV_AUTH_COOKIE_DOMAIN') as string,
  });
};

const Provider: React.FC<DispatchProps> = ({ children, resetStore }) => {
  const { push } = useRouter();
  const [token, setToken] = useState(Cookies.get(LOCALSTORAGE_TOKEN_KEY) || '');
  const [
    isAuthenticated,
    setIsAuthenticated,
  ] = useState(initialState.isAuthenticated || Boolean(token));
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(initialState.loading);
  const [passwordReset, setPasswordReset] = useState(false);
  const [impersonated, setImpersonated] = useState(false);
  const [lastName, setLastName] = useState('');
  const [initials, setInitials] = useState('');
  const [isDebtorChange, setIsDebtorChange] = useState(false);
  const [hasUserData, setHasUserData] = useState(false);
  const [credentials, setCredentials] = useState<{ username: string, password: string }>();
  const [hasApolloClient, setHasApolloClient] = useState<boolean>(false);

  const onRequestStart = () => {
    setLoading(true);
    setError(null);
  };

  const onRequestSuccess = async (responseData: ResponseData) => {
    setToken(responseData.access_token);
    storeToken(responseData.access_token, responseData.expires_in);
    setIsAuthenticated(true);
    setLoading(false);
  };

  const onRequestError = (err: string) => {
    setToken('');
    setIsAuthenticated(false);
    setLoading(false);
    setError(err);
  };

  const updateCredentials = ({
    username,
    password,
  }: Record<string, string>) => {
    setCredentials({ username, password });
  };

  const login = async ({
    username,
    password,
  }: Record<string, string>) => {
    onRequestStart();
    const body = {
      grant_type: 'password',
      client_id: getEnv('NEXT_PUBLIC_MYENV_AUTH_CLIENT_ID'),
      client_secret: getEnv('NEXT_PUBLIC_MYENV_AUTH_CLIENT_SECRET'),
      username,
      password,
    };

    let responseData: AuthResponse;
    try {
      responseData = await fetchData({
        endpoint: getEnv('NEXT_PUBLIC_MYENV_AUTH_URL', '') as string,
        method: 'POST',
        body: JSON.stringify(body),
      });
      await onRequestSuccess(responseData);
      return responseData.access_token;
    } catch (err: any) {
      onRequestError(err.errorCode);
    }
    return '';
  };

  const logout = async (toLogin: boolean = true) => {
    localStorage.setItem('apollo-cache-persist', '{}');
    setImpersonated(false);
    deleteToken();
    setToken('');
    setLastName('');
    setInitials('');
    setIsAuthenticated(false);
    setError(null);
    resetStore();
    if (toLogin) {
      push('/login');
    }
  };

  const impersonate = async (login_token: string) => {
    await logout(false);
    onRequestStart();
    const body = {
      login_token,
    };

    let responseData: AuthResponse;
    try {
      responseData = await fetchData({
        endpoint: getEnv('NEXT_PUBLIC_MYENV_IMPERSONATE_URL', '') as string,
        method: 'POST',
        body: JSON.stringify(body),
        noError: true,
      });
      if (responseData.access_token) {
        await onRequestSuccess(responseData);
        setImpersonated(true);
        return responseData.access_token;
      }
      onRequestError('Invalid token');
    } catch (err: any) {
      onRequestError(err.errorCode);
    }
    return '';
  };

  const resetPassword = async (email: string) => {
    onRequestStart();
    const body = {
      email,
    };

    try {
      await fetchData({
        endpoint: '/api/reset-password',
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setPasswordReset(true);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setError(err);
    }
  };

  const register = async (registerToken: string, email: string, password: string) => {
    onRequestStart();
    const body = {
      password,
    };

    try {
      const responseData = await fetchData({
        endpoint: `${getEnv('NEXT_PUBLIC_MYENV_REGISTER_URL')}/${registerToken}`,
        method: 'POST',
        body: JSON.stringify(body),
      });

      if (responseData) {
        updateCredentials({ username: email, password });
      }
    } catch (err: any) {
      onRequestError(err.errorCode);
    }
  };

  const setUser = async (user: User) => {
    setLastName(user.lastName || lastName);
    setInitials(user.initials || initials);
    setIsDebtorChange(user.isDebtorChange || isDebtorChange);
    setHasUserData(true);
  };

  return (
    <AuthContext.Provider value={{
      isAuthenticated,
      accessToken: token,
      loading,
      error,
      passwordReset,
      impersonated,
      lastName,
      initials,
      isDebtorChange,
      login,
      register,
      impersonate,
      logout,
      resetPassword,
      setUser,
      hasUserData,
      updateCredentials,
      credentials,
      setHasApolloClient,
      hasApolloClient,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const mapDispatchToProps = (dispatch: (...args: Array<any>) => any): DispatchProps => ({
  resetStore: () => dispatch(reset()),
});

export const AuthContextProvider = connect(null, mapDispatchToProps)(Provider);
