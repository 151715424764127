import React from 'react';
import {
  CardContent,
  CardHeader,
  Html,
  LinkButton,
} from '@atoms';
import { createSiteUrl } from '@utils';
import { useI18n } from '@hooks/i18n';
import { isCustomerServiceEnabled } from '@flags';
import ButtonBar from './components/buttonBar.styled';
import { Card } from '../card';

interface Props {
  fullWidth?: boolean;
}

const CustomerServiceContact: React.FC<Props> = ({
  fullWidth = false,
}) => {
  const {
    t,
    langCode,
  } = useI18n();

  const getContactLink = () => (isCustomerServiceEnabled() ? '/customer-service' : '/contact');

  const getFaqButton = () => {
    if (!isCustomerServiceEnabled()) {
      return (
        <LinkButton
          fullWidth
          outline
          target="_blank"
          to={`${createSiteUrl(langCode)}/faq`}
        >
          {t('quotes-contact-button-primary')}
        </LinkButton>
      );
    }
    return null;
  };

  return (
    <Card renderHeader={() => <CardHeader>{t('quotes-contact-title')}</CardHeader>}>
      <CardContent>
        <Html dangerouslySetInnerHTML={{ __html: t('quotes-contact-description', { type: 'html' }) }} />
        <ButtonBar fullWidth={fullWidth}>
          {getFaqButton()}
          <LinkButton fullWidth to={getContactLink()}>
            {t('quotes-contact-button-seconadary')}
          </LinkButton>
        </ButtonBar>
      </CardContent>
    </Card>
  );
};

export default CustomerServiceContact;
