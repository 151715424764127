import styled from 'styled-components';
import { StyledComponentProps } from '@type';

export const TableRow = styled.tr<StyledComponentProps>`
  height: 3.25rem;
  box-shadow: 0 calc(-1.25rem - 1px) 0 -1.25rem ${({ theme }) => theme.table.dividerColor};

  &:first-of-type {
    box-shadow: none;
  }
`;

export const TableHeaderRow = styled.tr`
  width: 100%;
  background-color: ${({ theme }) => theme.table.headerColor};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.table.headerTextColor};
  height: 2rem;
`;
