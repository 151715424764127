import React from 'react';
import { MessageType } from '@constants/messageStack';
import Info from './info.svg';
import Error from './error.svg';
import Warning from './warning.svg';
import Success from './success.svg';

export const Icons: Record<MessageType, React.Component> = {
  [MessageType.Info]: Info,
  [MessageType.Error]: Error,
  [MessageType.Warning]: Warning,
  [MessageType.Success]: Success,
};

interface Props {
  type: MessageType;
}

export const Icon: React.FC<Props> = ({ type }) => {
  const MessageIcon: React.Component = Icons[type];
  // @ts-ignore
  return <MessageIcon />;
};
