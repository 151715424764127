import React from 'react';
import { HeaderNavigationItem } from '@type/molecules';
import {
  Navigation,
  NavigationItem,
  NavigationLink,
  NavigationAction,
} from './components';

interface Props {
  menuItems: Array<HeaderNavigationItem>;
  onClick: (...args: Array<any>) => any;
  className: string;
}

const HeaderNavigationItems: React.FC<Props> = (({
  menuItems,
  onClick,
  className,
}) => (
  <Navigation onClick={onClick} className={className}>
    {menuItems && menuItems.map((item) => (
      <NavigationItem key={item.label}>
        {item.to && <NavigationLink href={item.to}>{item.label}</NavigationLink>}
        {item.callback && (
          <NavigationAction onClick={item.callback}>{item.label}</NavigationAction>
        )}
      </NavigationItem>
    ))}
  </Navigation>
));

export default HeaderNavigationItems;
