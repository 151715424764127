import React, { useState, useEffect } from 'react';
import { useFormData } from '@hooks/formData';
import { getEnv } from '@utils';
import { useI18n } from '@hooks/i18n';
import {
  UploadArea, UploadIcon, UploadText, RemoveImageButton,
} from '@atoms';
import { MessageType } from '@constants/messageStack';
import { useMessageStack } from '@hooks/messageStack';
import { UPLOAD_MIME_TYPES } from '@constants';
import { UploadTitle } from './imageUploadField.styled';

interface Props {
  title: string;
  url?: string | null;
  icon: string;
  fieldName: string;
  imageId: string;
  addImage: (...args: Array<any>) => any;
}

export const ImageUploadRHFField: React.FC<Props> = ({
  fieldName,
  imageId,
  addImage,
  title,
  icon,
  url = null,
}) => {
  const { t } = useI18n();
  const { infoMessage } = useMessageStack();
  const [dragOver, setDragOver] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | ArrayBuffer | null>(null);
  const { formData, setFormDataValue } = useFormData();

  useEffect(() => {
    const image = formData[fieldName] && formData[fieldName][imageId];
    if (image?.base64) {
      setCurrentImage(image);
    }
  }, [formData]);

  const removeImageClick = () => {
    const images = formData[fieldName];
    delete images[imageId];
    setFormDataValue(fieldName, images);
    setCurrentImage(null);
  };

  const handleChange = (evt: { target: HTMLInputElement }) => {
    let files = evt.target.files || [];
    const file = files[0];

    if (file?.size < Number(getEnv('NEXT_PUBLIC_MYENV_MAX_UPLOAD_SIZE'))) {
      const reader = new FileReader();
      reader.onload = () => {
        setCurrentImage(reader.result);
        addImage(reader.result, file);
      };
      reader.readAsDataURL(file);
    } else {
      infoMessage(t('image-upload-image-size-too-large', { replace: { '%size%': '2MB' } }), MessageType.Warning);
      files = new FileList();
    }
  };

  return (
    <article>
      <UploadTitle>
        {title}
      </UploadTitle>
      <UploadArea
        htmlFor={imageId}
        dragOver={dragOver}
        bgImage={(currentImage && (currentImage as any).base64) || (url && url)}
      >
        {!currentImage && (
        <>
          <UploadIcon icon={dragOver ? 'fileDrop' : icon} />
          <UploadText>{t('image-upload-add-image')}</UploadText>
          <input
            name="image"
            id={imageId}
            type="file"
            accept={UPLOAD_MIME_TYPES}
            onChange={handleChange}
            onDragEnter={() => setDragOver(true)}
            onDragLeave={() => setDragOver(false)}
            onDrop={() => setDragOver(false)}
          />
        </>
        )}
        {currentImage && (
        <RemoveImageButton onClick={removeImageClick}>
          {t('image-upload-remove-image')}
        </RemoveImageButton>
        )}
      </UploadArea>
    </article>
  );
};
