import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Toaster, Button, CardContent, CardActions, CardHeader,
} from '@atoms';
import { ChooseBank, PageLoader, Card } from '@molecules';
import { getEnv, postFormData } from '@utils';
import { useI18n } from '@hooks/i18n';
import { StyledComponentProps as StyledProps, TextColor } from '@type';
import { useAuthentication } from '@hooks/authentication';
import withAbortController, { AbortControllerProps } from '@hocs/withAbortController';
import { getStatus } from '@store/quoteFlow';
import {
  QuoteProgressRedirectTypes, REDIRECT_URI, QuoteProgressRedirectStatus,
} from '@constants/quoteProgress';
import { GlobalState } from '@store';

const InfoText = styled.p<StyledProps>`
  color: ${({ theme }) => theme.deposit.text.generalInfoColor};
  margin-bottom: 1rem;
`;

const ToasterContainer = styled.div`
  margin-top: 1rem;
`;

interface Props {
  quoteNumber: string;
}

const AuthorisationToDo: React.FC<Props & AbortControllerProps> = ({
  abortAll,
  getAbortSignal,
  quoteNumber,
}) => {
  const { t } = useI18n();
  const { accessToken } = useAuthentication();
  const [issuer, setIssuer] = useState('');
  const [issuers, setIssuers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const failed = useSelector((state: GlobalState) => (
    getStatus(
      state,
      quoteNumber,
      QuoteProgressRedirectTypes.Sepa,
    ) === QuoteProgressRedirectStatus.Failed
  ));

  const onChangeIssuer = (issuerChangeEvent: { target: HTMLInputElement }) => {
    setIssuer(issuerChangeEvent.target.value);
  };

  const getIssuers = async () => {
    try {
      const responseData: any = await postFormData({
        endpoint: `${getEnv('NEXT_PUBLIC_MYENV_LEADHUB_URL')}/api/sepa/issuers`,
        method: 'GET',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        // @ts-ignore
        signal: getAbortSignal('getIssuers'),
      });
      setIssuers(responseData.sepaDirectory);
    } catch (e) {
      console.warn('Error fetching issuers in authorisationTodo.js', e);
      setError(true);
    }
  };

  useEffect(() => {
    sessionStorage.setItem(
      REDIRECT_URI[QuoteProgressRedirectTypes.Sepa],
      document.location.pathname,
    );
    getIssuers();
    return () => {
      abortAll();
    };
  }, []);

  const makePayment = async () => {
    setLoading(true);
    const body: any = new FormData();
    body.append('issuer', issuer);
    const response: any = await postFormData({
      endpoint: `${getEnv('NEXT_PUBLIC_MYENV_LEADHUB_URL')}/api/sepa`,
      method: 'POST',
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
      body,
    });
    setLoading(false);
    window.location.href = response.redirectToUri;
  };

  if (error) {
    return (
      <Card renderHeader={() => <CardHeader>{t('authorisation-title')}</CardHeader>}>
        <CardContent>
          <Toaster color={TextColor.Error}>{t('authorisation-error-title')}</Toaster>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      {loading && <PageLoader />}
      <Card
        renderHeader={() => <CardHeader>{t('authorisation-title')}</CardHeader>}
        renderFooter={() => (
          <CardActions>
            <Button disabled={issuer === ''} onClick={makePayment} caret>
              {t('authorisation-sign')}
            </Button>
          </CardActions>
        )}
      >
        <CardContent>
          <InfoText>{t('authorisation-status-description')}</InfoText>
          <ChooseBank issuers={issuers} changeIssuer={(event) => onChangeIssuer(event)} />
          {failed && (
          <ToasterContainer>
            <Toaster color={TextColor.Error}>
              {t('authorisation-failed')}
            </Toaster>
          </ToasterContainer>
          )}
        </CardContent>
      </Card>
    </>
  );
};

export default withAbortController<Props>(AuthorisationToDo);
