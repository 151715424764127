import React, { useContext } from 'react';
import { DocumentNode } from 'graphql';
import { useMutation } from '@apollo/client';
import { ActionButton } from '@atoms';
import { ApolloContext } from '@context';
import { useI18n } from '@hooks/i18n';

interface Props {
  disabled?: boolean;
  mutation: DocumentNode;
  onError: (...args: Array<any>) => any;
  onCompleted: (...args: Array<any>) => any;
  mutationVariables: {
    quote: {
      quoteNumber?: string;
      extras?: Array<number>;
      extraBundle?: number;
      colorId?: number | null | undefined;
      packageId?: number | null | undefined;
    };
  };
  refetchQueries: Array<{
    query: DocumentNode;
    variables?: {
      [key: string]: any;
    };
  }>;
}

export const ChangeBundleButton: React.FC<Props> = ({
  disabled = false,
  mutation,
  mutationVariables,
  onCompleted,
  onError,
  refetchQueries,
}) => {
  const { t } = useI18n();
  const client = useContext(ApolloContext).leadHub;
  const [mutate, { loading }] = useMutation(mutation, {
    client,
    onCompleted,
    onError,
    variables: { ...mutationVariables },
    refetchQueries,
  });

  return (
    <ActionButton
      caret
      className="test-submit-change-button"
      loading={loading}
      disabled={disabled || loading}
      onClick={async () => {
        try {
          await mutate();
        } catch (error) {
          console.error(error);
          onError();
        }
      }}
    >
      {t('change-quote-bundle')}
    </ActionButton>
  );
};
