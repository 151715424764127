import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getUiSetting, setUiSetting } from '@store/ui';
import { useI18n } from '@hooks/i18n';
import {
  Divider, Button, Heading, CardActions, CardContent, PriceFooter,
} from '@atoms';
import { Card } from '@molecules';
import { optionMap } from '@molecules/changeItem';
import { OptionsResponse, ExtrasResponse } from '@type/graphql';
import { HeadingLevel } from '@type';
import { ChangeContractLine } from '@constants/changeQuoteContract';
import { translation } from './translations';
import { getSingleSelectPrice } from './helpers';

interface Props {
  availableOptions: Array<OptionsResponse>;
  availableOptionsTitle: string;
  currentOption: ExtrasResponse;
  currentOptionTitle: string;
  groupName: ChangeContractLine;
  isContract: boolean;
  itemNumber: string;
  itemPrice: number;
  renderSubmitButton: (activeSelection: string) => JSX.Element;
}

export const AddItemSingleSelect: React.FC<Props> = ({
  groupName,
  itemNumber,
  currentOption,
  availableOptions,
  availableOptionsTitle,
  currentOptionTitle,
  isContract,
  itemPrice,
  renderSubmitButton,
}) => {
  const { t } = useI18n();
  const { push, back } = useRouter();
  const dispatch = useDispatch();
  const groupId = `${groupName}-${itemNumber}`;
  const getTranslation = translation(t);
  const OptionComponent = optionMap.radio;
  const CurrentOptionComponent = optionMap.checkbox;
  const activeSelection = useSelector((state) => getUiSetting<string>(state, groupId));

  useEffect(() => {
    if (currentOption) {
      dispatch(setUiSetting(groupId, currentOption.id));
    }
  }, []);

  const setChosenOption = (optionId: string) => {
    dispatch(setUiSetting(groupId, optionId));
  };

  return (
    <>
      <Card renderFooter={() => (
        <>
          <PriceFooter
            price={
              getSingleSelectPrice(
                activeSelection,
                itemPrice,
                currentOption,
                availableOptions,
                groupName,
              )
            }
          />
          <CardActions>
            <Button
              outline
              onClick={() => {
                if (window.history.length) {
                  back();
                  return;
                }
                push(isContract ? `/contract/${itemNumber}` : `/quote/${itemNumber}`);
              }}
            >
              {t('cancel')}
            </Button>
            {renderSubmitButton(activeSelection)}
          </CardActions>
        </>
      )}
      >
        <CardContent>
          {currentOption && (
          <>
            <Heading level={HeadingLevel.H4}>{currentOptionTitle}</Heading>
            <CurrentOptionComponent
              name={currentOption.name}
              key={currentOption.id}
              groupId={`current-${groupId}`}
              onChange={() => {}}
              checked
              id={currentOption.id}
              price={currentOption.price}
              infoModal=""
            />
            <Divider />
          </>
          )}
          <Heading level={HeadingLevel.H4}>{availableOptionsTitle}</Heading>
          {availableOptions && availableOptions.length > 0
            ? availableOptions.map((option) => (
              <OptionComponent
                name={option.name}
                key={option.id}
                groupId={groupId}
                checked={activeSelection === option.id}
                id={option.id}
                onChange={() => setChosenOption(option.id)}
                price={option.price}
              />
            )) : (
              <span>{getTranslation(groupName)}</span>
            )}
        </CardContent>
      </Card>
    </>
  );
};
