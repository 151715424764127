import React from 'react';

import { CardComponent } from '@atoms';

interface Props {
  as?: React.ElementType;
  renderFooter?: () => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  className?: string;
}

export const Card: React.FC<Props> = ({
  as = 'article',
  children,
  renderFooter,
  renderHeader,
  className,
}) => (
  <CardComponent
    as={as}
    className={className}
    hasHeader={Boolean(renderHeader)}
    hasFooter={Boolean(renderFooter)}
  >
    {renderHeader && renderHeader()}
    {children}
    {renderFooter && renderFooter()}
  </CardComponent>
);
