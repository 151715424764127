import styled from 'styled-components';
import { Heading } from '@atoms';
import { HeadingLevel, StyledComponentProps as StyledProps, TextColor } from '@type';
import { ProductState } from '@constants';

export default styled.section`
  align-items: middle;
  display: flex;

  & + a {
    margin-top: 1rem;
  }
`;

interface TitleProps extends StyledProps {
  productState: ProductState;
}

export const Title = styled(Heading).attrs({
  level: HeadingLevel.H5,
  color: TextColor.Sub,
})<TitleProps>`
  color: ${({ theme, productState }) => theme.quote.documents.title.color[productState]};
  flex-grow: 1;
  padding-left: 1rem;
  align-self: center;
`;

export const Icon = styled.div<TitleProps>`
  color: ${({ theme, productState }) => theme.quote.documents.title.color[productState]};
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
`;

export const DocumentDescription = styled.section`
  font-size: 0.875rem;

  & + a {
    margin-top: 1rem;
  }
`;
