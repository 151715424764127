const padZero = (number: number): string => {
  if (number < 10) {
    return `0${number.toString()}`;
  }
  return number.toString();
};

export const isDateInThePast = (date: Date) => date < new Date();

export const isDateInTheFuture = (date: Date) => date > new Date();

export const subtractTime = (date: Date = new Date()): string => `${padZero(date.getHours())}:${padZero(date.getMinutes())}`;

export const subtractDate = (date: Date = new Date()): string => `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`;

export const dateYearsInPast = (years: number): string => {
  const today = new Date();
  return `${today.getFullYear() - years}-${padZero(today.getMonth() + 1)}-${padZero(today.getDate())}`;
};

export const getDuration = (start: Date, end: Date, seconds: boolean = false) => {
  const duration = new Date(end.getTime() - start.getTime());
  let result = `${padZero(duration.getUTCHours())}:${padZero(duration.getUTCMinutes())}`;
  if (seconds) {
    result += `:${padZero(duration.getUTCHours())}`;
  }

  return result;
};

export const getW3CTime = (date: Date = new Date()) => {
  if (date.getHours() === 0) {
    date.setHours(12);
  }
  const time = date.toISOString().split('.')[0];
  const timeZone = `${padZero(Math.abs(date.getTimezoneOffset()) / 60)}:00`;
  const direction = date.getTimezoneOffset() < 0 ? '+' : '-';

  return `${time}${direction}${timeZone}`;
};

export const dayDiff = (start: Date, end: Date) => {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const startMs = start.getTime();
  const endMs = end.getTime();

  const diffMs = Math.abs(startMs - endMs);

  return Math.round(diffMs / ONE_DAY);
};

export const getDateYear = (date: Date = new Date()): number => parseInt(date.toLocaleString('default', { year: 'numeric' }), 10);

export const getNbDaysMonth = (month: number, year: number) => new Date(year, month, 0).getDate();
