import { gql } from '@apollo/client';

export const GET_CUSTOMER_CONTACT_INFO_BL = gql`
  query UserProfile {
    me {
      zipCode
      address
      houseNumber
      houseNumberAddition
      city
      locale
      province
      phone
      phone2
      prospectNumber
      cocNumber
      companyName
      hasSignedContract
    }
  }
`;

const GET_CUSTOMER_CONTACT_INFO = gql`
  query UserProfile {
    me {
      zipCode
      address
      houseNumber
      houseNumberAddition
      city
      locale
      province
      phone
      phone2
      prospectNumber
    }
  }
`;

export default GET_CUSTOMER_CONTACT_INFO;
