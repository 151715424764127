import styled from 'styled-components';
import { StyledComponentProps as Props } from '@type';

export default styled.div<Props>`
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -3px;

  h2 {
    font-size: 40px;
    color: ${({ theme }) => theme.progressCircle.titleColor};
  }

  p {
    color: ${({ theme }) => theme.progressCircle.subtitleColor};
    font-size: 18px;
  }
`;
