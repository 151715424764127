import { ChangeContractLine } from '@constants/changeQuoteContract';
import { ExtrasResponse, OptionsResponse } from '@type/graphql';

const getSingleSelectPrice = (
  activeBundleId: string,
  quotePrice: number,
  currentOption: ExtrasResponse | null,
  options: Array<OptionsResponse>,
  groupName: ChangeContractLine,
): number => {
  const activeBundle = options && options.find((item) => item.id === activeBundleId);

  if (groupName === ChangeContractLine.Prolongation) {
    let price = quotePrice;

    if (activeBundle) {
      price += activeBundle.price || 0;
    }

    return price;
  }

  if (activeBundleId === '0' && currentOption) {
    return quotePrice - currentOption.price;
  }

  if (!activeBundle || (currentOption && activeBundleId === currentOption.id)) {
    return quotePrice;
  }
  // no initial selected option, so add the price in it's whole
  if (!currentOption) {
    return quotePrice + activeBundle.price;
  }
  // return the quoteprice minus the relative price of the package
  return quotePrice - (currentOption.price - activeBundle.price);
};

export default getSingleSelectPrice;
