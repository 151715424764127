import { State, Action } from '@store/nextButton/types';
import { SET_STATUS } from './constants';

const initialState: State = { shouldEnableButton: true };

const nextButton = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        shouldEnableButton: action.shouldEnableButton,
      };
    default:
      return state;
  }
};

export default nextButton;
