import React, { ComponentType } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { preferesReducedMotion } from '@utils';

const withTransition = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const TransitionComponent: React.FC<P> = (props) => {
    if (preferesReducedMotion()) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent className="page-enter-done" {...props as P} />;
    }

    return (
      <TransitionGroup component={null}>
        <CSSTransition
          onEnter={() => window.scrollTo(0, 0)}
          appear
          key={Math.random()}
          timeout={{ enter: 0, exit: 0 }}
          classNames="page"
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <WrappedComponent {...props as P} />
        </CSSTransition>
      </TransitionGroup>
    );
  };

  return TransitionComponent;
};

export default withTransition;
