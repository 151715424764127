import styled, { css } from 'styled-components';
import React from 'react';

interface Props {
  ordered?: boolean;
}

const sharedStyles = css`
  > li {
    margin: 0 0 1rem;

    &:last-child {
      margin: 0;
    }
  }
`;

export const OrderedList = styled.ol`
  ${sharedStyles}
`;

export const UnorderedList = styled.ul`
  ${sharedStyles}
`;

export const List: React.FC<Props> = ({
  ordered = false,
  children,
}) => (
  <>
    {!ordered && <UnorderedList>{children}</UnorderedList>}
    {ordered && <OrderedList>{children}</OrderedList>}
  </>
);
