import React from 'react';
import styled, { css } from 'styled-components';
import { FontWeight, StyledComponentProps as StyledProps, TextColor } from '@type';
import { SvgIcon, Span } from '@atoms';

interface Props {
  number?: string;
  title: string;
  description: string;
  icon?: boolean;
  dashed?: boolean;
}

interface ContainerProps extends StyledProps {
  dashed?: boolean;
}

const Container = styled.section<ContainerProps>`
  margin-bottom: 0.9rem;
  padding-left: 3rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0.775rem;
    top: 1.75rem;
    height: 100%;
    width: 0.2rem;
    background-color: ${({ theme }) => theme.quote.deliveryProcess.color};
  }

  ${({ dashed, theme }) => dashed && css`
    &::after {
      background-image: linear-gradient(to bottom, ${theme.quote.deliveryProcess.color} 50%, transparent 50%);
      background-size: 100% 1.25rem;
      background-color: transparent;
      margin-top: 0.1rem;
    }
  `};

  &:last-of-type {
    margin-bottom: 0;
  }

  &:last-of-type::after {
    display: none;
  }
`;

const DetailsContainer = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  flex: 1;

  > span {
    margin-bottom: 0.25rem;
  }
`;

const Icon = styled(SvgIcon)`
  color: ${({ theme }) => theme.quote.deliveryProcess.color};
  font-size: 1.75rem;

  rect {
    fill: white;
  }
`;

const Circle = styled.div<StyledProps>`
  background-color: ${({ theme }) => theme.quote.deliveryProcess.color};
  font-weight: bold;
  display: block;
  font-size: 0.875rem;
  position: absolute;
  left: 0;
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  line-height: 1.75;
  color: white;
`;

const CircleNumber = styled.div`
  font-size: 1.15rem;
  line-height: 1.72rem;
  text-align: center;
`;

const ProcessStep: React.FC<Props> = ({
  number = '',
  title,
  description,
  dashed = false,
  icon = false,
}) => (
  <Container dashed={dashed}>
    <Circle>
      <CircleNumber>{icon ? <Icon icon="circleCross" /> : number}</CircleNumber>
    </Circle>
    <DetailsContainer>
      <Span color={TextColor.Primary} fontWeight={FontWeight.SemiBold}>{title}</Span>
      <Span color={TextColor.Sub} fontSize="0.875rem">{description}</Span>
    </DetailsContainer>
  </Container>
);

export default ProcessStep;
