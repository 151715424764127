import styled, { css } from 'styled-components';
import { StyledComponentProps, TextColor, FontWeight } from '@type';

interface Props extends StyledComponentProps {
  color?: TextColor;
  fontWeight?: FontWeight;
  fontSize?: string;
}

export const Span = styled.span<Props>`
  color: ${({ color, theme }) => (color ? theme.span.color[color] : theme.span.color.default)};
  font-weight: ${({ fontWeight }) => (fontWeight || FontWeight.Normal)};
  ${({ fontSize }) => fontSize && css`
    font-size: ${fontSize};
  `}
`;
