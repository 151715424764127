import styled from 'styled-components';
import Modal from 'react-modal';
import zIndexMap from '@utils/zIndexMap';
import { StyledComponentProps, ModalSizes } from '@type';

interface Props extends StyledComponentProps {
  align: string;
  size: ModalSizes;
}

export const overlayStyle = {
  overlay: {
    backgroundColor: 'rgba(23, 23, 23, 0.5)',
    zIndex: zIndexMap.modal,
  },
};

export const ModalContainer = styled(Modal)<Props>`
  background-color: ${({ theme }) => theme.modal.backgroundColor};
  border-radius: ${({ theme }) => theme.modal.borderRadius};
  bottom: auto;
  left: 50%;
  max-width: 95vw;
  outline: none;
  padding: 0;
  position: absolute;
  right: auto;
  text-align: ${({ align }) => align};
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ theme, size }) => theme.modal.sizes[size]};
`;

export const ModalFlexWrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  /*
  Mediaquery targets only ie10 and ie11.
  This hack is needed because on ie the modal isnt scrolling correctly.
  Remove this if ie11 suport is droped.
  */
  @media all and (-ms-high-contrast: none) {
    overflow-y: auto;
  }

  padding-bottom: 2rem;
  flex-basis: auto;
`;
