import styled, { css } from 'styled-components';
import { StyledComponentProps as StyleProps } from '@type';

interface Props extends StyleProps {
  error: boolean;
}

const Button = styled.div<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.form.password.backgroundColor};
  border: 1px solid ${({ theme }) => theme.form.password.borderColor};
  border-radius: 0 ${({ theme }) => theme.form.password.borderRadius} ${({ theme }) => theme.form.password.borderRadius} 0;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 1.9rem;
  transform: translateY(-50%);
  transition: background-color 0.3s ease;
  width: 3rem;
  z-index: 1;

  div {
    height: 1.25rem;
    width: 1.25rem;
  }

  svg {
    fill: ${({ theme }) => theme.form.password.iconColor};
  }

  &:hover {
    background-color: ${({ theme }) => theme.form.password.backgroundColorHover};
  }

  &:focus,
  &:active {
    outline: none;
  }

  ${({ theme, error }) => error && css`
    border-radius: 0 ${theme.form.password.borderRadius} 0 0;
    border-bottom-color: ${theme.form.password.borderColorError};
  `}
`;

export default Button;
