import React from 'react';
import { useI18n } from '@hooks/i18n';
import { Button as ButtonComponent } from '@atoms/button';
import { Justify } from '@constants/theme';
import SvgIcon from '../../svgIcon';
import StyledFooter, { Button, StyledButtonBar } from './styled';

interface Props {
  onClose: (...args: Array<any>) => any | null | undefined;
  onConfirm?: (...args: Array<any>) => any | null | undefined;
  hasPassedValidation?: boolean;
}

export const ModalFooter: React.FC<Props> = ({
  onClose,
  onConfirm,
  hasPassedValidation,
}) => {
  const { t } = useI18n();

  const useDefaultFooter = onConfirm === undefined && onClose;

  return (
    <StyledFooter>
      {useDefaultFooter
        ? (
          <Button onClick={onClose}>
            {`${t('modal-close-button-text')}`}
            <SvgIcon icon="cross" />
          </Button>
        )
        : (
          <StyledButtonBar justify={Justify.FlexEnd}>
            <ButtonComponent
              onClick={onClose}
              outline
            >
              {`${t('modal-close-button-text')}`}
            </ButtonComponent>
            <ButtonComponent onClick={onConfirm} disabled={!hasPassedValidation}>
              {`${t('modal-confirm')}`}
            </ButtonComponent>
          </StyledButtonBar>
        )}
    </StyledFooter>
  );
};
