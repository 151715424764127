import { ChangeContractLine } from '@constants/changeQuoteContract';
import { GetTranslation } from '@hooks/i18n';

export const translation = (t: GetTranslation) => (key: ChangeContractLine) => {
  const translations = {
    [ChangeContractLine.Accessoires]: t('no-accessoires-available'),
    [ChangeContractLine.Claimfree]: t('no-claimfrees-available'),
    [ChangeContractLine.Color]: t('no-colors-available'),
    [ChangeContractLine.Duration]: t('no-durations-available'),
    [ChangeContractLine.ExtraBundle]: t('no-bundles-available'),
    [ChangeContractLine.Extras]: t('no-extras-available'),
    [ChangeContractLine.Mileage]: t('no-mileages-available'),
    [ChangeContractLine.Package]: t('no-packages-available'),
    [ChangeContractLine.Prolongation]: t('no-prolongations-available'),
  };

  return translations[key] || key;
};
