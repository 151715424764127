import { State, Action } from '@store/device/types';
import { breakpointsPx } from '@utils/breakpoints';
import { SET_DEVICE } from './constants';

const initialState: State = { isMobile: true };

const device = (state: State = initialState, action: Action) => {
  if (action.type === SET_DEVICE) {
    if (action.screenWidth < breakpointsPx.sm) {
      return {
        ...state,
        isMobile: true,
      };
    }
  }

  return { ...state, isMobile: false };
};

export default device;
