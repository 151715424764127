import flattenError, { FlatError } from '@utils/flattenError';
import { compose } from 'redux';
import { postFormData, getEnv } from '@utils';
import { rdwDataMapper } from '@utils/mappers';
import {
  CANCELING, CANCEL_SUCCESS, CANCEL_FAILED,
  GET_CREDIT_CHECK_FORM_METADATA, GET_CREDIT_CHECK_FORM_METADATA_FAIL,
  GET_CREDIT_CHECK_FORM_METADATA_SUCCESS, SORT_FIELD_DIRECTION,
  GET_RDW_DATA, GET_RDW_DATA_FAIL, GET_RDW_DATA_SUCCESS, CLEAR_RDW, SAVE_FLOW_TYPE,
  SET_PREORDER_DATA,
} from './constants';
import {
  CancelSuccess, CancelFailed, Canceling, SetPreorderData,
} from './types';

const canceling = (quoteNumber: string): Canceling => ({
  type: CANCELING,
  quoteNumber,
});

export const cancelSuccess = (quoteNumber: string): CancelSuccess => ({
  type: CANCEL_SUCCESS,
  quoteNumber,
});
export const getRDWData = (license: string) => async (dispatch: (...args: Array<any>) => any) => {
  const licensePlate = license.replace('-', '');
  const licenseEndpoint = `${getEnv('NEXT_PUBLIC_MYENV_RDW_URL')}${licensePlate}`;
  const fuelEndpoint = `${getEnv('NEXT_PUBLIC_MYENV_RDW_FUEL_URL')}${licensePlate}`;

  dispatch({ type: GET_RDW_DATA });

  try {
    const licenseResult = await fetch(licenseEndpoint);
    const fuelResult = await fetch(fuelEndpoint);
    const [licenseData] = await licenseResult.json();
    const [fuelData] = await fuelResult.json();
    return dispatch({
      type: GET_RDW_DATA_SUCCESS,
      payload: rdwDataMapper(licenseData, fuelData),
    });
  } catch (error) {
    return dispatch({
      type: GET_RDW_DATA_FAIL,
      payload: {
        data: {
          errors: {
            message: 'post-license-plate-error',
          },
        },
      },
    });
  }
};

export const clearRDW = () => (dispatch: (...args: Array<any>) => any) => dispatch({
  type: CLEAR_RDW,
});

export const getCreditCheckMetadata = (
  token: string,
  quoteId: number,
) => async (dispatch: (...args: Array<any>) => any) => {
  const endpoint = `${getEnv('NEXT_PUBLIC_MYENV_LEADHUB_URL')}/api/credit-check-form-meta-data/${quoteId}`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  dispatch({ type: GET_CREDIT_CHECK_FORM_METADATA });

  try {
    const response = await fetch(endpoint, {
      method: 'get',
      headers,
    });
    const data = await response.json();
    dispatch({
      type: GET_CREDIT_CHECK_FORM_METADATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CREDIT_CHECK_FORM_METADATA_FAIL,
      payload: error,
    });
  }
};

const cancelFailed = (
  quoteNumber: string,
) => (err: FlatError | null | undefined): CancelFailed => ({
  type: CANCEL_FAILED,
  quoteNumber,
  err,
});

export const sortAction = (
  field: string,
  direction: string,
) => (dispatch: (...args: Array<any>) => any) => dispatch({
  type: SORT_FIELD_DIRECTION,
  payload: {
    field,
    direction,
  },
});

const cancelQuote = (
  quoteNumber: string,
  uri: string,
  token: string,
) => async (dispatch: (...args: Array<any>) => any) => {
  dispatch(canceling(quoteNumber));

  const endpoint = `${getEnv('NEXT_PUBLIC_MYENV_UPLOAD_URL')}${uri}`;

  const headers = {
    authorization: `Bearer ${token}`,
  };

  try {
    await postFormData({
      endpoint,
      method: 'POST',
      headers,
    });
  } catch (error) {
    compose(dispatch, cancelFailed(quoteNumber), flattenError)(error);

    throw error;
  }

  dispatch(cancelSuccess(quoteNumber));
};

export const setQuoteFlowInStore = (
  quoteFlow:string,
) => (dispatch: (...args: Array<any>) => any) => {
  dispatch({
    type: SAVE_FLOW_TYPE,
    payload: quoteFlow,
  });
};

export const setPreorderData = (quoteNumber: string, isPreorder: boolean): SetPreorderData => ({
  type: SET_PREORDER_DATA,
  quoteNumber,
  isPreorder,
});

// @ts-ignore
export default cancelQuote;
