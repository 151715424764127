import { keyframes } from 'styled-components';

export const rotate = keyframes`
  0% {
    transform:
      rotate(0deg)
      translate(-50%, -50%);
  }

  100% {
    transform:
      rotate(360deg)
      translate(-50%, -50%);
  }
`;
