import React from 'react';
import {
  Html,
  CardImageHeader,
  CardContent,
  Heading,
} from '@atoms';
import { HeadingLevel } from '@type';
import { Card } from '../card';

interface ErrorCardType {
  title: string;
  content: string;
  renderFooter?: React.ReactNode;
}

const ErrorCard: React.FC<ErrorCardType> = ({
  title,
  content,
  renderFooter,
}) => (
  <Card
    renderHeader={() => <CardImageHeader src="/images/error.svg" alt={title} large />}
    renderFooter={renderFooter ? () => renderFooter : undefined}
  >
    <CardContent>
      <Heading level={HeadingLevel.H2}>{title}</Heading>
      <Html dangerouslySetInnerHTML={{ __html: content }} />
    </CardContent>
  </Card>
);

export default ErrorCard;
