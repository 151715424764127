import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { getUiSetting, setUiSetting } from '@store/ui';
import { useI18n } from '@hooks/i18n';
import {
  Divider, Heading, Button, CardActions, CardContent, PriceFooter,
} from '@atoms';
import { CheckBoxGroup } from '@molecules/changeItem';
import { PageHeader, Card } from '@molecules';
import { OptionsResponse } from '@type/graphql';
import { HeadingLevel } from '@type';
import { ChangeContractLine } from '@constants/changeQuoteContract';
import { translation } from './translations';
import { getMultipleSelectPrice } from './helpers';

interface SelectedOptions {
  [key: string]: boolean;
}

interface StateProps {
  getActiveSelection: (...args: Array<any>) => any;
  getActiveOptionIds: (...args: Array<any>) => any;
}

interface DispatchProps {
  setChosenOption: (...args: Array<any>) => any;
}

interface Props extends DispatchProps, StateProps {
  availableOptions: Array<OptionsResponse>;
  availableOptionsTitle: string;
  cardTitle: string;
  currentOptions: Array<OptionsResponse>;
  currentOptionsTitle: string;
  noCurrentOptionsText: string;
  groupName: ChangeContractLine;
  isQuote?: boolean;
  isContract?: boolean;
  itemNumber: string;
  itemPrice: number;
  renderSubmitButton: (...args: Array<any>) => any;
}

const getOptionState = (
  currentOptions: Array<OptionsResponse>,
  options: Array<OptionsResponse>,
) => ({
  ...options.reduce((acc, option) => ({
    ...acc,
    [option.id]: false,
  }), {}),
  ...currentOptions.reduce((acc, option) => ({
    ...acc,
    [option.id]: true,
  }), {}),
});

const ChangeItemMultipleSelect: React.FC<Props> = ({
  setChosenOption,
  groupName,
  itemNumber,
  currentOptions,
  availableOptions,
  availableOptionsTitle,
  cardTitle,
  currentOptionsTitle,
  noCurrentOptionsText,
  getActiveOptionIds,
  getActiveSelection,
  isQuote = false,
  isContract = false,
  itemPrice,
  renderSubmitButton,
}) => {
  const { t } = useI18n();
  const getTranslation = translation(t);
  const { push, back } = useRouter();
  const groupId = `${groupName}-${itemNumber}`;
  const activeSelection = getActiveSelection(groupId);
  const activeOptionIdsMultiSelect = getActiveOptionIds(groupId);

  useEffect(() => {
    setChosenOption(groupId, getOptionState(currentOptions, availableOptions));
  }, []);

  const setLocalChosenOption = (optionId: string) => {
    setChosenOption(groupId, {
      ...activeSelection,
      [optionId]: !activeSelection[optionId],
    });
  };

  return (
    <>
      {cardTitle && <PageHeader title={cardTitle} />}
      <Card renderFooter={() => (
        <>
          <PriceFooter
            price={getMultipleSelectPrice(
              activeOptionIdsMultiSelect,
              itemPrice,
              currentOptions,
              availableOptions,
            )}
          />
          <CardActions>
            <Button
              outline
              onClick={() => {
                if (window.history.length) {
                  back();
                  return;
                }
                push(!isQuote ? `/contract/${itemNumber}` : `/quote/${itemNumber}`);
              }}
            >
              {t('cancel')}
            </Button>
            {renderSubmitButton(activeOptionIdsMultiSelect)}
          </CardActions>
        </>
      )}
      >
        <CardContent>
          {currentOptions && currentOptions.length > 0
            ? (
              <CheckBoxGroup
                isQuote={isQuote}
                disabled={isContract || !isQuote}
                optionTitle={currentOptionsTitle}
                activeSelection={activeSelection}
                options={currentOptions}
                onChange={setLocalChosenOption}
                groupId={groupId}
              />
            )
            : (
              <>
                <Heading level={HeadingLevel.H4}>{currentOptionsTitle}</Heading>
                <span>{noCurrentOptionsText}</span>
              </>
            )}
          {availableOptions && availableOptions.length > 0
            ? (
              <CheckBoxGroup
                optionTitle={availableOptionsTitle}
                activeSelection={activeSelection}
                options={availableOptions}
                onChange={setLocalChosenOption}
                groupId={groupId}
                divider
              />
            ) : (
              <>
                <Divider />
                <Heading level={HeadingLevel.H4}>{availableOptionsTitle}</Heading>
                <span>{getTranslation(groupName)}</span>
              </>
            )}
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  getActiveSelection: (key: string) => getUiSetting(state, key),
  getActiveOptionIds: (key: string) => (
    Object.entries(getUiSetting(state, key))
      .filter(([, value]) => value === true)
      .map(([k]) => parseInt(k, 10))
  ),
});

const mapDispatchToProps = (dispatch: (...args: Array<any>) => any): DispatchProps => ({
  setChosenOption: (
    optionGroup: string,
    value: SelectedOptions,
  ) => dispatch(setUiSetting(optionGroup, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeItemMultipleSelect);
