import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import zIndexMap from '@utils/zIndexMap';
import { useI18n } from '@hooks/i18n';
import { LoadingAnimation, P } from '@atoms';

const ModalContainer = styled(Modal)`
  font-size: 1rem;
  font-weight: bold;
  background-color: white;
  border-radius: 0.5rem;
  bottom: auto;
  left: 50%;
  width: 15rem;
  height: 12rem;
  outline: none;
  padding: 0 1rem 1rem;
  position: absolute;
  right: auto;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const overlayStyle = {
  overlay: {
    backgroundColor: 'rgba(23, 23, 23, 0.5)',
    zIndex: zIndexMap.modal,
  },
};

export const PageLoader = () => {
  const { t } = useI18n();
  const appElement = (typeof document !== 'undefined') ? document.getElementById('modal-area') || undefined : undefined;

  return (
    <ModalContainer
      isOpen
      style={overlayStyle}
      appElement={appElement}
    >
      <LoadingAnimation />
      <P>
        {t('wait-message')}
      </P>
      <P>
        {t('loading-message')}
      </P>
    </ModalContainer>
  );
};
