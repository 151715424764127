import styled from 'styled-components';
import { StyledComponentProps } from '@type';

interface Props extends StyledComponentProps {
  color?: string;
}

export default styled.circle<Props>`
  stroke: ${({ theme, color }) => (color || theme.progressCircle.circle.borderColor)};
  stroke-width: 5;
  transition: all 1s ease;
`;
