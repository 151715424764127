import React from 'react';
import styled from 'styled-components';
import { StyledComponentProps, TextColor } from '@type';

interface Props {
  className?: string;
  color?: TextColor;
}

type StyledProps = Props & StyledComponentProps;

const ToasterContainer = styled.div<StyledProps>`
  background-color: ${({ theme, color }) => (color === TextColor.Error ? theme.toaster.error.backgroundColor : theme.toaster.default.backgroundColor)};
  border-radius: ${({ theme }) => theme.toaster.borderRadius};
  color: ${({ color, theme }) => (color === TextColor.Error ? theme.toaster.error.color : theme.toaster.default.color)};
  font-size: 0.875rem;
  padding: 0.75rem;
`;

const Toaster: React.FC<Props> = ({
  className,
  color = TextColor.Default,
  children,
}) => (
  <ToasterContainer className={className} color={color}>
    {children}
  </ToasterContainer>
);

export default Toaster;
