import { RECEIVED_UI_SETTINGS, SET_UI_SETTING, TOGGLE_UI_SETTING } from './constants';
import {
  UiSettings, SetUiSettingResult, ReceivedUiSettingsResult, ToggleUiSettingResult,
} from './types';

export type State = UiSettings;

export type ActionProps = SetUiSettingResult | ToggleUiSettingResult | ReceivedUiSettingsResult;

const reducers = ((state: State = {}, action: ActionProps): State => {
  switch (action.type) {
    case SET_UI_SETTING:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case RECEIVED_UI_SETTINGS:
      return {
        ...state,
        ...action.payload.settings,
      };
    case TOGGLE_UI_SETTING:
      return {
        ...state,
        [action.payload.key]: !state[action.payload.key],
      };

    default:
      return state;
  }
});

export default reducers;
