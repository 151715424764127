import React from 'react';
import {
  CardHeader, Price, Table, TableHeaderCell, TableHeaderRow, TableRow,
} from '@atoms';
import { Card } from '@molecules';
import { useI18n } from '@hooks/i18n';
import { InvoiceType } from '@queries/contract/getInvoices';
import { HeadingLevel } from '@type';
import { NoInvoiceCard } from '@molecules/noInvoiceCard';
import { getEnv } from '@utils';
import { PdfIcon } from './pdfIcon';
import { Cell } from './invoiceTable.styled';

const sortByDate = (invoiceA: InvoiceType, invoiceB: InvoiceType) => {
  const dateA = new Date(invoiceA.invoiceDate).getTime();
  const dateB = new Date(invoiceB.invoiceDate).getTime();
  return dateB - dateA;
};

interface Props {
  contractNumber: string;
  contract: {
    contractNumber: string;
    startDate: string;
    leaseItem: {
      make: string;
      model: string;
      licensePlate: string;
    };
    invoices: InvoiceType[];
  }
}

export const InvoiceTable: React.FC<Props> = ({
  contractNumber,
  contract,
}) => {
  const { t, dateFormat } = useI18n();
  const {
    startDate,
    invoices,
    leaseItem: {
      make,
      model,
      licensePlate,
    },
  } = contract;

  return (
    <>
      {(invoices && invoices.length < 1) && <NoInvoiceCard startDate={startDate} />}
      {(invoices && invoices.length > 0) && (
        <Card renderHeader={() => (
          <CardHeader level={HeadingLevel.H4}>
            {`${make} ${model} ${licensePlate ? `(${licensePlate})` : ''} - ${contractNumber}`}
          </CardHeader>
        )}
        >
          <Table
            head={(
              <TableHeaderRow>
                <TableHeaderCell>{t('invoice-table-date')}</TableHeaderCell>
                <TableHeaderCell>{t('invoice-table-invoicenumber')}</TableHeaderCell>
                <TableHeaderCell>&nbsp;</TableHeaderCell>
                <TableHeaderCell>&nbsp;</TableHeaderCell>
              </TableHeaderRow>
          )}
            body={(
              <>
                {[...invoices].sort(sortByDate).map(({
                  id,
                  invoiceDate,
                  invoiceNumber,
                  totalPriceIncl,
                  document: {
                    downloadUri,
                  },
                }) => (
                  <TableRow key={id}>
                    <Cell>
                      {dateFormat(new Date(invoiceDate), {
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                      })}
                    </Cell>
                    <Cell>{invoiceNumber}</Cell>
                    <Cell>
                      {totalPriceIncl && (
                      <Price
                        amount={totalPriceIncl / 100}
                        displayZeroes
                        decimalDash
                        displayCurrencySymbol
                      />
                      )}
                    </Cell>
                    <Cell><PdfIcon href={`${getEnv('NEXT_PUBLIC_MYENV_INVOICE_DOWNLOAD_URL')}${downloadUri}`} /></Cell>
                  </TableRow>
                ))}
              </>
          )}
          />
        </Card>
      )}
    </>
  );
};
