import { gql } from '@apollo/client';

const GET_CUSTOMER_INFO = gql`
query MeInfo {
  me {
    firstName
    initials
    lastName
    birthDate
    address
    houseNumber
    houseNumberAddition
    city
    zipCode
    province
    phone
    phone2
    email
  }
}
`;

export const GET_CUSTOMER_INFO_BL = gql`
  query MeInfo {
    me {
      firstName
      initials
      lastName
      birthDate
      address
      houseNumber
      houseNumberAddition
      city
      zipCode
      province
      phone
      phone2
      email
      cocNumber
      companyName
      hasSignedContract
    }
  }
`;

export interface CustomerInfoResponse {
  me: {
    firstName: string;
    initials: string;
    lastName: string;
    birthDate: string;
    address: string;
    houseNumber: string;
    houseNumberAddition: string;
    city: string;
    zipCode: string;
    province: string;
    phone: string;
    phone2: string;
    email: string;
  };
}

export default GET_CUSTOMER_INFO;
