import styled, { css } from 'styled-components';
import { media } from '@utils';
import { StyledComponentProps as StyledProps } from '@type';

interface Props extends StyledProps {
  active: boolean;
}

export const NavigationButton = styled.button<Props>`
  align-items: center;
  background-color: ${({ theme }) => theme.headerNavigation.backgroundColor.default};
  border: none;
  border-radius: 4px 4px 0 0;
  color: ${({ theme }) => theme.headerNavigation.color.default};
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
  transition: all 0.3s ease;

  ${({ active }) => css`
    ${media.sm(css`
      & > div {
        margin-right: 1rem;
      }

      ${active && css`
        box-shadow: none;
      `}
    `)}
  `}

  svg {
    fill: currentColor;
  }

  ${({ theme, active }) => (active && css`
    background-color: ${theme.headerNavigation.backgroundColor.active};
    box-shadow: 0 1rem 0 ${theme.headerNavigation.backgroundColor.active};
    color: ${theme.headerNavigation.color.active};
  `)}
`;

export const ButtonLabel = styled.span`
  display: none;
  ${media.sm(css`
    display: inline;
  `)};
`;
