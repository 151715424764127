import styled, { css } from 'styled-components';
import media from '@utils/media';
import InfoModal from '@molecules/modals/infoModal/infoModal.styled';

interface Props {
  hasUploads: boolean;
}

export default styled.header<Props>`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  padding: 0.5rem 0;

  ${
/* sc-sel */
  InfoModal} {
    flex-grow: 0;
    margin-left: 0.5rem;
  }

  ${({ hasUploads }) => !hasUploads && media.md(css`
    flex-grow: 1;
  `)}
`;
