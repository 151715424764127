import React from 'react';
import styled from 'styled-components';
import { PreDeliveryState as PreDeliveryStateEnum } from '@constants';
import { P } from '@atoms';
import { Card } from '@molecules';
import {
  Content, TitleContainer, Title, SubTitle, Price, FooterBase, Image, PreDeliveryState,
} from './components';

interface Props {
  name: string;
  description: string;
  price: number;
  carImage: string;
  priceLabel: string;
  segmentTitle: string;
  submitButtonText?: string;
  preDeliveryState?: PreDeliveryStateEnum;
  onClick?: () => void;
}

const StyledContent = styled(Content)`
  grid-template-rows: 10rem 8.5rem 1fr;
`;

const PreDeliveryGridItem: React.FC<Props> = ({
  name,
  description,
  price,
  onClick,
  priceLabel,
  submitButtonText,
  segmentTitle,
  carImage,
  preDeliveryState,
}) => (
  <Card as="li">
    <StyledContent>
      <Image backgroundImage={carImage} />
      <TitleContainer>
        <Title>{segmentTitle}</Title>
        <P>{name}</P>
        <SubTitle>{description}</SubTitle>
      </TitleContainer>
      <FooterBase>
        <Price label={priceLabel} price={price} />
        <PreDeliveryState
          onClick={onClick}
          submitButtonText={submitButtonText}
          preDeliveryState={preDeliveryState}
        />
      </FooterBase>
    </StyledContent>
  </Card>
);

PreDeliveryGridItem.defaultProps = {
  onClick: () => {},
  submitButtonText: '',
  preDeliveryState: PreDeliveryStateEnum.Requested,
};

export default PreDeliveryGridItem;
