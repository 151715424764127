import React, { useEffect, useState } from 'react';
import { useI18n } from '@hooks/i18n';
import { StyleShowButton, StylePanel } from './panel.styled';

export interface Props {
  className?: string;
  content: { summary: string, hiddenContent: string, title?: string };
}

const Panel: React.FC<Props> = ({
  className,
  content,
}) => {
  const { t } = useI18n();
  const showMore = t('show-more');
  const showLess = t('show-less');
  const [showFullContent, setShowFullContent] = useState(false);
  const [actionText, setActionText] = useState(showMore);
  const getClassname = `panel${className ? ` ${className}` : ''}`;

  useEffect(() => {
    if (showFullContent) {
      setActionText(showLess);
    } else {
      setActionText(showMore);
    }
  }, [showFullContent]);

  const handleClickShowButton = () => setShowFullContent(!showFullContent);
  return (
    <StylePanel className={getClassname}>
      {content.title ? <h4>{content.title}</h4> : null}
      {content.summary}
      {showFullContent && <p dangerouslySetInnerHTML={{ __html: content.hiddenContent }} />}
      {content.hiddenContent
        && (
        <StyleShowButton
          role="button"
          onClick={handleClickShowButton}
          onKeyDown={handleClickShowButton}
          tabIndex={0}
        >
          {actionText}
        </StyleShowButton>
        )}
    </StylePanel>
  );
};

export default Panel;
