import { FetchError } from '../fetchData';

export type FlatError = {
  error: boolean;
  name: string;
  message: string;
  stack: string | undefined;
  string: string;
  meta?: Record<string, any>;
  status?: number;
};

const flattenError = ((err: Error | FetchError): FlatError => {
  let error = {
    error: true,
    name: err.name,
    message: err.message,
    stack: err.stack,
    string: err.toString(),
  };

  // fix me because:
  // extending the Error object doesnt work well in babel yet
  // so we cannot use instanceof
  // checking on the name, still doesnt branch that it's not an Error
  // so flow still thinks this could be an Error, which doesn't have status and meta
  // I see no method to branch into FetchError properly right now.
  if (err.name === 'FetchError') {
    error = {
      ...error,
      // @ts-ignore
      status: err.status,
      // @ts-ignore
      meta: err.meta,
    };
  }

  return error;
});

export default flattenError;
