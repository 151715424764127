import { getEnv } from '@utils/getEnv';
import { isMultiLanguage } from '@flags';
import languageMap from '../languageMap';

const createSiteUrl = ((activeLanguage: Intl.UnicodeBCP47LocaleIdentifier): string => {
  const isActiveLanguageDefaultLanguage = (
    activeLanguage === getEnv('NEXT_PUBLIC_MYENV_DEFAULT_LANGUAGE')
  );

  if (isMultiLanguage() && !isActiveLanguageDefaultLanguage) {
    return `${getEnv('NEXT_PUBLIC_MYENV_SITE_URL')}/${languageMap[activeLanguage]}`;
  }
  return getEnv('NEXT_PUBLIC_MYENV_SITE_URL') as string;
});

export default createSiteUrl;
