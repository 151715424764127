import { css, FlattenSimpleInterpolation } from 'styled-components';
import breakpoints, { Breakpoints, breakpointKeys } from '../breakpoints';

const isMobileDeviceRegEx = /^[a-z]{2}Down$/;

type Media = Record<
keyof Breakpoints,
(style: FlattenSimpleInterpolation
) => FlattenSimpleInterpolation>;

// Iterate through the sizes and create a media template
const media = breakpointKeys
  .reduce((acc: Media, label: keyof Breakpoints) => {
    if (isMobileDeviceRegEx.test(label)) {
      acc[label] = (str) => css`
      @media (max-width: ${breakpoints[label]}em) {
        ${str}
      }
    `;
    } else {
      acc[label] = (str) => css`
      @media (min-width: ${breakpoints[label]}em) {
        ${str}
      }
    `;
    }

    return acc;
  }, {} as Media);

export default media;
