import React from 'react';
import Status from './status';

interface Props {
  leaseNumber: string;
  progress: number;
}

const PendingProspect: React.FC<Props> = ({
  leaseNumber,
  progress,
}) => <Status leaseNumber={leaseNumber} progress={progress} />;

export default PendingProspect;
