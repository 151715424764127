import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { DateFieldBase } from './dateField.base';

interface Props {
  control: Control<Record<string, any>>;
  label: string;
  name: string;
  error: any;
  disabled?: boolean;
  min?: string | null;
  max?: string | null;
  rules?: any;
  defaultValue?: string;
}

export const DateRHFControlledField: React.FC<Props> = ({
  name,
  rules = {},
  control,
  min = null,
  max = null,
  label,
  error,
  disabled = false,
  defaultValue = '',
}) => (
  <Controller
    as={DateFieldBase}
    name={name}
    rules={rules}
    control={control}
    min={min}
    max={max}
    label={label}
    error={error}
    disabled={disabled}
    defaultValue={defaultValue}
  />
);
