import React from 'react';
import styled, { css } from 'styled-components';
import media from '@utils/media';
import { StyledComponentProps as StyledProps } from '@type';

interface Props {
  listStyle?: boolean;
}

const Wrapper = styled.div<StyledProps>`
  color: ${({ theme }) => theme.keyValue.value.color};
  font-size: 1em;
  grid-area: 2 / 1 / 3 / 4;
  ${media.md(css`
    grid-area: 1 / 2 / 2 / 3;
    padding-left: 0.5rem;
  `)}
`;

const List = styled.ul.attrs((props: Props) => ({
  listStyle: props.listStyle || false,
}))<Props>`
  list-style: none;
  margin: 0;
  padding-left: ${({ listStyle }) => (listStyle ? '0' : '1rem')};
`;

const ListItem = styled.li.attrs((props: Props) => ({
  listStyle: props.listStyle || false,
}))<Props>`
  margin-bottom: 0.5rem;

  &::before {
    background: ${({ theme }) => theme.keyValue.value.bulletColor};
    border-radius: 50%;
    content: '';
    display: ${({ listStyle }) => (listStyle ? 'none' : 'inline-block')};
    height: 0.5rem;
    margin: 0 0.5rem 0.125rem -1rem;
    width: 0.5rem;
  }
`;
export const Value: React.FC<Props> = ({
  children,
  listStyle,
}) => {
  if (!Array.isArray(children)) {
    return <Wrapper>{children}</Wrapper>;
  }
  return (
    <Wrapper>
      <List listStyle={listStyle}>
        {children && children.map((value) => (
          <ListItem
            key={Math.random()}
            listStyle={listStyle}
          >
            {value}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
